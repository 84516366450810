import {
  BudgetedItem,
  BudgetedItemsDto,
  BudgetedItemToUpdateDto,
  GetBudgetDto,
} from "./../types/budget";
import { sum } from "./../utils/math.utils";
import { get, post } from "../lib/axios";
import { budgetedItemsToDto, MyBudget } from "../types/budget";
import { isDateInMonth, monthFromString } from "../utils/date.utils";
import { getJwt } from "../utils/storage.utils";

export function formatMyBudget(
  { budget, transactions: allTransactions }: GetBudgetDto,
  stringMonth?: string
): MyBudget {
  const budgetedIncomes = sum(
    budget.filter((x) => x.type === "INCOME").map((x) => x.amount)
  );
  const budgetedExpenses = sum(
    budget.filter((x) => x.type === "EXPENSE").map((x) => x.amount)
  );

  const month = monthFromString(stringMonth);

  const transactions = allTransactions.filter((t) =>
    isDateInMonth(new Date(t.date), month)
  );

  const incomeTransactions = transactions.filter(
    (t) => t.value && t.category !== "INTERNAL_TRANSFER" && t.value >= 0
  );

  const expenseTransactions = transactions.filter(
    (t) => t.value && t.category !== "INTERNAL_TRANSFER" && t.value < 0
  );

  const realIncomes = Math.round(sum(incomeTransactions.map((t) => t.value!)));
  const realExpenses = Math.round(
    sum(expenseTransactions.map((t) => t.value!))
  );

  const capacity = budgetedIncomes - budgetedExpenses;
  const incomesDifference = realIncomes - budgetedIncomes;
  const expensesDifference = budgetedExpenses + realExpenses;
  const budgetDifference = incomesDifference + expensesDifference;
  const realTotal = realIncomes + realExpenses;

  return {
    realIncomes,
    budgetedIncomes,
    incomesDifference,
    realExpenses,
    budgetedExpenses,
    expensesDifference,
    realTotal,
    capacity,
    budgetDifference,
    items: budgetedItemsToDto(budget) as BudgetedItemsDto,
    transactions,
  };
}

export const getMyBudget = () => {
  return get<GetBudgetDto>("/budget/getMyBudget");
};

export const updateBudget = (data: BudgetedItemToUpdateDto) => {
  return post<BudgetedItem>("/budget/updateBudget", data);
};

export const exportBudget = (month: string) => {
  return post("/budget/exportBudget", { month, jwt: getJwt() }, { responseType: 'blob' });
};