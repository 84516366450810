import { format, isToday, setDefaultOptions } from "date-fns";
import { fr } from "date-fns/locale";
import { Currency } from "../types/patrimony.enums";

setDefaultOptions({ locale: fr });

export const round = (x: number, decimals = 2) => {
  const factor = Math.pow(10, decimals);
  return Math.round(x * factor) / factor;
};

export const roundPercentage = (x: number) => round(x, 4);

export const NO_BREAK_SPACE = "\xa0";

export const currency = (
  x: number,
  currency: Currency = "EUR",
  maximumFractionDigits = 0
) => {
  const format = (oldBrowser?: true) =>
    x
      .toLocaleString(undefined, {
        style: "currency",
        currency,
        currencyDisplay: oldBrowser ? "symbol" : "narrowSymbol",
        minimumFractionDigits: 0,
        maximumFractionDigits,
      })
      // removes narrow no break spaces
      .replace(/\u202f/g, NO_BREAK_SPACE)
      // removes negative zeros
      .replace(/-0(?![.,])/, "0");

  try {
    return format();
  } catch (e) {}

  return format(true);
};

export const formatTransaction = (
  value: number | undefined,
  accountCurrency: Currency | undefined,
  spaces = true
) =>
  value
    ? currency(value, accountCurrency, 2)
    : `-${spaces ? NO_BREAK_SPACE.repeat(4) : ""}`;

export const ownedValue = (totalValue?: number, ownershipPercentage?: number) =>
  Math.round((totalValue || 0) * (ownershipPercentage || 0));

export const formatUpdatedAt = (updatedAt: string, hasError: boolean) => {
  const prefix = hasError ? "Dernière mise" : "Mis";
  const date = new Date(updatedAt);
  const stringFormat = isToday(date) ? "'à' HH:mm" : "'le' dd/MM 'à' HH:mm";
  const dateText = format(date, stringFormat);

  return `${prefix} à jour ${dateText}`;
};

export function capitalizeFirstLetter(string: string) {
  return string.replace(/^./, (s) => s.toUpperCase());
}

export function formatNumberPeople(n: number): string {
  if (n >= 1_000_000) {
    const num = +(n / 1_000_000).toPrecision(2);
    const s = num !== 1 ? "s" : "";
    return `${num} million${s}`;
  }
  return n.toLocaleString("fr").replace(/\u202f/g, NO_BREAK_SPACE);
}

export function percentage(n: number, decimals?: number): string {
  return `${round(n * 100, decimals)}${NO_BREAK_SPACE}%`;
}

export const spacify = (num: number) => {
    var str = num.toString().includes('.') ? num.toString().split('.') : num.toString().split(',');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
    }
    if (str[1] && str[1].length >= 4) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join(',');
}

export const onFormattedNumericInputChange = (e: React.ChangeEvent<HTMLInputElement>, setFormatted: any, handleChange: any) => {
    if(!e.target.value) {
        setFormatted('');
        return handleChange(e);
    }
    if (!e.target.value.charAt(e.target.value.length - 1).match(/\d|[,]|[.]/)) return;
    if (e.target.value.endsWith(',') || e.target.value.endsWith('.')) return setFormatted(e.target.value);

    const [integer, decimals] = e.target.value.replace(/\s/g, '').replace(',', '.').split('.');
    if (decimals?.length > 2) return;
    e.target.value = integer + (decimals ? '.' + decimals : '')
    handleChange(e);
    setFormatted(spacify(parseInt(integer)) + (decimals ? ',' + decimals : ''));
}