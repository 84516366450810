/*  CGU et CGV :            https://docs.google.com/document/d/1Cuk4r7uMa-98cFldjnIJxL_N6Y5YIgvy
    code HTML généré sur    https://wordtohtml.net/
    code JSX généré sur     https://transform.tools/html-to-jsx
    remplacer les :
<p>
    <br />
</p>
    par des <br /> (ctrl+h)
*/

import logo from "../../assets/logo.svg";
import { Body } from "./Cgu";

const MentionsLegales = () => {
  return (
    <Body>
      <div style={{ height: "20px" }} />
      <img src={logo} alt="" height={28} />
      <h3>Mentions Légales</h3>

      <h4>Éditeur</h4>
      <p>
        Ce site est édité par la société Arpagon SAS au capital social de 100 €,
        dont le siège social est situé au 22, rue Gay-Lussac 75005 Paris et
        immatriculée au RCS Paris sous le numéro 913 818 423.&nbsp;
      </p>
      <p>
        Pour toute question vous pouvez nous contacter directement en ligne ou
        par email :{" "}
        <a data-fr-linked="true" href="mailto:contact@arpagon.fr">
          contact@arpagon.fr
        </a>
      </p>

      <br />
      <h4>Hébergement</h4>
      <p>
        Le site arpagon.fr est hébergé sur Amazon Web Services (AWS) dans
        l'Union européenne (Paris, France).
      </p>
      <p>
        L'hébergeur du site est la société Amazon Web Services EMEA SARL dont le
        siège social est situé au 38 AV JOHN F KENNEDY L 1855 99137 LUXEMBOURG
      </p>
      <p>Téléphone : +352 27 89 00 00.</p>

      <br />
      <h4>Directeur de la publication</h4>
      <p>
        Le directeur de la publication est Emmanuel Cresseveur, Président de
        Arpagon SAS.
      </p>
    </Body>
  );
};

export default MentionsLegales;
