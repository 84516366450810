import { useRef, useState, useCallback, useEffect } from "react";
import { ObjectEntries, ObjectKeys } from "../utils/utils";

type El = HTMLDivElement | HTMLTableSectionElement;

export const useScroll = <Section extends string>(
  defaultSection: Section,
  isReady: boolean
) => {
  const refs = useRef({} as Record<Section, El>);

  const [optionsToScrollTo, setOptionsToScrollTo] = useState<Section[]>([]);
  const prevOptionsToScrollToLength = useRef(0);

  const scrollTo = useCallback(
    (id: Section) => {
      const current = refs.current[id];
      if (current) {
        setOptionsToScrollTo((prev) => [...prev, id]);
        setTimeout(() => {
          setOptionsToScrollTo((prev) => prev.slice(1));
        }, 1000);
      }
    },
    [refs]
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const [scrolledOption, setScrolledOption] = useState<Section>(defaultSection);

  const handleScroll = useCallback(() => {
    if (!optionsToScrollTo.length && containerRef.current) {
      const containerTop = containerRef.current.getBoundingClientRect().y;
      const sectionsTop = ObjectKeys(refs.current).reduce(
        (acc, section) => ({
          ...acc,
          [section]: refs.current[section].getBoundingClientRect().y ?? -1000,
        }),
        {} as Record<Section, number>
      );

      const optionId = ObjectEntries(sectionsTop)
        .reverse()
        .find(([, sectionTop]) => containerTop >= sectionTop)?.[0];

      if (optionId && optionId !== scrolledOption) {
        setScrolledOption(optionId);
      }
    }
  }, [optionsToScrollTo, scrolledOption, refs]);

  useEffect(() => {
    if (prevOptionsToScrollToLength.current && !optionsToScrollTo.length) {
      handleScroll();
    }

    if (optionsToScrollTo.length > prevOptionsToScrollToLength.current) {
      const id = optionsToScrollTo[optionsToScrollTo.length - 1]!;
      refs.current[id]!.scrollIntoView({ behavior: "smooth" });
      setScrolledOption(id);
    }

    prevOptionsToScrollToLength.current = optionsToScrollTo.length;
  }, [optionsToScrollTo, handleScroll, refs]);

  useEffect(() => {
    if (!isReady) return;

    const current = containerRef.current;
    current?.addEventListener("scroll", handleScroll, { passive: true });
    return () => current?.removeEventListener("scroll", handleScroll);
  }, [scrolledOption, refs, isReady, handleScroll]);

  return {
    scrollTo,
    scrolledOption,
    containerRef,
    refCallback: (id: Section) => (el: any) => (refs.current[id] = el),
    refs,
  };
};
