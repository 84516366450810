const SmartphoneIcon = ({ big }: { big?: boolean }) => (
  <svg
    width={big ? 48 : 24}
    height={big ? 48 : 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.2" cx="12" cy="12" r="12" fill="#6A8BFF" />
    <g>
      <path
        d="M14.5 4H9.5C8.96957 4 8.46086 4.21071 8.08579 4.58579C7.71071 4.96086 7.5 5.46957 7.5 6V18C7.5 18.5304 7.71071 19.0391 8.08579 19.4142C8.46086 19.7893 8.96957 20 9.5 20H14.5C15.0304 20 15.5391 19.7893 15.9142 19.4142C16.2893 19.0391 16.5 18.5304 16.5 18V6C16.5 5.46957 16.2893 4.96086 15.9142 4.58579C15.5391 4.21071 15.0304 4 14.5 4V4ZM15.5 18C15.5 18.2652 15.3946 18.5196 15.2071 18.7071C15.0196 18.8946 14.7652 19 14.5 19H9.5C9.23478 19 8.98043 18.8946 8.79289 18.7071C8.60536 18.5196 8.5 18.2652 8.5 18V6C8.5 5.73478 8.60536 5.48043 8.79289 5.29289C8.98043 5.10536 9.23478 5 9.5 5H9.85469C9.90304 4.99982 9.95039 5.01378 9.99092 5.04015C10.0314 5.06652 10.0634 5.10416 10.0828 5.14844C10.1605 5.32717 10.2886 5.47932 10.4516 5.58619C10.6145 5.69306 10.8051 5.75 11 5.75H13C13.1949 5.75 13.3855 5.69306 13.5484 5.58619C13.7114 5.47932 13.8395 5.32717 13.9172 5.14844C13.9366 5.10416 13.9686 5.06652 14.0091 5.04015C14.0496 5.01378 14.097 4.99982 14.1453 5H14.5C14.7652 5 15.0196 5.10536 15.2071 5.29289C15.3946 5.48043 15.5 5.73478 15.5 6V18Z"
        fill="#6A8BFF"
      />
      <path
        d="M14.5 5.5C14.4424 5.49965 14.3855 5.51271 14.3338 5.53814C14.2821 5.56358 14.2371 5.6007 14.2022 5.64656C14.0626 5.83378 13.8812 5.98583 13.6725 6.09059C13.4638 6.19536 13.2335 6.24994 13 6.25H11C10.7665 6.24994 10.5362 6.19536 10.3275 6.09059C10.1188 5.98583 9.93739 5.83378 9.79781 5.64656C9.76295 5.6007 9.71789 5.56358 9.6662 5.53814C9.61451 5.51271 9.55761 5.49965 9.5 5.5C9.36739 5.5 9.24021 5.55268 9.14645 5.64645C9.05268 5.74021 9 5.86739 9 6V18C9 18.1326 9.05268 18.2598 9.14645 18.3536C9.24021 18.4473 9.36739 18.5 9.5 18.5H14.5C14.6326 18.5 14.7598 18.4473 14.8536 18.3536C14.9473 18.2598 15 18.1326 15 18V6C15 5.86739 14.9473 5.74021 14.8536 5.64645C14.7598 5.55268 14.6326 5.5 14.5 5.5Z"
        fill="#6A8BFF"
      />
      <path
        d="M14.5 4H9.5C8.96957 4 8.46086 4.21071 8.08579 4.58579C7.71071 4.96086 7.5 5.46957 7.5 6V18C7.5 18.5304 7.71071 19.0391 8.08579 19.4142C8.46086 19.7893 8.96957 20 9.5 20H14.5C15.0304 20 15.5391 19.7893 15.9142 19.4142C16.2893 19.0391 16.5 18.5304 16.5 18V6C16.5 5.46957 16.2893 4.96086 15.9142 4.58579C15.5391 4.21071 15.0304 4 14.5 4V4ZM15.5 18C15.5 18.2652 15.3946 18.5196 15.2071 18.7071C15.0196 18.8946 14.7652 19 14.5 19H9.5C9.23478 19 8.98043 18.8946 8.79289 18.7071C8.60536 18.5196 8.5 18.2652 8.5 18V6C8.5 5.73478 8.60536 5.48043 8.79289 5.29289C8.98043 5.10536 9.23478 5 9.5 5H9.85469C9.90304 4.99982 9.95039 5.01378 9.99092 5.04015C10.0314 5.06652 10.0634 5.10416 10.0828 5.14844C10.1605 5.32717 10.2886 5.47932 10.4516 5.58619C10.6145 5.69306 10.8051 5.75 11 5.75H13C13.1949 5.75 13.3855 5.69306 13.5484 5.58619C13.7114 5.47932 13.8395 5.32717 13.9172 5.14844C13.9366 5.10416 13.9686 5.06652 14.0091 5.04015C14.0496 5.01378 14.097 4.99982 14.1453 5H14.5C14.7652 5 15.0196 5.10536 15.2071 5.29289C15.3946 5.48043 15.5 5.73478 15.5 6V18Z"
        fill="#6A8BFF"
      />
    </g>
    <defs></defs>
  </svg>
);

export default SmartphoneIcon;
