import styled from "styled-components";
import { currency } from "../../utils/format.utils";
import { Swiper, SwiperProps, SwiperRef, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "../../lib/swiper.scss";

const barMaxHeight = 200;
const topFaceOrientedHeight = (15 / 229) * barMaxHeight;
const barOrientedMaxHeight = barMaxHeight + topFaceOrientedHeight;
const svgHeight = barOrientedMaxHeight + 24;

const barWidth = (57 / 229) * barMaxHeight;
const rightFaceOrientedWidth = (19 / 57) * barWidth;
const barOrientedWidth = barWidth + rightFaceOrientedWidth;

type ContainerProps = { mobileBreakPoint: number };

const DesktopBody = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  gap: 24px;
  .bar {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    font-family: "Inter";
  }

  @media (max-width: ${(p) => p.mobileBreakPoint}px) {
    display: none;
  }
`;

const StyledSwiper = styled<
  React.FunctionComponent<
    React.RefAttributes<SwiperRef> & SwiperProps & ContainerProps
  >
>(Swiper).withConfig({
  shouldForwardProp: (propName) => propName !== "mobileBreakPoint",
})<ContainerProps>`
  @media (min-width: ${(p) => p.mobileBreakPoint + 1}px) {
    display: none !important;
  }
`;

type DataElt = {
  id: string;
  label: string;
  value: number;
  incomplete?: boolean;
  colors: [string, string, string];
};

const Bar = ({ x, maxValue }: { x: DataElt; maxValue: number }) => {
  let ratio = x.value / (maxValue || 1);
  if (ratio < 0.05) ratio = 0.05;
  const barHeight = barMaxHeight * ratio;
  const barOrientedHeight = barHeight + topFaceOrientedHeight;
  const facePoints = `0,0 ${barWidth},0 ${barWidth},${barHeight} 0,${barHeight}`;
  const rightfacePoints = `${barWidth},0 ${barOrientedWidth},${topFaceOrientedHeight} ${barOrientedWidth},${barOrientedHeight} ${barWidth},${barHeight}`;
  const topfacePoints = `0,${barHeight} ${barWidth},${barHeight} ${barOrientedWidth},${barOrientedHeight} ${rightFaceOrientedWidth},${barOrientedHeight}`;

  return (
    <div className="bar" key={x.id}>
      <svg
        viewBox={`0 0 ${barOrientedWidth} ${svgHeight}`}
        xmlns="http://www.w3.org/2000/svg"
        width={barOrientedWidth}
        height={svgHeight}
        style={{ overflow: "visible" }}
      >
        <g transform={`translate(0, ${svgHeight}) scale(1, -1)`}>
          <text
            transform={`translate(${barOrientedWidth / 2}, ${
              barOrientedHeight + 12
            }) scale(1, -1)`}
            textAnchor="middle"
            fontSize={14}
            fontWeight={600}
            children={currency(x.value) + (x.incomplete ? " (Incomplet)" : "")}
          />
          <polygon points={facePoints} fill={x.colors[0]} />
          <polygon points={rightfacePoints} fill={x.colors[1]} />
          <polygon points={topfacePoints} fill={x.colors[2]} />
        </g>
      </svg>
      <div>{x.label}</div>
    </div>
  );
};

export type BarChartProps = {
  data: DataElt[];
  mobileBreakPoint?: number;
};
const BarChart = ({ data, mobileBreakPoint = 600 }: BarChartProps) => {
  const maxValue = Math.max(...data.map((x) => x.value));

  const bars = data.map((x) => <Bar key={x.id} x={x} maxValue={maxValue} />);

  return (
    <>
      <DesktopBody mobileBreakPoint={mobileBreakPoint}>{bars}</DesktopBody>

      <StyledSwiper
        mobileBreakPoint={mobileBreakPoint}
        navigation
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}
      >
        {bars.map((x) => (
          <SwiperSlide key={x.key}>{x}</SwiperSlide>
        ))}
      </StyledSwiper>
    </>
  );
};

export default BarChart;
