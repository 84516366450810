import ReactSwitch from "react-switch";

type Props = {
  id: string;
  checked: boolean;
  onChange: () => {};
  checkedIcon?: boolean;
  uncheckedIcon?: boolean;
};

const ToggleSwitch = (props: Props) => (
  <ReactSwitch
    className="react-switch"
    onChange={props.onChange}
    checked={props.checked}
    checkedIcon={false}
    uncheckedIcon={false}
    onColor="#FFE32B"
    offColor="#9e9e9e"
    height={16}
    width={32}
    handleDiameter={20}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
  />
);

export default ToggleSwitch;
