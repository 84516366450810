import Loader from "../Loader";

const Icon = ({ color, className }: { color: string, className: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className={className}>
    <path fill={color} d="M17 12v5H3v-5H1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5z"/>
    <path fill={color} d="M10 15l5-6h-4V1H9v8H5l5 6z"/>
  </svg>
);

const DownloadIcon = ({ isLoading }: { isLoading?: boolean }) => isLoading ? (
  <>
    <div className="desktop-only"><Loader color="#000000" /></div>
    <div className="mobile-only"><Loader color="#FFFFFF" /></div>
  </>
) : (
  (
    <>
      <Icon color="#000000" className="desktop-only" />
      <Icon color="#FFFFFF" className="mobile-only" />
    </>
  )
)

export default DownloadIcon;