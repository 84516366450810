const HandCoinsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9167 3.35648C15.418 3.40284 16.9173 3.21532 18.3609 2.80065C18.4015 2.78637 18.4366 2.75988 18.4615 2.72481C18.4864 2.68975 18.4999 2.64783 18.5 2.60482V2.52148C18.4998 2.41107 18.4557 2.30527 18.3775 2.22732C18.28 2.12982 17.3242 1.27148 13.9167 1.27148C10.5092 1.27148 9.55254 2.12982 9.45504 2.22732C9.37715 2.30541 9.3334 2.41119 9.33337 2.52148V2.61648C9.33335 2.66009 9.34701 2.7026 9.37242 2.73803C9.39784 2.77347 9.43373 2.80003 9.47504 2.81398C10.9203 3.21492 12.4174 3.39777 13.9167 3.35648Z"
      fill="#FFE32B"
    />
    <path
      d="M13.9167 7.52349C15.4179 7.56985 16.9173 7.38233 18.3609 6.96765C18.4014 6.95337 18.4366 6.92688 18.4615 6.89182C18.4864 6.85675 18.4999 6.81484 18.5 6.77182V3.91682C18.4999 3.8845 18.4923 3.85265 18.4779 3.82374C18.4634 3.79483 18.4425 3.76965 18.4167 3.75015C18.391 3.73076 18.3611 3.71765 18.3294 3.71186C18.2977 3.70607 18.2651 3.70777 18.2342 3.71682C16.8181 4.04086 15.3693 4.19997 13.9167 4.19099C12.4638 4.20004 11.0147 4.04092 9.59835 3.71682C9.56743 3.70784 9.53485 3.70617 9.50317 3.71196C9.47149 3.71774 9.4416 3.73082 9.41585 3.75015C9.39017 3.76974 9.3693 3.79494 9.35486 3.82382C9.34041 3.85271 9.33277 3.88452 9.33252 3.91682V6.78515C9.33249 6.82876 9.34615 6.87127 9.37157 6.9067C9.39698 6.94214 9.43287 6.9687 9.47419 6.98265C10.9198 7.38311 12.4172 7.5654 13.9167 7.52349Z"
      fill="#FFE32B"
    />
    <path
      d="M13.9167 8.35643C12.4638 8.36548 11.0147 8.20637 9.59835 7.88226C9.56743 7.87328 9.53485 7.87162 9.50317 7.8774C9.47149 7.88319 9.4416 7.89626 9.41585 7.9156C9.39017 7.93518 9.3693 7.96038 9.35486 7.98927C9.34041 8.01816 9.33277 8.04997 9.33252 8.08227V10.4398C9.33252 11.5681 12.5375 11.6898 13.9159 11.6898C15.2942 11.6898 18.4992 11.5681 18.4992 10.4398V8.0831C18.4991 8.05078 18.4915 8.01892 18.477 7.99002C18.4626 7.96111 18.4416 7.93593 18.4159 7.91643C18.3901 7.89704 18.3602 7.88392 18.3286 7.87814C18.2969 7.87235 18.2643 7.87405 18.2334 7.8831C16.8175 8.20679 15.369 8.36562 13.9167 8.35643Z"
      fill="#FFE32B"
    />
    <path
      d="M17.2542 13.7723H17.245L14.3109 14.7498C14.2826 14.7596 14.2566 14.7753 14.2348 14.7958C14.213 14.8163 14.1957 14.8413 14.1842 14.8689C14.0533 15.1623 13.8405 15.4115 13.5712 15.5867C13.302 15.7619 12.9879 15.8556 12.6667 15.8564H9.33338C9.22287 15.8564 9.11689 15.8125 9.03875 15.7344C8.96061 15.6563 8.91671 15.5503 8.91671 15.4398C8.91671 15.3293 8.96061 15.2233 9.03875 15.1452C9.11689 15.067 9.22287 15.0231 9.33338 15.0231H12.6667C12.8877 15.0231 13.0997 14.9353 13.256 14.779C13.4122 14.6228 13.5 14.4108 13.5 14.1898C13.5 13.9688 13.4122 13.7568 13.256 13.6005C13.0997 13.4442 12.8877 13.3564 12.6667 13.3564H9.75005C8.79297 12.6086 7.62953 12.1723 6.41671 12.1064H4.70338C4.18581 12.1066 3.67538 12.2273 3.21254 12.4589L0.698378 13.7156C0.663753 13.733 0.63465 13.7596 0.614336 13.7926C0.594022 13.8256 0.583302 13.8636 0.583378 13.9023V18.3973C0.583154 18.4345 0.592951 18.4711 0.611743 18.5033C0.630535 18.5354 0.657629 18.5619 0.690184 18.58C0.722739 18.598 0.759555 18.607 0.796772 18.6059C0.833989 18.6049 0.870236 18.5938 0.901711 18.5739L3.58338 16.8989C3.68905 16.8336 3.80779 16.7923 3.9312 16.7779C4.0546 16.7635 4.17967 16.7764 4.29754 16.8156C12.6009 19.6139 9.82171 19.6264 19.1817 14.8914C19.2458 14.8579 19.3005 14.8089 19.3409 14.7488C19.3812 14.6887 19.4059 14.6195 19.4127 14.5475C19.4195 14.4755 19.4082 14.4029 19.3798 14.3363C19.3513 14.2698 19.3068 14.2114 19.25 14.1664C18.9797 13.934 18.6538 13.7755 18.3041 13.7064C17.9543 13.6373 17.5926 13.66 17.2542 13.7723Z"
      fill="#FFE32B"
    />
  </svg>
);

export default HandCoinsIcon;
