import styled from "styled-components";
import Chip from "../../components/Chip";
import { useEffect, useState } from "react";
import { Advisor } from "../../types/advisor.enums";
import { useQuery } from "@tanstack/react-query";
import { getByExpertise } from "../../services/advisor.service";
import CardAdvisor from "./CardAdvisor";
import { getAllExpertise } from "../../services/expertise.service";
import { Expertise } from "../../types/expertise";

const StyledFilterExpertise = styled.div`
  width: 100%;
  p {
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .chip-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 1rem;
  }
  .advisor-group {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
`;

const FilterExpertise = () => {
  const [activesChip, setActivesChip] = useState<Expertise[]>([]);
  const [advisors, setAdvisors] = useState<Advisor[]>([]);
  const { data: expertises } = useQuery<Expertise[]>(
    ["expertises"],
    getAllExpertise
  );

  const handleChipClick = (expertise: Expertise) => {
    if (activesChip.includes(expertise)) {
      setActivesChip(activesChip.filter((chip) => chip !== expertise));
    } else {
      setActivesChip([...activesChip, expertise]);
    }
  };

  const { data, isSuccess } = useQuery(["advisors", activesChip], () =>
    getByExpertise(activesChip)
  );

  useEffect(() => {
    if (isSuccess) {
      setAdvisors(data);
    }
  }, [data, isSuccess]);

  return (
    <StyledFilterExpertise>
      <div>
        <p>FILTRER PAR EXPERTISE :</p>
        <div className="chip-group">
          {expertises?.map((expertise) => (
            <Chip
              key={"Chip-" + expertise.label}
              label={expertise.label}
              active={activesChip.includes(expertise)}
              onClick={() => handleChipClick(expertise)}
              clickable={true}
            />
          ))}
        </div>
      </div>
      <div className="advisor-group">
        {advisors.map((advisor) => (
          <CardAdvisor key={"CardAdvisor-" + advisor.id} advisor={advisor} />
        ))}
      </div>
    </StyledFilterExpertise>
  );
};

export default FilterExpertise;
