/**
 * Calculate the number of months from the current date until the specified date.
 *
 * @param maturityDate
 *
 * @returns The number of months from now to the target date.
 */
export const calcRemainingMaturities = (maturityDate: string): number => {
  // Parse the target date
  const target = new Date(maturityDate);

  // Check that it was a valid date
  if (typeof target === "undefined" || isNaN(target.getTime())) {
    throw Error("Invalid date.");
  }

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in months
  const monthsDifference = (target.getFullYear() - currentDate.getFullYear()) * 12 +
    (target.getMonth() - currentDate.getMonth());

  // Include the current month in the count
  return Math.max(monthsDifference + 1, 0);
}

