const HomeKeyIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M9.27167 12.7153C9.81228 12.1725 10.4712 11.7621 11.1968 11.5164C11.9224 11.2706 12.695 11.1961 13.4542 11.2986C13.5157 11.3069 13.5783 11.3013 13.6375 11.2823C13.6966 11.2633 13.7507 11.2313 13.7958 11.1886L16.7725 8.37696C17.0805 8.08218 17.4903 7.91743 17.9167 7.91696C17.9924 7.91676 18.0664 7.89387 18.129 7.85125C18.1916 7.80863 18.24 7.74823 18.268 7.67784C18.296 7.60745 18.3022 7.5303 18.2859 7.45632C18.2697 7.38235 18.2316 7.31494 18.1767 7.2628L10.8583 0.341963C10.6264 0.123269 10.3196 0.00146484 10.0008 0.00146484C9.68204 0.00146484 9.3753 0.123269 9.14334 0.341963L0.130005 8.86446C0.0694705 8.9218 0.0275318 8.99599 0.00961312 9.07742C-0.00830558 9.15885 -0.00138001 9.24379 0.0294942 9.32125C0.0603683 9.3987 0.113771 9.46511 0.182795 9.51189C0.25182 9.55866 0.333291 9.58366 0.416672 9.58363H2.08334C2.19385 9.58363 2.29983 9.62753 2.37797 9.70567C2.45611 9.78381 2.50001 9.88979 2.50001 10.0003V18.3336C2.50001 18.5546 2.5878 18.7666 2.74408 18.9229C2.90036 19.0792 3.11233 19.167 3.33334 19.167H8.02084C8.09254 19.167 8.16302 19.1485 8.22548 19.1133C8.28794 19.0781 8.34026 19.0274 8.37738 18.966C8.4145 18.9047 8.43516 18.8348 8.43738 18.7632C8.43959 18.6915 8.42327 18.6205 8.39001 18.557C7.8957 17.6163 7.71465 16.5423 7.87324 15.4916C8.03182 14.4409 8.52176 13.4682 9.27167 12.7153Z"
        fill="#FFE32B"
      />
      <path
        d="M19.5833 9.16675H17.9166C17.8104 9.1667 17.7082 9.20724 17.6308 9.28008L14.1858 12.5301C14.1338 12.5793 14.07 12.6143 14.0005 12.6317C13.931 12.649 13.8582 12.6482 13.7891 12.6292C12.9921 12.4124 12.1457 12.4648 11.3815 12.7783C10.6173 13.0918 9.97795 13.6488 9.56277 14.3629C9.1476 15.077 8.97983 15.9082 9.08551 16.7275C9.19119 17.5467 9.5644 18.3081 10.1472 18.8935C10.73 19.4789 11.4898 19.8555 12.3085 19.9648C13.1273 20.074 13.9592 19.9099 14.6752 19.4979C15.3911 19.0859 15.951 18.449 16.2678 17.6862C16.5847 16.9234 16.6408 16.0773 16.4275 15.2792C16.4082 15.2084 16.408 15.1337 16.4268 15.0627C16.4457 14.9917 16.483 14.927 16.535 14.8751L19.8775 11.5417C19.9551 11.4644 19.9991 11.3596 20 11.2501V9.58341C20 9.47291 19.9561 9.36693 19.8779 9.28879C19.7998 9.21065 19.6938 9.16675 19.5833 9.16675ZM13.9658 17.1534C13.7619 17.3574 13.502 17.4962 13.2191 17.5525C12.9362 17.6087 12.643 17.5799 12.3765 17.4695C12.1101 17.3591 11.8823 17.1722 11.7221 16.9324C11.5619 16.6925 11.4763 16.4106 11.4763 16.1222C11.4763 15.8337 11.5619 15.5518 11.7221 15.312C11.8823 15.0722 12.1101 14.8852 12.3765 14.7749C12.643 14.6645 12.9362 14.6356 13.2191 14.6918C13.502 14.7481 13.7619 14.887 13.9658 15.0909C14.1018 15.2261 14.2097 15.3869 14.2833 15.5639C14.3569 15.741 14.3948 15.9308 14.3948 16.1226C14.3948 16.3143 14.3569 16.5042 14.2833 16.6812C14.2097 16.8583 14.1018 17.019 13.9658 17.1542V17.1534Z"
        fill="#FFE32B"
      />
    </g>
  </svg>
);

export default HomeKeyIcon;
