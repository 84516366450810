import { MyPatrimony } from "../../../types/patrimony";
import { MyBudget } from "../../../types/budget";
import { User } from "../../../types/user";
import { AddEmptyStringToProps, ObjectEntries } from "../../../utils/utils";
import CircleCheckedIcon from "../../../components/icons/CircleCheckedIcon";
import CircleWavedIcon from "../../../components/icons/CircleWavedIcon";
import Link from "../../../components/Link";
import { css } from "styled-components";
import CircleCrossIcon from "../../../components/icons/CircleCrossIcon";

export type UpdateUserDto = Partial<
  AddEmptyStringToProps<
    Pick<
      User,
      | "purchasePrice"
      | "purchaseFees"
      | "borrowingAmount"
      | "borrowingRate"
      | "borrowingInsuranceRate"
      | "incomeTaxRate"
      | "landIncomesConsiderationRate"
      | "landIncomesConsiderationRateAfter"
      | "landIncomesAfterBorrowing"
      | "rentAfterBorrowing"
      | "futureYears"
    >
  > &
    Pick<User, "borrowingDuration">
>;

export const BorrowingDurations = {
  7: "7 ans",
  10: "10 ans",
  15: "15 ans",
  20: "20 ans",
  25: "25 ans",
} as const;

export const DefaultBorrowingRates = {
  7: { borrowingRate: 0.02 },
  10: { borrowingRate: 0.022 },
  15: { borrowingRate: 0.024 },
  20: { borrowingRate: 0.025 },
  25: { borrowingRate: 0.028 },
} as const;

export function turnEmptyStringsToZeros(changedUser: UpdateUserDto) {
  return ObjectEntries(changedUser).reduce<Partial<User>>(
    (acc, [k, v]) => ({ ...acc, [k]: v === "" ? 0 : v }),
    {}
  );
}

export function getFigures(
  user: User,
  budget: MyBudget,
  myPatrimony: MyPatrimony
) {
  const mensualiteHorsAssurance = PMT(
    user.borrowingRate / 12,
    user.borrowingDuration,
    user.borrowingAmount
  );
  const mensualite =
    mensualiteHorsAssurance +
    (user.borrowingInsuranceRate * user.borrowingAmount) / 12;
  const coutCredit = mensualite * user.borrowingDuration - user.borrowingAmount;

  const revenusApresImpots =
    budget.budgetedIncomes - budget.items.REAL_ESTATE.amount;
  const revenusNetsAvantImpot = revenusApresImpots / (1 - user.incomeTaxRate);

  const totalRevenusActuel = revenusNetsAvantImpot + budget.items.REAL_ESTATE.amount * user.landIncomesConsiderationRate;
  const totalRevenusFutur = revenusNetsAvantImpot + user.landIncomesAfterBorrowing * user.landIncomesConsiderationRateAfter;

  const totalChargesActuel =
    budget.items.RENTS.amount + budget.items.CREDITS_PAYMENTS.amount;
  const totalChargesFutur =
    user.rentAfterBorrowing + budget.items.CREDITS_PAYMENTS.amount + mensualite;

  const ratioEndettementActuel = totalChargesActuel / (totalRevenusActuel || 1);
  const ratioEndettementFutur = totalChargesFutur / (totalRevenusFutur || 1);

  const resteAVivreActuel = totalRevenusActuel - totalChargesActuel;
  const resteAVivreFutur = totalRevenusFutur - totalChargesFutur;

  const totalCreditsActuel = -myPatrimony.sections.CREDITS.total;
  const totalCreditsFutur =
    -myPatrimony.sections.CREDITS.total + user.borrowingAmount;

  const montantAFinancer = user.purchasePrice + user.purchaseFees;
  const apportPersonnel = Math.max(montantAFinancer - user.borrowingAmount, 0);
  const pourcentageApportPersonnel = apportPersonnel / (montantAFinancer || 1);

  const patrimoineBrutActuel = myPatrimony.totalGross;
  const patrimoineBrutFutur = patrimoineBrutActuel + user.purchasePrice - apportPersonnel;

  const ratioDetteBrutActuel = totalCreditsActuel / (patrimoineBrutActuel || 1);
  const ratioDetteBrutFutur = totalCreditsFutur / (patrimoineBrutFutur || 1);

  // Si l'utilisateur est marié ou pacsé
  const numberOfAdults = ["MARRIED", "PACSED"].includes(user.maritalStatus) ? 2 : 1;

  // Nous voulons que le ratio d'enttement soit KO quand >35%
  const ratioEndettementMax = 0.35;
  const resteAVivreMin = 1000 * numberOfAdults + 500 * user.numberOfChildren;
  const ratioDetteBrutMax = 0.5;

  // Vérification de l'état du ratio d'entemment
  const healthRatioEndettementActuel =
    ratioEndettementActuel < ratioEndettementMax ? "OK" : "KO";
  const healthRatioEndettementFutur =
    ratioEndettementFutur < ratioEndettementMax ? "OK" : "KO";

  const healthResteAVivreActuel =
    resteAVivreActuel > resteAVivreMin ? "OK" : "KO";
  const healthResteAVivreFutur =
    resteAVivreFutur > resteAVivreMin ? "OK" : "KO";

  const healthRatioDetteBrutActuel =
    ratioDetteBrutActuel < ratioDetteBrutMax ? "OK" : "KO";
  const healthRatioDetteBrutFutur =
    ratioDetteBrutFutur < ratioDetteBrutMax ? "OK" : "KO";

  const risqueActuel = getRisk(
    healthRatioEndettementActuel,
    healthResteAVivreActuel,
    healthRatioDetteBrutActuel
  );
  const risqueFutur = getRisk(
    healthRatioEndettementFutur,
    healthResteAVivreFutur,
    healthRatioDetteBrutFutur
  );

  return {
    mensualite,
    coutCredit,
    healthRatioEndettementActuel,
    healthRatioEndettementFutur,
    healthResteAVivreActuel,
    healthResteAVivreFutur,
    healthRatioDetteBrutActuel,
    healthRatioDetteBrutFutur,
    numberOfAdults,
    montantAFinancer,
    apportPersonnel,
    pourcentageApportPersonnel,
    revenusApresImpots,
    revenusNetsAvantImpot,
    totalRevenusActuel,
    totalRevenusFutur,
    totalChargesActuel,
    totalChargesFutur,
    ratioEndettementActuel,
    ratioEndettementFutur,
    resteAVivreActuel,
    resteAVivreFutur,
    totalCreditsActuel,
    totalCreditsFutur,
    patrimoineBrutActuel,
    patrimoineBrutFutur,
    ratioDetteBrutActuel,
    ratioDetteBrutFutur,
    risqueActuel,
    risqueFutur,
  } as const;
}

export const HealthIcons = {
  OK: <CircleCheckedIcon />,
  KO: <CircleCrossIcon />,
} as const;
type Health = keyof typeof HealthIcons;

function getRisk(
  healthRatioEndettement: Health,
  healthResteAVivre: Health,
  healthRatioDetteBrut: Health
) {
  if (
    [healthRatioEndettement, healthResteAVivre, healthRatioDetteBrut].every(
      (x) => x === "OK"
    )
  ) {
    return "FAIBLE";
  }
  if (healthResteAVivre === "OK") return "MOYEN";
  return "ÉLEVÉ";
}

/**
 * Calcul des mensualités d'un emprunt
 * https://stackoverflow.com/a/22385930
 * @param ir   - interest rate per month
 * @param np   - number of periods (months)
 * @param pv   - present value
 * @param fv   - future value
 * @param type - when the payments are due:
 *        0: end of the period, e.g. end of month
 *        1: beginning of period (default)
 */
function PMT(ir: number, np: number, pv: number, fv = 0, type = 1) {
  if (ir === 0) return -(pv + fv) / np;

  const pvif = Math.pow(1 + ir, np);
  let pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type) pmt /= 1 + ir;

  return -pmt;
}

export const WeUseDataFromText = ({ budget }: { budget?: true }) => (
  <>
    Nous utilisons les données de{" "}
    <Link to="/patrimoine" underLine children="Patrimoine" />{" "}
    {budget && (
      <>
        et de <Link to="/budget" underLine children="Budget" />{" "}
      </>
    )}
    que vous avez renseignées.
  </>
);

export const EmbeddedSelect = css`
  select {
    background-color: #fff4aa !important;
    height: 2.4em;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
    border: none !important;
    outline: none !important;
    padding-right: 2em;
    option {
      background-color: white;
    }
  }
`;

export const EmbeddedInput = css`
  width: 75px;
  input {
    background-color: #fff4aa !important;
    height: 2.4em;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
    border: none !important;
    outline: none !important;
    text-align: end;
    border-radius: 2px;
    padding-left: 0.5em;
  }
`;
