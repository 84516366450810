import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import styled, { css } from "styled-components";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../lib/swiper.scss";

import { queryClient } from "../../lib/react-query";

import LogoAlternative from "../../assets/LogoAlternative";

import Loader from "../../components/Loader";
import NavPills from "../../components/NavPills";
import DownloadIcon from "../../components/icons/DownloadIcon";
import DownloadButton from "../../components/DownloadButton";

import { downloadPDF } from "../../utils/export.utils";
import { ObjectEntries, ObjectKeys } from "../../utils/utils";
import { onSuccessUpdateUser } from "../../utils/auth.utils";
import { ONE_DAY, ONE_HOUR } from "../../utils/time.utils";
import {
  getPatrimonyScroll,
  setPatrimonyScroll,
} from "../../utils/storage.utils";

import {
  getMyPatrimony,
  getConnections,
  syncBiAccounts,
  exportPatrimony,
} from "../../services/patrimony.service";
import { updateBiAuthToken } from "../../services/user.service";

import { IncomeType } from "../../types/patrimony";
import {
  PatrimonySection as PatrimonySectionType,
  PatrimonySections,
} from "../../types/patrimony.enums";

import Account from "./Account";
import CompletePatrimony, {
  CompletePatrimonyRoundLink,
} from "./complete/CompletePatrimony";
import PatrimonyHistory from "./history/PatrimonyHistory";

import PatrimonyBox from "./PatrimonyBox";
import PatrimonySection from "./PatrimonySection";
import CompletePatrimonyButton from "./CompletePatrimonyButton";
import { post } from "../../lib/axios";
import ProfileButton from "../../components/ProfileButton";
import UserCircle from "../../components/icons/UserCircle";

export const Body = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
  /* margin-bottom: 60px; */

  @media (min-width: 900px) {
    margin-top: 16px;
    border-top-left-radius: 32px;
    background-color: var(--gray-100);
  }
`;

export const Content = styled.div<{ chartDisplayed?: boolean }>`
  ${(p) => p.chartDisplayed && "padding: 0px 32px 32px;"}
  overflow: hidden auto;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;

  .welcome-text {
    margin-top: 12px;
  }
  .complete-button {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
  .patrimony-sections {
    display: flex;
    flex-direction: column;
    padding: 0 32px 32px 32px;
    overflow: auto;
    position: relative;
  }

  @media (max-width: 900px) {
    background-color: var(--gray-100);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding: 0;
    .patrimony-sections {
      padding: 0 16px;
      overflow: auto;
      overflow-x: hidden;
    }
  }

  @media (max-width: 600px) {
    .category-title,
    .section-title {
      font-size: 14px;
    }
  }
`;

export const Header = styled.div<{ showGrossOnMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 24px 32px;
  .header-first-row {
    display: flex;
    align-items: center;
    .left-side {
      flex: auto;
      .logo {
        display: flex;
      }
    }
  }

  .header-second-row {
    color: white;
    &.desktop-only {
      display: grid;
      grid-template-columns: auto auto;
      gap: 32px;
    }
    &.swiper-container {
      margin-bottom: -8px;
    }
  }

  @media (max-width: 900px) {
    padding: 16px;
  }
`;

export const PageTitle = styled.div<{ noPaddingBottom?: boolean }>`
  font-size: 32px;
  font-weight: 600;
  ${(p) => !p.noPaddingBottom && "padding-bottom: 12px;"}
`;

export const NavPillsContainer = styled.div`
  padding: 12px;
  @media (min-width: 900px) {
    padding: 0;
    padding-bottom: 16px;
  }
  @media (max-width: 360px) {
    font-size: 14px;
  }
`;

export const StyledPatrimonySection = styled.div<{
  noGap?: true;
  isLastSection?: boolean;
}>`
  ${(p) =>
    p.isLastSection &&
    css`
      min-height: 100%;
      ::after {
        content: "";
        display: block;
        height: 12px;
        height: 16px;
      }
    `}
  margin-top: 32px;
  &:first-child {
    margin-top: 0;
  }
  .header {
    margin: 0 16px 16px 0;
    display: flex;
    font-weight: 600;
    .title {
      flex: auto;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .total {
      white-space: nowrap;
    }
  }
  .categories {
    display: flex;
    flex-direction: column;
    ${({ noGap }) => (noGap ? "" : "gap: 16px;")}
  }

  page-break-before: always !important;
`;

export const PatrimonySectionsOptions = [
  { id: "SAVINGS", label: "Épargne" },
  { id: "REAL_ESTATE", label: "Immobilier" },
  { id: "CREDITS", label: "Crédits" },
  { id: "OTHERS", label: "Autres" },
] as const;

type Props = {
  selectedOptionId: PatrimonySectionType;
  setSelectedOptionId: React.Dispatch<
    React.SetStateAction<PatrimonySectionType>
  >;
  pdf?: boolean;
};

const PatrimonyPage = ({ selectedOptionId, setSelectedOptionId }: Props) => {
  const {
    data: myPatrimony,
    isLoading,
    isRefetching,
  } = useQuery(["myPatrimony"], getMyPatrimony, {
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: ONE_HOUR,
  });

  const { data: currentConnections } = useQuery(
    ["connections"],
    getConnections
  );

  const {
    data: syncedConnections,
    isFetching: isSyncFetching,
    refetch: refetchSync,
  } = useQuery(["syncBiAccounts"], syncBiAccounts, {
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      queryClient.invalidateQueries(["connections"]);
    },
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: ONE_DAY,
    enabled: false,
  });

  const connections = syncedConnections || currentConnections;

  const lastSection =
    myPatrimony &&
    ObjectKeys(PatrimonySections)
      .reverse()
      .find(
        (section) =>
          !!Object.keys(myPatrimony.sections[section].categories).length
      );

  const [showNetOnMobile, setShowNetOnMobile] = useState(true);
  const [chartDisplayed, realSetChartDisplayed] = useState<IncomeType>();
  const setChartDisplayed = (
    value: React.SetStateAction<IncomeType | undefined>
  ) => {
    realSetChartDisplayed(value);
    if (value) {
      setDontRestoreScroll(false);
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const biCode = searchParams.get("code");

  useEffect(() => {
    if (biCode) {
      updateBiAuthToken(biCode).then((data) => {
        setSearchParams();
        onSuccessUpdateUser(data);
      });
    }
  }, [biCode, setSearchParams, refetchSync]);

  const shouldRefetch = searchParams.get("refetch");
  useEffect(() => {
    if (shouldRefetch === "true") {
      refetchSync();
    }
  }, [shouldRefetch, setSearchParams, refetchSync]);

  /* Scroll Part Start */

  const location = useLocation();
  const navigate = useNavigate();

  const savingsRef = useRef<HTMLDivElement>(null);
  const realEstateRef = useRef<HTMLDivElement>(null);
  const creditsRef = useRef<HTMLDivElement>(null);
  const othersRef = useRef<HTMLDivElement>(null);

  const sectionsRefs = useMemo(() => {
    return {
      SAVINGS: savingsRef,
      REAL_ESTATE: realEstateRef,
      CREDITS: creditsRef,
      OTHERS: othersRef,
    };
  }, []);

  const scrollTo = useCallback(
    (id: PatrimonySectionType) => {
      const current = sectionsRefs[id].current;
      if (current) {
        setOptionsToScrollTo((prev) => [...prev, id]);
        setTimeout(() => {
          setOptionsToScrollTo((prev) => prev.slice(1));
        }, 1000);
      }
    },
    [sectionsRefs]
  );

  const removeNavigationState = useCallback(
    () => navigate("/patrimoine", { state: null, replace: true }),
    [navigate]
  );

  useEffect(() => {
    if (!location.state || !myPatrimony) return;

    if (chartDisplayed) setChartDisplayed(undefined);

    const section = location.state as PatrimonySectionType;

    if (!Object.keys(myPatrimony.sections[section].categories).length) {
      removeNavigationState();
      return;
    }

    if (sectionsRefs[section].current) {
      scrollTo(section);
      removeNavigationState();
    }
  }, [
    location,
    sectionsRefs,
    chartDisplayed,
    scrollTo,
    removeNavigationState,
    myPatrimony,
  ]);

  /* Scroll Part End */

  /* Scroll Restoration Part Start  */

  const sectionsContainerRef = useRef<HTMLDivElement>(null);

  const [dontRestoreScroll, setDontRestoreScroll] = useState(!!location.state);

  useEffect(() => {
    if (myPatrimony && !dontRestoreScroll && !chartDisplayed) {
      sectionsContainerRef.current?.scrollTo({ top: getPatrimonyScroll() });
      setDontRestoreScroll(true);
    }
  }, [myPatrimony, dontRestoreScroll, chartDisplayed]);

  const [optionsToScrollTo, setOptionsToScrollTo] = useState<
    PatrimonySectionType[]
  >([]);
  const prevOptionsToScrollToLength = useRef(0);

  const [scrollPosition, setScrollPosition] = useState(getPatrimonyScroll());

  const handleScroll = useCallback(() => {
    const position = sectionsContainerRef.current?.scrollTop;
    if (
      !optionsToScrollTo.length &&
      position !== undefined &&
      position !== scrollPosition
    ) {
      setScrollPosition(position);
    }
  }, [optionsToScrollTo, scrollPosition]);

  useEffect(() => {
    if (prevOptionsToScrollToLength.current && !optionsToScrollTo.length) {
      handleScroll();
    }

    if (optionsToScrollTo.length > prevOptionsToScrollToLength.current) {
      const id = optionsToScrollTo[optionsToScrollTo.length - 1]!;
      const current = sectionsRefs[id].current!;
      current.scrollIntoView({ behavior: "smooth" });
      setSelectedOptionId(id);
      setScrollPosition(current.offsetTop);
    }

    prevOptionsToScrollToLength.current = optionsToScrollTo.length;
  }, [optionsToScrollTo, handleScroll, sectionsRefs, setSelectedOptionId]);

  useEffect(() => {
    const current = sectionsContainerRef.current;
    if (current && !chartDisplayed) {
      current.addEventListener("scroll", handleScroll, { passive: true });
      return () => current.removeEventListener("scroll", handleScroll);
    }
  }, [sectionsContainerRef, handleScroll, chartDisplayed]);

  useEffect(() => {
    if (getPatrimonyScroll() !== scrollPosition) {
      setPatrimonyScroll(scrollPosition);
    }
  }, [scrollPosition]);

  useEffect(() => {
    if (myPatrimony && !chartDisplayed) {
      const sectionsTop = ObjectKeys(PatrimonySections).reduce(
        (acc, section) => ({
          ...acc,
          [section]: sectionsRefs[section].current?.offsetTop || -1000,
        }),
        {} as Record<PatrimonySectionType, number>
      );

      const optionId = ObjectEntries(sectionsTop)
        .reverse()
        .find(([, sectionTop]) => scrollPosition >= sectionTop - 32)?.[0];

      if (optionId) {
        setSelectedOptionId(optionId);
      }
    }
  }, [
    scrollPosition,
    myPatrimony,
    sectionsRefs,
    chartDisplayed,
    setSelectedOptionId,
  ]);

  /* Scroll Restoration Part End  */
  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownload = async () => {
    if (myPatrimony) {
      setIsDownloading(true);
      const pdfData = await exportPatrimony();
      downloadPDF(pdfData, "arpagon-patrimoine.pdf");
      setIsDownloading(false);
    }
  };

  const anyAssetExists = !!(
    myPatrimony &&
    Object.values(myPatrimony.sections).some(
      (c) => Object.keys(c.categories).length
    )
  );

  const DisplayedPatrimonyBox = ({
    type,
    value,
  }: {
    type: IncomeType;
    value: number | undefined;
  }) => (
    <PatrimonyBox
      type={type}
      isLoading={isLoading}
      value={value}
      chartDisplayed={chartDisplayed}
      setChartDisplayed={setChartDisplayed}
      anyAssetExists={anyAssetExists}
    />
  );

  return (
    <Body>
      <Header showGrossOnMobile={!showNetOnMobile}>
        <div className="header-first-row">
          <div className="left-side">
            <div className="mobile-only logo">
              <ProfileButton onClick={() => navigate("/profil")}>
                <UserCircle />
              </ProfileButton>
            </div>
            <PageTitle className="desktop-only">Mon patrimoine</PageTitle>
          </div>
          <DownloadButton onClick={handleDownload}>
            <DownloadIcon isLoading={isDownloading} />
          </DownloadButton>
          <CompletePatrimonyRoundLink />
        </div>
        <div className="header-second-row desktop-only">
          <DisplayedPatrimonyBox type="net" value={myPatrimony?.totalNet} />
          <DisplayedPatrimonyBox type="brut" value={myPatrimony?.totalGross} />
        </div>
        <div className="header-second-row mobile-only swiper-container">
          <Swiper
            pagination={{ clickable: true }}
            modules={[Pagination]}
            onSlideChange={({ activeIndex }) => {
              const net = !!activeIndex;
              setShowNetOnMobile(net);
              if (chartDisplayed) setChartDisplayed(net ? "brut" : "net");
            }}
          >
            <SwiperSlide>
              <DisplayedPatrimonyBox type="net" value={myPatrimony?.totalNet} />
            </SwiperSlide>
            <SwiperSlide>
              <DisplayedPatrimonyBox
                type="brut"
                value={myPatrimony?.totalGross}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </Header>

      <Content chartDisplayed={!!chartDisplayed}>
        <PatrimonyHistory type={chartDisplayed} />
        {!chartDisplayed ? (
          <>
            <NavPillsContainer className="mobile-only">
              <NavPills
                options={PatrimonySectionsOptions}
                onOptionSelected={scrollTo}
                selectedOptionId={selectedOptionId}
              />
            </NavPillsContainer>
            <div className="desktop-only" />
            <div
              ref={sectionsContainerRef}
              className="patrimony-sections"
              id="patrimony-sections-wrapper"
            >
              {isLoading ? (
                <Loader />
              ) : !myPatrimony ? (
                <div>Erreur</div>
              ) : !anyAssetExists ? (
                <>
                  <div className="welcome-text">
                    Bienvenue sur Arpagon, complétez votre patrimoine afin de
                    pouvoir suivre vos investissement et vos transactions etc.
                  </div>
                  <CompletePatrimonyButton />
                </>
              ) : (
                ObjectKeys(PatrimonySections).map((section) => (
                  <PatrimonySection
                    key={section}
                    {...{
                      section,
                      connections,
                      isLastSection: lastSection === section,
                      refetchSync,
                      isRefreshing: isSyncFetching || isRefetching,
                      myPatrimony,
                      sectionsRefs,
                    }}
                  />
                ))
              )}
            </div>
          </>
        ) : null}
      </Content>
    </Body>
  );
};

const Patrimony = (props: Props) => (
  <Routes>
    <Route path="/" element={<PatrimonyPage {...props} />} />
    <Route path="completer/*" element={<CompletePatrimony />} />
    <Route path="compte/*" element={<Account />} />
    <Route path="*" element={<Navigate to="/patrimoine" replace />} />
  </Routes>
);

export default Patrimony;
