import styled from "styled-components";
import logo from "../../assets/logo.svg";
import loginIllustration from "../../assets/login-illustration.png";
import { PropsWithChildren } from "react";

const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 100%;
  height: 100vh;
  overflow: hidden;
`;

const LogoAndChildContent = styled.div`
  box-sizing: content-box;
  min-width: 460px;
  padding: 96px;
  padding-bottom: 0;
  margin-bottom: 32px;
  display: grid;
  grid-template-rows: min-content auto;
  overflow: hidden auto;

  @media (max-width: 900px) {
    box-sizing: border-box;
    min-width: 100vw;
    padding: 16px;
    img {
      justify-self: center;
      height: 24px;
    }
  }
`;

const LoginImage = styled.img`
  overflow: hidden;
  height: 100vh;
  transition: none;
`;

const AuthenticationLayout = ({ children }: PropsWithChildren) => {
  return (
    <Body>
      <LogoAndChildContent>
        <img src={logo} alt="" height={32} />
        <div>{children}</div>
      </LogoAndChildContent>
      <LoginImage className="desktop-only" src={loginIllustration} alt="" />
    </Body>
  );
};

export default AuthenticationLayout;
