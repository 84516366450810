import styled from "styled-components";
import BackButton from "./buttons/BackButton";
import Link from "./Link";

const Header = styled.div`
  padding: 32px 32px 16px 32px;
  @media (max-width: 900px) {
    padding: 8px;
  }
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  flex: 1;
  text-align: center;
  @media (max-width: 900px) {
    font-size: 20px;
    text-align: unset;
  }
`;

const FillSpace = styled.div`
  flex: 1;
  @media (max-width: 900px) {
    display: none;
  }
`;

const BackButtonWrapper = styled.div`
    @media (min-width: 900px) {
        display: flex; 
        flex: 1;
    }
`;

type HeaderBackButtonTitleProps = {
    backTo: string;
    title: string | JSX.Element;
};

const HeaderBackButtonTitle = ({ backTo, title }: HeaderBackButtonTitleProps) => (
    <Header>
        <BackButtonWrapper>
            <Link to={backTo}>
                <BackButton />
            </Link>
        </BackButtonWrapper>
        <Title>
            {title}
        </Title>
        <FillSpace/>
    </Header>
);

export default HeaderBackButtonTitle;