import Button from "../Button";
import styled from "styled-components";
import Modal, { ModalProps } from "./Modal";

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  width: 100%;
  flex-wrap: wrap-reverse;
  gap: 0.5rem;
  button {
    flex: auto;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.1em;
  align-self: center;
`;

type Props = ModalProps & {
  title?: string;
  cancel?: () => void;
  cancelText?: string;
  apply?: () => void;
  applyText?: string;
  formId?: string;
  loading?: boolean;
  hideCancelButton?: boolean;
};

const ActionModal = ({
  children,
  open,
  close,
  title,
  cancel,
  cancelText,
  apply,
  applyText,
  formId,
  loading,
  hideCancelButton,
}: Props) => {
  return (
    <Modal open={open} close={close}>
      {title && <Title>{title}</Title>}
      {children}

      <ButtonsContainer>
        {!hideCancelButton && (
          <Button type="button" variant="white" onClick={cancel || close}>
            {cancelText || "Annuler"}
          </Button>
        )}
        <Button
          type={formId ? "submit" : "button"}
          form={formId}
          variant="primary"
          onClick={apply}
          loading={loading}
        >
          {applyText || "Confirmer"}
        </Button>
      </ButtonsContainer>
    </Modal>
  );
};

export default ActionModal;
