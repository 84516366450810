const GoldBarsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M9.42701 16.783C9.37765 16.5609 9.25405 16.3623 9.07661 16.22C8.89917 16.0776 8.6785 16 8.45101 16H3.05601C2.82836 15.9998 2.60745 16.0773 2.42981 16.2197C2.25216 16.362 2.12841 16.5608 2.07901 16.783L0.524011 22.783C0.491518 22.9292 0.492266 23.0808 0.526199 23.2266C0.560132 23.3725 0.626384 23.5088 0.720065 23.6256C0.813747 23.7424 0.932465 23.8367 1.06746 23.9015C1.20245 23.9663 1.35027 24 1.50001 24H10.007C10.1567 24 10.3046 23.9663 10.4396 23.9015C10.5746 23.8367 10.6933 23.7424 10.787 23.6256C10.8806 23.5088 10.9469 23.3725 10.9808 23.2266C11.0148 23.0808 11.0155 22.9292 10.983 22.783L9.42701 16.783Z"
        fill="#FFE32B"
      />
      <path
        d="M21.427 16.783C21.3776 16.5609 21.254 16.3623 21.0766 16.22C20.8992 16.0776 20.6785 16 20.451 16H15.051C14.8234 15.9998 14.6024 16.0773 14.4248 16.2197C14.2472 16.362 14.1234 16.5608 14.074 16.783L12.519 22.783C12.4864 22.9296 12.4873 23.0816 12.5215 23.2278C12.5557 23.374 12.6224 23.5107 12.7166 23.6276C12.8109 23.7445 12.9302 23.8387 13.0658 23.9032C13.2015 23.9676 13.3498 24.0007 13.5 24H22.007C22.1567 24 22.3046 23.9663 22.4396 23.9015C22.5746 23.8367 22.6933 23.7424 22.787 23.6256C22.8806 23.5088 22.9469 23.3725 22.9808 23.2266C23.0148 23.0808 23.0155 22.9292 22.983 22.783L21.427 16.783Z"
        fill="#FFE32B"
      />
      <path
        d="M8.07901 7.283L6.52401 13.283C6.49152 13.4292 6.49227 13.5808 6.5262 13.7266C6.56013 13.8725 6.62638 14.0088 6.72006 14.1256C6.81375 14.2424 6.93247 14.3367 7.06746 14.4015C7.20245 14.4663 7.35027 14.5 7.50001 14.5H16.007C16.1567 14.5 16.3046 14.4663 16.4396 14.4015C16.5746 14.3367 16.6933 14.2424 16.787 14.1256C16.8806 14.0088 16.9469 13.8725 16.9808 13.7266C17.0148 13.5808 17.0155 13.4292 16.983 13.283L15.427 7.283C15.3776 7.06094 15.254 6.86234 15.0766 6.71999C14.8992 6.57764 14.6785 6.50004 14.451 6.5H9.05101C8.82421 6.50095 8.60448 6.57896 8.42786 6.72124C8.25123 6.86351 8.12823 7.06161 8.07901 7.283Z"
        fill="#FFE32B"
      />
      <path
        d="M3.85301 8.47101C3.85453 8.66605 3.93207 8.85279 4.06914 8.99155C4.20622 9.1303 4.39201 9.21011 4.58701 9.21401H4.60001C4.79311 9.2145 4.97898 9.14059 5.11902 9.00763C5.25905 8.87467 5.34249 8.69288 5.35201 8.50001C5.44182 7.7032 5.79726 6.95982 6.36108 6.38965C6.9249 5.81949 7.66425 5.45574 8.46001 5.35701C8.5585 5.35806 8.65623 5.3397 8.74763 5.30298C8.83903 5.26626 8.92229 5.2119 8.99268 5.143C9.06307 5.0741 9.11919 4.99201 9.15786 4.90141C9.19652 4.81082 9.21696 4.7135 9.21801 4.61501C9.21906 4.51652 9.2007 4.41878 9.16398 4.32739C9.12726 4.23599 9.0729 4.15272 9.00399 4.08234C8.93509 4.01195 8.853 3.95582 8.76241 3.91716C8.67182 3.8785 8.5745 3.85806 8.47601 3.85701C7.677 3.79109 6.92654 3.44656 6.35572 2.8836C5.7849 2.32064 5.43 1.57503 5.35301 0.777011V0.750011C5.35314 0.651229 5.33373 0.553397 5.2959 0.462146C5.25807 0.370896 5.20256 0.288031 5.13257 0.218321C5.06258 0.148611 4.9795 0.0934344 4.8881 0.0559673C4.7967 0.0185003 4.69879 -0.000516944 4.60001 1.06856e-05V1.06856e-05C4.50165 -0.000120724 4.40422 0.0191226 4.3133 0.0566421C4.22238 0.0941615 4.13974 0.149222 4.07009 0.21868C4.00045 0.288139 3.94517 0.370634 3.9074 0.461457C3.86964 0.552279 3.85014 0.649651 3.85001 0.748011C3.77951 1.55006 3.42839 2.30154 2.85844 2.87021C2.28848 3.43889 1.53621 3.78832 0.734007 3.85701C0.53659 3.85991 0.348396 3.94105 0.210768 4.08262C0.0731404 4.22418 -0.00266419 4.41459 7.25204e-06 4.61201C0.00211599 4.8105 0.0825168 5.00013 0.22372 5.13965C0.364923 5.27917 0.555505 5.35729 0.754007 5.35701C1.55949 5.4144 2.317 5.76132 2.88662 6.33371C3.45625 6.90609 3.79951 7.66526 3.85301 8.47101Z"
        fill="#FFE32B"
      />
      <path
        d="M23.571 9.00498L23.306 8.96698C22.9851 8.92003 22.6879 8.7708 22.4585 8.54146C22.2292 8.31212 22.08 8.0149 22.033 7.69398L22 7.42898C21.983 7.30979 21.9236 7.20074 21.8327 7.12184C21.7417 7.04295 21.6254 6.99951 21.505 6.99951C21.3846 6.99951 21.2683 7.04295 21.1773 7.12184C21.0864 7.20074 21.027 7.30979 21.01 7.42898L20.972 7.69398C20.925 8.01505 20.7756 8.31237 20.5461 8.54173C20.3166 8.77109 20.0191 8.92023 19.698 8.96698L19.434 9.00498C19.3148 9.02196 19.2058 9.08138 19.1269 9.17231C19.048 9.26324 19.0045 9.37959 19.0045 9.49998C19.0045 9.62037 19.048 9.73671 19.1269 9.82765C19.2058 9.91858 19.3148 9.978 19.434 9.99498L19.699 10.033C20.0199 10.0799 20.3172 10.2292 20.5465 10.4585C20.7758 10.6878 20.9251 10.9851 20.972 11.306L21.01 11.571C21.027 11.6902 21.0864 11.7992 21.1773 11.8781C21.2683 11.957 21.3846 12.0004 21.505 12.0004C21.6254 12.0004 21.7417 11.957 21.8327 11.8781C21.9236 11.7992 21.983 11.6902 22 11.571L22.038 11.306C22.085 10.9849 22.2344 10.6876 22.4639 10.4582C22.6935 10.2289 22.9909 10.0797 23.312 10.033L23.576 9.99498C23.6952 9.978 23.8043 9.91858 23.8831 9.82765C23.962 9.73671 24.0055 9.62037 24.0055 9.49998C24.0055 9.37959 23.962 9.26324 23.8831 9.17231C23.8043 9.08138 23.6952 9.02196 23.576 9.00498H23.571Z"
        fill="#FFE32B"
      />
    </g>
  </svg>
);

export default GoldBarsIcon;
