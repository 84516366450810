import { useState } from "react";
import styled, { css } from "styled-components";
import EyeIcon from "./icons/EyeIcon";
import EyeOffIcon from "./icons/EyeOffIcon";

type InputType = React.ComponentPropsWithoutRef<"input">;

const Body = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  .input-and-children {
    width: 100%;
    position: relative;
    .unit,
    .password-eye {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
    }
    .unit {
      padding: 0.5em;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid transparent;
      border-left: none;
      background-clip: padding-box;
      pointer-events: none;
      ${(p) => p.disabled && "color: #757575"}
      font-family: Poppins;
    }
    .password-eye {
      padding: 0 0.8em;
      cursor: pointer;
      user-select: none;
      svg path {
        transition: 0.2s;
        stroke: #757575;
      }
      &:hover:not(:active) {
        svg path {
          stroke: currentColor;
        }
      }
    }
  }
  .error-message {
    font-size: 0.9em;
    margin-left: 2px;
    color: var(--error-500);
  }
`;

const Label = styled.label<{ show: boolean }>`
  font-size: 0.9em;
  margin-left: 2px;
  color: ${({ show }) => (show ? "unset" : css`var(--transparent)`)};
  transition: 0.2s;
`;

const StyledInput = styled.input<InputProps & { realType: InputType["type"] }>`
  width: 100%;
  height: 3em;
  padding: 0.8em 1em;
  ${(p) => p.unit && "padding-right: 1.7em;"}
  ${(p) => p.realType === "password" && "padding-right: calc(1.6em + 24px);"}
  font-family: unset;
  font-size: unset;
  border-radius: 4px;
  border: 1px solid var(--gray-400);
  outline: 1px solid var(--transparent);
  transition: 0.2s;
  box-shadow: var(--box-shadow);
  :not([disabled]) {
    background-color: var(--white);
  }

  &:hover:not([disabled]) {
    border-color: var(--gray-500);
  }
  &:focus {
    border-color: var(--gray-700);
    border-width: 2px;
  }

  /* Force gray placeholder for input type date */
  &:invalid::-webkit-datetime-edit {
    color: #757575;
  }

  /* Remove Arrows */
  &[type="number"] {
    /* Webkit */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    -moz-appearance: textfield;
  }

  ${(p) =>
    p.error !== undefined &&
    css`
      border-color: var(--error-200);
      &:hover:not([disabled]) {
        border-color: var(--error-500);
      }
      &:focus {
        outline-color: var(--error-500);
      }
    `}
`;

export type InputProps = {
  label?: string;
  unit?: string;
  children?: JSX.Element;
  blurOnEnter?: boolean;
  error?: string;
  noValidation?: boolean;
} & InputType;
const Input = ({
  children,
  className,
  blurOnEnter,
  noValidation,
  ...props
}: InputProps) => {
  const [seePassword, setSeePassword] = useState(false);
  const toggleSeePassword = () => setSeePassword(!seePassword);
  const type: InputType["type"] =
    props.type === "password" && seePassword ? "text" : props.type;

  return (
    <Body className={`input-body ${className || ""}`} disabled={props.disabled}>
      {props.label && (
        <Label
          show={
            props.type === "date" ||
            !!props.value?.toString() ||
            props.label !== props.placeholder
          }
        >
          {props.label}
        </Label>
      )}
      <div className="input-and-children">
        <StyledInput
          title=""
          realType={props.type}
          {...props}
          type={type}
          onChange={(e) => {
            if (e.currentTarget.validity.customError) {
              e.currentTarget.setCustomValidity("");
            }

            if (!e.currentTarget.validity.valid) {
              e.currentTarget.setCustomValidity(" ");
            }

            props.onChange?.(e);
          }}
          onKeyUp={(e) => {
            if (blurOnEnter && e.code === "Enter") {
              e.currentTarget.blur();
            }
            props.onKeyUp?.(e);
          }}
          minLength={!noValidation && props.type === "password" ? 8 : undefined}
          onInvalid={(e) => {
            if (e.currentTarget.validationMessage === " ") {
              e.currentTarget.setCustomValidity("");
            }

            if (
              !noValidation &&
              props.type === "password" &&
              e.currentTarget.validity.tooShort
            ) {
              e.currentTarget.setCustomValidity(
                "Votre mot de passe doit contenir au minimum 8 caractères"
              );
            }
          }}
        />
        {props.type === "password" && !props.readOnly && !props.disabled && (
          <div
            className="password-eye"
            onClick={toggleSeePassword}
            children={seePassword ? <EyeOffIcon /> : <EyeIcon />}
          />
        )}
        {props.unit && <div className="unit" children={props.unit} />}
        {children}
      </div>
      {props.error && <div className="error-message">{props.error}</div>}
    </Body>
  );
};

export default Input;
