import styled from "styled-components";
import RefreshIcon from "../../components/icons/Refresh";

const StyledRefreshButton = styled.div<{ isLoading: boolean }>`
  display: flex;
  cursor: pointer;

  ${({ isLoading }) => (isLoading ? "animation: spin 1s linear infinite;" : "")}

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-180deg);
    }
  }
`;
type RefreshButtonProps = {
  isLoading: boolean;
  onClick: () => void;
};
const RefreshButton = ({ isLoading, onClick }: RefreshButtonProps) => (
  <StyledRefreshButton isLoading={isLoading} onClick={onClick} className="refresh-button">
    <RefreshIcon />
  </StyledRefreshButton>
);

export default RefreshButton;