const GolfSwingIcon = ({ big }: { big?: boolean }) => (
  <svg
    width={big ? 48 : 24}
    height={big ? 48 : 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6808 4.44734C10.4931 4.30188 10.2711 4.2071 10.0363 4.17208C9.80137 4.13705 9.56144 4.16297 9.33945 4.24734C8.62679 4.47734 8.20012 5.07267 8.37079 5.60667C8.40598 5.7144 8.46255 5.81392 8.53713 5.89927C8.61171 5.98461 8.70274 6.05402 8.80479 6.10334C8.9935 6.19736 9.20196 6.2449 9.41279 6.242C9.59387 6.24151 9.77378 6.21295 9.94612 6.15734C10.0518 6.12293 10.1538 6.07827 10.2508 6.024C10.2825 6.00592 10.3194 5.99885 10.3556 6.00392C10.3917 6.00899 10.4252 6.02589 10.4508 6.052L13.3701 8.98C13.4533 9.0618 13.5566 9.12007 13.6696 9.14889C13.7827 9.1777 13.9013 9.17601 14.0135 9.144L14.0501 9.13334C14.1586 9.10192 14.2571 9.04269 14.3356 8.9615C14.4142 8.88031 14.4701 8.78 14.498 8.67051C14.5258 8.56101 14.5245 8.44615 14.4943 8.3373C14.464 8.22846 14.4058 8.12942 14.3255 8.05L10.8068 4.53334C10.7683 4.49981 10.726 4.47094 10.6808 4.44734Z"
      fill="#9747FF"
    />
    <path
      d="M9.33325 10.334C10.1617 10.334 10.8333 9.66243 10.8333 8.834C10.8333 8.00557 10.1617 7.334 9.33325 7.334C8.50482 7.334 7.83325 8.00557 7.83325 8.834C7.83325 9.66243 8.50482 10.334 9.33325 10.334Z"
      fill="#9747FF"
    />
    <path
      d="M9.71855 11.5147C9.6653 11.6751 9.6536 11.8465 9.68455 12.0127C9.68455 12.0187 9.68455 12.0227 9.68455 12.0293L10.2365 14.79C10.2456 14.8352 10.2451 14.8817 10.2353 14.9267C10.2254 14.9717 10.2063 15.0141 10.1792 15.0513L7.85788 18.2453C7.70184 18.4598 7.6374 18.7275 7.67872 18.9895C7.72004 19.2515 7.86374 19.4863 8.07821 19.6423C8.29269 19.7984 8.56036 19.8628 8.82235 19.8215C9.08433 19.7802 9.31918 19.6365 9.47521 19.422L11.7999 16.2273C11.9023 16.0847 11.9872 15.9303 12.0525 15.7673C12.0635 15.7399 12.0816 15.7159 12.1049 15.6979C12.1283 15.6798 12.156 15.6682 12.1853 15.6644C12.2145 15.6606 12.2443 15.6647 12.2715 15.6762C12.2987 15.6877 12.3223 15.7063 12.3399 15.73L12.4665 15.8987C12.4976 15.9403 12.5185 15.9886 12.5278 16.0397C12.537 16.0909 12.5343 16.1434 12.5199 16.1933L11.9659 18.078C11.9241 18.2053 11.9084 18.3397 11.9195 18.4732C11.9307 18.6067 11.9686 18.7365 12.0309 18.8551C12.0933 18.9737 12.1788 19.0785 12.2824 19.1634C12.3861 19.2483 12.5057 19.3115 12.6342 19.3492C12.7628 19.387 12.8976 19.3985 13.0307 19.3832C13.1637 19.3678 13.2924 19.3259 13.4089 19.2598C13.5255 19.1938 13.6276 19.105 13.7092 18.9988C13.7908 18.8925 13.8502 18.771 13.8839 18.6413L14.4385 16.7573C14.5402 16.4088 14.5595 16.0415 14.495 15.6842C14.4304 15.3269 14.2837 14.9896 14.0665 14.6987L12.4852 12.59C12.4688 12.5684 12.458 12.5432 12.4537 12.5164C12.4494 12.4897 12.4517 12.4623 12.4605 12.4367C12.4692 12.4111 12.4839 12.3879 12.5034 12.3692C12.5229 12.3505 12.5466 12.3369 12.5725 12.3293L15.6092 11.4627C15.8643 11.3898 16.0799 11.2186 16.2088 10.9868C16.3376 10.7549 16.3691 10.4814 16.2962 10.2263C16.2234 9.97129 16.0522 9.75562 15.8203 9.62678C15.5885 9.49794 15.3149 9.46649 15.0599 9.53934L10.3932 10.8727C10.2371 10.9169 10.0941 10.9985 9.97658 11.1103C9.85904 11.2222 9.77048 11.361 9.71855 11.5147Z"
      fill="#9747FF"
    />
    <circle opacity="0.2" cx="12" cy="12" r="12" fill="#9747FF" />
  </svg>
);

export default GolfSwingIcon;
