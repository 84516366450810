const ClockIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00004 4.50001V8.50001L10.6667 9.83334M14.6667 8.50001C14.6667 12.1819 11.6819 15.1667 8.00004 15.1667C4.31814 15.1667 1.33337 12.1819 1.33337 8.50001C1.33337 4.81811 4.31814 1.83334 8.00004 1.83334C11.6819 1.83334 14.6667 4.81811 14.6667 8.50001Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClockIcon;
