export function ObjectKeys<T extends string | number | symbol>(
  o: Partial<Record<T, any>>
) {
  return Object.keys(o) as Array<T>;
}

export function ObjectEntries<T extends object>(o: T) {
  return Object.entries(o) as [keyof T, T[keyof T]][];
}

export type AddEmptyStringToProps<T> = { [P in keyof T]: T[P] | "" };

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;
