import styled from "styled-components";
import { Meeting } from "../../types/meeting.enums";
import ClockIcon from "../../components/icons/Clock";
import CalendarIcon from "../../components/icons/Calendar";
import Link from "../../components/Link";
import { formatDateToFR, getLocalDate } from "../../utils/date.utils";
import Chip from "../../components/Chip";
import { config } from "../../config/config";

const StyledCardMeeting = styled(Link)`
  :hover {
    box-shadow: 0px 0px 16px 0px var(--gray-300);
    border: 1px solid var(--yellow-700);
  }

  text-
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 32%;
  max-width: 32%;
  max-height: 20rem;

  @media (max-width: 1200px) {
    flex-basis: 100%;
    max-width: 100%;
  }

  .header {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
    p {
      font-size: 16px;
      font-weight: 600;
    }
    background-color: var(--yellow-400);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 1px solid var(--gray-300);
    border-left: 1px solid var(--gray-300);
    border-right: 1px solid var(--gray-300);

    img {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
    }

    .information {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      align-items: center;
    }

    .extra-information {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        p {
          text-wrap: pretty;
        }
    }
    
    .chip-group {
        display: flex;
        gap: 8px;
    }
  }
`;

type Props = {
  meeting: Meeting;
  disabled?: boolean;
  withExpertise?: boolean;
  withBiography?: boolean;
};

const MeetingCard = (props: Props) => {
  const biography =
    props.meeting.advisor.biography?.length > 100
      ? props.meeting.advisor.biography.slice(0, 150) + "..."
      : props.meeting.advisor.biography;
  return (
    <StyledCardMeeting
      to={"rendez-vous/" + props.meeting.id.toString()}
      className={props.disabled ? "disabled" : ""}
    >
      <div className="header">
        <ClockIcon />
        <p>{formatDateToFR(new Date(props.meeting.date))}</p>
        <CalendarIcon />
        <p>
          {getLocalDate(new Date(props.meeting.date))
            .getUTCHours()
            .toString()
            .padStart(2, "0")}
          h
          {new Date(props.meeting.date)
            .getUTCMinutes()
            .toString()
            .padStart(2, "0")}
        </p>
      </div>
      <div className="content">
        <div className="information">
          <img
            alt="Profil du conseiller"
            src={config.bucketUrl + props.meeting.advisor.profilePicture}
          />
          <div className="personal-information">
            <p>
              {props.meeting.advisor.firstname} {props.meeting.advisor.lastname}
            </p>
            <p>
              {props.meeting.advisor.adress}, {props.meeting.advisor.company}
            </p>
          </div>
        </div>
        <div className="extra-information">
          {props.withExpertise ? (
            <div className="chip-group">
              {props.meeting.advisor.expertise.map((expertise) => (
                <Chip
                  key={expertise.expertise.label}
                  label={expertise.expertise.label}
                  active={true}
                />
              ))}
            </div>
          ) : null}
          {props.withBiography ? <p>{biography}</p> : null}
        </div>
      </div>
    </StyledCardMeeting>
  );
};

export default MeetingCard;
