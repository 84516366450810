import { Routes, Route } from "react-router-dom";

import ExportPatrimony from "./ExportPatrimony";
import ExportBudget from "./ExportBudget";

const ExportRouter = () => (
  <Routes>
    <Route path="patrimoine" element={<ExportPatrimony />} />
    <Route path="budget/:monthParam" element={<ExportBudget />} />
  </Routes>
);

export default ExportRouter;