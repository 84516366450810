const MoneyBagIcon = ({ big }: { big?: boolean }) => (
  <svg
    width={big ? 48 : 24}
    height={big ? 48 : 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.2" cx="12" cy="12" r="12" fill="#81C926" />
    <path
      d="M9.99992 9.33333H13.9999C14.1767 9.33333 14.3463 9.2631 14.4713 9.13807C14.5963 9.01305 14.6666 8.84348 14.6666 8.66667C14.6666 8.48986 14.5963 8.32029 14.4713 8.19526C14.3463 8.07024 14.1767 8 13.9999 8H9.99992C9.82311 8 9.65354 8.07024 9.52851 8.19526C9.40349 8.32029 9.33325 8.48986 9.33325 8.66667C9.33325 8.84348 9.40349 9.01305 9.52851 9.13807C9.65354 9.2631 9.82311 9.33333 9.99992 9.33333V9.33333Z"
      fill="#81C926"
    />
    <path
      d="M10.3139 6.99999C10.3241 7.08105 10.3636 7.15557 10.425 7.2094C10.4865 7.26323 10.5656 7.29262 10.6473 7.29199H13.3566C13.4383 7.29262 13.5174 7.26323 13.5788 7.2094C13.6403 7.15557 13.6798 7.08105 13.6899 6.99999C13.8336 6.27961 14.1059 5.59104 14.4939 4.96732C14.5276 4.90634 14.5414 4.83633 14.5333 4.76712C14.5251 4.69791 14.4955 4.633 14.4486 4.5815C14.4016 4.53 14.3397 4.4945 14.2716 4.48C14.2034 4.4655 14.1324 4.47272 14.0686 4.50066L12.8273 5.03399C12.8068 5.04268 12.7849 5.04721 12.7627 5.04731C12.7405 5.04741 12.7185 5.04308 12.6981 5.03458C12.6776 5.02607 12.659 5.01356 12.6434 4.99777C12.6278 4.98199 12.6155 4.96325 12.6073 4.94266L12.3133 4.20932C12.2885 4.14757 12.2458 4.09465 12.1906 4.05737C12.1355 4.0201 12.0705 4.00018 12.0039 4.00018C11.9374 4.00018 11.8724 4.0201 11.8172 4.05737C11.7621 4.09465 11.7194 4.14757 11.6946 4.20932L11.4006 4.94266C11.3923 4.96325 11.3801 4.98199 11.3645 4.99777C11.3489 5.01356 11.3303 5.02607 11.3098 5.03458C11.2893 5.04308 11.2673 5.04741 11.2451 5.04731C11.2229 5.04721 11.201 5.04268 11.1806 5.03399L9.93326 4.50332C9.86942 4.47539 9.79843 4.46817 9.73028 4.48267C9.66212 4.49717 9.60022 4.53266 9.55328 4.58416C9.50634 4.63567 9.47672 4.70058 9.46859 4.76979C9.46045 4.83899 9.47421 4.90901 9.50793 4.96999C9.89634 5.59275 10.1694 6.28041 10.3139 6.99999V6.99999Z"
      fill="#81C926"
    />
    <path
      d="M14.2919 10.0807C14.2235 10.0285 14.1399 10.0002 14.0539 10H9.94591C9.85985 9.99989 9.77617 10.0283 9.70791 10.0807C8.00724 11.3807 6.10791 13.6807 6.10791 15.6807C6.10658 18.5 7.67858 20 11.9999 20C16.3212 20 17.8932 18.5 17.8932 15.6787C17.8932 13.682 15.9932 11.3807 14.2919 10.0807ZM12.6426 14.5C12.7752 14.5 12.9024 14.5527 12.9961 14.6464C13.0899 14.7402 13.1426 14.8674 13.1426 15C13.1426 15.1326 13.0899 15.2598 12.9961 15.3536C12.9024 15.4473 12.7752 15.5 12.6426 15.5H11.4712C11.4465 15.5 11.4221 15.5055 11.3998 15.5161C11.3775 15.5267 11.3579 15.5421 11.3423 15.5613C11.3267 15.5804 11.3156 15.6028 11.3097 15.6268C11.3039 15.6508 11.3035 15.6758 11.3086 15.7C11.5219 16.672 12.2246 17.1667 13.4046 17.1667C13.5372 17.1667 13.6644 17.2193 13.7581 17.3131C13.8519 17.4069 13.9046 17.5341 13.9046 17.6667C13.9046 17.7993 13.8519 17.9265 13.7581 18.0202C13.6644 18.114 13.5372 18.1667 13.4046 18.1667C11.6492 18.1667 10.5306 17.2333 10.2952 15.642C10.2895 15.6029 10.2701 15.5672 10.2404 15.5412C10.2107 15.5151 10.1727 15.5005 10.1332 15.5H9.59991C9.4673 15.5 9.34013 15.4473 9.24636 15.3536C9.15259 15.2598 9.09991 15.1326 9.09991 15C9.09991 14.8674 9.15259 14.7402 9.24636 14.6464C9.34013 14.5527 9.4673 14.5 9.59991 14.5H10.1332C10.1731 14.5 10.2117 14.4856 10.2419 14.4596C10.2721 14.4335 10.292 14.3975 10.2979 14.358C10.5332 12.7687 11.6519 11.8333 13.4072 11.8333C13.5399 11.8333 13.667 11.886 13.7608 11.9798C13.8546 12.0735 13.9072 12.2007 13.9072 12.3333C13.9072 12.4659 13.8546 12.5931 13.7608 12.6869C13.667 12.7807 13.5399 12.8333 13.4072 12.8333C12.2272 12.8333 11.5246 13.3253 11.3112 14.3C11.3063 14.3242 11.3068 14.3491 11.3127 14.3731C11.3186 14.397 11.3297 14.4194 11.3452 14.4385C11.3608 14.4576 11.3804 14.4731 11.4027 14.4837C11.4249 14.4943 11.4493 14.4999 11.4739 14.5H12.6426Z"
      fill="#81C926"
    />
  </svg>
);

export default MoneyBagIcon;
