const CircledRefreshArrowsIcon = ({
  fill = "#FFAB00",
  big,
}: {
  fill?: string;
  big?: boolean;
}) => (
  <svg
    width={big ? 48 : 24}
    height={big ? 48 : 24}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.28597 7.28596C9.88947 4.68247 14.1106 4.68247 16.714 7.28596C17.2461 7.81804 17.6669 8.26349 18 8.63577V6.66668C18 6.29849 18.2985 6.00001 18.6667 6.00001C19.0349 6.00001 19.3334 6.29849 19.3334 6.66668V10.6667C19.3334 11.0349 19.0349 11.3333 18.6667 11.3333H14.6667C14.2985 11.3333 14 11.0349 14 10.6667C14 10.2985 14.2985 10 14.6667 10H17.4172C17.0757 9.58855 16.5566 9.01418 15.7712 8.22878C13.6884 6.14598 10.3116 6.14598 8.22878 8.22878C7.49012 8.96744 7.01427 9.86706 6.79937 10.8142C6.71791 11.1733 6.36078 11.3983 6.00172 11.3168C5.64265 11.2353 5.41762 10.8782 5.49909 10.5192C5.76801 9.33392 6.36427 8.20767 7.28597 7.28596Z" />
    <path d="M17.2007 13.1858C17.2821 12.8267 17.6393 12.6017 17.9983 12.6832C18.3574 12.7647 18.5825 13.1217 18.501 13.4808C18.2321 14.6661 17.6358 15.7923 16.7141 16.714C14.1106 19.3175 9.88951 19.3175 7.28602 16.714C6.75417 16.1822 6.33336 15.7367 6.00008 15.3643V17.3333C6.00008 17.7015 5.7016 18 5.33341 18C4.96523 18 4.66675 17.7015 4.66675 17.3333V13.3333C4.66675 12.9651 4.96523 12.6667 5.33341 12.6667H9.33341C9.70161 12.6667 10.0001 12.9651 10.0001 13.3333C10.0001 13.7015 9.70161 14 9.33341 14H6.58294C6.92397 14.4109 7.44289 14.9853 8.22883 15.7712C10.3116 17.854 13.6885 17.854 15.7713 15.7712C16.5099 15.0325 16.9858 14.1329 17.2007 13.1858Z" />
    <circle opacity="0.2" cx="12" cy="12" r="12" />
  </svg>
);

export default CircledRefreshArrowsIcon;
