import { FC } from "react";
import styled, { css } from "styled-components";

const Container = styled.label<{ readOnly?: boolean }>`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;

  .label {
    margin-left: 0.5em;
    cursor: pointer;
  }

  ${({ readOnly }) =>
    readOnly
      ? css`
          cursor: default !important;
          & * {
            cursor: default !important;
          }
        `
      : ""}
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ span {
    border: 1px solid var(--black);
    &::after {
      background-color: var(--black);
    }
  }

  &:focus ~ span {
    outline: medium solid black;
  }
`;

const Checkmark = styled.span`
  display: grid;
  place-items: center;
  border: var(--border);
  stroke: transparent;
  height: 1.3em;
  width: 1.3em;
  margin: 0.125em 0;
  aspect-ratio: 1/1;
  position: relative;
  border-radius: 50%;
  &::after {
    content: "";
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
  }
  outline-width: 1px !important;
`;

export type Props = {
  label?: string | React.ReactElement;
  checked?: boolean;
  onCheck: () => void;
  readOnly?: boolean;
  id?: string | number;
  name?: string;
  tabIndex?: number | undefined;
  required?: boolean;
};
const Radio: FC<Props> = ({
  label,
  checked,
  onCheck,
  name,
  required,
  readOnly,
  tabIndex,
}) => {
  return (
    <Container readOnly={readOnly}>
      <Input
        type="radio"
        checked={checked}
        onChange={(e) => e.target.checked && onCheck()}
        name={name}
        required={required}
        readOnly={readOnly}
        tabIndex={readOnly ? -1 : tabIndex}
      />
      <Checkmark />
      <div className="label">{label}</div>
    </Container>
  );
};

export default Radio;
