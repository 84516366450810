const OtherIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM7.08334 10C7.08334 10.3296 6.98559 10.6519 6.80245 10.926C6.61932 11.2 6.35902 11.4137 6.05448 11.5398C5.74993 11.6659 5.41482 11.699 5.09152 11.6346C4.76822 11.5703 4.47125 11.4116 4.23816 11.1785C4.00507 10.9454 3.84634 10.6485 3.78203 10.3252C3.71772 10.0018 3.75073 9.66674 3.87687 9.36219C4.00302 9.05765 4.21664 8.79735 4.49072 8.61422C4.7648 8.43108 5.08704 8.33333 5.41667 8.33333C5.8587 8.33333 6.28262 8.50893 6.59518 8.82149C6.90774 9.13405 7.08334 9.55797 7.08334 10ZM10 8.33333C10.3296 8.33333 10.6519 8.43108 10.926 8.61422C11.2 8.79735 11.4137 9.05765 11.5398 9.36219C11.666 9.66674 11.699 10.0018 11.6346 10.3252C11.5703 10.6485 11.4116 10.9454 11.1785 11.1785C10.9454 11.4116 10.6485 11.5703 10.3252 11.6346C10.0019 11.699 9.66674 11.6659 9.3622 11.5398C9.05766 11.4137 8.79736 11.2 8.61422 10.926C8.43109 10.6519 8.33334 10.3296 8.33334 10C8.33334 9.55797 8.50893 9.13405 8.82149 8.82149C9.13405 8.50893 9.55798 8.33333 10 8.33333V8.33333ZM14.5833 11.6667C14.2537 11.6667 13.9315 11.5689 13.6574 11.3858C13.3833 11.2026 13.1697 10.9423 13.0435 10.6378C12.9174 10.3333 12.8844 9.99815 12.9487 9.67485C13.013 9.35155 13.1717 9.05458 13.4048 8.82149C13.6379 8.5884 13.9349 8.42967 14.2582 8.36536C14.5815 8.30105 14.9166 8.33405 15.2211 8.4602C15.5257 8.58635 15.786 8.79997 15.9691 9.07405C16.1523 9.34813 16.25 9.67036 16.25 10C16.25 10.442 16.0744 10.866 15.7618 11.1785C15.4493 11.4911 15.0254 11.6667 14.5833 11.6667Z"
        fill="#FFE32B"
      />
    </g>
  </svg>
);

export default OtherIcon;
