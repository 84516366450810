import BankIcon from "../components/icons/patrimony/Bank";
import BuildingIcon from "../components/icons/patrimony/Building";
import BullIcon from "../components/icons/patrimony/Bull";
import GlobeIcon from "../components/icons/patrimony/Globe";
import HandCoinsIcon from "../components/icons/patrimony/HandCoins";
import HandHouseIcon from "../components/icons/patrimony/HandHouse";
import HomeCoinIcon from "../components/icons/patrimony/HomeCoin";
import HomeKeyIcon from "../components/icons/patrimony/HomeKey";
import HomeStarIcon from "../components/icons/patrimony/HomeStar";
import InsuranceCardIcon from "../components/icons/patrimony/InsuranceCard";
import OtherIcon from "../components/icons/patrimony/Other";
import RetirementIcon from "../components/icons/patrimony/Retirement";
import SuitCaseIcon from "../components/icons/patrimony/SuitCase";

export const PatrimonySections = {
  SAVINGS: "Mon Épargne",
  REAL_ESTATE: "Immobilier",
  CREDITS: "Crédits",
  OTHERS: "Autres biens",
};

export type PatrimonySection = keyof typeof PatrimonySections;

export const RealEstateCategories = {
  MAIN_RESIDENCE: "Résidence principale",
  SECOND_RESIDENCE: "Résidence secondaire",
  RENTAL_INVESTMENTS: "Investissement locatif",
  COMPANIES: "Société",
};

export type RealEstateCategory = keyof typeof RealEstateCategories;

const RealEstateResidenceProducts = {
  HOUSE: "Maison",
  APARTMENT: "Appartement",
};

const RealEstateInvestmentsProducts = {
  MONUMENT: "Monument historique",
  LMP: "LMP",
  LMNP: "LMNP",
  UNFURNISHED_RENTAL_INVESTMENT: "Investissement locatif non meublé",
  OPCI: "OPCI",
  OPPCI: "OPPCI",
  RENTED_PARKING: "Parking",
  RENTED_BOX: "Box",
  BORLOO: "Borloo",
  CENSI_BOUVARD: "Censi Bouvard",
  DUFLOT: "Duflot",
  PINEL: "Pinel",
  ROBIEN: "Robien",
  SCELLIER: "Scellier",
  MALRAUX: "Malraux",
  VIAGER: "Viager",
  OTHERS: "Autre",
};

const RealEstateCompaniesProducts = {
  SCPI: "SCPI",
  SCI: "SCI",
};

export type RealEstateProduct =
  | keyof typeof RealEstateResidenceProducts
  | keyof typeof RealEstateInvestmentsProducts
  | keyof typeof RealEstateCompaniesProducts;

const AllRealEstateProduct = {
  ...RealEstateResidenceProducts,
  ...RealEstateInvestmentsProducts,
  ...RealEstateCompaniesProducts,
};

export const RealEstateProductsOf: Record<
  RealEstateCategory,
  Partial<typeof AllRealEstateProduct>
> = {
  MAIN_RESIDENCE: RealEstateResidenceProducts,
  SECOND_RESIDENCE: RealEstateResidenceProducts,
  RENTAL_INVESTMENTS: RealEstateInvestmentsProducts,
  COMPANIES: RealEstateCompaniesProducts,
};

export const Currencies = {
  EUR: "EUR",
  USD: "USD",
  GBP: "GBP",
  CHF: "CHF",
  AED: "AED",
  BRL: "BRL",
  CZK: "CZK",
  DKK: "DKK",
  HUF: "HUF",
  MAD: "MAD",
  MXN: "MXN",
  NOK: "NOK",
  RUB: "RUB",
  SAR: "SAR",
  SEK: "SEK",
  SGD: "SGD",
  TRY: "TRY",
};

export type Currency = keyof typeof Currencies;

export const OtherAssetCategories = {
  PROFESSIONAL: "Bien professionnel",
  OTHERS: "Autres",
};

export type OtherAssetCategory = keyof typeof OtherAssetCategories;

const OtherAssetProfessionalProducts = {
  NONE: "",
};

const OtherAssetOthersProducts = {
  WINE: "Vin",
  CARS: "Voitures",
  PRECIOUS_METAL: "Or / Métaux précieux",
  WATCHES: "Montres",
  FURNITURE: "Meubles",
  FOREST: "Forêt",
  SME_INVESTMENTS: "Investissements PME",
  SOFICA: "Sofica",
  ARTS_ANTIQUES: "Œuvres d'art et antiquités",
  FARMLAND: "Terres agricoles",
  JEWELS: "Bijoux",
  CROWDFUNDING: "Crowdfunding",
  CRYPTO: "Cryptos",
  OTHERS: "Autre",
};

export type OtherAssetProduct =
  | keyof typeof OtherAssetProfessionalProducts
  | keyof typeof OtherAssetOthersProducts;

const AllOtherAssetProducts = {
  ...OtherAssetProfessionalProducts,
  ...OtherAssetOthersProducts,
};
export const OtherAssetProductsOf: Record<
  OtherAssetCategory,
  Partial<typeof AllOtherAssetProducts>
> = {
  PROFESSIONAL: OtherAssetProfessionalProducts,
  OTHERS: OtherAssetOthersProducts,
};

export const CreditCategories = {
  REAL_ESTATE_CREDIT: "Crédit immobilier",
  CONSUMER_CREDIT: "Crédit à la consommation",
  OTHERS: "Autre",
};

export type CreditCategory = keyof typeof CreditCategories;

export const CreditTypes = {
  AMORTIZATION: "Amortissement",
  IN_FINE: "In fine",
};

export type CreditType = keyof typeof CreditTypes;

export const TimeUnits = { YEAR: "ANS", MONTH: "MOIS" };

export type TimeUnit = keyof typeof TimeUnits;

export const SavingCategories = {
  CURRENT_ACCOUNTS_AND_CARDS: "Comptes courants et cartes",
  PASSBOOKS_AND_SAVINGS_PLANS: "Livrets et plans d'épargne",
  LIFE_INSURANCE: "Assurance Vie",
  INVESTMENT_ACCOUNTS: "Comptes d'investissement",
  RETIREMENT_PLANS: "Plans retraite",
};

export type SavingCategory = keyof typeof SavingCategories;

export const SavingProducts = {
  CURRENT_ACCOUNTS_AND_CARDS: {
    CURRENT_ACCOUNT: "Compte courant",
    CARD: "Carte",
  },
  PASSBOOKS_AND_SAVINGS_PLANS: {
    CAT: "CAT",
    LDD: "LDD",
    LEP: "LEP",
    PEL: "PEL",
    CEL: "CEL",
    LIVRET_A: "Livret A",
    LIVRET_JEUNE: "Livret jeune",
    LIVRET_EPARGNE: "Livret d'épargne",
  },
  LIFE_INSURANCE: {
    FONDS_EUROS: "Fonds Euros",
    UC: "Unités de Comptes (UC)",
    LUXEMBOURGISH: "Assurance Vie de droit Luxembourgeois",
  },
  INVESTMENT_ACCOUNTS: {
    COMPTE_TITRES: "Compte-Titres",
    CONTRAT_CAPITALISATION: "Contrat de capitalisation",
    PEA: "PEA",
    PEE: "PEE",
    FCPI: "FCPI",
    FCPR: "FCPR",
    FIP: "FIP",
    FPCI: "FPCI",
    FPS: "FPS",
    CROWDFUNDING: "Crowdfunding",
    SCPI: "SCPI",
    RSP: "RSP",
    OTHERS: "OTHERS",
  },
  RETIREMENT_PLANS: {
    PER: "PER",
    PERP: "PERP",
    COREM: "COREM",
    PERCO: "PERCO",
    MADELIN: "Madelin",
    ARTICLE_83: "Article 83",
  },
};

const AllSavingProducts = {
  ...SavingProducts.CURRENT_ACCOUNTS_AND_CARDS,
  ...SavingProducts.PASSBOOKS_AND_SAVINGS_PLANS,
  ...SavingProducts.LIFE_INSURANCE,
  ...SavingProducts.INVESTMENT_ACCOUNTS,
  ...SavingProducts.RETIREMENT_PLANS,
};

export type SavingProduct = keyof typeof AllSavingProducts;

export type SavingLifeInsuranceProduct =
  keyof typeof SavingProducts.LIFE_INSURANCE;

export const AllPatrimonyCategoriesIcons: Record<
  PatrimonyCategory,
  JSX.Element
> = {
  CURRENT_ACCOUNTS_AND_CARDS: <BankIcon />,
  PASSBOOKS_AND_SAVINGS_PLANS: <GlobeIcon />,
  LIFE_INSURANCE: <InsuranceCardIcon />,
  INVESTMENT_ACCOUNTS: <BullIcon />,
  RETIREMENT_PLANS: <RetirementIcon />,
  MAIN_RESIDENCE: <HomeKeyIcon />,
  SECOND_RESIDENCE: <HomeStarIcon />,
  RENTAL_INVESTMENTS: <HomeCoinIcon />,
  COMPANIES: <BuildingIcon />,
  REAL_ESTATE_CREDIT: <HandHouseIcon />,
  CONSUMER_CREDIT: <HandCoinsIcon />,
  PROFESSIONAL: <SuitCaseIcon />,
  OTHERS: <OtherIcon />,
};

export const AllPatrimonyCategories = {
  ...SavingCategories,
  ...RealEstateCategories,
  ...CreditCategories,
  ...OtherAssetCategories,
};

export type PatrimonyCategory = keyof typeof AllPatrimonyCategories;
