import styled from "styled-components";
import Link from "../../components/Link";

const SummaryContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SummaryMainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SummaryHeader = styled.div`
  display: flex;
  align-items: center;
`;

const SummaryTitle = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #000000;
`;

const SummaryLink = styled(Link)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  text-decoration-line: underline !important;
  color: #333533;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
`;

const SummaryLabelContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: normal;
`;
const SummaryValueContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SpanValue = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  text-transform: uppercase;
`;

const SummaryRowsContainer = styled.div`
  border: 1px solid #F1F1F1;
  border-radius: 8px;
  background-color: #FFFFFF;
`;

const SummaryRowComponent = ({ label, value, rowStyle = {}, valueStyle = {} }: any) => {
  return (
    <SummaryRow style={rowStyle}>
      <SummaryLabelContainer>
        {label}
      </SummaryLabelContainer>
      <SummaryValueContainer>
        <SpanValue style={valueStyle}>{value}</SpanValue>
      </SummaryValueContainer>
    </SummaryRow>
  );
};

const Summary = ({
  title,
  icon,
  link,
  label1,
  value1,
  label2,
  value2,
  label3,
  value3
}: any) => {
  return (
    <Link to={link} className="budget-summary">
      <SummaryContainer>
        <SummaryMainHeader>
          <SummaryHeader>
            <img src={icon} style={{ width: '20px', height: '20px', marginRight: '8px' }}/>
            <SummaryTitle>{title}</SummaryTitle>
          </SummaryHeader>
          <div>
            <SummaryLink to={link} className="budget-summary">Voir les transactions</SummaryLink>
          </div>
        </SummaryMainHeader>
        <SummaryRowsContainer>
          <SummaryRowComponent
            label={label1}
            value={value1}
          />
          <SummaryRowComponent
            label={label2}
            value={value2}
            rowStyle={{ borderTop: '1px solid #F1F1F1', borderBottom: '1px solid #F1F1F1' }}
          />
          <SummaryRowComponent
            label={label3}
            value={value3}
            valueStyle={{ color: parseInt(value3) > 0 ? '#209E00' : parseInt(value3) < 0 ? '#B60000' : "#202020" }}
          />
        </SummaryRowsContainer>
      </SummaryContainer>
    </Link>
  );
}

export default Summary;