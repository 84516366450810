const BrownHouseIcon = ({ big }: { big?: boolean }) => (
  <svg
    width={big ? 48 : 24}
    height={big ? 48 : 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M17.3794 8.35734C17.4024 8.38147 17.4321 8.39816 17.4647 8.40524C17.4973 8.41231 17.5312 8.40946 17.5622 8.39704C17.5931 8.38462 17.6196 8.36321 17.6383 8.33557C17.6569 8.30793 17.6668 8.27534 17.6667 8.242V4.99534C17.6667 4.90693 17.6316 4.82215 17.5691 4.75963C17.5066 4.69712 17.4218 4.662 17.3334 4.662H15.0001C14.9117 4.662 14.8269 4.69712 14.7644 4.75963C14.7019 4.82215 14.6667 4.90693 14.6667 4.99534V5.38734C14.6667 5.4733 14.6999 5.55594 14.7594 5.618L17.3794 8.35734Z"
        fill="#A65000"
      />
      <path
        d="M19.8146 11.8667L12.4819 4.2C12.353 4.07418 12.18 4.00374 11.9999 4.00374C11.8198 4.00374 11.6468 4.07418 11.5179 4.2L4.18458 11.8667C4.0939 11.961 4.03299 12.0799 4.00943 12.2086C3.98587 12.3374 4.00071 12.4702 4.0521 12.5905C4.10349 12.7108 4.18916 12.8134 4.29843 12.8854C4.40769 12.9574 4.53573 12.9956 4.66658 12.9953H5.66658C5.75498 12.9953 5.83977 13.0305 5.90228 13.093C5.96479 13.1555 5.99991 13.2403 5.99991 13.3287V18.9953C5.99991 19.2606 6.10527 19.5149 6.2928 19.7024C6.48034 19.89 6.7347 19.9953 6.99991 19.9953H9.99991C10.1767 19.9953 10.3463 19.9251 10.4713 19.8001C10.5963 19.675 10.6666 19.5055 10.6666 19.3287V16.662C10.6666 16.3084 10.8071 15.9692 11.0571 15.7192C11.3072 15.4691 11.6463 15.3287 11.9999 15.3287C12.3535 15.3287 12.6927 15.4691 12.9427 15.7192C13.1928 15.9692 13.3332 16.3084 13.3332 16.662V19.3287C13.3332 19.5055 13.4035 19.675 13.5285 19.8001C13.6535 19.9251 13.8231 19.9953 13.9999 19.9953H16.9999C17.2651 19.9953 17.5195 19.89 17.707 19.7024C17.8946 19.5149 17.9999 19.2606 17.9999 18.9953V13.3287C17.9999 13.2403 18.035 13.1555 18.0975 13.093C18.1601 13.0305 18.2448 12.9953 18.3332 12.9953H19.3332C19.4639 12.9953 19.5917 12.957 19.7007 12.885C19.8097 12.813 19.8952 12.7105 19.9465 12.5903C19.9978 12.4701 20.0126 12.3375 19.9892 12.209C19.9657 12.0805 19.905 11.9617 19.8146 11.8673V11.8667Z"
        fill="#A65000"
      />
    </g>
    <circle opacity="0.2" cx="12" cy="12" r="12" fill="#A65000" />
  </svg>
);

export default BrownHouseIcon;
