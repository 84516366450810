const RefreshIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8926 4.10752C12.6383 0.853156 7.36188 0.853156 4.10752 4.10752C3.44242 4.77261 2.91643 5.32943 2.50004 5.79478V3.33342C2.50004 2.87318 2.12694 2.50008 1.66671 2.50008C1.20647 2.50008 0.833374 2.87318 0.833374 3.33342V8.33342C0.833374 8.79366 1.20647 9.16675 1.66671 9.16675H6.66671C7.12694 9.16675 7.50004 8.79366 7.50004 8.33342C7.50004 7.87318 7.12694 7.50008 6.66671 7.50008H3.22865C3.65547 6.98576 4.30427 6.26779 5.28602 5.28604C7.88952 2.68254 12.1106 2.68254 14.7141 5.28604C15.6375 6.20936 16.2323 7.33389 16.5009 8.51783C16.6027 8.96667 17.0491 9.24791 17.498 9.14608C17.9468 9.04425 18.228 8.59783 18.1262 8.14902C17.79 6.66747 17.0448 5.25966 15.8926 4.10752Z"
      fill="black"
    />
    <path
      d="M3.49922 11.4823C3.39738 11.0335 2.95098 10.7523 2.50215 10.8541C2.05333 10.9559 1.77203 11.4023 1.87387 11.8511C2.21002 13.3327 2.95533 14.7405 4.10747 15.8926C7.36183 19.147 12.6382 19.147 15.8926 15.8926C16.5574 15.2278 17.0834 14.671 17.5 14.2054V16.6668C17.5 17.127 17.8731 17.5001 18.3333 17.5001C18.7936 17.5001 19.1667 17.127 19.1667 16.6668V11.6668C19.1667 11.2065 18.7936 10.8334 18.3333 10.8334H13.3333C12.8731 10.8334 12.5 11.2065 12.5 11.6668C12.5 12.127 12.8731 12.5001 13.3333 12.5001H16.7714C16.3452 13.0138 15.6965 13.7317 14.7141 14.7141C12.1106 17.3176 7.88947 17.3176 5.28597 14.7141C4.36265 13.7908 3.76784 12.6663 3.49922 11.4823Z"
      fill="black"
    />
  </svg>
);

export default RefreshIcon;
