import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import {
  useParams,
} from "react-router-dom";
import MonthSelector from "../../components/MonthSelector";
import {
  formatMyBudget,
  getMyBudget,
} from "../../services/budget.service";
import {
  getNavigableStringMonths,
} from "../../types/budget";
import {
  BudgetExpenseCategories,
  BudgetIncomeCategories,
  CategoriesDictType,
} from "../../types/budget.enums";
import { getMonthInit, stringMonth } from "../../utils/date.utils";
import { currency } from "../../utils/format.utils";
import { ONE_HOUR } from "../../utils/time.utils";
import { ObjectEntries } from "../../utils/utils";
import {
  StyledPatrimonySection,
} from "../my-patrimony/Patrimony";


import Summary from "../budget/Summary";
import entreesIcon from "../../assets/entrees.png";
import sortiesIcon from "../../assets/sorties.png";
import ecartIcon from "../../assets/ecart.png";

import { 
  Categories,
  BudgetLine,
  SummaryWrapper
} from '../budget/Budget';

import { Logo } from "./ExportPatrimony";

const ExportBudget = () => {
  const { monthParam } = useParams<{ monthParam: string }>();

  const monthInit = getMonthInit(monthParam);
  const month = monthInit || stringMonth();


  const { data, isLoading } = useQuery(["budget"], getMyBudget, { cacheTime: Number.POSITIVE_INFINITY, staleTime: ONE_HOUR });
  const myBudget = useMemo(() => data && formatMyBudget(data, month), [data, month]);
  const months = useMemo(() => (data ? getNavigableStringMonths(data.transactions) : []), [data]);
  
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.position = "static";
      root.style.height = "auto";
    }
  }, []);

  const estimationSections = [
    {
      title: "Revenus mensuels moyens",
      total: myBudget?.budgetedIncomes || 0,
      categories: BudgetIncomeCategories as CategoriesDictType,
    },
    {
      title: "Dépenses mensuelles fixes",
      total: myBudget?.budgetedExpenses || 0,
      categories: BudgetExpenseCategories as CategoriesDictType,
    },
  ];

  
  if (isLoading || !myBudget) {
    return <div>Loading...</div>;
  }

  return (
    <div className="patrimony-sections">
      {estimationSections.map(({ title, total, categories }, i) => (
        <>
          <StyledPatrimonySection noGap key={title}>
            {i === 0 && (<Logo />)}
            <div className="header">
              <div className="title section-title">{title}</div>
              <div className="total"> {currency(total)}</div>
            </div>
            <Categories>
              {ObjectEntries(categories).map(([category, c]) => {
                const item = myBudget.items[category];
                const amount =  (item?.amount || 0);

                return (
                  <BudgetLine
                      category={category}
                      c={c}
                      item={item}
                      amount={amount}
                      validate={() => {}}
                      setChangedBudget={() => {}}
                  />
                );
              })}
            </Categories>
          </StyledPatrimonySection> 
        </>
      ))}

      <StyledPatrimonySection noGap isLastSection>
        <div className="header">
          <div className="title">
            Écarts entre mes flux bancaires et mon budget
          </div>
        </div>
        <SummaryWrapper>
          <MonthSelector
            months={months}
            month={month}
            altStyle={true}
            setMonth={() => {}}
          />
          <div className="summary-container">
            <div className="estimation-summaries">
              <Summary
                title="Entrées"
                icon={entreesIcon}
                link="flux/entrees"
                label1="Entrées Bancaires"
                value1={currency(myBudget.realIncomes)}
                label2="Revenus mensuels moyens"
                value2={currency(myBudget.budgetedIncomes)}
                label3="Écarts"
                value3={`${myBudget.incomesDifference > 0 ? "+" : ""}${currency(myBudget.incomesDifference)}`}
              />
              <Summary
                title="Sorties"
                icon={sortiesIcon}
                link="flux/sorties"
                label1="Sorties Bancaires"
                value1={currency(myBudget.realExpenses)}
                label2="Dépenses mensuelles fixes"
                value2={currency(-(myBudget.budgetedExpenses))}
                label3="Écarts"
                value3={`${myBudget.expensesDifference > 0 ? "+" : ""}${currency(myBudget.expensesDifference)}`}
              />
            </div>
            <Summary
              title="Écarts"
              icon={ecartIcon}
              link="flux/entrees-et-sorties"
              label1="Entrées - Sorties bancaires"
              value1={currency(myBudget.realTotal)}
              label2="Capacité d'épargne chaque mois"
              value2={currency(myBudget.capacity)}
              label3="Écarts"
              value3={`${myBudget.budgetDifference > 0 ? "+" : ""}${currency(myBudget.budgetDifference)}`}
            />
          </div>
        </SummaryWrapper>
      </StyledPatrimonySection>
    </div>
  );
}

export default ExportBudget;