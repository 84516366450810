import { format } from "date-fns";
import styled from "styled-components";
import { capitalizeFirstLetter } from "../utils/format.utils";
import Button from "./Button";
import ArrowIcon from "./icons/Arrow";

const Body = styled.div`
  margin: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  button {
    padding: 12px;
    border: var(--border);
    &:hover {
      filter: unset !important;
    }
    &:disabled {
      background-color: unset !important;
    }
  }
`;

const Month = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin: 0 16px;
  border: 1px solid #F1F1F1;
  border-radius: 64px;
  background-color: #FFFFFF;
`;

const MonthWrapper = ({ altStyle, children }: { altStyle: boolean; children: any }) => {
  return altStyle ? (
    <Month className="month">{children}</Month>
  ) : (
    <div className="month">{children}</div>
  );
}

type MonthSelectorProps = {
  months: string[];
  month: string;
  setMonth: (month: string) => void;
  altStyle?: boolean;
};

export const MonthSelector = ({
  months,
  month,
  setMonth,
  altStyle = false
}: MonthSelectorProps) => {
  const index = months.findIndex((x) => x === month);

  function changeMonth(next: boolean) {
    const i = next ? 1 : -1;
    setMonth(months[index + i]);
  }

  const ChangeButton = ({ next }: { next: boolean }) => (
    <Button
      className="change-month-button"
      icon={<ArrowIcon orientation={next ? "right" : "left"} />}
      size="roundIcon"
      variant={altStyle ? "primary" : "transparent"}
      onClick={() => changeMonth(next)}
      disabled={months.length < 2 || index === (next ? months.length - 1 : 0)}
    />
  );

  return (
    <Body className="month-selector">
      <ChangeButton next={false} />
      <MonthWrapper altStyle={altStyle}>
        {capitalizeFirstLetter(format(new Date(month), "MMMM yyyy"))}
      </MonthWrapper>
      <ChangeButton next={true} />
    </Body>
  );
};

export default MonthSelector;
