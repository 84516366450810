import { NO_BREAK_SPACE } from "./format.utils";
import { AxiosError } from "axios";
import { queryClient } from "../lib/react-query";
import { User } from "../types/user";
import { resetStorage, setJwt } from "../utils/storage.utils";

export const logout = () => {
  setJwt("");
  queryClient.setQueryData(["myPatrimony"], null);
  queryClient.setQueryData(["budget"], null);
  queryClient.setQueryData(["user"], null);
  resetStorage();
};

export const onSuccessLogin = (jwt: string) => {
  setJwt(jwt);
  queryClient.invalidateQueries(["user"]);
  queryClient.invalidateQueries(["myPatrimony"]);
  queryClient.invalidateQueries(["budget"]);
};

export const onSuccessUpdateUser = (user: User) =>
  queryClient.setQueryData<User>(["user"], user);

export const getConfirmEmailTexts = (
  confirmEmailError: AxiosError | null,
  confimationMailIsSent: boolean | undefined,
  connected: boolean
) => {
  const nbspNcolon = `${NO_BREAK_SPACE}:`;
  let text1: string;
  let text2: string;

  if (confirmEmailError?.code || confimationMailIsSent === false) {
    const errorMessage =
      confimationMailIsSent === false ||
      confirmEmailError?.code !== AxiosError.ERR_BAD_REQUEST
        ? "Une erreur est survenue"
        : "Ce lien a expiré ou est incorrect";
    text1 = `${errorMessage}. Pour valider votre adresse${
      connected ? nbspNcolon : " email"
    }`;
    text2 = "Tentez de renvoyer le lien en cliquant ci-dessous.";
  } else {
    text1 = `Nous avons envoyé un email à l'adresse${nbspNcolon}`;
    text2 =
      "Consultez votre boîte de réception et cliquez sur le lien afin de finaliser votre inscription à Arpagon.";
  }

  return [text1, text2];
};
