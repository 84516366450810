const BrowserUserHelpIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g clipPath="url(#a)" fill="currentColor">
      <path d="M8.929 4.079a.251.251 0 0 0-.035-.225.249.249 0 0 0-.2-.1H3.5A3 3 0 0 0 .5 6.7v16.052a1 1 0 1 0 2 0V8.251a.5.5 0 0 1 .5-.5h5.282a.2503.2503 0 0 0 .2368-.1698.2501.2501 0 0 0 .0122-.1022C8.511 7.239 8.5 7 8.5 6.751a8.493 8.493 0 0 1 .429-2.672ZM15 22.751a1.0001 1.0001 0 0 0 2 0V15.5a.2512.2512 0 0 0-.243-.25 8.517 8.517 0 0 1-1.457-.17.251.251 0 0 0-.3.245v7.426ZM16.934 13.384a6.5667 6.5667 0 0 0 4.5911-11.1813A6.5667 6.5667 0 0 0 16.934.251 6.4322 6.4322 0 0 0 10.5 6.818a6.6666 6.6666 0 0 0 .659 3.106.2521.2521 0 0 1 0 .223l-.87 1.826-.744 1.563a.5001.5001 0 0 0 .666.667l1.563-.745 1.826-.868a.2511.2511 0 0 1 .226.006 6.534 6.534 0 0 0 3.108.788ZM8.67 19.251c1.1046 0 2-.8954 2-2s-.8954-2-2-2c-1.1045 0-2 .8954-2 2s.8955 2 2 2ZM8.67 20.251a3.5935 3.5935 0 0 0-3.294 2.317.5.5 0 0 0 .47.683h5.647a.4998.4998 0 0 0 .5024-.4429.4994.4994 0 0 0-.0314-.2401 3.5934 3.5934 0 0 0-3.294-2.317Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default BrowserUserHelpIcon;
