import { useEffect, useState } from "react";
import { Formik } from "formik";
import styled from "styled-components";
import Button from "../../../components/Button";
import HeaderBackButtonTitle from "../../../components/HeaderBackButtonTitle";
import Checkbox from "../../../components/Checkbox";
import PlusIcon from "../../../components/icons/Plus";
import Input from "../../../components/Input";
import InputAndSelect from "../../../components/InputAndSelect";
import Select from "../../../components/Select";
import {
  Currencies,
  RealEstateCategories,
  RealEstateCategory,
  RealEstateProductsOf,
} from "../../../types/patrimony.enums";
import { Body, Content } from "./CompletePatrimony";
import { RealEstate } from "../../../types/patrimony";
import {
  addRealEstateToPatrimony,
  deleteRealEstate,
  editRealEstate,
  getRealEstate,
} from "../../../services/patrimony.service";
import { useNavigate, useParams } from "react-router-dom";
import { queryClient } from "../../../lib/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ownedValue,
  round,
  roundPercentage,
  spacify,
  onFormattedNumericInputChange
} from "../../../utils/format.utils";
import Loader from "../../../components/Loader";
import { FormContainer } from "./AddSaving";
import DeleteButton from "../../../components/buttons/DeleteButton";

const AnnexArea = styled.div`
  .input-body {
    width: 100%;
  }
  .checkbox {
    margin-bottom: 2px;
    font-size: 0.9em;
  }
`;

const AddRealEstate = () => {
  const navigate = useNavigate();
  const [hasCellar, setHasCellar] = useState(false);
  const [hasParking, setHasParking] = useState(false);
  const [hasTerrace, setHasTerrace] = useState(false);
  const [hasGarden, setHasGarden] = useState(false);
  const { id: sId } = useParams();
  const id = parseInt(sId || "");

  const { data: existingRealEstate, isFetching } = useQuery(
    ["existingRealEstate"],
    () => getRealEstate(id),
    { enabled: !!id, cacheTime: 0 }
  );

  const [formatted, setFormatted] = useState(
    existingRealEstate?.balance ? spacify(existingRealEstate.balance) : ""
  );

  useEffect(() => {
    if (existingRealEstate?.balance)    setFormatted(spacify(existingRealEstate.balance));
    if (existingRealEstate?.cellarArea) setHasCellar(true);
    if (existingRealEstate?.parkingArea) setHasParking(true);
    if (existingRealEstate?.terraceArea) setHasTerrace(true);
    if (existingRealEstate?.gardenArea) setHasGarden(true);
  }, [existingRealEstate])

  const { mutateAsync: tryDelete, isLoading: isDeleting } = useMutation({
    mutationFn: deleteRealEstate,
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      navigate("/patrimoine");
    },
    onError: (e) => console.error(e),
  });

  const { mutateAsync: tryAdd, isLoading } = useMutation({
    mutationFn: existingRealEstate ? editRealEstate : addRealEstateToPatrimony,
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      navigate("/patrimoine");
    },
    onError: (e) => console.error(e),
  });

  return (
    <Body className="complete-patrimony-body">
      <HeaderBackButtonTitle
        backTo={sId ? "/patrimoine" : "/patrimoine/completer"}
        title={isFetching ? <Loader /> : `${existingRealEstate ? "Modifier" : "Ajouter"} un bien immobilier`}
      />
      <Content>
        {isFetching ? (
          <Loader />
        ) : (
          <Formik
            initialValues={
              (id && existingRealEstate) ||
              ({
                name: "",
                category: "MAIN_RESIDENCE",
                product: "HOUSE",
                balance: undefined,
                currency: "EUR",
                ownershipPercentage: 0,
                livingArea: undefined,
                address: "",
                city: "",
                zipCode: "",
                cellarArea: undefined,
                parkingArea: undefined,
                terraceArea: undefined,
                gardenArea: undefined,
              } as Partial<RealEstate>)
            }
            onSubmit={(values) => {
                // Cast balance to number before submitting, it's an input type text to allow digits formatting 
    
                //@ts-ignore
                if (typeof values.balance === 'string' && values.balance?.length > 0) {
                    //@ts-ignore
                    values.balance = Number(values?.balance?.replace(/[^0-9.]+/g, ""));
                }
                if (typeof values.balance === 'number') {
                    values.balance = Math.round(values.balance)
                }
                tryAdd(values)
            }}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              isSubmitting,
            }) => (
              <FormContainer onSubmit={handleSubmit}>
                <div className="fields">
                  <Input
                    name="name"
                    onChange={handleChange}
                    placeholder="Maison"
                    label="Nom du bien"
                    value={values.name}
                    required
                  />
                  <Select
                    name="category"
                    optionsEnum={RealEstateCategories}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const category = e.target.value as RealEstateCategory;
                      if (!RealEstateProductsOf[category][values.product!]) {
                        setFieldValue(
                          "product",
                          Object.keys(RealEstateProductsOf[category])[0]
                        );
                      }
                      handleChange("category")(e);
                    }}
                    label="Catégorie"
                    value={values.category}
                    required
                  />
                  <Select
                    name="product"
                    optionsEnum={
                      RealEstateProductsOf[values.category || "MAIN_RESIDENCE"] as Record<string, string>
                    }
                    onChange={handleChange("product")}
                    label="Produit"
                    value={values.product}
                    required
                  />
                  <InputAndSelect
                    type="text"
                    inputMode="numeric"
                    name="balance"
                    selectName="currency"
                    optionsEnum={Currencies}
                    onChange={(e) => onFormattedNumericInputChange(e, setFormatted, handleChange)}
                    onSelectChange={handleChange("currency")}
                    placeholder="Montant"
                    label="Valeur actuelle estimée pour 100%"
                    value={formatted}
                    selectValue={values.currency}
                    min={0}
                    required
                  />
                  <Input
                    type="number"
                    unit="%"
                    name="ownershipPercentage"
                    onChange={(e) => {
                      const newValue = roundPercentage(parseFloat(e.target.value) / 100);
                      setFieldValue("ownershipPercentage", newValue > 1 ? 1 : newValue);
                    }}
                    placeholder="Pourcentage de détention"
                    label="Pourcentage de détention"
                    value={round((values.ownershipPercentage || 0) * 100) || ""}
                    min={0}
                    max={100}
                    step={0.01}
                    required
                  />
                  <Input
                    unit="€"
                    placeholder="Valeur pour le % détenu"
                    label="Valeur pour le % détenu"
                    value={spacify(
                      ownedValue(
                        values?.balance,
                        values?.ownershipPercentage
                      ) || 0
                    )}
                    readOnly
                    disabled
                  />
                  <Input
                    type="number"
                    unit="m²"
                    name="livingArea"
                    onChange={handleChange}
                    placeholder="Surface habitable"
                    label="Surface habitable"
                    value={values.livingArea || ""}
                    min={0}
                    required
                  />
                  <Input
                    placeholder="Prix au m²"
                    label="Prix au m²"
                    value={
                      values.livingArea && values.balance
                        ? (values.balance / values.livingArea).toFixed(0)
                        : ""
                    }
                    readOnly
                    disabled
                  />
                  <Input
                    name="address"
                    onChange={handleChange}
                    placeholder="Numéro, rue"
                    label="Adresse"
                    value={values.address}
                  />
                  <Input
                    name="city"
                    onChange={handleChange}
                    placeholder="Ville"
                    label="Ville"
                    value={values.city}
                  />
                  <Input
                    name="zipCode"
                    onChange={handleChange}
                    placeholder="Code postal"
                    label="Code postal"
                    value={values.zipCode}
                  />
                  <div />
                  <AnnexArea>
                    <Checkbox
                      label="Cave"
                      checked={hasCellar}
                      onChange={(e) => {
                        setHasCellar(e.target.checked);
                        if (!e.target.checked)
                          setFieldValue("cellarArea", undefined);
                      }}
                    />
                    <Input
                      type="number"
                      unit="m²"
                      name="cellarArea"
                      onChange={handleChange}
                      placeholder="Surface de la cave"
                      value={values.cellarArea || ""}
                      min={0}
                      disabled={!hasCellar}
                      readOnly={!hasCellar}
                      required={hasCellar}
                    />
                  </AnnexArea>
                  <AnnexArea>
                    <Checkbox
                      label="Parking"
                      checked={hasParking}
                      onChange={(e) => {
                        setHasParking(e.target.checked);
                        if (!e.target.checked)
                          setFieldValue("parkingArea", undefined);
                      }}
                    />
                    <Input
                      type="number"
                      unit="m²"
                      name="parkingArea"
                      onChange={handleChange}
                      placeholder="Surface du parking"
                      value={values.parkingArea || ""}
                      min={0}
                      disabled={!hasParking}
                      readOnly={!hasParking}
                      required={hasParking}
                    />
                  </AnnexArea>
                  <AnnexArea>
                    <Checkbox
                      label="Terrasse"
                      checked={hasTerrace}
                      onChange={(e) => {
                        setHasTerrace(e.target.checked);
                        if (!e.target.checked)
                          setFieldValue("terraceArea", undefined);
                      }}
                    />
                    <Input
                      type="number"
                      unit="m²"
                      name="terraceArea"
                      onChange={handleChange}
                      placeholder="Surface de la terrasse"
                      value={values.terraceArea || ""}
                      min={0}
                      disabled={!hasTerrace}
                      readOnly={!hasTerrace}
                      required={hasTerrace}
                    />
                  </AnnexArea>
                  <AnnexArea>
                    <Checkbox
                      label="Jardin"
                      checked={hasGarden}
                      onChange={(e) => {
                        setHasGarden(e.target.checked);
                        if (!e.target.checked)
                          setFieldValue("gardenArea", undefined);
                      }}
                    />
                    <Input
                      type="number"
                      unit="m²"
                      name="gardenArea"
                      onChange={handleChange}
                      placeholder="Surface du jardin"
                      value={values.gardenArea || ""}
                      min={0}
                      disabled={!hasGarden}
                      readOnly={!hasGarden}
                      required={hasGarden}
                    />
                  </AnnexArea>
                </div>
                <div className="buttons">
                  <Button
                    type="submit"
                    size="fullWidth"
                    icon={!existingRealEstate ? <PlusIcon /> : undefined}
                    loading={isLoading || isSubmitting}
                  >
                    {existingRealEstate ? "Enregistrer" : "Ajouter"}
                  </Button>

                  {existingRealEstate && (
                    <DeleteButton
                      size="fullWidth"
                      loading={isDeleting}
                      onClick={() => tryDelete(id)}
                    />
                  )}
                </div>
              </FormContainer>
            )}
          </Formik>
        )}
      </Content>
    </Body>
  );
};

export default AddRealEstate;
