import {
  ChangePasswordDto,
  LoginUser,
  SignupUser,
  User,
} from "./../types/user";
import { post } from "../lib/axios";

export const signup = (params: SignupUser) => {
  return post<{ jwt: string }>("/auth/signup", params);
};

export const login = (params: LoginUser) => {
  return post<{ jwt: string }>("/auth/login", params);
};

export const changePassword = (params: ChangePasswordDto) => {
  return post<{ jwt: string }>("/auth/changePassword", params);
};

export const sendForgottenPasswordMail = (email: string) => {
  return post<boolean>("/auth/sendForgottenPasswordMail", { email });
};

export const checkForgottenPasswordToken = (token: string) => {
  return post<boolean>("/auth/checkForgottenPasswordToken", { token });
};

export const changeForgottenPassword = (data: {
  token: string;
  password: string;
}) => {
  return post<void>("/auth/changeForgottenPassword", data);
};

export const sendConfimationMail = () => {
  return post<boolean>("/auth/sendConfimationMail");
};

export const emailConfirmed = (token: string) => {
  return post<User>("/auth/emailConfirmed", { token });
};

export const sendConfirmationCode = () => {
  return post<boolean>("/auth/sendConfirmationCode");
}

export const checkConfirmationCode = (code: string) => {
  return post<User>("/auth/checkConfirmationCode", { code });
}