import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import Button from "../../components/Button";
import BackButton from "../../components/buttons/BackButton";
import EditIcon from "../../components/icons/Edit";
import Link from "../../components/Link";
import Loader from "../../components/Loader";
import SearchBar from "../../components/SearchBar";
import Transactions, { MovementsDay } from "../../components/Transactions";
import { getAccount, getConnection } from "../../services/patrimony.service";
import { getMe } from "../../services/user.service";
import { AccountType, formatInvestmentName } from "../../types/movements";
import { ConnectionStateMessage } from "../../types/connection";
import { Credit, Saving } from "../../types/patrimony";
import { getBiReconnectUrl } from "../../utils/budget-insight.utils";
import { currency, formatUpdatedAt } from "../../utils/format.utils";
import { ObjectKeys } from "../../utils/utils";
import { CreditForm } from "./complete/AddCredit";
import { calcRemainingMaturities } from "../analysis-tools/utils/calcRemainingMaturities";

export const Body = styled.div`
  background-color: var(--gray-100);
  display: grid;
  grid-template-rows: min-content auto;
  overflow: hidden;
  @media (max-width: 900px) {
    background-color: var(--black) !important;
  }
  @media (min-width: 900px) {
    margin-top: 16px;
    border-top-left-radius: 32px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 900px) {
    padding: 32px 32px 16px 32px;
  }
  @media (max-width: 900px) {
    padding: 16px;
    color: var(--white);
  }
`;

export const NameAndBalance = styled.div`
  transition: 0.2s;
  font-size: 1.125rem;
  flex: auto;

  display: grid;
  text-align: center;

  .account-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .account-balance {
    font-weight: 600;
  }

  @media (max-width: 900px) {
    font-size: 16px;
    text-align: start;
  }
`;

export const FiltersContainer = styled.div`
  transition: 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;
  .search-bar {
    flex-grow: 1;
  }
  .filter-icon {
    flex-shrink: 0;
  }
  /* TODO */
  /* flex-grow: 1; */
`;

export const Container = styled.div`
  background-color: var(--gray-100);
  overflow: hidden auto;
  padding: 0 0 32px 32px;
  @media (max-width: 900px) {
    padding: 0 0 16px 16px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
`;

const LoaderContainer = styled.div`
  margin-top: 16px;
`;

const Content = styled.div`
  overflow: auto;
  padding-right: 32px;
  @media (max-width: 900px) {
    padding-right: 16px;
  }
`;

const Label = styled.div`
  margin-top: 32px;
`;

const Investments = styled.div``;

const StateBanner = styled.div<{ hasError: boolean }>`
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid var(--success-500);
  padding: 8px;
  background-color: #209e000d;
  color: var(--success-500);
  font-size: 14px;

  ${({ hasError }) =>
    hasError
      ? css`
          color: var(--error-500) !important;
          border-color: 1px solid var(--error-500);
          background-color: #b600000d;
        `
      : ""}

  @media (max-width: 900px) {
    margin-top: 16px;
  }
`;

const ReconnectLink = styled.a`
  text-decoration: underline;
`;

type EditAccountButtonProps = {
  frType: FrType;
  id: number;
  fromFuturePatrimony?: boolean;
};
export const EditAccountButton = ({
  frType,
  id,
  fromFuturePatrimony,
}: EditAccountButtonProps) => (
  <Link
    to={`/patrimoine/completer/${frType}/${id}${fromFuturePatrimony ? "?f" : ""}`}
  >
    <Button size="minimum" variant="transparent" noBoxShadow>
      <EditIcon />
    </Button>
  </Link>
);

const AccountTypes = {
  epargne: "saving" as AccountType,
  credit: "credit" as AccountType,
};
type FrType = keyof typeof AccountTypes;

const AccountPage = ({ frType }: { frType: FrType }) => {
  const { id: sId } = useParams<{ id: string }>();
  const id = parseInt(sId || "");
  const type = AccountTypes[frType];
  const [displayAccountNameAndBalance, setDisplayAccountNameAndBalance] = useState(true);
  const { data: account, isLoading: isAccountLoading } = useQuery(
    ["myPatrimony", "account", type, id],
    () => getAccount(id, type),
    { enabled: !!id }
  );
  const saving = type === "saving" ? (account as Saving) : undefined;
  const credit = type === "credit" ? (account as Credit) : undefined;
  const allTransactions = saving?.transactions || [];
  const investments = saving?.investments || [];

  const { data: connection, isLoading: isConnectionLoading } = useQuery(
    ["connections", "connection", type, id],
    () => getConnection(account!.biConnectionId!),
    { enabled: !!(id && account?.biConnectionId) }
  );

  const { data: user } = useQuery(["user"], getMe);

  const hasError = !!connection?.state;
  let biReconnectUrl = "";
  let connectionStateMessage = "";
  if (connection?.state && user) {
    biReconnectUrl = getBiReconnectUrl(user.biAuthToken, connection);
    connectionStateMessage = ConnectionStateMessage[connection.state];
  }

  const [searchValue, setSearchValue] = useState("");
  const transactions = allTransactions.filter((t) =>
    t.name.toLowerCase().includes(searchValue)
  );

  return (
    <Body>
      <Header>
        <Link to="/patrimoine">
          <BackButton />
        </Link>
        {isAccountLoading ? (
          <Loader />
        ) : (
          <>
            {displayAccountNameAndBalance && (
                <NameAndBalance>
                    <div className="account-name">{account?.name}</div>
                    <div className="account-balance">
                        {account && currency(account.balance, account.currency)}
                    </div>
                </NameAndBalance>
            )}
            
            <FiltersContainer style={displayAccountNameAndBalance ? {} : { flex: 1 }}>
              {saving && (
                <SearchBar
                  placeholder="Rechercher une transaction"
                  onChange={(v) => setSearchValue(v.toLowerCase())}
                  value={searchValue}
                  onFocus={() => setDisplayAccountNameAndBalance(false)}
                  onBlur={() => setDisplayAccountNameAndBalance(true)}
                />
              )}
              <EditAccountButton frType={frType} id={id} />
            </FiltersContainer>
          </>
        )}
      </Header>
      <Container>
        <Content>
          {isConnectionLoading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <StateBanner hasError={hasError}>
              <div>
                {account?.biUpdatedAt &&
                  formatUpdatedAt(account.biUpdatedAt, hasError)}
              </div>
              {hasError && (
                <div>
                  Synchronisation en pause -{" "}
                  {connectionStateMessage && `${connectionStateMessage} `}
                  {biReconnectUrl && (
                    <ReconnectLink href={biReconnectUrl}>
                      Cliquez ici pour la rétablir
                    </ReconnectLink>
                  )}
                </div>
              )}
            </StateBanner>
          )}

          {saving && (
            <>
              {investments.length > 0 && (
                <>
                  Investissements
                  <Investments>
                    <MovementsDay
                      movements={investments.map((i) => ({
                        ...i,
                        name: formatInvestmentName(i.name, i.product),
                      }))}
                      currency={saving.currency}
                    />
                  </Investments>
                  {transactions.length > 0 && <Label>Transactions</Label>}
                </>
              )}

              <Transactions
                transactions={transactions}
                currency={saving.currency}
                highlightIncomes
              />
            </>
          )}

          {credit && <CreditForm existingCredit={credit} disabledForm={true} />}
        </Content>
      </Container>
    </Body>
  );
};

const Account = () => (
  <Routes>
    {ObjectKeys(AccountTypes).map((frType) => (
      <Route
        key={frType}
        path={`${frType}/:id`}
        element={<AccountPage frType={frType} />}
      />
    ))}
    <Route path="*" element={<Navigate to="/patrimoine" replace />} />
  </Routes>
);

export default Account;
