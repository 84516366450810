import axios, { AxiosResponse } from "axios";
import { config } from "../config/config";
import { getJwt } from "../utils/storage.utils";

axios.defaults.baseURL = config.apiUrl;
const getBaseHeaders = () => ({
  authorization: getJwt(),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export const get = async <T>(url: string, params?: any, headers?: object) => {
  const response = await axios.get<any, AxiosResponse<T>>(url, {
    params,
    headers: {
      ...getBaseHeaders(),
      ...(headers || {}),
    },
  });
  return response.data;
};

export const post = async <T>(url: string, params?: any, headers?: object) => {
  const response = await axios.post<any, AxiosResponse<T>>(url, params, {
    headers: {
      ...getBaseHeaders(),
      ...(headers || {}),
    },
  });
  return response.data;
};

export const httpDelete = async <T>(url: string, params?: any) => {
  const response = await axios.delete<any, AxiosResponse<T>>(url, {
    params,
    headers: getBaseHeaders(),
  });
  return response.data;
};
