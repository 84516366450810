import { BudgetCategory } from "./budget.enums";
import { format } from "date-fns";
import { SavingLifeInsuranceProduct, SavingProducts } from "./patrimony.enums";
import { PatrimonyAsset } from "./patrimony";

export type AccountType = "saving" | "credit";

export interface Movement {
  id: number;
  name?: string;
  value: number | undefined;
  /** Datetime */
  date: string;
  bankName?: string;
}

export interface Transaction extends Movement {
  bankName: string;
  name: string;
  category: BudgetCategory;
  savingBiAccountId: number;
}

export type Investment = Movement &
  Partial<Pick<PatrimonyAsset, "annualYield" | "riskLevel" | "eurValue">> & {
    product?: SavingLifeInsuranceProduct;
    savingId: number;
  };

export type UpdateTransactionDto = Pick<Transaction, "id" | "category">;

export type UpdateInvestmentDto = Pick<Investment, "id"> &
  Partial<Pick<Investment, "annualYield" | "riskLevel">>;

export function formatInvestmentName(
  name: string | undefined,
  product: Investment["product"]
) {
  const suffix =
    product === "FONDS_EUROS"
      ? " (Fonds Euros)"
      : product === "UC"
      ? " (UC)"
      : "";
  return name + suffix || (product && SavingProducts.LIFE_INSURANCE[product]);
}

export function newInvestment(
  product: SavingLifeInsuranceProduct
): Omit<Investment, "id" | "savingId"> {
  return { value: 0, product, date: format(new Date(), "yyyy-MM-dd") };
}
