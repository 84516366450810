import styled from "styled-components";
import {
  Content,
  Body as PatrimonyBody,
  Header as PatrimonyHeader,
} from "../my-patrimony/Patrimony";
import { useQuery } from "@tanstack/react-query";
import { getAdvisor } from "../../services/advisor.service";
import { useEffect, useState } from "react";
import { Advisor } from "../../types/advisor.enums";
import Chip from "../../components/Chip";
import { Link } from "react-router-dom";
import BackButton from "../../components/buttons/BackButton";
import CalendlyButton from "../../components/CalendlyButton";
import { config } from "../../config/config";

const Body = styled(PatrimonyBody)``;

const Header = styled(PatrimonyHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  .header-button {
    margin-left: auto;
  }

  @media (max-width: 900px) {
    color: var(--white);
    h1 {
      margin-right: auto;
      margin-left: auto;
    }
  }
`;

const StyledBox = styled.div`
  background-color: var(--white);
  border: 1px solid "var(--gray-300)";
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 2rem;
  .no-icon {
    display: none;
  }
  img {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
  }

  .personal-information {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
  }

  .biography {
    white-space: pre-line;
  }

  .chip-group {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 1rem;
    align-self: center;
  }
`;

const AdvisorProfil = () => {
  const id = Number(window.location.pathname.split("/").pop());
  const [advisor, setAdvisors] = useState<Advisor>();

  const { data, isSuccess } = useQuery(["advisor", id], () =>
    getAdvisor(id ? id : 0).then((res) => res)
  );

  useEffect(() => {
    if (isSuccess) {
      setAdvisors(data);
    }
  }, [data, isSuccess]);

  if (!advisor) {
    return <div>Loading...</div>;
  }

  return (
    <Body>
      <Header>
        <Link to="/mon-conseiller">
          <BackButton />
        </Link>
        <h1>
          {advisor.firstname} {advisor.lastname}
        </h1>
        <CalendlyButton
          url={advisor.url_calendly}
          className="desktop-only header-button"
          id_advisor={advisor.id}
        />
      </Header>
      <Content>
        <StyledBox>
          <div className="personal-information">
            <img
              src={config.bucketUrl + advisor.profilePicture}
              alt="Profil du conseiller"
            ></img>
            <div className="information">
              <p className="name">
                <b>
                  {advisor.firstname} {advisor.lastname}
                </b>
              </p>
              <p className={"adress-company"}>
                {advisor.adress}, {advisor.company}
              </p>
            </div>
          </div>
          <div className="chip-group">
            {advisor.expertise.map((expertise) => (
              <Chip label={expertise.expertise.label} active={true} />
            ))}
          </div>
          <div className="biography">
            <p>{advisor.biography}</p>
          </div>
          <CalendlyButton
            className="sticky mobile-only"
            url={advisor.url_calendly}
            id_advisor={advisor.id}
          />
        </StyledBox>
      </Content>
    </Body>
  );
};

export default AdvisorProfil;
