import React, { FC } from "react";
import styled, { css } from "styled-components";

type Props = {
  active?: boolean;
  variant?: "classic" | "profile";
} & React.HTMLProps<HTMLDivElement>;

/* eslint-disable @typescript-eslint/no-unused-vars */
const BoxComponent: FC<Props> = ({ active, variant, ...props }) => {
  return <div {...props}></div>;
};

const Box = styled(BoxComponent)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.4rem;
  padding: 0.6rem 0.7rem;
  margin-bottom: 0.2em;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  outline-offset: -1px;

  > {
    .no-icon,
    svg {
      width: 1.4rem;
      flex-shrink: 0;
    }
  }

  .title {
    flex: auto;
    white-space: nowrap;
  }

  ${({ active, variant }) => {
    return variant === "profile"
      ? css`
          background-color: var(--white);
          border: 1px solid ${active ? "var(--yellow-700)" : "var(--gray-300)"};
          border-radius: 8px;
          padding: 1rem;
          .no-icon {
            display: none;
          }
          &:hover {
            border-color: ${!active ? "var(--gray-400)" : ""};
          }
          &:active {
            filter: brightness(0.98) !important;
          }
        `
      : css`
          background-color: ${active ? "var(--gray-800)" : "transparent"};
          &:hover {
            &:not(:has(button:hover)) {
              background-color: ${!active ? "var(--gray-800)" : ""};
            }
          }
          &:active {
            filter: brightness(0.95) !important;
          }
        `;
  }}
`;

export default Box;
