import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Content as CompletePatrimonyContent } from "../my-patrimony/complete/CompletePatrimony";
import {
  PageTitle,
  Body as PatrimonyBody,
  Header as PatrimonyHeader,
} from "../my-patrimony/Patrimony";
import styled from "styled-components";
import CalendarCheckIcon from "../../components/icons/CalendarCheck";
import UsersIcons from "../../components/icons/Users";
import FilterExpertise from "./FilterExpertise";
import AdvisorProfil from "./AdvisorProfil";
import React from "react";
import { Meeting } from "../../types/meeting.enums";
import { getMeetings } from "../../services/meeting.service";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import MeetingCard from "./MeetingCard";
import MyMeeting from "./MyMeeting";
import UserCircle from "../../components/icons/UserCircle";
import ProfileButton from "../../components/ProfileButton";

const Body = styled(PatrimonyBody)``;

const HeaderText = styled.div`
  @media (max-width: 900px) {
    color: var(--white);
    font-size: 14px;
    padding-top: 8px;
  }
`;

const Content = styled(CompletePatrimonyContent)`
  @media (max-width: 900px) {
    background-color: var(--gray-100);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
`;

const StyledBox = styled.div`
   {
    background-color: var(--white);
    border: 1px solid "var(--gray-300)";
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    .no-icon {
      display: none;
    }
    .title {
      flex: auto;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 600;
      margin-left: 1rem;
    }
    .description {
      flex-basis: 100%;
      font-weight: 400;
    }
    @media (max-width: 900px) {
      .title {
        font-size: 16px;
      }
    }
  }
`;

const MyAdvisorPage = () => {
  const navigate = useNavigate();
  const { data: myMeetings = [], isLoading: isLoadingMeetings } = useQuery(
    ["meetings"],
    getMeetings,
    { refetchOnWindowFocus: true }
  );

  return (
    <Body>
      <PatrimonyHeader>
        <div className="header-first-row">
          <div className="left-side">
            <div className="mobile-only logo">
              <ProfileButton onClick={() => navigate("/profil")}>
                <UserCircle />
              </ProfileButton>
            </div>
            <PageTitle className="desktop-only" noPaddingBottom>
              Mon Conseiller Patrimonial
            </PageTitle>
          </div>
        </div>
        <HeaderText className="mobile-only">
          Nous sélectionnons rigoureusement des conseillers patrimoniaux pour
          vous. Vous pouvez prendre rendez-vous pour un entretien personnalisé
          offert de 30 minutes avec l’un des conseillers ci-dessous. Offre
          limitée à deux rendez-vous par mois.
        </HeaderText>
      </PatrimonyHeader>
      <Content>
        <StyledBox>
          <CalendarCheckIcon />
          <div className="title">Mes prochains rendez-vous</div>
          {isLoadingMeetings ? (
            <Loader />
          ) : (
            <div className="description">Aucun rendez-vous à venir</div>
          )}

          {isLoadingMeetings ? (
            <Loader />
          ) : (
            myMeetings.map((meeting: Meeting) => (
              <MeetingCard key={meeting.id} meeting={meeting} />
            ))
          )}
        </StyledBox>
        <StyledBox>
          <UsersIcons />
          <div className="title">Nos conseillers patrimoniaux</div>
          <div className="description desktop-only">
            Nous sélectionnons rigoureusement des conseillers patrimoniaux pour
            vous. Vous pouvez prendre rendez-vous pour un entretien personnalisé
            offert de 30 minutes avec l’un des conseillers ci-dessous. Offre
            limitée à <b>deux rendez-vous par mois</b>.<br />
            Veuillez sélectionner ci-dessous les expertises demandées pour
            afficher les conseillers pertinents.
          </div>
          <FilterExpertise />
        </StyledBox>
      </Content>
    </Body>
  );
};

const MyAdvisor = () => (
  <Routes>
    <Route path="/" element={<MyAdvisorPage />} />
    <Route path="*" element={<Navigate to="/mon-conseiller" replace />} />
    <Route path="/:advisor" element={<AdvisorProfil />} />
    <Route path="/rendez-vous/:id" element={<MyMeeting />} />
  </Routes>
);

export default MyAdvisor;
