import { useState } from "react";
import { NO_BREAK_SPACE } from "../../utils/format.utils";
import Button, { ButtonProps } from "../Button";
import DeleteIcon from "../icons/Delete";
import ActionModal from "../modals/ActionModal";

type Props = {
  confirmationMessage?: string;
  onClick: () => void;
  label?: string;
} & ButtonProps;

const DeleteButton = ({
  confirmationMessage,
  onClick,
  loading,
  ...props
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ActionModal
        title={
          confirmationMessage
            ? confirmationMessage
            : `Êtes-vous sûr${NO_BREAK_SPACE}?`
        }
        open={open}
        close={() => setOpen(false)}
        apply={onClick}
        loading={loading}
      />
      <Button
        type="button"
        variant="white"
        icon={props.icon || <DeleteIcon />}
        {...props}
        onClick={() => setOpen(true)}
      >
        {props.label ? props.label : "Supprimer"}
      </Button>
    </>
  );
};

export default DeleteButton;
