import React, { createRef, FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useResolvedPath } from "react-router-dom";
import styled from "styled-components";
import { NO_BREAK_SPACE, round } from "../../utils/format.utils";
import Box from "./Box";
import RotatingChevron from "./RotatingChevron";
import { getPathMatch } from "./utils";

const Container = styled.li`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 0.5rem;
`;

const LinksContainer = styled.div<{
  height: number | "auto";
  selectedItem?: number;
  itemsLength: number;
}>`
  height: ${(p) => (p.height === "auto" ? "auto" : `${p.height}px`)};
  overflow: hidden;
  transition: 0.2s;
  font-size: 0.9em;

  position: relative;

  .electron {
    z-index: 1;
    position: absolute;
    left: 0.5em;
    top: ${(p) =>
      `calc( ${(p.selectedItem || 0) / p.itemsLength} * (${
        +p.height || 0
      }px + 0.2em))`};
    color: var(--yellow-400);
    pointer-events: none;
    user-select: none;
    transition: 0.2s;
  }
`;

const useRefHeight = (ref: React.RefObject<HTMLElement>) => {
  const [height, setHeight] = useState<number | "auto">("auto");
  useEffect(() => {
    if (ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;
      setHeight(round(height, 2));
      setTimeout(() => {
        const boundingRect = current.getBoundingClientRect();
        const { height } = boundingRect;
        setHeight(round(height, 2));
      }, 200)
    }
  }, [ref]);
  return height;
};

type ContextType = {
  open: boolean;
};
export const LinkDropDownContext = React.createContext({} as ContextType);
const { Provider } = LinkDropDownContext;

type LinkDropDownProps = {
  children: React.ReactNode;
  icon: React.ReactElement;
  title: string;
  href: string;
  selectedItem?: number;
  itemsLength: number;
};
const LinkDropDown: FC<LinkDropDownProps> = ({
  icon,
  title,
  href,
  children,
  selectedItem,
  itemsLength,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const secondaryList = createRef<HTMLUListElement>();
  // Code copied directly from the NavLink definition
  const location = useLocation();
  const path = useResolvedPath(href);

  const { isPath, isSubPath } = useMemo(
    () => getPathMatch(location, path),
    [location, path]
  );

  const isActive = isPath || isSubPath;

  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  const height = useRefHeight(secondaryList);

  const handleClick = () => {
    navigate(href, isPath ? { replace: true } : {});
  };

  return (
    <Provider value={{ open }}>
      <Container>
        <Box
          active={isActive}
          onClick={handleClick}
          tabIndex={0}
          onKeyUp={(e) => {
            if (e.code === "Enter") {
              handleClick();
            }
          }}
        >
          {icon}
          <div className="title">{title}</div>
          <RotatingChevron active={isActive} open={open} />
        </Box>
        <LinksContainer
          height={open ? height : 0}
          selectedItem={selectedItem}
          itemsLength={itemsLength}
        >
          <Box className="electron">
            <div className="no-icon bullet" children={NO_BREAK_SPACE} />
          </Box>
          <ul ref={secondaryList}>{children}</ul>
        </LinksContainer>
      </Container>
    </Provider>
  );
};

export default LinkDropDown;
