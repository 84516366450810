import { config } from "../config/config";
import { Connection } from "../types/connection";

export const getBiWebviewUrl = (biAuthToken?: string) => {
  const codeParam = biAuthToken ? `&code=${biAuthToken}` : "";
  const flow = biAuthToken ? "manage" : "connect";

  return `${config.bi.apiUrl}/auth/webview/${flow}?client_id=${config.bi.clientId}${codeParam}&redirect_uri=${config.bi.redirectUri}`
};


export const getBiReconnectUrl = (
  biAuthToken: string,
  connection: Connection
) => {
  let flowParam, codeParam, connexionIdParam: string;

  if (
    ["rateLimiting", "websiteUnavailable", "bug"].includes(
      connection.state || ""
    )
  ) {
    return "";
  } else if (connection.state === "webauthRequired") {
    flowParam = "webauth";
    codeParam = `token=${biAuthToken}`;
    connexionIdParam = `id_connection=${connection.id}`;
  } else {
    flowParam = "auth/webview/reconnect";
    codeParam = `code=${biAuthToken}`;
    connexionIdParam = `connection_id=${connection.id}`;
  }

  return `${config.bi.apiUrl}/${flowParam}?client_id=${config.bi.clientId}&${codeParam}&${connexionIdParam}&redirect_uri=${config.bi.redirectUri}`;
};
