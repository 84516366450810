const CircledHandCoinsIcon = ({
  fill = "#A52A2A",
  big,
}: {
  fill?: string;
  big?: boolean;
}) => (
  <svg
    width={big ? 48 : 24}
    height={big ? 48 : 24}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M15 5.33334C13.3431 5.33334 12 6.67649 12 8.33334C12 9.9902 13.3431 11.3333 15 11.3333C16.6569 11.3333 18 9.9902 18 8.33334C18 6.67649 16.6569 5.33334 15 5.33334Z" />
      <path d="M12.0211 5.18616C11.1869 5.97598 10.6666 7.09388 10.6666 8.33332C10.6666 9.13138 10.8823 9.87906 11.2587 10.5212C10.9672 10.6156 10.6562 10.6667 10.3333 10.6667C8.6764 10.6667 7.33325 9.32351 7.33325 7.66666C7.33325 6.0098 8.6764 4.66666 10.3333 4.66666C10.9591 4.66666 11.5403 4.85832 12.0211 5.18616Z" />
      <path d="M15.8059 14.5955C15.8059 15.1231 15.457 15.5692 14.976 15.7183L15.2853 15.704C15.6233 15.704 15.9519 15.5977 16.222 15.4003L18.1079 14.0244C18.5786 13.6811 19.2374 13.7267 19.6535 14.1315C20.1156 14.581 20.1156 15.3092 19.6535 15.7579L18.0353 17.3321C17.5997 17.7557 17.0439 18.0451 16.4398 18.1627L14.1527 18.6077C13.6991 18.6958 13.2315 18.6852 12.7827 18.5758L10.7141 18.0731C10.4667 18.0124 10.213 17.982 9.95773 17.982C9.68673 17.982 9.55123 17.982 9.44252 17.9432C9.25422 17.876 9.10604 17.7278 9.03881 17.5395C9 17.4308 9 17.2953 9 17.0243V14.7382C9 14.5477 9 14.4524 9.02447 14.3646C9.04614 14.2868 9.08177 14.2136 9.12961 14.1485C9.18361 14.0751 9.25859 14.0163 9.40854 13.8988L10.1989 13.2791C10.1989 13.2791 11.5088 12.6667 12.2324 12.6667C12.5321 12.6667 12.8303 12.7031 13.1215 12.7753L14.9106 13.2201C15.4367 13.3505 15.8059 13.8209 15.8059 14.3603V14.5955Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31578 12.6667C6.32165 12.6667 6.32753 12.6667 6.33342 12.6667C6.33931 12.6667 6.34519 12.6667 6.35107 12.6667C6.4911 12.6667 6.62752 12.6666 6.74357 12.6745C6.87048 12.6832 7.02097 12.7035 7.177 12.7681C7.5037 12.9035 7.76326 13.1631 7.89859 13.4897C7.96322 13.6458 7.98354 13.7963 7.9922 13.9232C8.00012 14.0392 8.0001 14.1757 8.00009 14.3157C8.00008 14.3215 8.00008 14.3275 8.00008 14.3333L8.00009 17.6843C8.0001 17.8243 8.00012 17.9607 7.9922 18.0768C7.98354 18.2037 7.96322 18.3542 7.89859 18.5103C7.76326 18.8369 7.5037 19.0965 7.177 19.2318C7.02097 19.2965 6.87048 19.3168 6.74357 19.3255C6.62752 19.3333 6.4911 19.3333 6.35107 19.3333H6.31576C6.17574 19.3333 6.03932 19.3333 5.92327 19.3255C5.79635 19.3168 5.64587 19.2965 5.48984 19.2318C5.16314 19.0965 4.90357 18.8369 4.76824 18.5103C4.70362 18.3542 4.6833 18.2037 4.67464 18.0768C4.66672 17.9607 4.66674 17.8243 4.66675 17.6843V14.3333C4.66675 14.3275 4.66675 14.3215 4.66675 14.3157C4.66674 14.1757 4.66672 14.0392 4.67464 13.9232C4.6833 13.7963 4.70362 13.6458 4.76824 13.4897C4.90357 13.1631 5.16314 12.9035 5.48984 12.7681C5.64587 12.7035 5.79636 12.6832 5.92327 12.6745C6.03932 12.6666 6.17574 12.6667 6.31578 12.6667Z"
      />
    </g>
    <circle opacity="0.2" cx="12" cy="12" r="12" />
  </svg>
);

export default CircledHandCoinsIcon;
