import styled from "styled-components";
import Button from "../../components/Button";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../services/auth.service";
import Link from "../../components/Link";
import AuthenticationLayout from "./AuthenticationLayout";
import Input from "../../components/Input";
import { Formik } from "formik";
import { onSuccessLogin } from "../../utils/auth.utils";
import { AxiosError } from "axios";
import { LoginUser } from "../../types/user";
import { NO_BREAK_SPACE } from "../../utils/format.utils";

export const FormContainer = styled.form`
  width: 460px;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  .change-box {
    text-align: center;
    .change-text {
      margin: 16px 0 8px;
    }
    .change-link {
      font-weight: 600;
    }
  }

  > button[type="submit"] {
    margin-top: 24px;
  }
  .error-message {
    margin-top: 4px;
    font-size: 0.9em;
    margin-left: 2px;
    color: var(--error-500);
  }

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 64px;
  }
`;

export const Title = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 46px;
  @media (max-width: 900px) {
    font-size: 1.5rem;
    margin: auto;
    margin-bottom: 12px;
  }
`;

export const LinkContainer = styled.div`
  font-size: 0.9em;
  text-align: end;
  margin-top: 4px;
  a {
    &:hover {
      text-decoration: underline !important;
    }
  }
`;

const Login = () => {
  const {
    mutateAsync: tryLogin,
    isLoading,
    error,
  } = useMutation<{ jwt: string }, AxiosError, LoginUser>({
    mutationFn: login,
    onSuccess: ({ jwt }) => onSuccessLogin(jwt),
  });

  return (
    <AuthenticationLayout>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={(values) => tryLogin(values)}
      >
        {({ values, handleSubmit, handleChange }) => (
          <FormContainer onSubmit={handleSubmit}>
            <Title>Se connecter</Title>
            <Input
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              placeholder="Email"
              label="Email"
              value={values.email}
              required
            />
            <Input
              id="password"
              type="password"
              name="password"
              onChange={handleChange}
              placeholder="Mot de passe"
              label="Mot de passe"
              value={values.password}
              required
              noValidation
            />
            <LinkContainer>
              <Link to="/mot-de-passe-oublie">Mot de passe oublié ?</Link>
            </LinkContainer>
            <div className="button-container">
              {
                <div className="error-message">
                  {error
                    ? error.response?.status === 401
                      ? "Adresse email ou mot de passe incorrect."
                      : "Une erreur est survenue."
                    : NO_BREAK_SPACE}
                </div>
              }

              <Button type="submit" size="fullWidth" loading={isLoading} id="login-submit">
                Se connecter
              </Button>
            </div>
            <div className="change-box">
              <div className="change-text">
                Vous n'avez pas encore de compte ?
              </div>
              <Link to="/inscription" className="change-link">
                Inscrivez-vous
              </Link>
            </div>
          </FormContainer>
        )}
      </Formik>
    </AuthenticationLayout>
  );
};

export default Login;
