/*  CGU et CGV :            https://docs.google.com/document/d/1Cuk4r7uMa-98cFldjnIJxL_N6Y5YIgvy
    code HTML généré sur    https://wordtohtml.net/
    code JSX généré sur     https://transform.tools/html-to-jsx
    remplacer les :
<p>
    <br />
</p>
    par des <br /> (ctrl+h)
*/

import logo from "../../assets/logo.svg";
import { DetailsTab } from "../analysis-tools/RiskReturnProfile";
import { Body } from "./Cgu";

const PolitiqueConfidentialite = () => {
  //@ts-ignore
  return (
    <Body>
      <div style={{ height: "20px" }} />
      <img src={logo} alt="" height={28} />

      <h3>Politique de Confidentialité et de Protection de la Vie Privée</h3>

      <p>Entrée en vigueur : 01/03/2023</p>
      <br />
      <h5>1. OBJET</h5>
      <p>
        La transparence étant une valeur fondamentale pour Arpagon (ci-après
        Arpagon SAS ou l'Entreprise, société par actions simplifiée immatriculée
        au RCS Paris numéro 913 818 423 dont le siège social est 22, rue
        Gay-Lussac - 75005 Paris), cette dernière accorde la plus grande
        importance au respect de la vie privée de l'utilisateur et respecte en
        ce sens les dispositions de la loi informatique et liberté n° 78-17 du 6
        janvier 1978 dans sa version en vigueur et du Règlement Général sur la
        Protection des Données (ci-après le « RGPD ») applicable en France
        depuis le 25 mai 2018.
      </p>
      <br />
      <p>
        En utilisant l'outil web et mobile « Arpagon », l'utilisateur déclare
        accepter les termes de la présente politique de confidentialité.
      </p>
      <br />
      <p>
        Cette politique de confidentialité et de Protection de la Vie Privée
        permet à l'utilisateur d'en savoir plus sur l'origine et l'usage des
        informations et données personnelles collectées et traitées à l'occasion
        de son utilisation de Arpagon et de l'informer sur ses droits.
      </p>
      <br />
      <h5>2. DONNEES COLLECTEES</h5>
      <p>
        Arpagon SAS vous met à disposition, en tant qu'utilisateur final, une
        application de gestion en ligne de comptes bancaires, du budget et du
        patrimoine sous la marque Arpagon.
      </p>
      <br />
      <p>
        Arpagon SAS en sa qualité de responsable du traitement, recueille et
        traite vos Données Personnelles aux fins d'accès, d'agrégation.
      </p>
      <br />
      <p>Nous collectons des données qui relèvent des catégories suivantes :</p>
      <ul>
        <li>Mode de connexion (notamment votre adresse IP, logs) ;</li>
        <li>
          Identité (notamment vos nom, prénom, adresse email, numéro de
          téléphone) ;
        </li>
        <li>
          Situation personnelle : situation matrimoniale, nombre d'enfants,
          profession, profession du conjoint, carrière… ;
        </li>
        <li>
          Situation financière et patrimoniale : situation professionnelle,
          revenus, nature des revenus, composition du patrimoine, crédits en
          cours, impôts… ;
        </li>
        <li>
          Profil d'investisseur : connaissance des marchés financiers, attitude
          face au risque… ;
        </li>
        <li>
          Données issues de la synchronisation des comptes bancaires : produits
          financiers détenus ; nom des établissements ; soldes et mouvements des
          comptes bancaires ; soldes, supports détenus et opérations des comptes
          d'épargne ; capital restant dû des crédits…
        </li>
        <li>
          Et plus généralement toute donnée pouvant être utile à l'Utilisateur
          dans la gestion de ses finances personnelles et de son patrimoine.
        </li>
      </ul>
      <br />
      <h5>3. FINALITÉS ET FONDEMENTS DU TRAITEMENT DE DONNÉES PERSONNELLES</h5>
      <p>
        Vos données personnelles sont collectées et traitées, de manière
        adéquate, pertinente et limitée à ce qui est nécessaire au regard des
        principales finalités sur le fondement de base légale comme indiqué dans
        le tableau ci-dessous :
      </p>
      <br />

      <DetailsTab>
        <thead>
          <tr>
            <th>Finalité du traitement de vos données personnelles</th>
            <th>Fondement juridique</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Vous fournir des services d'agrégation de compte pour les
              particuliers et les professionnels (c'est à dire les personnes
              physiques dûment autorisées pour accéder au(x) compte(s) du
              titulaire personne morale)
            </td>
            <td>Exécution du contrat conclu avec vous</td>
          </tr>
          <tr>
            <td>
              Vous donner des conseils personnalisés sur la gestion de votre
              budget
            </td>
            <td>Votre consentement</td>
          </tr>
          <tr>
            <td>Constituer un fichier de clients et prospects</td>
            <td>L'intérêt légitime de Arpagon</td>
          </tr>
          <tr>
            <td>
              Vous informer au sujet des nouvelles caractéristiques, des
              nouveaux services et produits émanant de Arpagon et de ses
              partenaires
              <br />
              Adresser des newsletters, sollicitations et messages promotionnels
            </td>
            <td>L'intérêt légitime de Arpagon</td>
          </tr>
          <tr>
            <td>
              Vous informer au sujet de votre activité et du solde de vos
              comptes
            </td>
            <td>Exécution du contrat conclu avec vous</td>
          </tr>
          <tr>
            <td>
              Répondre à nos obligations réglementaires
              <br />
              Notamment en matière de lutte contre le blanchiment de capitaux et
              le financement du terrorisme, de lutte contre la fraude, de
              protection de vos Données Personnelles et de sécurité des
              processus d'accès et d'agrégation des informations de vos Comptes
              de Paiement ou d'Autres Actifs ainsi que d'initiation de paiement.
            </td>
            <td>Nos obligations légales</td>
          </tr>
          <tr>
            <td>Répondre à vos demandes adressées au support utilisateurs</td>
            <td>L'exécution d'un contrat conclu avec vous</td>
          </tr>
          <tr>
            <td>Nous permettre de mesurer l'audience</td>
            <td>
              Les outils de mesures d'audience sont utilisés pour obtenir des
              informations sur votre navigation sur notre site ou notre
              application lorsque vous êtes liés avec nous par un contrat. Ils
              permettent exclusivement d'améliorer le service fourni.
            </td>
          </tr>
        </tbody>
      </DetailsTab>

      <br />
      <h5>4. DESTINATAIRES DE VOS DONNÉES PERSONNELLES</h5>
      <p>
        Vos Données Personnelles ne sont transmises qu'aux personnes physiques
        ou morales qui ont légitimement vocation à les traiter, à savoir :
      </p>
      <ul>
        <li>
          les collaborateurs habilités dans la limite de leurs fonctions et
          missions ;
        </li>
        <li>
          les sous-traitants de Arpagon SAS dans la limite de leurs fonctions et
          missions ;
        </li>
        <li>
          notre partenaires pour l'agrégation des comptes bancaires : Budget
          Insight ;
        </li>
        <li>
          toutes entités et personnes désignées par la réglementation ainsi que
          toutes personnes auxquelles vous autorisez expressément la
          divulgation.
        </li>
        <br />
      </ul>
      <p>
        En cas de traitement de données nécessitant un transfert de données,
        votre consentement vous sera demandé.
      </p>
      <br />
      <h5>5. DURÉE DE CONSERVATION DE VOS DONNÉES PERSONNELLES</h5>
      <p>
        Vos Données Personnelles seront conservées durant toute la relation
        contractuelle, tel que requis par les politiques internes de Arpagon SAS
        et dans le respect des exigences légales nous incombant. En application
        des obligations liées à la fraude et la lutte contre le blanchiment des
        capitaux et le financement du terrorisme, elles peuvent être conservées
        au maximum pendant 5 ans à compter de la fin des relations
        contractuelles. Une fois le délai de conservation écoulé, Arpagon SAS
        détruit vos données personnelles.
      </p>
      <br />
      <h5>6. ANONYMISATION ET PSEUDONYMISATION</h5>
      <p>
        Arpagon SAS se réserve la possibilité d'anonymiser vos Données
        Personnelles, c'est à dire de faire en sorte qu'après ce traitement
        d'anonymisation, vous ne puissiez plus être identifié directement ou
        indirectement, pour pouvoir utiliser ces données anonymisées à des fins
        d'amélioration du service, de benchmark, de statistiques, de recherche,
        ou pour pouvoir partager ces données anonymisées avec ses partenaires
        commerciaux.
      </p>
      <br />
      <p>
        Lorsqu'il n'est pas possible de recourir à une technique d'anonymisation
        pour les finalités ci dessus, Arpagon se réserve le droit de
        pseudonymiser vos Données Personnelles d'une façon rendant votre
        identification impossible sans conjonction avec d'autres de vos Données
        Personnelles et de pouvoir partager ces données pseudonymisées avec ses
        partenaires commerciaux s'interdisant toute action de ré-identification.
        Les Données Personnelles partagées dans ce cadre ne pourront en aucun
        cas être utilisées afin de tenter de vous identifier, ni à fortiori afin
        de vous faire parvenir de la publicité personnalisée.
      </p>
      <br />
      <h5>7. SECURITE DES DONNEES</h5>
      <p>
        Arpagon SAS attache une importance toute particulière à la sécurité des
        Données de l'Utilisateur et met en œuvre toutes mesures appropriées aux
        fins de restreindre les risques de perte, détérioration ou mauvaise
        utilisation de celles-ci.
      </p>
      <br />
      <p>
        Compte tenu de la complexité de l'environnement internet et de la
        téléphonie mobile, Arpagon SAS ne saurait être tenue d'une obligation de
        résultat à ce titre.
      </p>
      <br />
      <p>
        L'Utilisateur a conscience que les données circulant sur internet ou sur
        les réseaux de téléphonie mobile ne sont pas nécessairement protégées,
        notamment contre les détournements éventuels, ce qu'il reconnaît.
      </p>
      <br />
      <p>
        L'Utilisateur est seul responsable de l'utilisation et de la mise en
        œuvre de moyens de sécurité, de protection et de sauvegarde de ses
        équipements, de ses données et logiciels. A ce titre, il s'engage à
        prendre toutes les mesures appropriées de façon à protéger ses propres
        données.
      </p>
      <br />
      <p>
        L'Utilisateur s'engage à ne commettre aucun acte qui pourrait mettre en
        cause la sécurité du système.
      </p>
      <br />
      <p>
        De façon générale, il est vivement recommandé à l'Utilisateur (i) de
        s'assurer que le mot de passe choisi comprend à la fois des chiffres et
        des lettres en majuscules et minuscules, de façon à être suffisamment
        complexe, (ii) de mettre à jour les logiciels dont il dispose et qui
        permettent, directement ou indirectement, d'utiliser le Service.
      </p>
      <br />
      <p>
        Il est fortement déconseillé à l'Utilisateur d'utiliser le Service sur
        un appareil mobile ou un ordinateur dont il n'est pas propriétaire ou
        dont l'usage est partagé avec une tierce personne. En ce cas, Arpagon
        SAS ne pourrait en aucun cas être tenue responsable des dommages causés
        à l'Utilisateur.
      </p>
      <br />
      <p>
        Lorsqu'il en a connaissance, l'Utilisateur s'oblige à informer sans
        délai Arpagon de toute défaillance de son Compte ou de tout doute quant
        à un éventuel accès frauduleux à son Compte.
      </p>
      <br />
      <h5>8. VOS DROITS</h5>
      <p>
        Vous disposez des droits suivants s'agissant de vos données personnelles
        :
      </p>
      <ul>
        <li>
          le droit d'être informé(e), à savoir le droit de recevoir des détails
          sur les activités de traitement effectuées par Arpagon SAS, tel que
          décrit ici ;
        </li>
        <li>
          le droit d'accès à vos Données Personnelles, à savoir le droit
          d'obtenir les informations collectées et la façon dont elles ont été
          traitées ;
        </li>
        <li>
          le droit de rectification, à savoir le droit d'obtenir la
          rectification par Arpagon SAS des données inexactes et de compléter
          les données incomplètes dans la limite des obligations réglementaires
          de Arpagon SAS ;
        </li>
        <li>
          le droit d'effacer vos données, dans la mesure où les conditions
          légales sont remplies - toutefois, à la suite de la demande
          d'effacement des données, Arpagon SAS se réserve le droit de rendre
          ces données anonymes (en les privant de leur nature personnelle) ;
        </li>
        <li>
          le droit de limiter le traitement dans la mesure où les conditions
          légales sont remplies ;
        </li>
        <li>
          le droit à la portabilité des données, à savoir (i) le droit de
          recevoir les données à caractère personnel dans un format structuré,
          couramment utilisé et lisible par machine, et (ii) le droit de
          demander que les données soient envoyées par Arpagon SAS à un autre
          responsable du traitement, dans la mesure où les conditions légales
          sont dûment remplies ;
        </li>
        <li>
          le droit de s'opposer, pour de bonnes raisons et dans les conditions
          prévues par la loi, au traitement futur des données. Toutefois,
          Arpagon SAS pourra être amener à mettre un terme aux Services Arpagon
          (dans le cas de l'impossibilité pour l'établissement de lui fournir le
          Service concerné sans le traitement en cause) ;
        </li>
        <li>le droit de définir le sort de vos données après votre décès ;</li>
        <li>
          le droit de ne pas être soumis à une décision individuelle
          automatisée, y compris le profilage, qui produit des effets juridiques
          concernant la personne concernée ou qui l'affecte de manière
          significative, uniquement sur la base d'activités de traitement
          automatisé ;
        </li>
        <li>
          le droit de retirer votre consentement à tout moment pour les
          activités de traitement effectuées par Arpagon SAS nécessitant un
          consentement. Dans ce cas, l'activité de traitement ne sera affectée
          que pour l'avenir ; et
        </li>
        <li>
          le droit de s'adresser à l'Autorité Nationale de Contrôle du
          traitement des données personnelles ou les tribunaux compétents, dans
          la mesure où vous le jugez nécessaire.
        </li>
      </ul>
      <br />
      <p>
        Pour toutes questions relatives au traitement de vos Données
        Personnelles (y compris celles concernant la manière dont vous pouvez
        exercer les droits ci-dessus), veuillez contacter le service client à
        l'adresse suivante :{" "}
        <a data-fr-linked="true" href="mailto:contact@arpagon.fr">
          contact@arpagon.fr
        </a>{" "}
        en joignant à votre demande une copie de votre pièce d'identité en cours
        de validité et en indiquant votre adresse e-mail renseignée lors de la
        souscription au Service.
      </p>
      <br />
      <p>
        Pour des raisons de sécurité et en cas de doutes sur la valeur et
        l'authenticité des justificatifs que vous produisez, Arpagon SAS se
        réserve le droit de solliciter toute autre pièce justificative.
      </p>
      <br />
      <h5>9. MODIFICATIONS</h5>
      <p>
        Nous pouvons modifier à tout moment la présente politique, afin
        notamment de nous conformer à toutes évolutions réglementaires,
        jurisprudentielles, éditoriales ou techniques. Ces modifications
        s'appliqueront à la date d'entrée en vigueur de la version modifiée pour
        tous les utilisateurs de Arpagon SAS. Vous êtes donc invité à consulter
        régulièrement la dernière version de cette politique.
      </p>
      <br />
    </Body>
  );
};
export default PolitiqueConfidentialite;
