import { get } from "../lib/axios";
import { Advisor } from "../types/advisor.enums";
import { Expertise } from "../types/expertise";

export const getByExpertise = (expertise: Expertise[]) => {
  return get<Advisor[]>("/advisor", { expertise });
};

export const getAdvisor = (id: number) => {
  return get<Advisor>("/advisor/" + id);
};
