import Radio from "./Radio";
import TitleList from "./TitleList";
import { FormikHelpers } from "formik";
import { ObjectEntries } from "../utils/utils";

type Props<T extends string> = {
  optionsEnum: Record<T, string>;
  selectedValue?: T;
  title?: string;
  name?: string;
  setFieldValue?: FormikHelpers<{}>["setFieldValue"];
  onChange?: (enumValue: T) => void;
  required?: boolean;
};
const RadioGroup = <T extends string>({
  optionsEnum,
  title,
  selectedValue,
  name,
  setFieldValue,
  onChange,
  required,
}: Props<T>) => (
  <TitleList title={title || ""}>
    {ObjectEntries(optionsEnum).map(([v, label]) => (
      <Radio
        name={name}
        key={v}
        label={label}
        onCheck={() => (name && setFieldValue?.(name, v)) || onChange?.(v)}
        checked={selectedValue === v}
        required={required}
      />
    ))}
  </TitleList>
);

type BooleanRadioGroupProps = {
  trueOption?: string;
  falseOption?: string;
  selectedValue?: boolean;
} & Omit<Props<"true" | "false">, "optionsEnum" | "selectedValue">;
export const BooleanRadioGroup = ({
  selectedValue,
  setFieldValue,
  trueOption = "Oui",
  falseOption = "Non",
  name,
  onChange,
  ...props
}: BooleanRadioGroupProps) => (
  <RadioGroup
    optionsEnum={{ true: trueOption, false: falseOption }}
    onChange={(v) =>
      (name && setFieldValue!(name, v === "true")) || onChange?.(v)
    }
    selectedValue={
      selectedValue === undefined ? undefined : selectedValue ? "true" : "false"
    }
    {...props}
  />
);

export default RadioGroup;
