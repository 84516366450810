const PieChartIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 2.79544C11 2.20332 11 1.90726 10.8546 1.66796C10.7385 1.47698 10.506 1.29628 10.2923 1.23096C10.0245 1.14912 9.77489 1.21271 9.27574 1.33989C4.51831 2.55203 1 6.86511 1 11.9999C1 18.075 5.92487 22.9999 12 22.9999C13.4348 22.9999 14.8055 22.7252 16.0623 22.2255C16.5406 22.0353 16.7797 21.9402 16.9484 21.7166C17.083 21.5381 17.1649 21.2552 17.1466 21.0324C17.1236 20.7533 16.9496 20.5138 16.6016 20.0348L11.1977 12.597C11.0735 12.4303 11 12.2237 11 11.9999V2.79544Z" />
    <path d="M21.2045 13C21.7966 13 22.0926 13 22.3319 13.1454C22.5229 13.2615 22.7036 13.494 22.7689 13.7077C22.8508 13.9755 22.7872 14.225 22.6601 14.7239C22.2522 16.325 21.4931 17.7858 20.4696 19.0194C20.14 19.4167 19.9752 19.6153 19.7104 19.7066C19.4989 19.7795 19.2048 19.7701 18.9985 19.6837C18.7401 19.5756 18.5658 19.3358 18.2173 18.8561L14.8855 14.2702C14.5877 13.8603 14.4388 13.6554 14.4442 13.4843C14.4488 13.3353 14.5197 13.1962 14.6375 13.1049C14.7727 13 15.0261 13 15.5327 13H21.2045Z" />
    <path d="M13 2.79544C13 2.20332 13 1.90727 13.1454 1.66796C13.2615 1.47698 13.494 1.29628 13.7077 1.23097C13.9755 1.14912 14.2251 1.21271 14.7242 1.33988C18.6105 2.33004 21.6699 5.38945 22.6601 9.27571C22.7872 9.77484 22.8508 10.0244 22.769 10.2922C22.7036 10.5059 22.523 10.7384 22.332 10.8545C22.0927 10.9999 21.7966 10.9999 21.2045 10.9999H13.8C13.52 10.9999 13.38 10.9999 13.273 10.9454C13.1789 10.8975 13.1024 10.821 13.0545 10.7269C13 10.6199 13 10.4799 13 10.1999V2.79544Z" />
  </svg>
);

export default PieChartIcon;
