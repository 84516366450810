import styled, { css } from "styled-components";
import Loader from "./Loader";

export type ButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactElement;
  loading?: boolean;
  size?: "default" | "minimum" | "fullWidth" | "roundIcon";
  variant?: "primary" | "transparent" | "white";
  noBoxShadow?: boolean;
  loaderReplaceChildren?: boolean;
  bold?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
const Button = ({
  onClick,
  icon,
  children,
  loading,
  disabled,
  size = "default",
  variant = "primary",
  loaderReplaceChildren,
  bold = false,
  ...props
}: ButtonProps) => (
  <StyledButton
    {...props}
    bold={bold}
    size={size}
    variant={variant}
    onClick={(e) => !loading && onClick?.(e)}
    disabled={disabled || loading}
  >
    {!loaderReplaceChildren && loading ? <Loader /> : icon}
    {loaderReplaceChildren && loading ? <Loader /> : children}
  </StyledButton>
);

const StyledButton = styled.button<ButtonProps>`
  padding: 1em 2em;
  cursor: pointer;
  font-family: unset;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  user-select: none;
  color: unset;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  &:focus {
    outline: none;
  }
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ size }) => {
    if (size === "fullWidth")
      return css`
        width: 100%;
      `;
    if (size === "roundIcon")
      return css`
        width: min-content;
        border-radius: 50%;
        padding: 8px;
      `;
    if (size === "minimum")
      return css`
        width: min-content;
        white-space: nowrap;
        padding: 1em;
      `;
  }};
  ${({ noBoxShadow }) =>
    noBoxShadow
      ? css``
      : css`
          box-shadow: var(--box-shadow);
        `};

  &:hover:not([disabled]) {
    filter: brightness(1.02);
  }

  &&:active {
    filter: brightness(0.98);
  }

  &:disabled {
    cursor: default;
    background-color: var(--gray-300);
    color: var(--gray-700);
    border-color: var(--gray-300);
  }

  ${({ variant }) => {
    switch (variant) {
      case "transparent":
        return css`
          background-color: inherit;
          &:hover:not([disabled]) {
            filter: brightness(1.15);
          }

          &&:active {
            filter: brightness(0.9);
          }

          &:disabled {
            background-color: inherit;
          }
        `;
      case "white":
        return css`
          background-color: var(--white);
        `;
      case "primary":
      default:
        return css`
          background-color: var(--yellow-400);
        `;
    }
  }};
`;

export default Button;
