const InsuranceCardIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M8.12496 9.99992H7.29163C7.23637 9.99992 7.18338 9.97797 7.14431 9.9389C7.10524 9.89983 7.08329 9.84684 7.08329 9.79159V8.95825C7.08307 8.79256 7.01715 8.63372 6.89999 8.51655C6.78283 8.39939 6.62399 8.33347 6.45829 8.33325H5.20829C5.0426 8.33347 4.88376 8.39939 4.76659 8.51655C4.64943 8.63372 4.58351 8.79256 4.58329 8.95825V9.79159C4.58329 9.84684 4.56134 9.89983 4.52227 9.9389C4.4832 9.97797 4.43021 9.99992 4.37496 9.99992H3.54163C3.37593 10.0001 3.21709 10.0661 3.09993 10.1832C2.98277 10.3004 2.91685 10.4592 2.91663 10.6249V11.8749C2.91685 12.0406 2.98277 12.1995 3.09993 12.3166C3.21709 12.4338 3.37593 12.4997 3.54163 12.4999H4.37496C4.43021 12.4999 4.4832 12.5219 4.52227 12.5609C4.56134 12.6 4.58329 12.653 4.58329 12.7083V13.5416C4.58351 13.7073 4.64943 13.8661 4.76659 13.9833C4.88376 14.1004 5.0426 14.1664 5.20829 14.1666H6.45829C6.62399 14.1664 6.78283 14.1004 6.89999 13.9833C7.01715 13.8661 7.08307 13.7073 7.08329 13.5416V12.7083C7.08329 12.653 7.10524 12.6 7.14431 12.5609C7.18338 12.5219 7.23637 12.4999 7.29163 12.4999H8.12496C8.29065 12.4997 8.4495 12.4338 8.56666 12.3166C8.68382 12.1995 8.74974 12.0406 8.74996 11.8749V10.6249C8.74974 10.4592 8.68382 10.3004 8.56666 10.1832C8.4495 10.0661 8.29065 10.0001 8.12496 9.99992Z"
        fill="#FFE32B"
      />
      <path
        d="M20 3.74992C20 3.30789 19.8244 2.88397 19.5118 2.57141C19.1993 2.25885 18.7754 2.08325 18.3333 2.08325H1.66667C1.22464 2.08325 0.800716 2.25885 0.488155 2.57141C0.175595 2.88397 0 3.30789 0 3.74992L0 16.2499C0 16.6919 0.175595 17.1159 0.488155 17.4284C0.800716 17.741 1.22464 17.9166 1.66667 17.9166H18.3333C18.7754 17.9166 19.1993 17.741 19.5118 17.4284C19.8244 17.1159 20 16.6919 20 16.2499V3.74992ZM2.08333 16.2499C1.97283 16.2499 1.86685 16.206 1.78871 16.1279C1.71057 16.0497 1.66667 15.9438 1.66667 15.8333V7.08325C1.66667 6.97275 1.71057 6.86676 1.78871 6.78862C1.86685 6.71048 1.97283 6.66659 2.08333 6.66659H17.9167C18.0272 6.66659 18.1332 6.71048 18.2113 6.78862C18.2894 6.86676 18.3333 6.97275 18.3333 7.08325V15.8333C18.3333 15.9438 18.2894 16.0497 18.2113 16.1279C18.1332 16.206 18.0272 16.2499 17.9167 16.2499H2.08333Z"
        fill="#FFE32B"
      />
      <path
        d="M16.0417 9.79175H12.0834C11.9176 9.79175 11.7586 9.8576 11.6414 9.97481C11.5242 10.092 11.4584 10.251 11.4584 10.4167C11.4584 10.5825 11.5242 10.7415 11.6414 10.8587C11.7586 10.9759 11.9176 11.0417 12.0834 11.0417H16.0417C16.2075 11.0417 16.3664 10.9759 16.4837 10.8587C16.6009 10.7415 16.6667 10.5825 16.6667 10.4167C16.6667 10.251 16.6009 10.092 16.4837 9.97481C16.3664 9.8576 16.2075 9.79175 16.0417 9.79175Z"
        fill="#FFE32B"
      />
      <path
        d="M15 12.2917H12.0834C11.9176 12.2917 11.7586 12.3576 11.6414 12.4748C11.5242 12.592 11.4584 12.751 11.4584 12.9167C11.4584 13.0825 11.5242 13.2415 11.6414 13.3587C11.7586 13.4759 11.9176 13.5417 12.0834 13.5417H15C15.1658 13.5417 15.3248 13.4759 15.442 13.3587C15.5592 13.2415 15.625 13.0825 15.625 12.9167C15.625 12.751 15.5592 12.592 15.442 12.4748C15.3248 12.3576 15.1658 12.2917 15 12.2917Z"
        fill="#FFE32B"
      />
    </g>
  </svg>
);

export default InsuranceCardIcon;
