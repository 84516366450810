import styled, { css } from "styled-components";
import SignalIcon from "../../components/icons/Signal";
import Loader from "../../components/Loader";
import { IncomeType } from "../../types/patrimony";
import { currency } from "../../utils/format.utils";


const StyledPatrimonyBox = styled.div<{
  type: IncomeType;
  chartDisplayed: IncomeType | undefined;
}>`
  border-radius: 24px;
  box-shadow: var(--box-shadow);
  cursor: pointer;

  .content {
    .text-row {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
    }
    .value-row {
      font-size: 24px;
      font-weight: 700;
      white-space: nowrap;
      display: flex;
    }
  }
  svg {
    fill: var(--white);
  }

  @media (min-width: 900px) {
    .content {
      padding: 16px;
    }

    ${({ type }) =>
      type === "net"
        ? css`
            background-color: var(--black);
            color: var(--white);
            &&:active {
              filter: brightness(1.2);
            }
          `
        : css`
            background-color: var(--white);
            color: var(--black);
            svg {
              fill: black;
            }
            &&:active {
              filter: brightness(0.98);
            }
          `};
  }

  @media (max-width: 900px) {
    color: var(--white);
    position: relative;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &&:active {
      filter: brightness(0.95);
    }
  }

  ${({ chartDisplayed, type }) =>
    chartDisplayed === type
      ? css`
          svg {
            fill: var(--yellow-400);
          }
        `
      : chartDisplayed
      ? css`
          @media (max-width: 900px) {
            svg {
              fill: var(--yellow-400);
            }
          }
        `
      : ""}
`;

type PatrimonyBoxProps = {
  isLoading: boolean;
  value?: number;
  type: IncomeType;
  chartDisplayed: IncomeType | undefined;
  setChartDisplayed: (v: IncomeType | undefined) => void;
  anyAssetExists: boolean;
};

const PatrimonyBox = ({
  isLoading,
  type,
  value,
  setChartDisplayed,
  chartDisplayed,
  anyAssetExists,
}: PatrimonyBoxProps) => {
  const net = type === "net";

  const onClick = () => {
    if (chartDisplayed === type) {
      setChartDisplayed(undefined);
    } else if (net) {
      setChartDisplayed("net");
    } else {
      setChartDisplayed("brut");
    }
  };

  return (
    <StyledPatrimonyBox
      type={type}
      chartDisplayed={chartDisplayed}
      onClick={anyAssetExists ? onClick : undefined}
    >
      <div className="content">
        <div className="value-row">
          {isLoading ? (
            <>
              <Loader />
              &nbsp;
            </>
          ) : (
            currency(value || 0)
          )}
        </div>
        <div className="text-row">
          <div>Mon patrimoine {type}</div>
          <SignalIcon />
        </div>
      </div>
    </StyledPatrimonyBox>
  );
};

export default PatrimonyBox;