import { useMutation } from "@tanstack/react-query";
import { format, isToday, isYesterday } from "date-fns";
import { useState } from "react";
import styled from "styled-components";
import { queryClient } from "../lib/react-query";
import { updateTransaction } from "../services/patrimony.service";
import { GetBudgetDto } from "../types/budget";
import {
  AllBudgetCategories,
  BudgetCategory,
  getRelevantBudgetCategories,
} from "../types/budget.enums";
import {
  Movement,
  Transaction,
  UpdateTransactionDto,
} from "../types/movements";
import { Currency } from "../types/patrimony.enums";
import { formatTransaction } from "../utils/format.utils";
import ActionModal from "./modals/ActionModal";
import Select from "./Select";
import { Saving } from "../types/patrimony";

const StyledTransactionsDay = styled.div`
  margin-top: 16px;
  .t-day {
    color: var(--gray-700);
    margin-bottom: 8px;
  }
  .transactions {
    border: var(--border);
    border-radius: 8px;
    background-color: var(--white);
  }
`;

const StyledTransaction = styled.div<{
  value?: number;
  highlightIncomes?: true;
  onClick?: () => void;
  isGrayedOut?: boolean;
}>`
  &:not(:first-child) {
    border-top: var(--border);
  }
  padding: 16px;
  ${(p) => p.onClick && "cursor: pointer;"}

  > * {
    filter: opacity(1);
    transition: 0.2s;
  }

  &:not(:hover) {
    > * {
      ${(p) => p.isGrayedOut && "filter: opacity(0.5);"}
    }
  }

  display: flex;
  gap: 8px;

  svg {
    align-self: center;
    flex-shrink: 0;
  }

  .t-text {
    flex: auto;
    .minor-row {
      margin-left: 0.1em;
      font-size: 0.7em;
      color: var(--gray-600);
      white-space: nowrap;
    }
  }

  .t-value {
    align-self: center;
    ${({ value, highlightIncomes }) =>
      highlightIncomes && value && value > 0 && "color: var(--success-500);"}
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-bottom: 4px;
  }
  .t-value {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .t-name {
    font-weight: 500;
    margin-bottom: 4px;
  }
  .t-date-bankName {
    color: var(--gray-600);
    margin-bottom: 12px;
  }
`;

const formatDay = (date: Date) =>
  isToday(date)
    ? "AUJOURD'HUI"
    : isYesterday(date)
    ? "HIER"
    : format(date, "dd/MM/yy");

const formatTransactionDays = (transactions: Transaction[]) => {
  return transactions.reduce<{ [day: string]: Transaction[] }>((acc, t) => {
    const day = formatDay(new Date(t.date));
    if (acc[day]) {
      acc[day].push(t);
    } else {
      acc[day] = [t];
    }
    return acc;
  }, {});
};

function changeTransactionInCache(newTransac: Partial<Transaction>) {
  let cachedBudget = queryClient.getQueryData<GetBudgetDto>(["budget"]);
  const transactionInBudget = cachedBudget?.transactions.find(
    (t) => t.id === newTransac.id
  );
  if (cachedBudget && transactionInBudget) {
    Object.assign(transactionInBudget, newTransac);
    cachedBudget = queryClient.setQueryData<GetBudgetDto>(["budget"], {
      ...cachedBudget,
      transactions: cachedBudget.transactions.filter(
        (t) => t.id !== newTransac.id
      ),
    })!;
    queryClient.setQueryData<GetBudgetDto>(["budget"], {
      ...cachedBudget,
      transactions: [...cachedBudget.transactions, transactionInBudget],
    });
  }

  /* the following can be simplified if a refactor changes transactions'
  savingBiAccountId by savingId, or if transactions' savingId are added to the dto */

  const cachedAccounts = queryClient
    .getQueriesData<Saving>(["myPatrimony", "account", "saving"])
    .map(([, account]) => account)
    .flat();
  const cachedAccount = cachedAccounts.find(
    (account) => newTransac.savingBiAccountId === account?.biAccountId
  );

  const transactionInAccount = cachedAccount?.transactions!.find(
    (t) => t.id === newTransac.id
  );

  if (cachedAccount && transactionInAccount) {
    Object.assign(transactionInAccount, newTransac);
    queryClient.setQueryData<Saving>(
      ["myPatrimony", "account", "saving", cachedAccount.id],
      cachedAccount
    );
  }
}

type MovementsDayProps = {
  movements: Movement[];
  currency?: Currency;
  day?: string;
  displayDateIndividually?: true;
  displayBankIndividually?: true;
  highlightIncomes?: true;
  grayOutInternalTransfers?: boolean;
};
export const MovementsDay = ({
  movements,
  currency,
  day,
  displayDateIndividually,
  displayBankIndividually,
  highlightIncomes,
  grayOutInternalTransfers,
}: MovementsDayProps) => {
  const [tte, setTransactionToEdit] = useState<Transaction>();
  const closeUpdateTransaction = () => setTransactionToEdit(undefined);

  const { mutateAsync: tryUpdate } = useMutation({
    mutationFn: (data: {
      previous: Transaction;
      new: UpdateTransactionDto;
    }) => {
      changeTransactionInCache(data.new);
      return updateTransaction(data.new);
    },
    onError: (e, reqData) => {
      console.error(e);
      changeTransactionInCache(reqData.previous);
    },
  });

  return (
    <StyledTransactionsDay>
      {day && <div className="t-day">{day}</div>}
      <div className="transactions">
        {movements.map((m, i) => {
          const t = "category" in m ? (m as Transaction) : undefined;
          return (
            <StyledTransaction
              key={i}
              value={m.value}
              highlightIncomes={highlightIncomes}
              onClick={t && (() => setTransactionToEdit(t))}
              isGrayedOut={
                grayOutInternalTransfers && t?.category === "INTERNAL_TRANSFER"
              }
            >
              {t && AllBudgetCategories[t.category].icon}
              <div className="t-text">
                {displayDateIndividually && (
                  <div className="minor-row">{formatDay(new Date(m.date))}</div>
                )}

                <div 
                    className="t-name desktop-only" 
                >
                    {m?.name?.length && m?.name?.length > 100 
                        ? m.name.slice(0, 100) + "..." 
                        : m?.name || ''}
                </div>

                <div className="t-name mobile-only">{
                    m?.name?.length && m?.name?.length > 27 
                        ? m.name.slice(0, 27) + "..." 
                        : m?.name || ''
                }</div>


                {displayBankIndividually && (
                  <div className="minor-row">{m.bankName}</div>
                )}
              </div>
              <div className="t-value">
                {formatTransaction(m.value, currency)}
              </div>
            </StyledTransaction>
          );
        })}
      </div>
      {tte && (
        <ActionModal
          open={!!tte}
          close={closeUpdateTransaction}
          apply={() => {
            tryUpdate({
              new: tte,
              previous: {
                ...(movements.find(
                  (m) => "category" in m && m.id === tte.id
                )! as Transaction),
              },
            });
            closeUpdateTransaction();
          }}
          hideCancelButton
          applyText="Enregistrer"
        >
          <ModalContent>
            {{
              ...AllBudgetCategories[tte.category].icon,
              props: {
                ...AllBudgetCategories[tte.category].icon.props,
                big: true,
              },
            }}
            <div className="t-value">
              {formatTransaction(tte.value, currency, false)}
            </div>
            <div className="t-name">{tte.name}</div>
            <div className="t-date-bankName">
              {format(new Date(tte.date), "dd/MM/yy")}
              {tte.bankName && ` | ${tte.bankName}`}
            </div>
            <Select
              label="Catégorie"
              optionsEnum={getRelevantBudgetCategories(tte.value)}
              value={tte.category}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setTransactionToEdit({
                  ...tte,
                  category: e.target.value as BudgetCategory,
                });
              }}
            />
          </ModalContent>
        </ActionModal>
      )}
    </StyledTransactionsDay>
  );
};

type TransactionsProps = {
  transactions: Transaction[];
  currency?: Currency;
  displayBankIndividually?: true;
  highlightIncomes?: true;
  grayOutInternalTransfers?: boolean;
};
const Transactions = (p: TransactionsProps) => (
  <div className="transaction-days">
    {Object.entries(formatTransactionDays(p.transactions)).map(
      ([day, transactions]) => (
        <MovementsDay key={day} movements={transactions} day={day} {...p} />
      )
    )}
    
         
  </div>
);

export default Transactions;
