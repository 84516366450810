import BabyIcon from "../components/icons/budget/BabyIcon";
import BlueHouseIcon from "../components/icons/budget/BlueHouseIcon";
import BrownHouseIcon from "../components/icons/budget/BrownHouseIcon";
import BuildingsCoinIcon from "../components/icons/budget/BuildingsCoinIcon";
import BusAtStopIcon from "../components/icons/budget/BusAtStopIcon";
import CircledHandCoinsIcon from "../components/icons/budget/CircledHandCoinsIcon";
import CircledRefreshArrowsIcon from "../components/icons/budget/CircledRefreshArrowsIcon";
import ForkSpoonIcon from "../components/icons/budget/ForkSpoonIcon";
import GolfSwingIcon from "../components/icons/budget/GolfSwingIcon";
import HeartIcon from "../components/icons/budget/HeartIcon";
import MoneyBagIcon from "../components/icons/budget/MoneyBagIcon";
import OrangeHouseIcon from "../components/icons/budget/OrangeHouseIcon";
import ShoppingBagIcon from "../components/icons/budget/ShoppingBagIcon";
import SmartphoneIcon from "../components/icons/budget/SmartphoneIcon";
import StonksDragonIcon from "../components/icons/budget/StonksDragonIcon";
import { ObjectEntries } from "../utils/utils";

const BudgetCategories = {
  // INCOME
  WAGE_OR_PENSION: "WAGE_OR_PENSION",
  ALLOWANCE: "ALLOWANCE",
  REAL_ESTATE: "REAL_ESTATE",
  FINANCIAL_INVESTENTS: "FINANCIAL_INVESTENTS",

  // EXPENSE
  RENTS: "RENTS",
  CREDITS_PAYMENTS: "CREDITS_PAYMENTS",
  TAXES: "TAXES",
  LODGING_CHARGES: "LODGING_CHARGES",
  HOUSEHOLD_HOME: "HOUSEHOLD_HOME",
  FOOD: "FOOD",
  TRANSPORTS: "TRANSPORTS",
  MEDIA_TELECOM: "MEDIA_TELECOM",
  HEALTH: "HEALTH",
  HOBBIES: "HOBBIES",
  SHOPPING: "SHOPPING",

  // OTHERS
  INTERNAL_TRANSFER: "INTERNAL_TRANSFER",
  TO_CATEGORIZE: "TO_CATEGORIZE",
} as const;

export type BudgetCategory = keyof typeof BudgetCategories;

export const BudgetIncomeCategories = {
  WAGE_OR_PENSION: {
    text: "Salaires, retraites",
    description: "net d'impôts sur le revenu",
    icon: <MoneyBagIcon />,
  },
  ALLOWANCE: { text: "Allocations et pensions", icon: <BabyIcon /> },
  REAL_ESTATE: { text: "Revenus fonciers", icon: <BuildingsCoinIcon /> },
  FINANCIAL_INVESTENTS: {
    text: "Investissements financiers",
    icon: <StonksDragonIcon />,
  },
} as const;

export const BudgetExpenseCategories = {
  RENTS: { text: "Loyers", icon: <OrangeHouseIcon /> },
  CREDITS_PAYMENTS: {
    text: "Remboursements emprunts",
    icon: <CircledRefreshArrowsIcon />,
  },
  TAXES: {
    text: "Impôts et taxes",
    icon: <CircledHandCoinsIcon />,
  },
  LODGING_CHARGES: { text: "Logement / charges", icon: <BrownHouseIcon /> },
  HOUSEHOLD_HOME: { text: "Foyer / Maison", icon: <BlueHouseIcon /> },
  FOOD: { text: "Alimentation", icon: <ForkSpoonIcon /> },
  TRANSPORTS: { text: "Transport", icon: <BusAtStopIcon /> },
  MEDIA_TELECOM: {
    text: "Télécommunications et medias",
    icon: <SmartphoneIcon />,
  },
  HEALTH: { text: "Santé", icon: <HeartIcon /> },
  HOBBIES: { text: "Loisirs", icon: <GolfSwingIcon /> },
  SHOPPING: { text: "Shopping", icon: <ShoppingBagIcon /> },
} as const;

const BudgetOthersCategories = {
  INTERNAL_TRANSFER: {
    text: "Transferts internes",
    icon: <CircledRefreshArrowsIcon fill="gray" />,
  },
  TO_CATEGORIZE: {
    text: "À catégoriser",
    icon: <CircledHandCoinsIcon fill="#5AB0FF" />,
  },
} as const;

const ExtendedBudgetIncomeCategories = {
  ...BudgetIncomeCategories,
  ...BudgetOthersCategories,
} as const;

const ExtendedBudgetExpenseCategories = {
  ...BudgetExpenseCategories,
  ...BudgetOthersCategories,
} as const;

export type CategoriesDictType = Record<
  BudgetCategory,
  { text: string; description?: string; icon: JSX.Element }
>;

export const AllBudgetCategories: CategoriesDictType = {
  ...BudgetIncomeCategories,
  ...BudgetExpenseCategories,
  ...BudgetOthersCategories,
} as const;

export function getRelevantBudgetCategories(value: number | undefined) {
  const ExtendedCategories = !value
    ? AllBudgetCategories
    : value > 0
    ? ExtendedBudgetIncomeCategories
    : ExtendedBudgetExpenseCategories;

  return ObjectEntries(ExtendedCategories).reduce<
    Record<BudgetCategory, string>
  >(
    (acc, [k, v]) => ({ ...acc, [k]: v?.text }),
    {} as Record<BudgetCategory, string>
  );
}

export const BudgetSections = {
  INCOMES: "Revenus",
  EXPENSES: "Dépenses",
  FLOWS: "Flux bancaires",
};

export type BudgetSection = keyof typeof BudgetSections;
