import styled from "styled-components";

const LoaderBody = styled.div<{ color?: string }>`
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid ${(p) => p.color || "currentColor"};
  border-radius: 50%;
  border-right: transparent;
  border-top: transparent;
  animation: spin 1.3s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = (props: { color?: string }) => <LoaderBody {...props} />;

export default Loader;
