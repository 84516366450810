import { formatNumberPeople, round } from "../../../utils/format.utils";

function getStats(
  value: number,
  distribution: Array<{ pourcentage_inf: number; seuil: number }>,
  totalPopulation: number
) {
  const { pourcentage_inf } =
    distribution
      .slice()
      .reverse()
      .find((x) => value >= x.seuil) || distribution[0];

  const pourcentage_sup = 1 - pourcentage_inf;
  const nombre_individus = pourcentage_sup * totalPopulation;

  return {
    x: round(pourcentage_sup * 100, 3),
    numberOfIndividuals: formatNumberPeople(Math.round(nombre_individus)),
    numberTotal: formatNumberPeople(totalPopulation),
  };
}

export const getIncomesStats = (monthlyIncomes: number) =>
  getStats(monthlyIncomes * 12, incomesDistribution, incomesTotalPopulation);

export const getPatrimonyStats = (patrimonyTotalNet: number) =>
  getStats(patrimonyTotalNet, patrimonyDistribution, patrimonyTotalPopulation);

const incomesTotalPopulation = 52_755_720;

const incomesDistribution = [
  { pourcentage_inf: 0.01, seuil: 276 },
  { pourcentage_inf: 0.02, seuil: 2_119 },
  { pourcentage_inf: 0.03, seuil: 4_108 },
  { pourcentage_inf: 0.04, seuil: 5_086 },
  { pourcentage_inf: 0.05, seuil: 6_700 },
  { pourcentage_inf: 0.06, seuil: 8_168 },
  { pourcentage_inf: 0.07, seuil: 9_519 },
  { pourcentage_inf: 0.08, seuil: 10_154 },
  { pourcentage_inf: 0.09, seuil: 10_685 },
  { pourcentage_inf: 0.1, seuil: 10_983 },
  { pourcentage_inf: 0.11, seuil: 11_349 },
  { pourcentage_inf: 0.12, seuil: 11_690 },
  { pourcentage_inf: 0.13, seuil: 12_048 },
  { pourcentage_inf: 0.14, seuil: 12_335 },
  { pourcentage_inf: 0.15, seuil: 12_696 },
  { pourcentage_inf: 0.16, seuil: 13_045 },
  { pourcentage_inf: 0.17, seuil: 13_382 },
  { pourcentage_inf: 0.18, seuil: 13_771 },
  { pourcentage_inf: 0.19, seuil: 14_094 },
  { pourcentage_inf: 0.2, seuil: 14_408 },
  { pourcentage_inf: 0.21, seuil: 14_703 },
  { pourcentage_inf: 0.22, seuil: 14_991 },
  { pourcentage_inf: 0.23, seuil: 15_283 },
  { pourcentage_inf: 0.24, seuil: 15_544 },
  { pourcentage_inf: 0.25, seuil: 15_821 },
  { pourcentage_inf: 0.26, seuil: 16_055 },
  { pourcentage_inf: 0.27, seuil: 16_273 },
  { pourcentage_inf: 0.28, seuil: 16_523 },
  { pourcentage_inf: 0.29, seuil: 16_772 },
  { pourcentage_inf: 0.3, seuil: 17_013 },
  { pourcentage_inf: 0.31, seuil: 17_286 },
  { pourcentage_inf: 0.32, seuil: 17_523 },
  { pourcentage_inf: 0.33, seuil: 17_765 },
  { pourcentage_inf: 0.34, seuil: 18_058 },
  { pourcentage_inf: 0.35, seuil: 18_271 },
  { pourcentage_inf: 0.36, seuil: 18_513 },
  { pourcentage_inf: 0.37, seuil: 18_739 },
  { pourcentage_inf: 0.38, seuil: 19_011 },
  { pourcentage_inf: 0.39, seuil: 19_251 },
  { pourcentage_inf: 0.4, seuil: 19_469 },
  { pourcentage_inf: 0.41, seuil: 19_684 },
  { pourcentage_inf: 0.42, seuil: 19_964 },
  { pourcentage_inf: 0.43, seuil: 20_205 },
  { pourcentage_inf: 0.44, seuil: 20_372 },
  { pourcentage_inf: 0.45, seuil: 20_607 },
  { pourcentage_inf: 0.46, seuil: 20_824 },
  { pourcentage_inf: 0.47, seuil: 21_050 },
  { pourcentage_inf: 0.48, seuil: 21_274 },
  { pourcentage_inf: 0.49, seuil: 21_503 },
  { pourcentage_inf: 0.5, seuil: 21_727 },
  { pourcentage_inf: 0.51, seuil: 21_967 },
  { pourcentage_inf: 0.52, seuil: 22_259 },
  { pourcentage_inf: 0.53, seuil: 22_479 },
  { pourcentage_inf: 0.54, seuil: 22_753 },
  { pourcentage_inf: 0.55, seuil: 23_026 },
  { pourcentage_inf: 0.56, seuil: 23_347 },
  { pourcentage_inf: 0.57, seuil: 23_609 },
  { pourcentage_inf: 0.58, seuil: 23_937 },
  { pourcentage_inf: 0.59, seuil: 24_235 },
  { pourcentage_inf: 0.6, seuil: 24_543 },
  { pourcentage_inf: 0.61, seuil: 24_802 },
  { pourcentage_inf: 0.62, seuil: 25_084 },
  { pourcentage_inf: 0.63, seuil: 25_446 },
  { pourcentage_inf: 0.64, seuil: 25_775 },
  { pourcentage_inf: 0.65, seuil: 26_122 },
  { pourcentage_inf: 0.66, seuil: 26_488 },
  { pourcentage_inf: 0.67, seuil: 26_800 },
  { pourcentage_inf: 0.68, seuil: 27_199 },
  { pourcentage_inf: 0.69, seuil: 27_490 },
  { pourcentage_inf: 0.7, seuil: 27_958 },
  { pourcentage_inf: 0.71, seuil: 28_353 },
  { pourcentage_inf: 0.72, seuil: 28_689 },
  { pourcentage_inf: 0.73, seuil: 29_122 },
  { pourcentage_inf: 0.74, seuil: 29_630 },
  { pourcentage_inf: 0.75, seuil: 30_259 },
  { pourcentage_inf: 0.76, seuil: 30_713 },
  { pourcentage_inf: 0.77, seuil: 31_189 },
  { pourcentage_inf: 0.78, seuil: 31_732 },
  { pourcentage_inf: 0.79, seuil: 32_294 },
  { pourcentage_inf: 0.8, seuil: 32_923 },
  { pourcentage_inf: 0.81, seuil: 33_534 },
  { pourcentage_inf: 0.82, seuil: 34_415 },
  { pourcentage_inf: 0.83, seuil: 35_305 },
  { pourcentage_inf: 0.84, seuil: 36_396 },
  { pourcentage_inf: 0.85, seuil: 37_486 },
  { pourcentage_inf: 0.86, seuil: 38_629 },
  { pourcentage_inf: 0.87, seuil: 39_882 },
  { pourcentage_inf: 0.88, seuil: 41_200 },
  { pourcentage_inf: 0.89, seuil: 42_480 },
  { pourcentage_inf: 0.9, seuil: 43_740 },
  { pourcentage_inf: 0.91, seuil: 45_301 },
  { pourcentage_inf: 0.92, seuil: 47_145 },
  { pourcentage_inf: 0.93, seuil: 49_368 },
  { pourcentage_inf: 0.94, seuil: 52_122 },
  { pourcentage_inf: 0.95, seuil: 55_658 },
  { pourcentage_inf: 0.96, seuil: 60_439 },
  { pourcentage_inf: 0.97, seuil: 67_426 },
  { pourcentage_inf: 0.98, seuil: 79_104 },
  { pourcentage_inf: 0.99, seuil: 105_307 },
  { pourcentage_inf: 0.991, seuil: 110_128 },
  { pourcentage_inf: 0.992, seuil: 115_819 },
  { pourcentage_inf: 0.993, seuil: 122_680 },
  { pourcentage_inf: 0.994, seuil: 131_180 },
  { pourcentage_inf: 0.995, seuil: 142_096 },
  { pourcentage_inf: 0.996, seuil: 156_853 },
  { pourcentage_inf: 0.997, seuil: 178_420 },
  { pourcentage_inf: 0.998, seuil: 214_464 },
  { pourcentage_inf: 0.999, seuil: 295_347 },
  { pourcentage_inf: 0.9991, seuil: 310_225 },
  { pourcentage_inf: 0.9992, seuil: 327_792 },
  { pourcentage_inf: 0.9993, seuil: 348_971 },
  { pourcentage_inf: 0.9994, seuil: 375_206 },
  { pourcentage_inf: 0.9995, seuil: 408_902 },
  { pourcentage_inf: 0.9996, seuil: 454_453 },
  { pourcentage_inf: 0.9997, seuil: 521_020 },
  { pourcentage_inf: 0.9998, seuil: 632_278 },
  { pourcentage_inf: 0.9999, seuil: 881_936 },
  { pourcentage_inf: 0.99991, seuil: 927_861 },
  { pourcentage_inf: 0.99992, seuil: 982_082 },
  { pourcentage_inf: 0.99993, seuil: 1_047_457 },
  { pourcentage_inf: 0.99994, seuil: 1_128_435 },
  { pourcentage_inf: 0.99995, seuil: 1_232_444 },
  { pourcentage_inf: 0.99996, seuil: 1_373_044 },
  { pourcentage_inf: 0.99997, seuil: 1_578_516 },
  { pourcentage_inf: 0.99998, seuil: 1_921_933 },
  { pourcentage_inf: 0.99999, seuil: 2_692_547 },
];

const patrimonyTotalPopulation = 52_991_200;

const patrimonyDistribution = [
  { pourcentage_inf: 0.01, seuil: 168 },
  { pourcentage_inf: 0.02, seuil: 274 },
  { pourcentage_inf: 0.03, seuil: 370 },
  { pourcentage_inf: 0.04, seuil: 459 },
  { pourcentage_inf: 0.05, seuil: 566 },
  { pourcentage_inf: 0.06, seuil: 707 },
  { pourcentage_inf: 0.07, seuil: 874 },
  { pourcentage_inf: 0.08, seuil: 1_064 },
  { pourcentage_inf: 0.09, seuil: 1_277 },
  { pourcentage_inf: 0.1, seuil: 1_515 },
  { pourcentage_inf: 0.11, seuil: 1_785 },
  { pourcentage_inf: 0.12, seuil: 2_093 },
  { pourcentage_inf: 0.13, seuil: 2_429 },
  { pourcentage_inf: 0.14, seuil: 2_780 },
  { pourcentage_inf: 0.15, seuil: 3_146 },
  { pourcentage_inf: 0.16, seuil: 3_533 },
  { pourcentage_inf: 0.17, seuil: 3_951 },
  { pourcentage_inf: 0.18, seuil: 4_416 },
  { pourcentage_inf: 0.19, seuil: 4_950 },
  { pourcentage_inf: 0.2, seuil: 5_590 },
  { pourcentage_inf: 0.21, seuil: 6_362 },
  { pourcentage_inf: 0.22, seuil: 7_252 },
  { pourcentage_inf: 0.23, seuil: 8_241 },
  { pourcentage_inf: 0.24, seuil: 9_314 },
  { pourcentage_inf: 0.25, seuil: 10_465 },
  { pourcentage_inf: 0.26, seuil: 11_699 },
  { pourcentage_inf: 0.27, seuil: 13_025 },
  { pourcentage_inf: 0.28, seuil: 14_476 },
  { pourcentage_inf: 0.29, seuil: 16_105 },
  { pourcentage_inf: 0.3, seuil: 17_975 },
  { pourcentage_inf: 0.31, seuil: 20_146 },
  { pourcentage_inf: 0.32, seuil: 22_637 },
  { pourcentage_inf: 0.33, seuil: 25_435 },
  { pourcentage_inf: 0.34, seuil: 28_516 },
  { pourcentage_inf: 0.35, seuil: 31_849 },
  { pourcentage_inf: 0.36, seuil: 35_385 },
  { pourcentage_inf: 0.37, seuil: 39_078 },
  { pourcentage_inf: 0.38, seuil: 42_864 },
  { pourcentage_inf: 0.39, seuil: 46_669 },
  { pourcentage_inf: 0.4, seuil: 50_411 },
  { pourcentage_inf: 0.41, seuil: 54_058 },
  { pourcentage_inf: 0.42, seuil: 57_683 },
  { pourcentage_inf: 0.43, seuil: 61_414 },
  { pourcentage_inf: 0.44, seuil: 65_355 },
  { pourcentage_inf: 0.45, seuil: 69_571 },
  { pourcentage_inf: 0.46, seuil: 74_084 },
  { pourcentage_inf: 0.47, seuil: 78_843 },
  { pourcentage_inf: 0.48, seuil: 83_732 },
  { pourcentage_inf: 0.49, seuil: 88_532 },
  { pourcentage_inf: 0.5, seuil: 92_923 },
  { pourcentage_inf: 0.51, seuil: 96_695 },
  { pourcentage_inf: 0.52, seuil: 99_999 },
  { pourcentage_inf: 0.53, seuil: 103_192 },
  { pourcentage_inf: 0.54, seuil: 106_605 },
  { pourcentage_inf: 0.55, seuil: 110_502 },
  { pourcentage_inf: 0.56, seuil: 115_058 },
  { pourcentage_inf: 0.57, seuil: 120_317 },
  { pourcentage_inf: 0.58, seuil: 126_141 },
  { pourcentage_inf: 0.59, seuil: 132_148 },
  { pourcentage_inf: 0.6, seuil: 137_688 },
  { pourcentage_inf: 0.61, seuil: 142_239 },
  { pourcentage_inf: 0.62, seuil: 145_924 },
  { pourcentage_inf: 0.63, seuil: 149_279 },
  { pourcentage_inf: 0.64, seuil: 152_846 },
  { pourcentage_inf: 0.65, seuil: 157_105 },
  { pourcentage_inf: 0.66, seuil: 162_423 },
  { pourcentage_inf: 0.67, seuil: 168_982 },
  { pourcentage_inf: 0.68, seuil: 176_695 },
  { pourcentage_inf: 0.69, seuil: 185_068 },
  { pourcentage_inf: 0.7, seuil: 193_079 },
  { pourcentage_inf: 0.71, seuil: 199_805 },
  { pourcentage_inf: 0.72, seuil: 205_290 },
  { pourcentage_inf: 0.73, seuil: 210_248 },
  { pourcentage_inf: 0.74, seuil: 215_491 },
  { pourcentage_inf: 0.75, seuil: 221_820 },
  { pourcentage_inf: 0.76, seuil: 229_947 },
  { pourcentage_inf: 0.77, seuil: 240_391 },
  { pourcentage_inf: 0.78, seuil: 253_242 },
  { pourcentage_inf: 0.79, seuil: 267_879 },
  { pourcentage_inf: 0.8, seuil: 282_652 },
  { pourcentage_inf: 0.81, seuil: 295_782 },
  { pourcentage_inf: 0.82, seuil: 307_007 },
  { pourcentage_inf: 0.83, seuil: 317_351 },
  { pourcentage_inf: 0.84, seuil: 328_235 },
  { pourcentage_inf: 0.85, seuil: 341_382 },
  { pourcentage_inf: 0.86, seuil: 358_737 },
  { pourcentage_inf: 0.87, seuil: 382_237 },
  { pourcentage_inf: 0.88, seuil: 413_179 },
  { pourcentage_inf: 0.89, seuil: 450_780 },
  { pourcentage_inf: 0.9, seuil: 489_882 },
  { pourcentage_inf: 0.91, seuil: 524_902 },
  { pourcentage_inf: 0.92, seuil: 559_796 },
  { pourcentage_inf: 0.93, seuil: 609_010 },
  { pourcentage_inf: 0.94, seuil: 690_276 },
  { pourcentage_inf: 0.95, seuil: 806_752 },
  { pourcentage_inf: 0.96, seuil: 941_197 },
  { pourcentage_inf: 0.97, seuil: 1_106_873 },
  { pourcentage_inf: 0.98, seuil: 1_462_111 },
  { pourcentage_inf: 0.99, seuil: 2_045_416 },
  { pourcentage_inf: 0.991, seuil: 2_461_442 },
  { pourcentage_inf: 0.992, seuil: 2_656_072 },
  { pourcentage_inf: 0.993, seuil: 2_881_385 },
  { pourcentage_inf: 0.994, seuil: 3_153_551 },
  { pourcentage_inf: 0.995, seuil: 3_503_327 },
  { pourcentage_inf: 0.996, seuil: 3_995_749 },
  { pourcentage_inf: 0.997, seuil: 4_790_073 },
  { pourcentage_inf: 0.998, seuil: 6_379_334 },
  { pourcentage_inf: 0.999, seuil: 8_609_626 },
  { pourcentage_inf: 0.9991, seuil: 10_081_408 },
  { pourcentage_inf: 0.9992, seuil: 10_806_461 },
  { pourcentage_inf: 0.9993, seuil: 11_688_612 },
  { pourcentage_inf: 0.9994, seuil: 12_792_408 },
  { pourcentage_inf: 0.9995, seuil: 14_227_248 },
  { pourcentage_inf: 0.9996, seuil: 16_205_422 },
  { pourcentage_inf: 0.9997, seuil: 19_197_208 },
  { pourcentage_inf: 0.9998, seuil: 24_606_302 },
  { pourcentage_inf: 0.9999, seuil: 31_897_730 },
  { pourcentage_inf: 0.99991, seuil: 36_720_888 },
  { pourcentage_inf: 0.99992, seuil: 39_164_192 },
  { pourcentage_inf: 0.99993, seuil: 42_139_688 },
  { pourcentage_inf: 0.99994, seuil: 45_863_024 },
  { pourcentage_inf: 0.99995, seuil: 50_703_944 },
  { pourcentage_inf: 0.99996, seuil: 57_379_488 },
  { pourcentage_inf: 0.99997, seuil: 67_481_352 },
  { pourcentage_inf: 0.99998, seuil: 85_753_408 },
  { pourcentage_inf: 0.99999, seuil: 629_336_448 },
];
