import BackButton from "../../components/buttons/BackButton";
import Link from "../../components/Link";
import MailToArpagon from "../../components/MailToArpagon";
import { Body, Container, Header } from "./EditProfile";

const ContactUs = () => (
  <Body>
    <Header>
      <div className="left mobile-only">
        <Link to="/profil">
          <BackButton />
        </Link>
      </div>
      <div className="title" style={{ margin: "10px 0" }}>
        Nous contacter
      </div>
      <div className="right"></div>
    </Header>
    <Container>
      <div style={{ marginTop: "16px" }}>
        Pour toute question, vous pouvez nous contacter directement par
        email&nbsp;: <MailToArpagon />
      </div>
    </Container>
  </Body>
);

export default ContactUs;
