/*  CGU et CGV :            https://docs.google.com/document/d/1Cuk4r7uMa-98cFldjnIJxL_N6Y5YIgvy
    code HTML généré sur    https://wordtohtml.net/
    code JSX généré sur     https://transform.tools/html-to-jsx
    remplacer les :
<p>
    <br />
</p>
    par des <br /> (ctrl+h)
*/

import styled from "styled-components";
import logo from "../../assets/logo.svg";

export const Body = styled.div`
  padding: 32px;
  @media (max-width: 900px) {
    padding: 16px;
  }
  overflow: auto;

  h3 {
    margin: 16px 0;
    text-decoration: underline;
    font-weight: 600;
  }
  h4 {
    margin: 4px 0;
    font-weight: 600;
  }
  h5 {
    font-size: unset;
    margin: 4px 0;
    font-weight: 500;
  }
  ul {
    padding-left: 20px;
  }
  a {
    color: #0000ee !important;
  }

  table {
    th {
      text-transform: unset !important;
      background-color: var(--gray-300);
    }
    th,
    td {
      text-align: left;
    }
  }
  @media (max-width: 450px) {
    th {
      font-size: 0.875em;
    }
    th,
    td {
      padding-left: 8px !important;
      padding-right: 8px !important;
      &:first-child {
        padding-right: 0px !important;
        word-break: normal;
        overflow-wrap: anywhere;
        hyphens: auto;
      }
    }
  }
`;

const Cgu = () => {
  //@ts-ignore
  return (
    <Body>
      <div style={{ height: "20px" }} />
      <img src={logo} alt="" height={28} />

      <h3>Conditions Générales d'Utilisation (CGU)</h3>
      <h4>1. Objet</h4>
      <p>
        L'outil internet et mobile « Arpagon », ci-après dénommé « l'Outil »,
        est une publication développée par la société ARPAGON SAS, SAS au
        capital de 100,00 € immatriculée au RCS de Paris sous le numéro 913 818
        423, dont le siège social est au 22, rue Gay-Lussac 75005 Paris.
      </p>
      <br />
      <p>
        L'Outil propose à ses utilisateurs des services permettant de gérer
        leurs finances et leur patrimoine en ligne, avec l'appui éventuel d'un
        conseiller en investissements financiers, ci-après dénommé « le
        Conseiller ».
      </p>
      <br />
      <p>
        La version internet de l'Outil est accessible à l'adresse{" "}
        <a data-fr-linked="true" href="//app.arpagon.fr">
          app.arpagon.fr
        </a>{" "}
        ; la version mobile de l'Outil peut être téléchargée sur l'Apple Store
        ou Google Play Store.
      </p>
      <br />
      <p>
        Les présentes Conditions Générales d'Utilisation régissent exclusivement
        les services fournis par ARPAGON SAS via l'Outil, ci-après dénommés «
        les Services ».
      </p>
      <br />
      <p>
        Elles ont pour objet de définir les rapports contractuels entre ARPAGON
        SAS, d'une part, et l'utilisateur de l'Outil, ci-après dénommé «
        l'Utilisateur », d'autre part.
      </p>
      <br />
      <p>
        Ces conditions ne régissent pas les services liés à la souscription de
        produits financiers ou l'accompagnement par le Conseiller, qui font
        l'objet de conditions spécifiques directement convenues entre
        l'Utilisateur et ce dernier.
      </p>
      <br />
      <p>
        Les présentes Conditions Générales d'Utilisation s'entendent sans
        préjudice des dispositions légales applicables en la matière et
        notamment celles prévues au Code de la consommation.
      </p>
      <br />
      <h4>2. Description des Services</h4>
      <p>
        L'Outil propose des Services permettant à l'Utilisateur de gérer son
        budget et patrimoine en ligne (consolider et suivre son patrimoine,
        calculer ses indicateurs patrimoniaux, suivre ses dépenses et son
        budget) et d'être accompagné par le Conseiller.
      </p>
      <br />
      <p>
        L'Utilisateur reconnaît que les Services proposés sont uniquement
        destinés à lui fournir une aide à la gestion financière personnelle, à
        partir des données renseignées ou collectées.
      </p>
      <br />
      <p>
        Les simulations réalisées reposent sur des hypothèses et, à des fins de
        simplification, n'ont pas vocation à couvrir l'ensemble des cas
        particuliers.
      </p>
      <br />
      <p>
        Les simulations de rendement moyen des actifs, de capacité d'emprunt, de
        patrimoine futur, d'impôt sur le revenu, d'impôt sur la fortune
        immobilière, de frais de succession ou de retraite peuvent ainsi être
        approximatifs et n'ont aucune valeur juridique. Ils ne peuvent engager
        la responsabilité de ARPAGON SAS si des écarts sont observés par rapport
        à la réalité.
      </p>
      <br />
      <p>
        Les simulations proposées ne remplacent en aucun cas les études qui
        peuvent être réalisées auprès des services administratifs et fiscaux,
        d'un notaire ou d'un conseiller en investissements financiers par
        exemple.
      </p>
      <br />
      <p>
        L'Utilisateur reconnaît expressément que toute décision de gestion ou
        d'investissement qu'il sera amené à prendre à partir des Services
        fournis le sera de manière autonome et indépendante. L'Utilisateur
        reconnaît être en capacité de prendre lui-même ce type de décisions et
        d'en évaluer les risques et conséquences.
      </p>
      <br />
      <p>
        ARPAGON SAS ne peut être tenue pour responsable des conséquences des
        décisions prises par l'Utilisateur suite à l'utilisation des Services
        fournis.
      </p>
      <br />
      <h4>3. Conditions d'accès aux Services</h4>
      <h5>3.1 Connexion à internet</h5>
      <p>
        L'utilisation des Services de l'Outil nécessite une connexion à
        internet. Il est précisé que cette connexion n'est pas prise en charge
        par ARPAGON SAS. Il appartient par conséquent à l'Utilisateur de
        souscrire préalablement à une offre Internet pour pouvoir les utiliser.
      </p>
      <br />
      <h5>3.2 Création d'un compte sur l'Outil</h5>
      <p>
        Pour utiliser les Services de l'Outil, l'utilisateur doit créer un
        compte (ci-après dénommé le « Compte »).
      </p>
      <br />
      <p>
        La création d'un Compte nécessite que l'Utilisateur choisisse une
        adresse email de correspondance ainsi qu'un mot de passe. L'adresse
        email ainsi que le mot de passe communiqués peuvent être modifiés par
        l'Utilisateur sur l'Outil internet dans la rubrique « Mon profil ».
      </p>
      <br />
      <p>
        L'Utilisateur doit informer immédiatement ARPAGON SAS de toute perte ou
        utilisation non autorisée de son Compte, de ses identifiant et mot de
        passe. L'identifiant et le mot de passe sont personnels et l'Utilisateur
        s'engage à ne pas les divulguer. A ce titre, ARPAGON SAS ne peut être
        tenue responsable de l'utilisation de l'identifiant et du mot de passe
        de l'Utilisateur par un tiers auquel l'Utilisateur les aurait
        communiqués ou bien qui y aurait eu accès suite à une faute, maladresse
        ou négligence de la part de l'Utilisateur.
      </p>
      <br />
      <p>
        ARPAGON SAS, ses partenaires, co-contractants ou ayants droit ne peuvent
        être tenus pour responsables des conséquences de l'utilisation fautive
        par l'Utilisateur de son Compte ou de toute action réalisée sur le
        Compte de l'Utilisateur par un tiers à qui l'Utilisateur aurait
        communiqué ses identifiants ou qui aurait eu accès au Compte suite à une
        faute, maladresse ou négligence de la part de l'Utilisateur.
      </p>
      <br />
      <h5>3.3 Acceptation des Conditions Générales</h5>
      <p>
        L'acceptation des présentes Conditions Générales par l'Utilisateur est
        un prérequis à la création d'un compte sur l'Outil et à l'accès aux
        Services.
      </p>
      <p>
        Cette acceptation ne peut être que pleine et entière. Toute adhésion
        sous réserve est considérée comme nulle et non avenue. L'Utilisateur qui
        n'accepte pas d'être lié par les présentes Conditions Générales ne doit
        pas accéder à la Plateforme ni utiliser les Services.
      </p>
      <br />
      <h5>3.4 Capacité juridique</h5>
      <p>
        L'Utilisateur déclare avoir la capacité d'accepter les présentes
        Conditions Générales d'Utilisation, c'est-à-dire avoir la majorité
        légale et ne pas faire l'objet d'une mesure de protection juridique des
        majeurs (mise sous sauvegarde de justice, sous tutelle ou sous
        curatelle) ou, si l'Utilisateur est mineur, être titulaire d'une
        autorisation parentale lui permettant de s'abonner aux Services.
      </p>
      <br />
      <h5>3.5 Utilisation personnelle</h5>
      <p>
        L'utilisation des Services de l'Outil n'est autorisée qu'à des fins
        personnelles et privées. Toute utilisation par l'Utilisateur en dehors
        de ce cadre est interdite.
      </p>
      <br />
      <h4>4. Suppression de Compte</h4>
      <p>
        L'Utilisateur peut à tout moment demander la suppression de son Compte
        sur l'Outil internet dans la rubrique « Mon compte ».
      </p>
      <br />
      <h4>5. Disponibilité et modification des Services</h4>
      <p>
        Les Services de l'Outil sont accessibles vingt-quatre heures sur
        vingt-quatre, sept jours sur sept, dans la mesure du possible et des
        stipulations des présentes Conditions Générales d'Utilisation.
      </p>
      <br />
      <p>
        ARPAGON SAS se réserve le droit d'apporter aux Services toutes les
        modifications et améliorations de son choix liées à l'évolution
        technique.
      </p>
      <br />
      <p>
        Par ailleurs, ARPAGON SAS se réserve le droit de suspendre
        temporairement l'accès aux Services pour assurer des opérations de
        maintenance liées à l'évolution technologique, ou nécessaires à la
        continuité des Services.
      </p>
      <br />
      <p>
        Les interruptions temporaires du Service seront, dans la mesure du
        possible, notifiées via l'Outil à l'avance sauf lorsque ces
        interruptions ont un caractère d'urgence.
      </p>
      <br />
      <h4>6. Responsabilité</h4>
      <p>
        La responsabilité de ARPAGON SAS ne pourra être retenue en cas
        d'inexécution ou de mauvaise exécution par l'Utilisateur des présentes
        Conditions Générales d'Utilisation, de fait imprévisible et
        insurmontable d'un tiers ou en cas de force majeure tel que défini par
        l'article 1218 du Code civil.
      </p>
      <br />
      <p>
        ARPAGON SAS ne peut garantir la qualité du réseau internet ni son accès
        et ne peut garantir l'absence d'interruption du Service propre au réseau
        internet. En conséquence, ARPAGON SAS ne pourra en aucun cas être tenue
        responsable de dysfonctionnements dans l'accès aux Services, des
        vitesses d'ouverture et de consultation des pages des Services, de
        l'inaccessibilité temporaire ou définitive de l'Outil, de l'utilisation
        frauduleuse par des tiers des informations mises à disposition sur
        l'Outil.
      </p>
      <br />
      <p>
        En conséquence également, il incombe à l'Utilisateur de protéger son
        matériel informatique ou autre notamment contre toute forme d'intrusion
        et/ou de contamination par des virus. ARPAGON SAS ne saurait en aucun
        cas être tenue pour responsable d'une intrusion et/ou contamination par
        des virus de son matériel résultant d'une faute, maladresse ou
        négligence de l'Utilisateur. ARPAGON SAS ne saurait être tenue
        responsable de tout dysfonctionnement ou de toute détérioration du
        matériel de l'Utilisateur résultant d'une faute, maladresse ou
        négligence de l'Utilisateur ou de fait imprévisible et insurmontable
        d'un tiers.
      </p>
      <br />
      <p>
        Plus généralement, ARPAGON SAS dégage toute responsabilité si un
        manquement à une quelconque obligation était le fait d'un cas de force
        majeure ou fortuite, y compris, mais sans y être limitées, catastrophes,
        incendies, grève interne ou externe, défaillance ou pannes internes ou
        externes, et d'une manière générale tout évènement irrésistible et
        imprévisible ne permettant pas la bonne exécution des commandes.
      </p>
      <br />
      <p>
        En aucun cas ARPAGON SAS ne saurait être tenue responsable dans
        l'hypothèse où le Service proposé s'avèrerait incompatible avec certains
        équipements et/ou fonctionnalités du matériel informatique de
        l'Utilisateur.
      </p>
      <br />
      <p>
        Enfin, ARPAGON SAS ne peut être tenue pour responsable de l'utilisation
        fautive faite par l'Utilisateur des Services ni de l'utilisation du
        Compte de l'Utilisateur faite par un tiers à qui l'Utilisateur aurait
        communiqué ses identifiants ou qui aurait eu accès au Compte suite à une
        faute, maladresse ou négligence de la part de l'Utilisateur.
      </p>
      <br />
      <p>
        L'Utilisateur s'engage dans ces cas à faire son affaire personnelle de
        toute réclamation, revendication, ou opposition et plus généralement de
        toute procédure formée contre ARPAGON SAS émanant d'un tiers.
      </p>
      <br />
      <p>Par ailleurs, ARPAGON SAS ne pourra être tenue pour responsable :</p>
      <ul>
        <li>
          de l'inadéquation du Service à un usage particulier ou aux attentes et
          besoins de l'Utilisateur, les Services de l'Outil étant proposés en
          l'état ;
        </li>
        <li>
          de l'inexactitude ou de la non-conformité des informations, produits,
          et autres contenus, incluant notamment les données renseignées par
          l'Utilisateur, concernant ses comptes, son budget et son patrimoine ;
        </li>
        <li>
          de l'inexactitude ou de la non-conformité des résultats obtenus via
          l'utilisation de l'Outil ;
        </li>
        <li>
          de l'usage que l'Utilisateur fait des informations ou des outils
          d'aide à la décision qui sont mis à sa disposition par le biais du
          Service, l'Utilisateur étant et demeurant seul responsable des
          décisions qu'il prend et des choix qu'il fait.
        </li>
      </ul>
      <br />
      <h4>7. Propriété intellectuelle</h4>
      <p>
        La structure générale de l'Outil, les Services et tous les éléments les
        composant (tels que notamment logos, noms de domaine, photographies,
        images, textes…) sont la propriété exclusive de ARPAGON SAS et/ou de ses
        concédants.
      </p>
      <br />
      <p>
        Ces éléments sont protégés par les lois relatives à la propriété
        intellectuelle et autres. L'Utilisateur ne pourra utiliser ces éléments
        que dans le cadre de l'utilisation des Services de l'Outil, conformément
        aux dispositions des présentes Conditions Générales d'Utilisation.
      </p>
      <br />
      <p>
        Toute représentation totale ou partielle de l'Outil et/ou des éléments
        le composant (tels que décrits ci-dessus) par quelque procédé que ce
        soit, sans l'autorisation expresse de ARPAGON SAS, est dès lors
        interdite et constituerait une contrefaçon sanctionnée par les articles
        L. 335-2 et suivants du Code de la propriété intellectuelle.
      </p>
      <br />
      <p>
        Toute utilisation artificielle d'un Compte, via des processus
        automatisés telles que des robots ou scripts ou par tout autre moyen est
        strictement interdite.
      </p>
      <br />
      <p>
        Toute utilisation à des fins autres que privées expose l'Utilisateur à
        des poursuites judiciaires civiles et/ou pénales.
      </p>
      <br />
      <p>
        L'Utilisateur déclare prendre acte que le fait de porter sciemment
        atteinte à une mesure technique de protection l'expose, par application
        des dispositions de l'article L. 335-4-1 du Code de la Propriété
        Intellectuelle au paiement d'une amende de 3.750 euros et que le fait de
        procurer ou proposer sciemment à autrui, directement ou indirectement,
        des moyens conçus ou spécialement adaptés pour porter atteinte à une
        mesure technique de protection étant pour sa part puni d'une peine
        d'emprisonnement de six mois et de 30.000 euros d'amende.
      </p>
      <br />
      <h4>
        8. Suspension de l'accès Utilisateur à l'initiative de ARPAGON SAS
      </h4>
      <p>
        Sans préjudice de tous dommages et intérêts que ARPAGON SAS pourrait
        solliciter, ARPAGON SAS se réserve le droit de suspendre l'accès d'un
        Utilisateur aux Services en cas de :
      </p>
      <br />
      <p>
        non respect par l'Utilisateur des présentes Conditions Générales
        d'Utilisation, et notamment : non respect des droits de propriété
        intellectuelle de ARPAGON SAS et/ou de ses concédants ; contournement ou
        tentative de contournement des mesures techniques de protection mises en
        place par ARPAGON SAS ; connexions multiples simultanées à un même
        Compte ou tentative de connexions multiples simultanées ; fourniture de
        fausses informations lors de son abonnement aux Services du Site ;
      </p>
      <p>agissements contraires aux intérêts commerciaux de ARPAGON SAS.</p>
      <br />
      <p>
        Arpagon se réserve par ailleurs le droit de fermer et supprimer tout
        Compte qui resterait inactif pendant une durée continue de 3 (trois)
        mois.
      </p>
      <br />
      <h4>
        9. Modifications des présentes Conditions Générales d'Utilisation et de
        Vente
      </h4>
      <p>
        ARPAGON SAS se réserve le droit de modifier à sa discrétion les
        présentes Conditions Générales d'Utilisation. ARPAGON SAS informera
        chaque Utilisateur par courrier électronique à l'adresse renseignée sur
        le Compte de l'Utilisateur de toute modification des présentes
        Conditions Générales d'Utilisation au minimum 15 (quinze) jours avant
        leur prise d'effet. Dans l'hypothèse où l'Utilisateur n'accepterait pas
        ces modifications, il sera libre de résilier son compte sur l'Outil.
      </p>
      <br />
      <p>
        L'Utilisateur est réputé avoir accepté la modification des CGU s'il n'a
        pas notifié à Arpagon, avant la date d'entrée en vigueur proposée de
        cette modification, qu'il ne l'acceptait pas.
      </p>
      <br />
      <p>
        Il en résulte que Arpagon ne peut en aucun cas être tenue responsable
        d'un quelconque dommage résultant de la modification des CGU dès lors
        que l'Utilisateur s'abstient de résilier son compte et continue à
        utiliser les Services après la date d'entrée en vigueur de la
        modification.
      </p>
      <br />
      <h4>10. Service clientèle</h4>
      <p>
        Pour toute information ou question concernant l'utilisation de l'Outil,
        un service de support par email est proposé dans l'Outil.
      </p>
      <br />
      <p>
        L'Utilisateur peut par ailleurs contacter ARPAGON SAS en adressant un
        courrier à l'adresse suivante 22, rue Gay-Lussac 75005 Paris ou en
        envoyant un mail à l'adresse{" "}
        <a data-fr-linked="true" href="mailto:contact@arpagon.fr">
          contact@arpagon.fr
        </a>
      </p>
      <br />
      <h4>11. Nullité d'une clause</h4>
      <p>
        Dans l'hypothèse où l'une des dispositions des présentes Conditions
        Générales d'Utilisation serait déclarée invalide ou inopposable pour
        quelque cause que ce soit, les autres dispositions demeureront
        applicables sans changement, sauf les cas où le contrat ne pourrait
        subsister sans elle.
      </p>
      <br />
      <h4>12. Informations relatives au traitement des réclamations</h4>
      <p>
        Les présentes Conditions Générales d'Utilisation sont soumises à la loi
        française.
      </p>
      <br />
      <p>
        En cas de litige ou de réclamation de l'Utilisateur, les parties
        contractantes s'engagent à rechercher en premier lieu un arrangement
        amiable.
      </p>
      <br />
      <p>
        Le client pourra présenter sa réclamation à l'adresse du Service
        Clientèle ci-avant indiquée, dans les délais légaux.
      </p>
      <br />
      <p>
        A défaut d'arrangement amiable, les parties pourront en second lieu
        informer un médiateur.
      </p>
      <br />
      <h4>13. Cession</h4>
      <p>
        Les présentes Conditions Générales d'Utilisation peuvent faire l'objet
        d'une cession totale ou partielle, à titre onéreux ou gracieux, par
        ARPAGON SAS, sans l'accord de l'Utilisateur, dès lors que cette cession
        n'engendre pas une diminution des droits de ce dernier au titre des
        présentes Conditions Générales d'Utilisation.
      </p>
      <br />
      <h4>
        14. Politique de Confidentialité et de Protection de la Vie Privée
      </h4>
      <p>
        La Politique de Confidentialité et de Protection de la Vie Privée est
        disponible en cliquant{" "}
        <a data-fr-linked="true" href="/politique-confidentialite">
          ici
        </a>
        .
      </p>
    </Body>
  );
};

export default Cgu;
