import { FC, useMemo, useEffect } from "react";
import {
  NavLink,
  NavLinkProps,
  useLocation,
  useResolvedPath,
} from "react-router-dom";
import styled from "styled-components";
import Box from "./Box";
import MobileBox from "./MobileBox";
import { getPathMatch } from "./utils";

export const Container = styled.div`
  transition: 0.2s;
  a {
    outline: none !important;
  }
  a:focus > div {
    /* outline: medium solid black; */
  }
  a > div,
  a:focus > div {
    outline-offset: -3px;
    outline-width: 2px;
    transition-property: background-color, color;
  }
`;

type NavigationLinkProps = {
  title: string | JSX.Element;
  icon?: React.ReactElement;
  activeLink?: string;
  isMobileBox?: true;
  variant?: "classic" | "profile";
  onActive?: () => void;
} & Omit<NavLinkProps, "title">;
const NavigationLink: FC<NavigationLinkProps> = ({
  title,
  icon,
  to,
  activeLink,
  isMobileBox,
  variant = "classic",
  onActive,
  ...props
}) => {
  // Code copied directly from the NavLink definition
  const location = useLocation();
  const path = useResolvedPath(activeLink || to);

  const active = useMemo(() => {
    const { isPath, isSubPath } = getPathMatch(location, path);
    return isPath || isSubPath;
  }, [location, path]);

  useEffect(() => {
    active && onActive?.();
  }, [active, onActive]);

  return (
    <Container>
      <NavLink {...props} to={to}>
        {isMobileBox ? (
          <MobileBox active={!onActive && active}>
            {icon}
            <div className="title">{title}</div>
          </MobileBox>
        ) : (
          <Box active={!onActive && active} variant={variant}>
            {icon || <div className="no-icon" />}
            <div className="title">{title}</div>
          </Box>
        )}
      </NavLink>
    </Container>
  );
};

export default NavigationLink;
