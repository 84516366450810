import React, { FC, HTMLProps, useMemo } from "react";
import styled from "styled-components";
import { ObjectEntries } from "../utils/utils";
import ChevronDownIcon from "./icons/ChevronDown";

type ContainerProps = {
  icon?: React.ReactElement;
  error?: boolean;
} & React.HTMLProps<HTMLDivElement>;
/* eslint-disable @typescript-eslint/no-unused-vars */
const ContainerComponent: FC<ContainerProps> = ({ icon, error, ...props }) => {
  return <div {...props} />;
};
const Container = styled(ContainerComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  stroke: var(--gray-500);

  svg {
    position: absolute;
    left: 1em;
    top: 0;
    height: 100%;
    pointer-events: none;
  }
  svg:last-child {
    position: absolute;
    left: auto;
    right: 1em;
    top: 0;
    height: 100%;
  }
  select {
    background-color: white;
    height: 3em;
    cursor: pointer;
    width: 100%;
    -webkit-appearance: none;
    border: 1px solid
      ${({ error }) => (error ? "var(--error-500)" : "var(--gray-400)")};
    border-radius: 4px;
    padding-right: 2.5em;
    padding-left: ${({ icon }) => (icon ? "2.8em" : "1em")};
    box-shadow: var(--box-shadow);
    transition: 0.2s;
    color: ${({ error }) => (error ? "var(--error-500)" : "var(--gray-800)")};
    font-family: unset;
    font-size: unset;

    &:hover:not([disabled]) {
      border: 1px solid
        ${({ error }) => (error ? "var(--error-500)" : "var(--gray-500)")};
    }
    &:focus {
      outline: 1px solid
        ${({ error }) => (error ? "var(--error-500)" : "var(--gray-800)")};
    }
    &::placeholder {
      color: ${({ error }) => (error ? "var(--error-500)" : "var(--gray-500)")};
      opacity: 1;
    }

    &:disabled {
      cursor: unset;
      border: var(--border);
      background-color: unset;
    }
  }
`;

export const Label = styled.label<{ error?: boolean }>`
  margin-left: 2px;
  font-size: 0.9em;
  color: ${({ error }) => (error ? "var(--error-500)" : "var(--black)")};
`;

export type SelectProps<T extends string> = {
  optionsEnum: Record<T, string>;
  disabledOptions?: T[];
  className?: string;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  value?: T;
  icons?: { value: T; icon: React.ReactElement }[];
  error?: boolean;
} & HTMLProps<HTMLSelectElement>;
const Select = <T extends string>({
  optionsEnum,
  disabledOptions,
  className,
  label,
  onChange,
  readOnly,
  value,
  icons,
  disabled,
  error,
  ...props
}: SelectProps<T>) => {
  const icon = useMemo(() => {
    return icons?.find((icon) => icon.value === value)?.icon;
  }, [icons, value]);

  return (
    <div className={`select-body ${className || ""}`}>
      {label && <Label error={error}>{label}</Label>}
      <Container className="select-container" icon={icon} error={error}>
        <select
          {...props}
          value={value}
          onChange={readOnly ? () => null : onChange}
          disabled={disabled || readOnly}
        >
          {ObjectEntries(optionsEnum).map(([k, label]) => (
            <option key={k} value={k} disabled={disabledOptions?.includes(k)}>
              {label}
            </option>
          ))}
        </select>
        {icon}
        <ChevronDownIcon />
      </Container>
    </div>
  );
};

export default Select;
