import styled from "styled-components";
import Input, { InputProps } from "./Input";
import Select, { SelectProps } from "./Select";

const Body = styled.div<{ paddingRight?: string }>`
  .input-body {
    width: 100%;
    input {
      padding-right: ${({ paddingRight }) => paddingRight || "90px"};
    }
    .input-and-children {
      position: relative;
      .nested-select {
        font-size: 0.9em;
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: 1px;
        .select-container {
          height: 100%;
          select {
            outline: none;
            border: none;
            box-shadow: none;
            height: 100%;
          }
        }
      }
    }
  }
`;

type Props<T extends string> = {
  selectName?: string;
  selectValue?: SelectProps<T>["value"];
  optionsEnum: SelectProps<T>["optionsEnum"];
  onSelectChange?: SelectProps<T>["onChange"];
  paddingRight?: string;
} & InputProps;
const InputAndSelect = <T extends string>({
  optionsEnum,
  selectName,
  selectValue,
  onSelectChange,
  paddingRight,
  ...props
}: Props<T>) => (
  <Body paddingRight={paddingRight}>
    <Input {...props}>
      <Select
        className="nested-select"
        name={selectName}
        optionsEnum={optionsEnum}
        onChange={onSelectChange}
        value={selectValue}
        required={props.required}
        disabled={props.disabled}
      />
    </Input>
  </Body>
);

export default InputAndSelect;
