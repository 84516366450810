import styled, { css } from "styled-components";
import { MyPatrimony } from "../../types/patrimony";
import {
  PatrimonySections as PatrimonySectionsType,
  PatrimonySection as PatrimonySectionType,
  AllPatrimonyCategoriesIcons,
} from "../../types/patrimony.enums";
import { currency } from "../../utils/format.utils";
import { ObjectKeys } from "../../utils/utils";
import PatrimonyCategory from "./PatrimonyCategory";
import { Connection } from "../../types/connection";
import RefreshButton from "./RefreshButton";
import CompletePatrimonyButton from "./CompletePatrimonyButton";

export const StyledPatrimonySection = styled.div<{
  noGap?: true;
  isLastSection?: boolean;
}>`
  ${(p) =>
    p.isLastSection &&
    css`
      min-height: 100%;
      ::after {
        content: "";
        display: block;
        height: 12px;
        height: 16px;
      }
    `}
  margin-top: 32px;
  &:first-child {
    margin-top: 0;
  }
  .header {
    margin: 0 16px 16px 0;
    display: flex;
    font-weight: 600;
    .title {
      flex: auto;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .total {
      white-space: nowrap;
    }
  }
  .categories {
    display: flex;
    flex-direction: column;
    ${({ noGap }) => (noGap ? "" : "gap: 16px;")}

    .first {
      @media print {
        break-inside: auto;
      }
    }
  }

  @media print {
    page-break-before: avoid !important;
  }

  page-break-before: always !important;
`;

type PatrimonySectionProps = {
  section: PatrimonySectionType;
  myPatrimony: MyPatrimony;
  sectionsRefs: Record<PatrimonySectionType, React.RefObject<HTMLDivElement>>;
  isRefreshing: boolean;
  refetchSync: () => void;
  connections: Connection[] | undefined;
  isLastSection: boolean | undefined;
  extraHeader?: any;
};

export const PatrimonySection = ({
  section,
  myPatrimony,
  sectionsRefs,
  isRefreshing,
  refetchSync,
  connections,
  isLastSection,
  extraHeader = null,
}: PatrimonySectionProps) => {
  const sectionData = myPatrimony.sections[section];

  return Object.keys(sectionData.categories).length > 0 || extraHeader ? (
    <StyledPatrimonySection isLastSection={isLastSection}>
      {extraHeader}
      {Object.keys(sectionData.categories).length > 0 ? (
        <>
          <div className="header" ref={sectionsRefs[section]}>
            <div className="title">
              {PatrimonySectionsType[section]}{" "}
              {["SAVINGS", "CREDITS"].includes(section) && (
                <RefreshButton isLoading={isRefreshing} onClick={refetchSync} />
              )}
            </div>
            <div className="total">{currency(sectionData.total)}</div>
          </div>
          <div className="categories">
            {ObjectKeys(AllPatrimonyCategoriesIcons).map((category, index) => {
              const data = sectionData.categories?.[category];
              return (
                data && (
                  <PatrimonyCategory
                    key={category}
                    connections={connections}
                    category={category}
                    total={data.total}
                    assets={data.assets}
                    index={index}
                  />
                )
              );
            })}
          </div>
        </>
      ) : null}
      {isLastSection && <CompletePatrimonyButton />}
    </StyledPatrimonySection>
  ) : (
    <div ref={sectionsRefs[section]} />
  );
};

export default PatrimonySection;
