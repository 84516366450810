import Button from "../../components/Button";
import { useMutation } from "@tanstack/react-query";
import { sendConfirmationCode, signup } from "../../services/auth.service";
import Link from "../../components/Link";
import AuthenticationLayout from "./AuthenticationLayout";
import Input from "../../components/Input";
import { FormContainer, Title, LinkContainer } from "./Login";
import { Formik } from "formik";
import { onSuccessLogin } from "../../utils/auth.utils";
import { SignupUser } from "../../types/user";
import { AxiosError } from "axios";
import Checkbox from "../../components/Checkbox";
import { useState } from "react";

const Signup = () => {
  const [showError, setShowError] = useState(false);

  const {
    mutateAsync: trySignup,
    isLoading,
    error,
  } = useMutation<{ jwt: string }, AxiosError, SignupUser>({
    mutationFn: signup,
    onSuccess: ({ jwt }) => {
      onSuccessLogin(jwt);
      sendConfirmationCode();
    },
    onSettled: () => setShowError(true),
  });

  return (
    <AuthenticationLayout>
      <Formik
        initialValues={{
          email: "",
          password: "",
          acceptCgu: false,
        }}
        onSubmit={({ acceptCgu, ...values }) => trySignup(values)}
      >
        {({ values, handleSubmit, handleChange, setFieldValue }) => (
          <FormContainer onSubmit={handleSubmit}>
            <Title>Créer votre compte</Title>
            <Input
              type="email"
              name="email"
              onChange={(e) => {
                setShowError(false);
                handleChange(e);
              }}
              autoComplete="off"
              placeholder="Email"
              label="Email"
              value={values.email}
              required
              error={
                showError && error
                  ? error.response?.status === 409
                    ? "Cette adresse email est déjà utilisée."
                    : "Une erreur est survenue."
                  : undefined
              }
            />
            <Input
              type="password"
              name="password"
              onChange={handleChange}
              autoComplete="off"
              placeholder="Mot de passe"
              label="Mot de passe"
              value={values.password}
              required
            />
            <LinkContainer style={{ textAlign: "start" }}>
              <Checkbox
                onChange={(e) => setFieldValue("acceptCgu", e.target.checked)}
                label={
                  <>
                    J'accepte les{" "}
                    <Link
                      to="/cgu?signup=true"
                      underLine
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      conditions générales
                    </Link>{" "}
                    et la{" "}
                    <Link
                      to="/politique-confidentialite?signup=true"
                      underLine
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      politique de confidentialité
                    </Link>{" "}
                    d'Arpagon
                  </>
                }
              />
            </LinkContainer>
            <Button
              type="submit"
              size="fullWidth"
              loading={isLoading}
              disabled={!values.acceptCgu}
            >
              S'inscrire
            </Button>
            <div className="change-box">
              <div className="change-text">Vous avez déjà un compte ?</div>
              <Link to="/connexion" className="change-link">
                Connectez-vous
              </Link>
            </div>
          </FormContainer>
        )}
      </Formik>
    </AuthenticationLayout>
  );
};

export default Signup;
