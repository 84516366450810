import { Fragment, useState } from "react";
import BackButton from "../../components/buttons/BackButton";
import Link from "../../components/Link";
import { Body as AccountBody } from "../my-patrimony/Account";
import styled, { css } from "styled-components";
import {
  CompletePatrimonyRoundLink,
  SectionBoxes,
} from "../my-patrimony/complete/CompletePatrimony";
import { WeUseDataFromText } from "./utils/borrowing-capacity.utils";
import {
  currency,
  percentage,
  round,
  roundPercentage,
} from "../../utils/format.utils";
import {
  editChangedAsset,
  getMyPatrimony,
} from "../../services/patrimony.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import { Header, HeaderText, HeaderWrapper } from "./AmIRich";
import { ObjectEntries } from "../../utils/utils";
import {
  RiskLevel,
  RiskLevels,
  areAssetsSame,
  ChangedAsset,
  MyPatrimony,
  assetsFrom,
  formatMyRiskedPatrimony,
  RiskedPatrimonyAsset,
  getTitle,
  getRiskLevelAvLabel,
  getRiskLevelProfile,
  getRiskedAssets,
  PatrimonyAsset,
} from "../../types/patrimony";
import Select from "../../components/Select";
import { queryClient } from "../../lib/react-query";
import Input from "../../components/Input";
import {
  AllPatrimonyCategories,
  PatrimonyCategory,
  PatrimonySection,
  SavingCategories,
  SavingCategory,
  SavingLifeInsuranceProduct,
  SavingProducts,
} from "../../types/patrimony.enums";
import AsterChart, {
  AsterChartProps,
} from "../../components/charts/AsterChart";
import { PatrimonySectionsOptions } from "../my-patrimony/Patrimony";
import NavPills, { Option } from "../../components/NavPills";
import {
  EmbeddedInput,
  EmbeddedSelect,
} from "./utils/borrowing-capacity.utils";
import { useScroll } from "../../hooks/useScroll.hook";
import { BulletText } from "../../components/BulletText";

const StyledAccountBody = styled(AccountBody)`
/* margin-bottom: 60px; */
`;

export const Container = styled.div`
  background-color: var(--gray-100);
  overflow: hidden auto;
  @media (max-width: 900px) {
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
`;

export const SummaryContainer = styled.div`
  padding: 0 32px;

  @media (max-width: 900px) {
    padding: 0 16px;
  }
`;

export const Box = styled.div`
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  background-color: var(--white);
  padding: 16px;
  text-align: center;
  align-self: center;
  flex: auto;
  height: 100%;
`;

export const InnerBoxContentCenter = styled.div`
    min-width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AsterChartBox = styled(Box)`
  margin: 16px 0;
  @media (max-width: 900px) {
    margin-top: 8px;
    margin-bottom: 12px;
  }
`;

const ChartColors = {
  CURRENT_ACCOUNTS_AND_CARDS: "#26c1c9",
  PASSBOOKS_AND_SAVINGS_PLANS: "#ab7df6",
  FONDS_EUROS: "#81c926",
  UC: "#466f14",
  LUXEMBOURGISH: "#88c0b6",
  INVESTMENT_ACCOUNTS: "#ff4f4f",
  RETIREMENT_PLANS: "#5ab0ff",
  REAL_ESTATE: "#faca00",
  OTHERS: "#d4bdfa",
} as const;

export const Details = styled.div`
  border-top: 1px solid var(--gray-400);
  padding: 0 32px 32px 32px;
  .text {
    padding: 16px 0;
    text-transform: uppercase;
    font-weight: 500;
  }
  .table-container > {
    table {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
    :not(table) + table {
      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 900px) {
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    .text {
      padding-top: 12px;
      padding-bottom: 8px;
    }
    .text,
    .nav-pills-container {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 8px;
    }
    .table-container {
      padding: 0 16px 12px 16px;
      flex: auto;
      overflow: hidden auto;
    }
    .table-container > table:not(:first-child) {
      margin-top: 8px;
    }
    .separator {
      height: 8px !important;
    }
  }
`;

export const DetailsTab = styled.table<{ adaptOnMobile?: boolean }>`
  width: 100%;
  font-family: Inter;
  border-spacing: 0;

  thead,
  tbody {
    background-color: var(--white);
  }

  thead:first-child,
  tbody:first-child,
  .separator + thead {
    tr:first-child {
      th,
      td {
        &:first-child {
          border-top-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
        }
      }
    }
  }
  tbody + thead tr th {
    padding-top: 24px;
  }

  td,
  th {
    padding: 16px;
    border-top: var(--border);
    text-align: center;
    
    &:first-child {
      width: 40%;
      text-align: start;
      border-left: var(--border);
    }
    &:last-child {
      border-right: var(--border);
    }
  }

  @media (min-width: 900px) {
    td,
    th { 
      width: 15%;
      text-align: right;
      &:first-child {
        width: 40%;
      }
    }
  }

  tbody {
    &:last-child,
    &.before-separator {
      tr:last-child td {
        border-bottom: var(--border);
        &:first-child {
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  th {
    font-size: 14px;
    font-weight: 600;
    &:not(.th-risk-level) {
      text-transform: uppercase;
    }
    &:first-child {
      text-align: start;
    }
  }

  .separator {
    height: 24px;
  }

  .annual-yield,
  .risk-level,
  .borrowing-input {
    padding: 8px 16px;
  }

  .risk-level,
  .borrowing-input {
    ${EmbeddedSelect}
  }

  .annual-yield {
    div .input-body {
      ${EmbeddedInput}
    }
  }

  ${(p) =>
    p.adaptOnMobile &&
    css`
      @media (max-width: 900px) {
        tbody:last-child tr:last-child td:not(:last-child) {
          border-bottom: none;
        }
        tbody + thead tr th:not(:first-child) {
          padding: 16px;
        }
        tbody {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        tr {
          display: table-cell;
          th:not(.th-risk-level) {
            text-transform: unset;
          }
          th {
            font-weight: unset;
            font-size: 15px;
          }
          th,
          td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-left: var(--border);
            border-right: var(--border);
            &:first-child {
              background-color: unset;
              border: none;
              font-weight: 500;
            }
            &:nth-child(2) {
              border-top: var(--border);
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
            }
            &:last-child {
              border-bottom: var(--border);
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
      }
    `}
`;

const DesktopMainTableHeader = () => (
    <thead className="desktop-only">
        <tr>
            <th></th>
            <th>Valeur</th>
            <th>Rendement moyen annuel</th>
            <th>Produit annuel</th>
            <th>Niveau de risque</th>
        </tr>
    </thead>
)

const TitleNode = (p: { title: string; color?: string }) => (
  <>{p.color ? <BulletText color={p.color} children={p.title} /> : p.title}</>
);

const THead = (p: {
  title: string;
  color?: string;
  total: number;
  annualYieldAv: number;
  annualProductTotal: number;
  riskLevelWeightedAv: number;
  myRef?: React.LegacyRef<HTMLTableSectionElement>;
}) => {
  const display = p.total === 0 ? { display: 'none' } : {};
  return (
    <thead ref={p.myRef} style={display}>
      <tr>
        <th>
          <div className="mobile-only bold-500">
            <TitleNode
              title={(p.title !== "Total" ? "Total " : "") + p.title}
              color={p.color}
            />
          </div>
          <div className="desktop-only">
            <TitleNode title={p.title} color={p.color} />
          </div>
        </th>
        <th>
          <div className="mobile-only bold-500">Valeur</div>
          {currency(p.total)}
        </th>
        <th>
          <div className="mobile-only bold-500">Rendement moyen annuel</div>
          {percentage(p.annualYieldAv)}
        </th>
        <th>
          <div className="mobile-only bold-500">Produit annuel</div>
          {currency(p.annualProductTotal)}
        </th>
        <th className="th-risk-level">
          <div className="mobile-only bold-500">Niveau de risque</div>
          {getRiskLevelAvLabel(p.riskLevelWeightedAv)}
        </th>
      </tr>
    </thead>
  );
}

type TBodyProps = {
  assets: RiskedPatrimonyAsset[];
  setChangedAssets: React.Dispatch<
    React.SetStateAction<ChangedAsset<"editing">[]>
  >;
  validate: (asset: RiskedPatrimonyAsset, newRiskLevel?: RiskLevel) => void;
};
const TBody = ({ assets, setChangedAssets, validate }: TBodyProps) => (
  <tbody>
    {assets.map((asset) => (
      <tr key={asset.id}>
        <td>{asset.name}</td>
        <td>
          <div className="mobile-only bold-500">Valeur</div>
          {currency(asset.eurValue)}
        </td>
        <td className="annual-yield">
          <div className="mobile-only bold-500">Rendement moyen annuel</div>
          <div>
            <Input
              type="number"
              unit="%"
              onChange={(e) => {
                const newValue =
                  e.target.value &&
                  roundPercentage(parseFloat(e.target.value) / 100);
                setChangedAssets((prev) => [
                  ...prev.filter((x) => !areAssetsSame(x, asset)),
                  {
                    id: asset.id,
                    section: asset.section,
                    savingId: asset.savingId,
                    annualYield: newValue,
                  },
                ]);
              }}
              value={asset.annualYield && round(asset.annualYield * 100)}
              step={0.01}
              onBlur={() => validate(asset)}
              onKeyUp={(e) => {
                if (e.code === "Enter") validate(asset);
              }}
              blurOnEnter
            />
          </div>
        </td>
        <td>
          <div className="mobile-only bold-500">Produit annuel</div>
          {currency(asset.annualProduct)}
        </td>
        <td className="risk-level">
          <div className="mobile-only bold-500">Niveau de risque</div>
          <Select
            optionsEnum={RiskLevels}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const newRiskLevel = e.target.value as RiskLevel;
              setChangedAssets((prev) => [
                ...prev.filter((x) => !areAssetsSame(x, asset)),
                {
                  id: asset.id,
                  section: asset.section,
                  savingId: asset.savingId,
                  riskLevel: newRiskLevel,
                },
              ]);
              validate(asset, newRiskLevel);
            }}
            value={asset.riskLevel}
          />
        </td>
      </tr>
    ))}
  </tbody>
);

type RiskReturnSection = Exclude<PatrimonySection, "CREDITS">;

const RiskReturnProfile = () => {
  const { data: myPatrimony, isLoading } = useQuery(
    ["myPatrimony"],
    getMyPatrimony
  );

  const [changedAssets, setChangedAssets] = useState<ChangedAsset<"editing">[]>(
    []
  );

  const { mutateAsync: tryUpdateAsset } = useMutation({
    mutationFn: editChangedAsset,
    onSuccess: (changedAsset) => {
      let data = queryClient.getQueryData<MyPatrimony>(["myPatrimony"])!;

      const assets = ObjectEntries(
        data.sections[changedAsset.section].categories
      )
        .map(([category, c]) =>
          getRiskedAssets(category, c, changedAsset.section)
        )
        .flat();
      const asset = assets.find((x) => areAssetsSame(changedAsset, x))!;

      if (!("savingId" in asset)) {
        asset.annualYield = changedAsset.annualYield!;
        asset.riskLevel = changedAsset.riskLevel!;
      } else {
        const saving = assetsFrom(
          data.sections[changedAsset.section].categories
        )
          .flat()
          .find((x) => x.id === asset.savingId) as PatrimonyAsset;

        const investment = saving.investments!.find((i) => i.id === asset.id)!;
        investment.annualYield = changedAsset.annualYield!;
        investment.riskLevel = changedAsset.riskLevel!;
        saving.investments = saving.investments!.filter(
          (i) => i.id !== asset.id
        );
        data = queryClient.setQueryData<MyPatrimony>(["myPatrimony"], data)!;
        saving.investments = [...saving.investments, investment].sort(
          (a, b) => b.eurValue! - a.eurValue!
        );
      }

      queryClient.setQueryData<MyPatrimony>(["myPatrimony"], data);
    },
    onError: (e) => console.error(e),
    onSettled: (res, error, reqData) =>
      setChangedAssets((prev) =>
        prev.filter((x) => !areAssetsSame(x, reqData))
      ),
  });

  const validate = (asset: RiskedPatrimonyAsset, newRiskLevel?: RiskLevel) => {
    const changedAsset = changedAssets.find((x) => areAssetsSame(x, asset));
    if (!newRiskLevel && !changedAsset) return;
    tryUpdateAsset({
      id: asset.id,
      section: asset.section,
      savingId: asset.savingId,
      riskLevel: newRiskLevel,
      annualYield: asset.annualYield || 0,
    });
  };

  const myRiskedPatrimony =
    myPatrimony && formatMyRiskedPatrimony(myPatrimony, changedAssets);

  const { sections, annualYieldAv = 0 } = myRiskedPatrimony || {};

  const chartData: AsterChartProps["data"] = sections
    ? [
        ...(
          ObjectEntries(sections.SAVINGS.categories).filter(
            ([category]) => category !== "LIFE_INSURANCE"
          ) as [
            Exclude<
              SavingCategory | SavingLifeInsuranceProduct,
              "LIFE_INSURANCE"
            >,
            { total: number }
          ][]
        ).map(([category, c]) => ({
          id: category,
          label: { ...SavingCategories, ...SavingProducts.LIFE_INSURANCE }[
            category
          ],
          value: c.total,
          color: ChartColors[category],
        })),
        ...(["REAL_ESTATE", "OTHERS"] as const).map((x) => ({
          id: x,
          label: getTitle(x),
          value: sections[x].total,
          color: ChartColors[x],
        })),
      ]
    : [];

  const isReady = !!myPatrimony && !!myRiskedPatrimony && !!sections;

  const { scrollTo, scrolledOption, containerRef, refCallback } =
    useScroll<RiskReturnSection>("SAVINGS", isReady);

    //@ts-ignore
  const mapSavings = ([category, c]) => {
    const title =
      category in AllPatrimonyCategories
        ? AllPatrimonyCategories[category as PatrimonyCategory]
        : SavingProducts.LIFE_INSURANCE[category as SavingLifeInsuranceProduct];

    const color =
      category in ChartColors
        ? ChartColors[category as keyof typeof ChartColors]
        : undefined;
    return (
      <Fragment key={category}>
        <THead
            title={title}
            color={color}
            {...c!}
        />
        <TBody
            assets={c!.assets}
            {...{ setChangedAssets, validate }}
        />
      </Fragment>
    )
  }

  return (
    <StyledAccountBody>
      <HeaderWrapper>
        <Header>
          <Link to="/outils-analyse">
            <BackButton />
          </Link>
          <div className="title">
            Quel est mon profil risque / rendement&nbsp;?
          </div>
          <CompletePatrimonyRoundLink className="desktop-only" />
        </Header>
        <HeaderText>
          <WeUseDataFromText />
        </HeaderText>
      </HeaderWrapper>
      <Container>
        {isLoading ? (
          <Loader />
        ) : !isReady ? (
          <div>Erreur</div>
        ) : (
          <>
            <SummaryContainer>
              <SectionBoxes>
                <Box>
                  Votre profil d'investissement est{" "}
                  <strong>
                    {getRiskLevelProfile(myRiskedPatrimony.riskLevelWeightedAv)}
                  </strong>{" "}
                  avec {percentage(myRiskedPatrimony.highRiskedAssetsShare, 0)}{" "}
                  d'actifs «&nbsp;risqués&nbsp;»
                </Box>
                <Box>
                  <InnerBoxContentCenter>
                    Le rendement moyen attendu de votre patrimoine est de{" "}
                    <strong>&nbsp;{percentage(annualYieldAv, 1)}</strong>
                  </InnerBoxContentCenter>
                </Box>
              </SectionBoxes>
              <AsterChartBox>
                <AsterChart data={chartData} />
              </AsterChartBox>
            </SummaryContainer>
            <Details>
              <div className="text">Détail de nos calculs</div>
              <div className="nav-pills-container mobile-only">
                <NavPills
                  options={
                    PatrimonySectionsOptions.filter(
                      (x) => x.id !== "CREDITS"
                    ) as Option<RiskReturnSection>[]
                  }
                  onOptionSelected={scrollTo}
                  selectedOptionId={scrolledOption}
                />
              </div>
              <div className="table-container" ref={containerRef}>
                <DetailsTab 
                    adaptOnMobile 
                    style={{
                            borderBottom: "1px solid #d6d6d6",
                            borderRadius: '4px'
                    }}
                >
                  <DesktopMainTableHeader/>
                  <THead
                    {...{
                      title: "Total",
                      total: myPatrimony.totalGross,
                      ...myRiskedPatrimony,
                    }}
                  />
                  </DetailsTab>
                  <DetailsTab adaptOnMobile>
                  {ObjectEntries(sections.SAVINGS).length > 0 ? (
                    <>
                      <THead
                        title={getTitle("SAVINGS")}
                        {...sections.SAVINGS}
                        myRef={refCallback("SAVINGS")}
                      />
                      {ObjectEntries(sections.SAVINGS.categories).map(mapSavings)}
                    </>
                  ) : null}
                  </DetailsTab>
                  {ObjectEntries(sections)
                    .filter(([section]) => section !== "SAVINGS")
                    .map(([section, s]) => {
                      const assets = assetsFrom(s.categories);
                      return assets.length > 0 ? (
                        <Fragment key={section}>
                          <DetailsTab adaptOnMobile>
                            <THead
                                title={getTitle(section)}
                                color={section !== "SAVINGS" ? ChartColors[section] : undefined}
                                {...s}
                                myRef={refCallback(section)}
                            />
                            <TBody
                                assets={assets}
                                {...{ setChangedAssets, validate }}
                            />
                          </DetailsTab>
                        </Fragment>
                      ) : null;
                    })}
              </div>
            </Details>
          </>
        )}
      </Container>
    </StyledAccountBody>
  );
};

export default RiskReturnProfile;