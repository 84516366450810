import BackButton from "../../components/buttons/BackButton";
import Link from "../../components/Link";
import {
  Body as AccountBody,
  Header as AccountHeader,
} from "../my-patrimony/Account";
import styled from "styled-components";
import {
  CompletePatrimonyRoundLink,
  SectionBoxes,
} from "../my-patrimony/complete/CompletePatrimony";
import { WeUseDataFromText } from "./utils/borrowing-capacity.utils";
import { currency } from "../../utils/format.utils";
import { getMyPatrimony } from "../../services/patrimony.service";
import { useQuery } from "@tanstack/react-query";
import { formatMyBudget, getMyBudget } from "../../services/budget.service";
import Loader from "../../components/Loader";
import { getIncomesStats, getPatrimonyStats } from "./utils/am-i-rich.utils";

const StyledAccountBody = styled(AccountBody)`
  /* margin-bottom: 60px; */
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Header = styled(AccountHeader)`
  .title {
    flex: auto;
    font-weight: 600;
    text-align: center;
    font-size: 1.125rem;
    @media (max-width: 900px) {
      font-size: 1.05em;
      position: relative;
      left: -24px; // arrow width / 2
      margin: 0 24px;
    }
  }
  padding-bottom: 0;
`;

export const HeaderText = styled.div`
  padding: 0 32px 16px 32px;
  width: max-content;
  max-width: 100%;
  align-self: center;

  @media (max-width: 900px) {
    text-align: unset;
    padding: 0 16px 12px 16px;
    color: var(--white);
    font-size: 14px;
  }
`;

const Container = styled.div`
  background-color: var(--gray-100);
  overflow: hidden auto;
  padding: 0 32px 32px 32px;
  @media (max-width: 900px) {
    padding: 0 16px 12px 16px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
`;

const StyledPyramidBox = styled.div`
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  background-color: var(--white);
  padding: 16px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 14px;
    text-align: center;
  }
  .amount {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  .text {
    font-size: 16px;
    text-align: center;
  }
  svg {
    align-self: center;
    text {
      font-family: unset;
    }
  }

  @media (max-width: 1200px) {
    .text {
      font-size: 14px;
    }
  }
  @media (max-width: 1000px) {
    max-width: 600px;
    .title,
    .amount {
      text-align: unset;
    }
  }
`;

type PyramidBoxProps = {
  title: string;
  amount: number;
  x: number;
  theMostText: string;
  numberOfIndividuals: string;
  numberTotal: string;
};
const PyramidBox = ({
  title,
  amount,
  x,
  theMostText,
  numberOfIndividuals,
  numberTotal,
}: PyramidBoxProps) => {
  const y = (x / 100) * 82 + 4;

  return (
    <StyledPyramidBox>
      <div className="title">{title}</div>
      <div className="amount">{currency(amount)}</div>
      <svg
        width="200"
        height="200"
        viewBox="0 0 100 90.4"
        xmlns="http://www.w3.org/2000/svg"
        overflow="visible"
      >
        <polygon
          points="4,86 96,86 50,4.4"
          fill="#ffe32b"
          stroke="#ffe32b"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line x1="4" x2="96" y1={y} y2={y} stroke="black" strokeWidth="0.6" />
        <text
          textAnchor="end"
          x="2"
          y={y + 2.5}
          fontSize={8}
          children={`${x} %`}
        />
      </svg>
      <div className="text">
        Vous faites partie des <strong>{x} %</strong> des Français {theMostText}{" "}
        &#40;
        {numberOfIndividuals} sur {numberTotal}&#41;
      </div>
    </StyledPyramidBox>
  );
};

const AmIRich = () => {
  const { data: myPatrimony, isLoading: patrimonyLoading } = useQuery(
    ["myPatrimony"],
    getMyPatrimony
  );

  const { data, isLoading: budgetLoading } = useQuery(["budget"], getMyBudget);
  const myBudget = data && formatMyBudget(data);
  const budgetedIncomes = myBudget?.budgetedIncomes;

  const isLoading = patrimonyLoading && budgetLoading;

  return (
    <StyledAccountBody>
      <HeaderWrapper>
        <Header>
          <Link to="/outils-analyse">
            <BackButton />
          </Link>
          <div className="title">Suis-je riche&nbsp;?</div>
          <CompletePatrimonyRoundLink className="desktop-only" />
        </Header>
        <HeaderText>
          <WeUseDataFromText budget />
        </HeaderText>
      </HeaderWrapper>
      <Container>
        {isLoading ? (
          <Loader />
        ) : budgetedIncomes === undefined || !myPatrimony ? (
          <div>Erreur</div>
        ) : (
          <SectionBoxes>
            <PyramidBox
              title="Patrimoine net"
              amount={myPatrimony.totalNet}
              theMostText="les plus riches"
              {...getPatrimonyStats(myPatrimony.totalNet)}
            />
            <PyramidBox
              title="Revenus mensuels"
              amount={budgetedIncomes}
              theMostText="ayant le plus de revenus"
              {...getIncomesStats(budgetedIncomes)}
            />
          </SectionBoxes>
        )}
      </Container>
    </StyledAccountBody>
  );
};

export default AmIRich;
