import styled from "styled-components";
import { Advisor } from "../../types/advisor.enums";
import Chip from "../../components/Chip";
import { Link } from "react-router-dom";
import CalendlyButton from "../../components/CalendlyButton";
import { config } from "../../config/config";

const StyledCardAdvisor = styled(Link)`
  :hover {
    box-shadow: 0px 0px 16px 0px var(--gray-300);
    border: 1px solid var(--yellow-700);
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--gray-300);
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 49%;
  max-width: 49%;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    max-width: 100%;
  }

  .personal-information {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  img {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
  }

  .name {
    font-size: 16px;
    font-weight: 500;
  }

  .adress-company {
    font-size: 14px;
    font-weight: 400;
  }

  .expertise {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .biography {
    font-size: 12px;
    font-weight: 400;
  }

  .chip {
    font-size: 10px;
    font-weight: 400;
    padding: 4px 8px 4px 8px;
  }

  .button-calendly {
    z-index: 1;
    max-width: 16rem;
  }
`;

const CardAdvisor = ({ advisor }: { advisor: Advisor }) => {
  const biography =
    advisor.biography?.length > 150
      ? advisor.biography.slice(0, 150) + "..."
      : advisor.biography;

  return (
    <StyledCardAdvisor key={advisor.id} to={advisor.id.toString()}>
      <div className="personal-information">
        <img
          alt="Profil du conseiller"
          src={config.bucketUrl + advisor.profilePicture}
        ></img>
        <div className="information">
          <p className="name">
            {advisor.firstname} {advisor.lastname}
          </p>
          <p className={"adress-company"}>
            {advisor.adress}, {advisor.company}
          </p>
          <div>
            <div className="chip-group">
              {advisor.expertise.map((expertise) => (
                <Chip
                  key={
                    "Chip-expertise-" + expertise.expertise.label + advisor.id
                  }
                  label={expertise.expertise.label}
                  active={true}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <p className="biography">{biography}</p>
      <div onClick={(e) => e.stopPropagation()}>
        <CalendlyButton
          key={advisor.id}
          className="button-calendly"
          url={advisor.url_calendly}
          id_advisor={advisor.id}
        />
      </div>
    </StyledCardAdvisor>
  );
};

export default CardAdvisor;
