import { useMutation, useQuery } from "@tanstack/react-query";
import BackButton from "../../components/buttons/BackButton";
import Link from "../../components/Link";
import Loader from "../../components/Loader";
import { Container as AccountContainer } from "../my-patrimony/Account";
import styled from "styled-components";
import { getMe, update } from "../../services/user.service";
import Button from "../../components/Button";
import { useState } from "react";
import { ChangePasswordDto, EditProfileUser } from "../../types/user";
import { Formik, Form } from "formik";
import Input from "../../components/Input";
import {
  MaritalStatusDisplay,
  ProfessionalStatusDisplay,
  ApproximateAnnualIncomeDisplay,
  ApproximateWealthDisplay,
  BusinessLineDisplay,
} from "../../types/user.enums";
import Select from "../../components/Select";
import { onSuccessLogin, onSuccessUpdateUser } from "../../utils/auth.utils";
import { format } from "date-fns";
import { ObjectEntries } from "../../utils/utils";
import { areDatesEqual } from "../../utils/date.utils";
import ActionModal from "../../components/modals/ActionModal";
import { changePassword } from "../../services/auth.service";
import { AxiosError } from "axios";

export const Body = styled.div`
  display: grid;
  grid-template-rows: min-content auto;
  overflow: hidden;
  height: 100%;
`;

export const Header = styled.div<{ isEditing?: boolean }>`
  display: flex;
  align-items: center;
  > * {
    flex: 1 1 0px;
  }
  .left {
    @media (max-width: 900px) {
      font-size: 1.15rem;
      text-align: center;
    }
  }
  .title {
    font-size: 20px;
    @media (max-width: 900px) {
      font-size: 1.15rem;
      text-align: center;
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    .edit-button {
      &.mobile-only {
        padding: 4px;
        font-size: 0.9rem;
        text-transform: uppercase;
        ${(p) => p.isEditing && "color: var(--yellow-400);"}
      }
      &.desktop-only {
        padding: 12px 24px;
        font-size: 1rem;
      }
    }
  }

  @media (min-width: 900px) {
    padding: 0 32px 16px 24px;
  }
  @media (max-width: 900px) {
    padding: 16px;
    color: var(--white);
  }
`;

export const Container = styled(AccountContainer)`
  padding: 0 32px 32px 24px;
  /* margin-bottom: 60px; */
  .title-form {
    margin-top: 16px;
    margin-bottom: 6px;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
  }
  .mini-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    @media (max-width: 1100px) {
      grid-template-columns: 1fr;
      gap: 8px;
    }
    .select-body {
      align-self: end;
    }
    .password-container {
      display: grid;
      .change-password {
        font-size: 14px;
        justify-self: end;
        font-weight: 500;
        margin-top: 4px;
        cursor: pointer;
        user-select: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media (max-width: 900px) {
    padding: 0 16px 16px 16px;
  }
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 16px;
  gap: 12px;
  @media (min-width: 900px) {
    .input-body {
      min-width: 300px;
    }
  }
`;


const EditProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const { data: user } = useQuery(["user"], getMe);

  const { mutateAsync: tryUpdate, isLoading } = useMutation({
    mutationFn: update,
    onSuccess: (data) => {
      onSuccessUpdateUser(data);
      setIsEditing(false);
    },
  });

  const [showPasswordError, setShowPasswordError] = useState(false);

  const {
    mutateAsync: tryChangePassword,
    isLoading: isChangePasswordLoading,
    error,
    reset: resetPasswordForm,
  } = useMutation<{ jwt: string }, AxiosError, ChangePasswordDto>({
    mutationFn: changePassword,
    onSuccess: ({ jwt }) => {
      onSuccessLogin(jwt);
      closeChangePassword();
    },
    onSettled: () => setShowPasswordError(true),
  });

  const closeChangePassword = () => {
    setOpenChangePassword(false);
    resetPasswordForm();
  };

  const buttonTitle = isEditing ? "Enregistrer" : "Modifier";

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isEditing) {
      setIsEditing(true);
      e.preventDefault();
    }
  };

  const onSubmit = (values: EditProfileUser) => {
    const { birthDate, ...submitedUser } = values;

    const changedUser = ObjectEntries(submitedUser)
      .filter(([k, v]) => user![k] !== v)
      .reduce<Partial<EditProfileUser>>(
        (acc, [k, v]) => ({ ...acc, [k]: v }),
        {}
      );

    if (!areDatesEqual(new Date(birthDate), new Date(user!.birthDate))) {
      changedUser.birthDate = birthDate;
    }

    if (Object.keys(changedUser).length) {
      tryUpdate(changedUser);
    } else {
      setIsEditing(false);
    }
  };
  
  return (
    <Body>
      <Header isEditing={isEditing}>
        <div className="left mobile-only">
          <Link to="/profil">
            <BackButton />
          </Link>
        </div>
        <div className="title">Mon profil</div>
        <div className="right">
          <Button
            className="edit-button desktop-only"
            onClick={onClick}
            children={buttonTitle}
            loading={isLoading}
            loaderReplaceChildren
            disabled={!user}
            type="submit"
            form="user-form"
          />
          <Button
            variant="transparent"
            className="edit-button mobile-only"
            onClick={onClick}
            children={buttonTitle}
            loading={isLoading}
            loaderReplaceChildren
            disabled={!user}
            type="submit"
            form="user-form"
          />
        </div>
      </Header>
      <Container>
        {!user ? (
          <Loader />
        ) : (
          <>
            <Formik
              initialValues={
                {
                  lastname: user.lastname,
                  firstname: user.firstname,
                  birthDate: format(new Date(user.birthDate), "yyyy-MM-dd"),
                  phoneNumber: user.phoneNumber,
                  maritalStatus: user.maritalStatus,
                  numberOfChildren: user.numberOfChildren,
                  professionalStatus: user.professionalStatus,
                  businessLine: user.businessLine,
                  approximateWealth: user.approximateWealth,
                  approximateAnnualIncome: user.approximateAnnualIncome,
                  isOwner: user.isOwner,
                } as EditProfileUser
              }
              onSubmit={(values) => onSubmit(values)}
            >
              {({ values, handleSubmit, handleChange, setFieldValue }) => (
                <Form
                  onSubmit={handleSubmit}
                  id={isEditing ? "user-form" : undefined}
                >
                  <div className="title-form">Compte</div>
                  <div className="mini-form">
                    <Input
                      placeholder="Email"
                      label="Email"
                      value={user.email}
                      disabled
                    />
                    <div className="password-container">
                      <Input
                        type="password"
                        label="Mot de passe"
                        value="**************"
                        disabled
                      />
                      <div
                        className="change-password"
                        onClick={() => setOpenChangePassword(true)}
                      >
                        Modifier le mot de passe
                      </div>
                    </div>
                  </div>
                  <div className="title-form">Contact</div>
                  <div className="mini-form">
                    <Input
                      name="lastname"
                      onChange={handleChange}
                      placeholder="Nom"
                      label="Nom"
                      value={values.lastname}
                      required
                      disabled={!isEditing}
                    />
                    <Input
                      name="firstname"
                      onChange={handleChange}
                      placeholder="Prénom"
                      label="Prénom"
                      value={values.firstname}
                      required
                      disabled={!isEditing}
                    />
                    <Input
                      type="date"
                      name="birthDate"
                      onChange={handleChange}
                      placeholder="Date de naissance"
                      label="Date de naissance"
                      value={values.birthDate}
                      required
                      disabled={!isEditing}
                    />
                    <Input
                      name="phoneNumber"
                      onChange={handleChange}
                      placeholder="Numéro de téléphone"
                      label="Numéro de téléphone"
                      value={values.phoneNumber}
                      required
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="title-form">Profil</div>
                  <div className="mini-form">
                    <Select
                      name="maritalStatus"
                      optionsEnum={MaritalStatusDisplay}
                      onChange={handleChange("maritalStatus")}
                      label="Statut marital"
                      value={values.maritalStatus}
                      required
                      disabled={!isEditing}
                    />
                    <Input
                      type="number"
                      name="numberOfChildren"
                      onChange={handleChange}
                      placeholder="Nombre d'enfants"
                      label="Nombre d'enfants"
                      value={values.numberOfChildren}
                      min={0}
                      required
                      disabled={!isEditing}
                    />
                    <Select
                      name="professionalStatus"
                      optionsEnum={ProfessionalStatusDisplay}
                      onChange={handleChange("professionalStatus")}
                      label="Statut professionnel"
                      value={values.professionalStatus}
                      required
                      disabled={!isEditing}
                    />
                    <Select
                      name="businessLine"
                      optionsEnum={BusinessLineDisplay}
                      onChange={handleChange("businessLine")}
                      label="Secteur d'activité"
                      value={values.businessLine}
                      required
                      disabled={!isEditing}
                    />
                  </div>
                  <div className="title-form">Patrimoine</div>
                  <div className="mini-form">
                    <Select
                      name="approximateWealth"
                      optionsEnum={ApproximateWealthDisplay}
                      onChange={handleChange("approximateWealth")}
                      label="Montant approximatif de votre patrimoine"
                      value={values.approximateWealth}
                      required
                      disabled={!isEditing}
                    />
                    <Select
                      name="approximateAnnualIncome"
                      optionsEnum={ApproximateAnnualIncomeDisplay}
                      onChange={handleChange("approximateAnnualIncome")}
                      label="Montant approximatif de vos revenus annuels"
                      value={values.approximateAnnualIncome}
                      required
                      disabled={!isEditing}
                    />
                    <Select
                      name="isOwner"
                      optionsEnum={{ true: "Propriétaire", false: "Locataire" }}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue("isOwner", e.target.value === "true");
                      }}
                      label="Propriétaire ou locataire"
                      value={`${values.isOwner}`}
                      required
                      disabled={!isEditing}
                    />
                  </div>
                </Form>
              )}
            </Formik>
            <ActionModal
              open={openChangePassword}
              close={closeChangePassword}
              formId="change-password-form"
              loading={isChangePasswordLoading}
              hideCancelButton
              applyText="Enregistrer"
              title="Modifier le mot de passe"
            >
              <Formik
                initialValues={{ currentPassword: "", newPassword: "" }}
                onSubmit={(values) => tryChangePassword(values)}
              >
                {({ values, handleSubmit, handleChange }) => (
                  <ModalForm
                    onSubmit={handleSubmit}
                    id={openChangePassword ? "change-password-form" : undefined}
                  >
                    <Input
                      type="password"
                      autoComplete="off"
                      name="currentPassword"
                      onChange={(e) => {
                        setShowPasswordError(false);
                        handleChange(e);
                      }}
                      placeholder="Mot de passe actuel"
                      label="Mot de passe actuel"
                      value={values.currentPassword}
                      required
                      noValidation
                      error={
                        showPasswordError && error
                          ? error.response?.status === 401
                            ? "Mot de passe incorrect."
                            : "Une erreur est survenue."
                          : undefined
                      }
                    />
                    <Input
                      type="password"
                      autoComplete="off"
                      name="newPassword"
                      onChange={handleChange}
                      placeholder="Nouveau mot de passe"
                      label="Nouveau mot de passe"
                      value={values.newPassword}
                      required
                    />
                  </ModalForm>
                )}
              </Formik>
            </ActionModal>
          </>
        )}
      </Container>
    </Body>
  );
};

export default EditProfile;
