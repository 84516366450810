const HandHouseIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M2.49997 11.7153H0.833324C0.373092 11.7153 0 12.0884 0 12.5487V18.3819C0 18.8422 0.373092 19.2152 0.833324 19.2152H2.49997C2.9602 19.2152 3.3333 18.8422 3.3333 18.3819V12.5487C3.3333 12.0884 2.9602 11.7153 2.49997 11.7153Z"
        fill="#FFE32B"
      />
      <path
        d="M19.7698 15.8891C19.8287 15.8595 19.8797 15.8163 19.9186 15.7632C19.9576 15.71 19.9834 15.6483 19.9938 15.5833C20.0043 15.5182 19.9992 15.4516 19.979 15.3888C19.9587 15.3261 19.9239 15.2691 19.8773 15.2225C19.5452 14.7909 19.0903 14.47 18.5724 14.3019C18.0545 14.1338 17.4978 14.1265 16.9757 14.2808C16.9273 14.295 15.3365 14.8041 15.289 14.8191C15.2036 14.8464 15.1293 14.9004 15.0771 14.9732C15.0248 15.046 14.9975 15.1337 14.999 15.2233C15.0034 15.4723 14.9582 15.7196 14.866 15.9509C14.7739 16.1822 14.6365 16.3928 14.4621 16.5704C14.2876 16.7481 14.0795 16.8892 13.8499 16.9855C13.6203 17.0819 13.3738 17.1316 13.1249 17.1316H8.74991C8.6394 17.1316 8.53342 17.0877 8.45528 17.0096C8.37714 16.9314 8.33325 16.8255 8.33325 16.715C8.33325 16.6045 8.37714 16.4985 8.45528 16.4203C8.53342 16.3422 8.6394 16.2983 8.74991 16.2983H13.1249C13.4011 16.2983 13.6661 16.1886 13.8614 15.9932C14.0568 15.7979 14.1665 15.5329 14.1665 15.2566C14.1665 14.9804 14.0568 14.7154 13.8614 14.5201C13.6661 14.3247 13.4011 14.215 13.1249 14.215L10.1607 14.2208C10.0803 14.2212 10.0015 14.198 9.93406 14.1542C9.8324 14.0892 9.69573 13.9875 9.5774 13.9042C8.64355 13.1051 7.47631 12.6295 6.24994 12.5483H4.58329C4.47278 12.5483 4.3668 12.5922 4.28866 12.6704C4.21052 12.7485 4.16663 12.8545 4.16663 12.965V17.8816C4.16652 17.9687 4.19371 18.0537 4.24438 18.1245C4.29505 18.1954 4.36666 18.2486 4.44912 18.2766C5.03745 18.4758 5.56661 18.6599 6.04661 18.8266C8.23325 19.5833 9.45323 19.9999 10.5616 19.9999C11.9149 19.9999 13.1024 19.3816 15.6773 18.0116C16.7448 17.4441 18.074 16.7375 19.7698 15.8891Z"
        fill="#FFE32B"
      />
      <path
        d="M15.1398 2.43247C15.1998 2.4858 15.274 2.52064 15.3534 2.53279C15.4328 2.54494 15.514 2.53388 15.5873 2.50095C15.6605 2.46802 15.7227 2.41462 15.7663 2.34718C15.8099 2.27974 15.8331 2.20113 15.8331 2.12081V0.833324C15.8331 0.612313 15.7453 0.400354 15.589 0.244075C15.4327 0.0877964 15.2208 1.89254e-08 14.9998 1.89254e-08H13.4998C13.4153 -2.5454e-05 13.3327 0.0256638 13.2631 0.0736569C13.1935 0.12165 13.1402 0.189676 13.1102 0.268703C13.0801 0.34773 13.0749 0.434019 13.0951 0.516112C13.1152 0.598205 13.1599 0.672219 13.2231 0.728325L15.1398 2.43247Z"
        fill="#FFE32B"
      />
      <path
        d="M4.58329 6.25015H6.24994C6.36045 6.25015 6.46642 6.29405 6.54456 6.37219C6.6227 6.45033 6.6666 6.55631 6.6666 6.66682V10.8334C6.6666 11.0544 6.7544 11.2664 6.91068 11.4227C7.06696 11.579 7.27891 11.6668 7.49993 11.6668H8.74991C8.97092 11.6668 9.18288 11.579 9.33916 11.4227C9.49544 11.2664 9.58324 11.0544 9.58324 10.8334V8.75012C9.58324 8.63962 9.62713 8.53364 9.70527 8.4555C9.78341 8.37736 9.88939 8.33346 9.9999 8.33346H11.6665C11.7771 8.33346 11.883 8.37736 11.9612 8.4555C12.0393 8.53364 12.0832 8.63962 12.0832 8.75012V10.8334C12.0832 11.0544 12.171 11.2664 12.3273 11.4227C12.4836 11.579 12.6955 11.6668 12.9165 11.6668H14.1665C14.3875 11.6668 14.5995 11.579 14.7558 11.4227C14.912 11.2664 14.9998 11.0544 14.9998 10.8334V6.66682C14.9998 6.55631 15.0437 6.45033 15.1219 6.37219C15.2 6.29405 15.306 6.25015 15.4165 6.25015H17.0832C17.1677 6.25018 17.2502 6.22449 17.3198 6.1765C17.3894 6.1285 17.4428 6.06048 17.4728 5.98145C17.5028 5.90242 17.5081 5.81613 17.4879 5.73404C17.4677 5.65195 17.423 5.57793 17.3598 5.52183L11.3865 0.210221C11.234 0.0747956 11.0372 0 10.8332 0C10.6293 0 10.4324 0.0747956 10.2799 0.210221L4.30663 5.52183C4.24339 5.57793 4.19872 5.65195 4.17855 5.73404C4.15837 5.81613 4.16364 5.90242 4.19366 5.98145C4.22368 6.06048 4.27703 6.1285 4.34662 6.1765C4.41621 6.22449 4.49876 6.25018 4.58329 6.25015Z"
        fill="#FFE32B"
      />
    </g>
  </svg>
);

export default HandHouseIcon;
