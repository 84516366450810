import { format, parseISO } from "date-fns";

const compareDates = (d1: Date, d2: Date) => d1.getTime() - d2.getTime();

export const monthDate = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth());

export const isDateBefore = (d1: Date, d2: Date) => compareDates(d1, d2) < 0;
export const areDatesEqual = (d1: Date, d2: Date) => compareDates(d1, d2) === 0;
export const isDateAfter = (d1: Date, d2: Date) => compareDates(d1, d2) > 0;

// Get from date: Date in Zulu time zone to local time zone
export const getLocalDate = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000);

/** format a month date to a YYYY-MM string */
export function monthFromString(stringMonth: string | undefined) {
  return stringMonth ? parseISO(stringMonth) : getCurrentMonth();
}

/** format a YYYY-MM string to a month date */
export function stringMonth(month?: Date) {
  return format(month || new Date(), "yyyy-MM");
}

export const getCurrentMonth = () => {
  const date = new Date();
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getNextMonth = (month?: Date) => {
  const date = month || getCurrentMonth();
  return new Date(date.getFullYear(), date.getMonth() + 1);
};

export function isDateInMonth(date: Date, monthDate?: Date) {
  const month = monthDate || getCurrentMonth();
  return (
    areDatesEqual(date, month) ||
    (isDateAfter(date, month) && isDateBefore(date, getNextMonth(month)))
  );
}

export function getMonthInit(monthParam: string | undefined) {
  if (!(monthParam && monthParam.match(/^\d{4}-\d{2}$/))) {
    return undefined;
  }
  return monthParam;
}

/** given 2 month dates, @returns every months between them, including the first and last */
export const monthsBetween = (d1: Date, d2: Date) => {
  let currentDate: Date;
  let endDate: Date;
  if (isDateBefore(d1, d2)) {
    currentDate = d1;
    endDate = d2;
  } else {
    currentDate = d2;
    endDate = d1;
  }

  const res: Date[] = [currentDate];
  while (isDateBefore(currentDate, endDate)) {
    currentDate = new Date(currentDate);
    currentDate.setMonth(currentDate.getMonth() + 1);
    res.push(currentDate);
  }
  return res;
};

export function monthsSince(stringDate: string) {
  return monthsBetween(new Date(stringDate), new Date()).length - 1;
}

export function yesterdayDatetime() {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
}

export function formatDateToFR(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
  // Capitalize the first letter of the day
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}
