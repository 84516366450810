import styled from "styled-components";

const MailTo = styled.a`
  color: #0000ee;
`;

const MailToArpagon = () => (
  <MailTo href="mailto:contact@arpagon.fr">contact@arpagon.fr</MailTo>
);

export default MailToArpagon;
