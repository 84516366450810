import { Formik, FormikHandlers, FormikHelpers } from "formik";
import { useState, useEffect } from 'react';
import styled from "styled-components";
import Button from "../../../components/Button";
import HeaderBackButtonTitle from "../../../components/HeaderBackButtonTitle";
import PlusIcon from "../../../components/icons/Plus";
import Input from "../../../components/Input";
import InputAndSelect from "../../../components/InputAndSelect";
import Select from "../../../components/Select";
import {
  Currencies,
  SavingCategories,
  SavingCategory,
  SavingProducts,
} from "../../../types/patrimony.enums";
import { Body, Content } from "./CompletePatrimony";
import {
  formatInitialSaving,
  formatUpdatedInvestments,
  Saving,
} from "../../../types/patrimony";
import { useNavigate, useParams } from "react-router-dom";
import {
  addSavingToPatrimony,
  deleteSaving,
  editSaving,
  getSaving,
} from "../../../services/patrimony.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../lib/react-query";
import Loader from "../../../components/Loader";
import DeleteButton from "../../../components/buttons/DeleteButton";
import { Investment, newInvestment } from "../../../types/movements";
import { ObjectKeys } from "../../../utils/utils";
import { sum } from "../../../utils/math.utils";
import { spacify, onFormattedNumericInputChange } from "../../../utils/format.utils";

export const FormContainer = styled.form`
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      gap: 8px;
    }
  }
  .buttons {
    button {
      margin-top: 16px;
    }
    @media (min-width: 900px) {
      display: flex;
      gap: 64px;
      justify-content: center;
      flex-direction: row-reverse;
      button {
        width: 180px;
      }
    }
  }
`;

const ProductContainer = styled.div<{ showLabel: boolean; second?: true }>`
  .buttons-container {
    display: ${(p) => (p.second ? "none" : "flex")};

    @media (max-width: 900px) {
      display: ${(p) => (!p.second ? "none" : "flex")};
    }

    gap: 6px;

    > div {
      margin-left: 0.3em;
      margin-top: 0.3em;
      font-size: 0.9em;
      cursor: pointer;
      user-select: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 900px) {
    label {
      ${(p) => !p.showLabel && "display:none;"}
    }
  }
`;
type AddProductButtonsProps = {
  showAddProduct: boolean;
  showRemoveProduct: boolean;
  setFieldValue: ProductInputsProps["setFieldValue"];
  values: ProductInputsProps["values"];
};
const AddProductButtons = ({
  showAddProduct,
  showRemoveProduct,
  setFieldValue,
  values,
}: AddProductButtonsProps) => {
  return showAddProduct ? (
    <div className="buttons-container">
      <div
        onClick={() => {
          setFieldValue("investments", [
            ...values.investments!,
            newInvestment("FONDS_EUROS"),
          ]);
        }}
        children="Ajouter un produit"
      />
      {showRemoveProduct && (
        <div
          style={{ color: "var(--error-500)" }}
          onClick={() => {
            setFieldValue(
              "investments",
              values.investments!.filter(
                (_, index, investments) => index !== investments.length - 1
              )
            );
          }}
          children="Supprimer un produit"
        />
      )}
    </div>
  ) : null;
};

type ProductInputsProps = {
  values: Partial<Saving>;
  existingSaving: Saving | undefined;
  investment?: Investment;
  i?: number;
  handleChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<unknown>["setFieldValue"];
};
const ProductInputs = ({
  values,
  existingSaving,
  investment,
  i,
  handleChange,
  setFieldValue,
}: ProductInputsProps) => {
  const showLabel = !investment || investment === values.investments?.[0];
  const showAddProduct =
    !values.biAccountId &&
    values.category === "LIFE_INSURANCE" &&
    i === (values.investments || []).length - 1;
  const showRemoveProduct = i !== 0;
  const productName = investment ? `investments.${i}.product` : "product";
  const balanceName = investment ? `investments.${i}.value` : "balance";
  const rawValue = (investment ? values.investments![i!].value : values.balance) || null
  
  
    const [formatted, setFormatted] = useState(rawValue ? spacify(rawValue) : "");

  return (
    <>
      <ProductContainer {...{ showLabel }}>
        <Select
          optionsEnum={
            SavingProducts[
              values.category || "CURRENT_ACCOUNTS_AND_CARDS"
            ] as Record<string, string>
          }
          label="Produit"
          name={productName}
          onChange={handleChange(productName)}
          value={investment ? values.investments![i!].product! : values.product}
          required
        />
        <AddProductButtons
          {...{ showAddProduct, setFieldValue, values, showRemoveProduct }}
        />
      </ProductContainer>
      <ProductContainer {...{ showLabel, second: true }}>
        <InputAndSelect
          type="text"
          inputMode="numeric"
          placeholder="Valeur"
          selectName="currency"
          optionsEnum={Currencies}
          onSelectChange={handleChange("currency")}
          selectValue={values.currency}
          disabled={!!existingSaving?.biAccountId}
          required
          onChange={(e) => onFormattedNumericInputChange(e, setFormatted, handleChange)}
          label="Valeur"
          name={balanceName}
          value={formatted !== '0' ? formatted : ""}
        />
        <AddProductButtons
          {...{ showAddProduct, setFieldValue, values, showRemoveProduct }}
        />
      </ProductContainer>
    </>
  );
};

const AddSaving = () => {
  const navigate = useNavigate();

  const { id: sId } = useParams();
  const id = parseInt(sId || "");

  const { data: existingSaving, isFetching } = useQuery(
    ["existingSaving"],
    () => getSaving(id),
    { enabled: !!id, cacheTime: 0 }
  );

  const { mutateAsync: tryDelete, isLoading: isDeleting } = useMutation({
    mutationFn: deleteSaving,
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      navigate("/patrimoine");
    },
    onError: (e) => console.error(e),
  });

  const { mutateAsync: tryAdd, isLoading } = useMutation({
    mutationFn: existingSaving ? editSaving : addSavingToPatrimony,
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      navigate("/patrimoine");
    },
    onError: (e) => console.error(e),
  });

  const submit = (values: Partial<Saving>) => {
    // Cast balance to number before submitting, it's an input type text to allow digits formatting 
    
    //@ts-ignore
    if (typeof values.balance === 'string' && values.balance?.length > 0) {
        //@ts-ignore
        values.balance = Number(values?.balance?.replace(/[^0-9.]+/g, ""));
    }
    if (typeof values.balance === 'number') {
        values.balance = Math.round(values.balance)
    }
    tryAdd(formatUpdatedInvestments(values));
  };

  const backLink = !sId
    ? "/patrimoine/completer/sync-epargne"
    : existingSaving?.biAccountId
    ? `/patrimoine/compte/epargne/${existingSaving.id}`
    : "/patrimoine";
    
  return (
    <Body className="complete-patrimony-body">
      <HeaderBackButtonTitle
        backTo={backLink}
        title={isFetching ? <Loader /> : `${existingSaving ? "Modifier" : "Ajouter"} un produit d'épargne`}
      />
      <Content>
        {isFetching ? (
          <Loader />
        ) : (
          <Formik
            initialValues={
              (id && formatInitialSaving(existingSaving)) ||
              ({
                name: "",
                category: "CURRENT_ACCOUNTS_AND_CARDS",
                product: "CURRENT_ACCOUNT",
                balance: undefined,
                currency: "EUR",
                bankName: "",
                investments: undefined,
              } as Partial<Saving>)
            }
            onSubmit={submit}
            
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              isSubmitting,
            }) => (
              <FormContainer onSubmit={handleSubmit}>
                <div className="fields">
                  <Input
                    name="name"
                    onChange={handleChange}
                    placeholder="Nom"
                    label="Nom du bien"
                    value={values.name}
                    required
                  />
                  <Select
                    name="category"
                    optionsEnum={SavingCategories}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const category = e.target.value as SavingCategory;
                      const firstCategoryProduct = ObjectKeys(
                        SavingProducts[category]
                      )[0];
                      setFieldValue("product", firstCategoryProduct);
                      handleChange("category")(e);
                      if (
                        category === "LIFE_INSURANCE" &&
                        !values.investments?.length
                      ) {
                        setFieldValue("investments", [
                          newInvestment("FONDS_EUROS"),
                        ]);
                      }
                    }}
                    label="Catégorie"
                    value={values.category}
                    required
                  />

                  {values.category !== "LIFE_INSURANCE" ? (
                    <ProductInputs
                      {...{
                        values,
                        existingSaving,
                        handleChange,
                        setFieldValue,
                      }}
                    />
                  ) : (
                    values.investments?.map((investment, i) => (
                      <ProductInputs
                        key={i}
                        {...{
                          investment,
                          i,
                          values,
                          existingSaving,
                          handleChange,
                          setFieldValue,
                        }}
                      />
                    ))
                  )}

                  <Input
                    name="bankName"
                    onChange={handleChange}
                    placeholder="Étalissement bancaire"
                    label="Étalissement bancaire"
                    value={values.bankName}
                    required
                  />
                  {values.category === "LIFE_INSURANCE" && (
                    <div
                      style={{
                        visibility:
                          values.investments?.length === 1
                            ? "hidden"
                            : "visible",
                      }}
                    >
                      <InputAndSelect
                        type="number"
                        label="Valeur totale"
                        placeholder="Valeur totale"
                        selectName="currency"
                        optionsEnum={Currencies}
                        selectValue={values.currency}
                        value={sum(
                          values.investments?.map((i) => i.value || 0) || []
                        )}
                        readOnly
                        disabled
                      />
                    </div>
                  )}
                </div>
                <div className="buttons">
                  <Button
                    type="submit"
                    size="fullWidth"
                    icon={!existingSaving ? <PlusIcon /> : undefined}
                    loading={isLoading || isSubmitting}
                  >
                    {existingSaving ? "Enregistrer" : "Ajouter"}
                  </Button>

                  {existingSaving && (
                    <DeleteButton
                      size="fullWidth"
                      loading={isDeleting}
                      onClick={() => tryDelete(id)}
                    />
                  )}
                </div>
              </FormContainer>
            )}
          </Formik>
        )}
      </Content>
    </Body>
  );
};

export default AddSaving;
