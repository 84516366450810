import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import EuroIncreaseIcon from "../../components/icons/EuroIncreaseIcon";
import GoldBarsIcon from "../../components/icons/GoldBarsIcon";
import HandMoneyBagIcon from "../../components/icons/HandMoneyBagIcon";
import UserCoinIcon from "../../components/icons/UserCoinIcon";
import { WeUseDataFromText } from "./utils/borrowing-capacity.utils";
import {
  CompletePatrimonyRoundLink,
  Content as CompletePatrimonyContent,
  SectionBox,
  SectionBoxes,
  SectionBoxProps,
} from "../my-patrimony/complete/CompletePatrimony";
import {
  Body as PatrimonyBody,
  Header as PatrimonyHeader,
  PageTitle,
} from "../my-patrimony/Patrimony";
import AmIRich from "./AmIRich";
import BorrowingCapacity from "./BorrowingCapacity";
import FuturePatrimony from "./FuturePatrimony";
import RiskReturnProfile from "./RiskReturnProfile";
import { NO_BREAK_SPACE } from "../../utils/format.utils";
import UserCircle from "../../components/icons/UserCircle";
import ProfileButton from "../../components/ProfileButton";

const Body = styled(PatrimonyBody)``;

const HeaderText = styled.div`
  @media (max-width: 900px) {
    color: var(--white);
    font-size: 14px;
    padding-top: 8px;
  }
`;

const Content = styled(CompletePatrimonyContent)`
  @media (max-width: 900px) {
    background-color: var(--gray-100);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
`;

export const AnalysisToolsLinks: Array<
  SectionBoxProps & { miniTitle?: string; element: JSX.Element }
> = [
  {
    link: "suis-je-riche",
    title: `Suis-je riche${NO_BREAK_SPACE}?`,
    description:
      "Situez votre patrimoine et vos revenus par rapport à l'ensemble des Français.",
    icon: <GoldBarsIcon />,
    element: <AmIRich />,
  },
  {
    link: "profil-risque",
    title: `Quel est mon profil risque / rendement${NO_BREAK_SPACE}?`,
    miniTitle: "Profil risque/rendement",
    description: `Évaluez votre profil, êtes-vous cigale ou plutôt fourmi${NO_BREAK_SPACE}?`,
    icon: <UserCoinIcon />,
    element: <RiskReturnProfile />,
  },
  {
    link: "capacite-emprunt",
    title: `Quelle est ma capacité d'emprunt${NO_BREAK_SPACE}?`,
    miniTitle: "Capacité d'emprunt",
    description:
      "Une envie de projet immobilier ? Calculez rapidement votre capacité d'emprunt.",
    icon: <HandMoneyBagIcon />,
    element: <BorrowingCapacity />,
  },
  {
    link: "patrimoine-futur",
    title: `Quelle sera la valeur de mon patrimoine dans le futur${NO_BREAK_SPACE}?`,
    miniTitle: "Patrimoine futur",
    description: "Regardez l'avenir !",
    icon: <EuroIncreaseIcon />,
    element: <FuturePatrimony />,
  },
];

const AnalysisToolsPage = () => {
  const navigate = useNavigate();
  return (
    <Body>
      <PatrimonyHeader>
        <div className="header-first-row">
          <div className="left-side">
            <div className="mobile-only logo">
              <ProfileButton onClick={() => navigate("/profil")}>
                <UserCircle />
              </ProfileButton>
            </div>
            <PageTitle className="desktop-only" noPaddingBottom>
              Outils d'analyse
            </PageTitle>
          </div>
          <CompletePatrimonyRoundLink />
        </div>
        <HeaderText>
          Découvrez les outils d'analyse et de simulation de l'équipe Arpagon.{" "}
          <WeUseDataFromText budget />
        </HeaderText>
      </PatrimonyHeader>
      <Content>
        <SectionBoxes>
          {AnalysisToolsLinks.map((x) => (
            <SectionBox key={x.link} {...x} />
          ))}
        </SectionBoxes>
      </Content>
    </Body>
  );
};

const AnalysisTools = () => (
  <Routes>
    <Route path="/" element={<AnalysisToolsPage />} />

    {AnalysisToolsLinks.map(({ link, element }) => (
      <Route key={link} path={link} element={element} />
    ))}

    <Route path="*" element={<Navigate to="/outils-analyse" replace />} />
  </Routes>
);

export default AnalysisTools;
