import { useMutation } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import styled from "styled-components";
import Button from "../../components/Button";
import BackButton from "../../components/buttons/BackButton";
import Input from "../../components/Input";
import Link from "../../components/Link";
import MailToArpagon from "../../components/MailToArpagon";
import { sendForgottenPasswordMail } from "../../services/auth.service";
import AuthenticationLayout from "./AuthenticationLayout";
import { Body } from "./ConfirmEmail";

export const PasswordForgottenForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 460px;
  margin: 0 auto;
`;

const PasswordForgotten = () => {
  const {
    mutateAsync: trySendForgottenPasswordMail,
    isLoading,
    isSuccess,
    isError,
    data: mailIsSent,
  } = useMutation({ mutationFn: sendForgottenPasswordMail });

  const formDisabled = isSuccess && mailIsSent;

  return (
    <AuthenticationLayout>
      <Body>
        <div className="title-container">
          <Link to="/connexion">
            <BackButton backText="" />
          </Link>
          <div className="title">Mot de passe oublié</div>
        </div>

        <div className="text">
          {isSuccess
            ? "Un email contenant un lien de réinitialisation vous a été envoyé"
            : "Veuillez indiquer votre adresse email pour réinitialiser votre mot de passe"}
        </div>

        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values) => trySendForgottenPasswordMail(values.email)}
        >
          {({ values, handleSubmit, handleChange }) => (
            <PasswordForgottenForm onSubmit={handleSubmit}>
              <Input
                style={{ marginTop: "8px" }}
                type="email"
                name="email"
                onChange={handleChange}
                placeholder="Email"
                value={values.email}
                required
                disabled={formDisabled}
              />
              <Button
                type="submit"
                size="fullWidth"
                loading={isLoading}
                disabled={formDisabled}
              >
                Envoyer le lien
              </Button>
            </PasswordForgottenForm>
          )}
        </Formik>

        {isError || (isSuccess && !mailIsSent) ? (
          <div className="text" style={{ marginTop: "8px" }}>
            Un problème persiste ? Contactez-nous&nbsp;: <MailToArpagon />
          </div>
        ) : null}
      </Body>
    </AuthenticationLayout>
  );
};

export default PasswordForgotten;
