import { FC, ReactNode } from "react";
import styled, { css } from "styled-components";
import CheckIcon from "./icons/Check";

const Container = styled.label<{ readOnly?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;

  .label {
    margin-left: 8px;
  }

  ${({ readOnly }) =>
    readOnly
      ? css`
          cursor: default !important;
          & * {
            cursor: default !important;
          }
        `
      : ""}
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ span {
    border-color: var(--black);
    stroke: var(--black);
  }

  &:focus ~ span {
    outline: var(--outline);
  }
`;

const Checkmark = styled.span`
  background-color: var(--white);
  display: grid;
  place-items: center;
  border: 2px solid var(--gray-300);
  stroke: transparent;
  height: 1.25em;
  width: 1.25em;
  border-radius: 2px;
  outline-width: 1px !important;
`;

type Props = {
  name?: string;
  checked?: boolean;
  readOnly?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  tabIndex?: number | undefined;
  label?: ReactNode;
};
const Checkbox: FC<Props> = ({
  name,
  checked,
  onChange,
  readOnly,
  value,
  tabIndex,
  label,
}) => {
  return (
    <Container readOnly={readOnly} className="checkbox">
      <Input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={readOnly ? () => null : onChange}
        readOnly={readOnly}
        value={value}
        tabIndex={readOnly ? -1 : tabIndex}
      />
      <Checkmark>
        <CheckIcon />
      </Checkmark>
      <div className="label">{label}</div>
    </Container>
  );
};

export default Checkbox;
