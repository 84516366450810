import { PopupModal } from "react-calendly";
import Button from "./Button";
import React, { useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getMe } from "../services/user.service";
import { createMeeting } from "../services/meeting.service";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../lib/react-query";

type Props = {
  className?: string;
  url: string;
  id_advisor: number;
};

const CalendlyButton = (props: Props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [idMeeting, setIdMeeting] = React.useState(String);
  const { data: user } = useQuery(["user"], getMe);
  const prefill = {
    name: user?.firstname + " " + user?.lastname,
    email: user?.email,
  };
  const mutation = useMutation({
    mutationFn: createMeeting,
  });

  function handleClose() {
    setIsOpen(false);
    if (idMeeting) {
      queryClient.invalidateQueries(["meetings"]);
      mutation.mutate(
        {
          id_meeting: idMeeting,
          id_advisor: props.id_advisor,
        },
        {
          onSuccess: (data) => {
            navigate(`/mon-conseiller/rendez-vous/${data.id}`);
          },
        }
      );
    }
  }

  useEffect(() => {
    window.addEventListener("message", (e) => {
      const isCalendlyScheduledEvent = (e: MessageEvent<any>) => {
        return (
          e.data.event &&
          e.data.event.indexOf("calendly") === 0 &&
          e.data.event === "calendly.event_scheduled"
        );
      };

      if (isCalendlyScheduledEvent(e)) {
        const url = e.data.payload.event.uri
          .replace("https://calendly.com/", "")
          .split("/");
        setIdMeeting(url[url.length - 1]);
      }
    });
  }, []);

  return (
    <div className={props.className}>
      <Button
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setIsOpen(true);
        }}
      >
        Prendre rendez-vous
      </Button>
      <PopupModal
        url={props.url}
        onModalClose={handleClose}
        open={isOpen}
        prefill={prefill}
        rootElement={document.getElementById("root")!}
      ></PopupModal>
    </div>
  );
};

export default CalendlyButton;
