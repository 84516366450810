import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import {
  Body as PatrimonyBody,
  Header as PatrimonyHeader,
  PageTitle,
} from "../my-patrimony/Patrimony";
import LogoAlternative from "../../assets/LogoAlternative";
import { deleteMe, deleteBiConnections, getMe } from "../../services/user.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import EditProfile from "./EditProfile";
import { ContentBackground } from "../AppLayout";
import LogoutIcon from "../../components/icons/Logout";
import Button from "../../components/Button";
import { logout } from "../../utils/auth.utils";
import SimpleArrowIcon from "../../components/icons/SimpleArrowIcon";
import NavigationLink from "../../components/navigation/NavigationLink";
import { XOR } from "../../utils/utils";
import ContactUs from "./ContactUs";
import { useState } from "react";
import ActionModal from "../../components/modals/ActionModal";
import { getBiWebviewUrl } from "../../utils/budget-insight.utils";
import { User } from "../../types/user";

import { syncBiAccounts } from "../../services/patrimony.service";
import { queryClient } from '../../lib/react-query';
import { ONE_DAY } from "../../utils/time.utils";

const Body = styled(PatrimonyBody)`
  .header-second-row {
    font-size: 1.4rem;
    font-weight: 500;
    align-self: center;
  }
`;

const Content = styled.div`
  padding-left: 32px;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: max(min(30%, 22.85rem), 13.2rem) auto;
  overflow: hidden;
  @media (max-width: 900px) {
    grid-template-columns: 100%;
    padding: 0 0 0 16px;
    background-color: var(--gray-100);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
`;

const StyledLinksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden auto;
  padding-right: 16px;
  padding-bottom: 16px;

  @media (max-width: 900px) {
    padding-top: 16px;
    width: 100%;
  }

  .profile-link {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: auto min-content;
    gap: 8px;
    > * {
      align-self: center;
    }
    .profile-link-text {
      white-space: break-spaces;
    }
  }
`;

type LinksProps = { title: string } & XOR<
  { link: string; element: JSX.Element },
  { route: string }
>;
const ProfileLinks: LinksProps[] = [
  {
    link: "mon-profil",
    title: "Mon profil",
    element: <EditProfile />,
  },
  {
    link: "nous-contacter",
    title: "Nous contacter",
    element: <ContactUs />,
  },
  {
    title: "Mentions légales",
    route: "/mentions-legales",
  },
  {
    title: "Conditions Générales d'Utilisation",
    route: "/cgu",
  },
  {
    title: "Politique de confidentialité",
    route: "/politique-confidentialite",
  },
];

const MenuButton = styled(Button)`
  padding: 1rem;
  justify-content: space-between;
  background-color: ${(props) => props.color || `var(--gray-200)`};
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  text-align: left;
  &:hover {
    filter: brightness(0.98) !important;
  }
  &:active {
    filter: brightness(0.95) !important;
  }
`;

type LinksListProps = {
  onPressDelete: () => void;
  onPressDeleteConnections: () => void;
  user: User | undefined;
};

const LinksList = ({ user, onPressDelete, onPressDeleteConnections }: LinksListProps) => (
  <StyledLinksList>
    {ProfileLinks.map((x) => (
      <NavigationLink
        key={x.link || x.route!}
        to={x.link || x.route!}
        title={
          <div className="profile-link">
            <div className="profile-link-text">{x.title}</div>
            <SimpleArrowIcon />
          </div>
        }
        variant="profile"
        {...(x.route ? { target: "_blank", rel: "noopener noreferrer" } : {})}
      />
    ))}
    {user && (
      <MenuButton color="white" onClick={() => window.location.href = getBiWebviewUrl(user.biAuthToken)}>
        <div>Gérer mes connexions bancaires</div>
        <SimpleArrowIcon />
      </MenuButton>
    )}
    {user && user.biAuthToken && (
      <MenuButton color="white" onClick={onPressDeleteConnections}>
        <div>Supprimer mes connexions bancaires</div>
        <SimpleArrowIcon />
      </MenuButton>
    )}
    <MenuButton onClick={logout}>
      <div>Se déconnecter</div>
      <LogoutIcon black />
    </MenuButton>
    <MenuButton onClick={onPressDelete} style={{ marginTop: '6px' }}>
      <div>Supprimer mon compte</div>
      <LogoutIcon transparent />
    </MenuButton>
  </StyledLinksList>
);

const SubRoutes = (
  <>
    <Route path="/" element={<EditProfile />} />
    {ProfileLinks.map(({ link, element, route }) => (
      <Route key={link || route!} path={link || route!} element={element} />
    ))}
  </>
);

const ProfilePage = ({ desktop }: { desktop?: boolean }) => {
  const { data: user, refetch: refetchUser } = useQuery(["user"], getMe);
  const [openConfirmAccountDeletion, setOpenConfirmAccountDeletion] = useState(false);
  const [openConfirmConnectionsDeletion, setOpenConfirmConnectionsDeletion] = useState(false);
  const [isLoadingDeleteConnections, setIsLoadingDeleteConnections] = useState(false);
  
  const { refetch: refetchSync } = useQuery(["syncBiAccounts"], syncBiAccounts, {
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      queryClient.invalidateQueries(["connections"]);
    },
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: ONE_DAY,
    enabled: false
  });

  const { mutateAsync: tryDeleteMe, isLoading: isLoadingDelete } = useMutation({
    mutationFn: deleteMe,
    onSuccess: logout,
  });

  const deleteConnections = async () => {
    setIsLoadingDeleteConnections(true);
    await deleteBiConnections();
    await refetchUser();
    await refetchSync();
    setIsLoadingDeleteConnections(false);
    setOpenConfirmConnectionsDeletion(false);
  }

  return (
    <Body>
      <PatrimonyHeader>
        <div className="header-first-row">
          <div className="left-side">
            <div className="mobile-only logo" style={{ marginTop: '4px' }}>
              <LogoAlternative />
            </div>
            <PageTitle className="desktop-only">Mon profil</PageTitle>
          </div>
        </div>
        <div className="header-second-row mobile-only" style={{ marginTop: '20px' }}>
          {!user ? <Loader /> : `${user.firstname} ${user.lastname}`}
        </div>
      </PatrimonyHeader>
      <Content>
        <LinksList 
          user={user} 
          onPressDelete={() => setOpenConfirmAccountDeletion(true)} 
          onPressDeleteConnections={() => setOpenConfirmConnectionsDeletion(true)} 
        />
        {desktop && <Routes>{SubRoutes}</Routes>}
      </Content>

      <ActionModal
        open={openConfirmConnectionsDeletion}
        close={() => setOpenConfirmConnectionsDeletion(false)}
        applyText="Confirmer"
        apply={deleteConnections}
        loading={isLoadingDeleteConnections}
        title="Êtes-vous sûr de vouloir supprimer vos connexions bancaires ?"
      >
        <div style={{ height: '30px' }} />
      </ActionModal>

      <ActionModal
        open={openConfirmAccountDeletion}
        close={() => setOpenConfirmAccountDeletion(false)}
        applyText="Confirmer"
        apply={tryDeleteMe}
        loading={isLoadingDelete}
        title="Êtes-vous sûr de vouloir supprimer votre compte ?"
      >
        <div style={{ height: '30px' }} />
      </ActionModal>
    </Body>
  );
};

const Profile = () => (
  <>
    <ContentBackground className="desktop-only">
      <Routes>
        <Route path="*" element={<ProfilePage desktop />} />
      </Routes>
    </ContentBackground>
    <ContentBackground className="mobile-only" style={{ paddingBottom: 0 }}>
      <Routes>
        <Route path="/" element={<ProfilePage />} />
        {SubRoutes}
        <Route path="*" element={<Navigate to="/profil" replace />} />
      </Routes>
    </ContentBackground>
  </>
);

export default Profile;
