import { ONE_HOUR } from "./../utils/time.utils";
import { AxiosError } from "axios";
import {
  DefaultOptions,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";

const queryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: ONE_HOUR,
    staleTime: ONE_HOUR,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...args: any[]) => unknown> =
  Awaited<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends (...args: any[]) => unknown> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  "queryKey" | "queryFn"
>;

export type MutationConfig<
  MutationFnType extends (...args: any[]) => unknown,
  ErrorData
> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  AxiosError<ErrorData>,
  Parameters<MutationFnType>[0]
>;
