import React, { useState } from 'react';
import Input from './Input';

interface DateInputProps {
  value?: string;
  onChange: (value: string) => void;
}

const MobileBirthDateInput: React.FC<DateInputProps> = ({ value, onChange }) => {
  const [formatedDate, setFormatedDate] = useState(
    !!value 
    ? value.split('-')?.reverse()?.join('/')
    : ""
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let formatedValue = value.replace(/[^0-9]/g, '').slice(0, 8);
    
    if (formatedValue.length > 1) {
      const day = formatedValue.slice(0, 2);
      formatedValue = parseInt(day) > 31
        ? `0${formatedValue[0]}/${formatedValue.slice(1, 3)}`
        : `${day}/${formatedValue.slice(2)}`;
    }
  
    if (formatedValue.length > 4) {
      const month = formatedValue.slice(3, 5);
      formatedValue = parseInt(month) > 12
        ? `${formatedValue.slice(0, 3)}0${formatedValue[3]}/${formatedValue.slice(4, 6)}`
        : `${formatedValue.slice(0, 3)}${month}/${formatedValue.slice(5)}`;
    }

    let onChangeFormated = formatedValue;

    if (formatedValue.length === 10) {
      const [day, month, year] = formatedValue.split('/');
      const birthdate = new Date(`${month}/${day}/${year}`);
      const now = new Date();
      const minBirthdate = new Date(now.getFullYear() - 120, now.getMonth(), now.getDate());
  
      if (birthdate > now || birthdate < minBirthdate) {
        // Invalid birthdate
        formatedValue = '';
      } else {
        formatedValue = `${day}/${month}/${year}`;
        onChangeFormated = `${year}-${month}-${day}`
      }
    }
    
    setFormatedDate(formatedValue);
    onChange(onChangeFormated);
  };
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      // Backspace key
      const { value, selectionStart } = event.target as HTMLInputElement;
      if(!selectionStart) return;
      let newValue = value.slice(0, selectionStart - 1) + value.slice(selectionStart);

      if (newValue.length === 2 || newValue.length === 5) {
        newValue = newValue.slice(0, -1);
        setFormatedDate(newValue);
        onChange(newValue);
      }
    }
  };
  

  return window.innerWidth < 900 ? (
    <Input
      label='Date de naissance'
      name="birthDate"
      type="text"
      className="mobile-only"
      placeholder='jj/mm/aaaa'
      inputMode="numeric"
      value={formatedDate}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      required
    />
  ) : null;
};

export default MobileBirthDateInput;
