const CalendarIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.16668H2M10.6667 1.83334V4.50001M5.33333 1.83334V4.50001M5.2 15.1667H10.8C11.9201 15.1667 12.4801 15.1667 12.908 14.9487C13.2843 14.7569 13.5903 14.451 13.782 14.0747C14 13.6468 14 13.0868 14 11.9667V6.36668C14 5.24657 14 4.68652 13.782 4.2587C13.5903 3.88237 13.2843 3.57641 12.908 3.38466C12.4801 3.16668 11.9201 3.16668 10.8 3.16668H5.2C4.07989 3.16668 3.51984 3.16668 3.09202 3.38466C2.71569 3.57641 2.40973 3.88237 2.21799 4.2587C2 4.68652 2 5.24657 2 6.36668V11.9667C2 13.0868 2 13.6468 2.21799 14.0747C2.40973 14.451 2.71569 14.7569 3.09202 14.9487C3.51984 15.1667 4.07989 15.1667 5.2 15.1667Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarIcon;
