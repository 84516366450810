import { Link as RouterLink, LinkProps } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled<
  React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLAnchorElement>
  >
>(RouterLink).withConfig({
  shouldForwardProp: (propName) => propName !== "underLine",
})<{ underLine?: true }>`
  text-decoration: ${({ underLine }) =>
    underLine ? "underline" : "none"} !important;
  color: unset;
  white-space: nowrap;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  &:focus {
    outline: none;
  }
`;

type Props = LinkProps & { underLine?: true };
const Link = (props: Props) => <StyledLink {...props} />;

export default Link;
