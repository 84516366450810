import { round } from "./format.utils";

export const resetStorage = () => {
  setJwt("");
  localStorage.setItem("lastPatrimonySyncDate", "");
  setPatrimonyScroll(0);
  setBudgetScroll(0);
  localStorage.setItem("loginDate", "");
};

export const getJwt = () => localStorage.getItem("jwt") || "";
export const setJwt = (jwt: string) => localStorage.setItem("jwt", jwt);

export const getPatrimonyScroll = () =>
  +(localStorage.getItem("patrimonyScroll") || 0);

export const setPatrimonyScroll = (v: number) =>
  localStorage.setItem("patrimonyScroll", `${round(v, 0)}`);

export const getBudgetScroll = () =>
  +(localStorage.getItem("budgetScroll") || 0);

export const setBudgetScroll = (v: number) =>
  localStorage.setItem("budgetScroll", `${round(v, 0)}`);

  
export const getSignupStep = () => {
  return localStorage.getItem("signupStep");
}

export const setSignupStep = (step: string) => {
  localStorage.setItem("signupStep", step);
}

export const removeSignupStep = () => {
  localStorage.removeItem("signupStep");
}