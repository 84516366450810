import styled, { css } from "styled-components";

const StyledChip = styled.div<{ active?: boolean; clickable?: boolean }>`
  border-radius: 32px;
  border: 1px solid var(--gray-600);
  padding: 8px 16px 8px 16px;

  ${({ active }) =>
    active
      ? css`
          background-color: var(--yellow-400);
          border-color: var(--yellow-700);
        `
      : css`
          background-color: var(--white);
        `}
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

type Props = {
  label: string;
  active?: boolean;
  onClick?: () => void;
  clickable?: boolean;
  key?: string;
};

const Chip = (props: Props) => (
  <StyledChip
    className="chip"
    active={props.active}
    clickable={props.clickable}
    onClick={props.onClick}
  >
    {props.label}
  </StyledChip>
);

export default Chip;
