import { FC, useCallback, useEffect } from "react";
import styled from "styled-components";

type ContainerProps = {
  close: () => void;
  children: React.ReactNode;
};
const Container = ({ close, children }: ContainerProps) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Escape") close();
    },
    [close]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return <StyledContainer onClick={close}>{children}</StyledContainer>;
};

const StyledContainer = styled.div`
  position: fixed;
  inset: 0;
  z-index: 50;
  display: grid;
  place-items: center;
  white-space: normal;
  overflow-y: auto;
  padding: 1rem;
`;
const Background = styled.div`
  position: fixed;
  inset: 0;
  background-color: var(--gray-800);
  opacity: 0.4;
  pointer-events: painted;
  z-index: -1;
`;

const SubContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--white);
  pointer-events: painted;
  padding: 1.5rem 2.5rem;
  margin: 5rem 0;
  border-radius: 4px;
  max-width: min(50rem, 100%);
  overflow-x: hidden;
  text-align: start;
`;

export type ModalProps = {
  open: boolean;
  close: () => void;
  children?: React.ReactNode;
};
const Modal: FC<ModalProps> = ({ open, close, children }: ModalProps) => {
  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };
  return open ? (
    <Container close={close}>
      <Background />
      <SubContainer>
        <ContentContainer onClick={stopPropagation}>
          {children}
        </ContentContainer>
      </SubContainer>
    </Container>
  ) : null;
};

export default Modal;
