import styled from "styled-components";
import Button, { ButtonProps } from "../Button";
import ArrowIcon from "../icons/Arrow";

const StyledBackButton = styled(Button)`
  gap: 1em !important;
  @media (max-width: 900px) {
    .back-text {
      display: none;
    }
  }
`;

const BackButton = ({
  backText = "Retour",
  style = {},
  ...props
}: ButtonProps & { backText?: string }) => (
  <StyledBackButton size="minimum" variant="transparent" noBoxShadow {...props}>
    <ArrowIcon />
    {backText && <div className="back-text" style={style}>{backText}</div>}
  </StyledBackButton>
);

export default BackButton;
