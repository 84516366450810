import styled, { css } from "styled-components";

const Body = styled.div<{ stepsNumber: number; currentStep: number }>`
  display: grid;
  grid-template-rows: 0 auto;
  .steps {
    display: flex;
    .step {
      flex: 1 1 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .progress-bar {
    height: 1px;
    ${({ stepsNumber, currentStep }) =>
      css`
        margin: 16px ${50 / stepsNumber}% 0;
        background: linear-gradient(
          to right,
          var(--yellow-400) ${(100 / (stepsNumber - 1)) * (currentStep - 1)}%,
          var(--gray-300) 0%
        );
        /* transition: 2s; */
      `}
  }
`;

const Dot = styled.div<{ index: number; currentStep: number }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ index, currentStep }) =>
    index <= currentStep ? css`var(--yellow-400)` : css`var(--gray-300)`};
`;

type Props = {
  steps: string[];
  currentStep: number;
} & React.HTMLAttributes<HTMLDivElement>;
const DotProgressBar = ({ steps, ...props }: Props) => {
  return (
    <Body stepsNumber={steps.length} {...props}>
      <div className="progress-bar" />
      <div className="steps">
        {steps.map((step, i) => (
          <div key={step} className="step">
            <Dot index={i + 1} currentStep={props.currentStep} />
            <div className="label"style={i+1 === props.currentStep ? { fontWeight: 'bold' } : {}}>{step}</div>
          </div>
        ))}
      </div>
    </Body>
  );
};

export default DotProgressBar;
