const SuitCaseIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.81258 9.25C7.81258 9.08424 7.87843 8.92527 7.99564 8.80806C8.11285 8.69085 8.27182 8.625 8.43758 8.625H11.5626C11.7283 8.625 11.8873 8.69085 12.0045 8.80806C12.1217 8.92527 12.1876 9.08424 12.1876 9.25V9.45834C12.1876 9.56884 12.2315 9.67483 12.3096 9.75297C12.3878 9.83111 12.4937 9.875 12.6042 9.875H19.1667C19.2773 9.875 19.3832 9.83111 19.4614 9.75297C19.5395 9.67483 19.5834 9.56884 19.5834 9.45834V6.33334C19.5834 6.11232 19.4956 5.90036 19.3393 5.74408C19.1831 5.5878 18.9711 5.5 18.7501 5.5H14.1459C14.0427 4.47215 13.5614 3.51926 12.7954 2.82624C12.0293 2.13322 11.0331 1.74948 10.0001 1.74948C8.96706 1.74948 7.97087 2.13322 7.2048 2.82624C6.43874 3.51926 5.95742 4.47215 5.85425 5.5H1.25008C1.02907 5.5 0.817106 5.5878 0.660826 5.74408C0.504545 5.90036 0.416748 6.11232 0.416748 6.33334V9.45834C0.416748 9.56884 0.460647 9.67483 0.538787 9.75297C0.616927 9.83111 0.722908 9.875 0.833415 9.875H7.39591C7.50642 9.875 7.6124 9.83111 7.69054 9.75297C7.76868 9.67483 7.81258 9.56884 7.81258 9.45834V9.25ZM10.0001 3.41667C10.5909 3.41668 11.1626 3.62591 11.6138 4.00727C12.065 4.38863 12.3666 4.91747 12.4651 5.5H7.53508C7.63355 4.91747 7.93513 4.38863 8.38636 4.00727C8.83758 3.62591 9.40928 3.41668 10.0001 3.41667V3.41667Z"
      fill="#FFE32B"
    />
    <path
      d="M12.1876 11.855C12.1872 12.1793 12.1149 12.4994 11.9758 12.7923C11.8367 13.0852 11.6343 13.3435 11.3833 13.5487C11.1322 13.7539 10.8388 13.9008 10.5241 13.9789C10.2094 14.0569 9.88125 14.0641 9.56341 14C9.05992 13.8853 8.6112 13.601 8.29255 13.1946C7.9739 12.7883 7.80473 12.2847 7.81341 11.7683V11.5425C7.81341 11.432 7.76951 11.326 7.69137 11.2479C7.61323 11.1697 7.50725 11.1258 7.39675 11.1258H0.833415C0.722908 11.1258 0.616927 11.1697 0.538787 11.2479C0.460647 11.326 0.416748 11.432 0.416748 11.5425V17.1667C0.416748 17.3877 0.504545 17.5997 0.660826 17.7559C0.817106 17.9122 1.02907 18 1.25008 18H18.7501C18.9711 18 19.1831 17.9122 19.3393 17.7559C19.4956 17.5997 19.5834 17.3877 19.5834 17.1667V11.5417C19.5834 11.4312 19.5395 11.3252 19.4614 11.2471C19.3832 11.1689 19.2773 11.125 19.1667 11.125H12.6417C12.584 11.1198 12.5257 11.1267 12.4708 11.1453C12.4158 11.1639 12.3653 11.1937 12.3226 11.233C12.2798 11.2722 12.2457 11.3199 12.2225 11.3731C12.1992 11.4262 12.1873 11.4837 12.1876 11.5417V11.855Z"
      fill="#FFE32B"
    />
    <path
      d="M10.5208 9.87582H9.47917C9.36866 9.87582 9.26268 9.91972 9.18454 9.99786C9.1064 10.076 9.0625 10.182 9.0625 10.2925V11.855C9.0625 12.1036 9.16127 12.3421 9.33709 12.5179C9.5129 12.6937 9.75136 12.7925 10 12.7925C10.2486 12.7925 10.4871 12.6937 10.6629 12.5179C10.8387 12.3421 10.9375 12.1036 10.9375 11.855V10.2925C10.9375 10.182 10.8936 10.076 10.8155 9.99786C10.7373 9.91972 10.6313 9.87582 10.5208 9.87582Z"
      fill="#FFE32B"
    />
  </svg>
);

export default SuitCaseIcon;
