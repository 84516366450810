import { useQuery } from "@tanstack/react-query";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import HeaderBackButtonTitle from "../../../components/HeaderBackButtonTitle";
import CircledPlusIcon from "../../../components/icons/CircledPlusIcon";
import RecommendedIcon from "../../../components/icons/Recommended";
import Link from "../../../components/Link";
import { getMe } from "../../../services/user.service";
import { getBiWebviewUrl } from "../../../utils/budget-insight.utils";
import AddBiOrManual from "./AddBiOrManual";
import AddCredit from "./AddCredit";
import AddOtherAsset from "./AddOtherAsset";
import AddRealEstate from "./AddRealEstate";
import AddSaving from "./AddSaving";

export const CompletePatrimonyRoundLink = (p: { className?: string }) => (
  <Link
    className={p.className}
    to="/patrimoine/completer"
    style={{ display: "flex", alignSelf: "start" }}
  >
    <CircledPlusIcon width={32} height={32} />
  </Link>
);

export const Body = styled.div`
  background-color: var(--gray-100);
  display: grid;
  grid-template-rows: min-content auto;
  overflow: hidden;
  @media (min-width: 900px) {
    margin-top: 16px;
    border-top-left-radius: 32px;
  }
`;

export const Header = styled.div`
  padding: 32px 32px 16px 32px;
  @media (max-width: 900px) {
    padding: 8px;
  }
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  flex: 1;
  text-align: center;
  @media (max-width: 900px) {
    font-size: 20px;
    text-align: unset;
  }
`;

export const FillSpace = styled.div`
  flex: 1;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const Content = styled.div`
  padding: 0 32px 32px 32px;
  @media (max-width: 900px) {
    padding: 0 16px 12px 16px;
  }
  overflow: hidden auto;
`;

export const SectionBoxes = styled.div`
  margin-top: 8px;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 1000px) {
    margin-top: 16px;
    gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledSectionBox = styled(Link)`
  border: 1px solid var(--gray-100);
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  background-color: var(--white);
  padding: 16px;
  display: flex;
  gap: 8px;
  .icon-container {
    padding-top: 3px;
  }
  .title-and-desc {
    white-space: break-spaces;
    .title {
      font-size: 1.125rem;
      font-weight: 600;
    }
    .description {
      font-size: 12px;
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export type SectionBoxProps = {
  link: string;
  title: string;
  description: string;
  icon?: JSX.Element;
};
export const SectionBox = ({
  link,
  title,
  description,
  icon = <CircledPlusIcon />,
}: SectionBoxProps) => (
  <StyledSectionBox to={link}>
    <div className="icon-container">{icon}</div>
    <div className="title-and-desc">
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </div>
  </StyledSectionBox>
);

const StyledBILinkBox = styled.a`
  border: 2px solid var(--yellow-400);
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  background-color: var(--white);
  padding: 16px;
  display: flex;
  flex-direction: column;

  .recommended {
    display: flex;
    background-color: var(--yellow-400);
    align-items: center;
    width: min-content;
    padding: 1px 5px;
    border-radius: 4px;
    gap: 4px;
    font-size: 12px;
  }
  .title {
    margin-top: 4px;
    font-size: 1.125rem;
    font-weight: 600;
  }
  .description {
    font-size: 12px;
    white-space: initial;
  }
`;

export const BILinkBox = ({ link, title, description }: SectionBoxProps) => (
  <StyledBILinkBox href={link}>
    <div className="recommended">
      <RecommendedIcon />
      <div className="label">Recommandé</div>
    </div>
    <div className="title">{title}</div>
    <div className="description">{description}</div>
  </StyledBILinkBox>
);

export const BackButtonWrapper = styled.div`
    @media (min-width: 900px) {
        display: flex; 
        flex: 1;
    }
`;

const CompletePatrimonyPage = () => {
  const { data: user } = useQuery(["user"], getMe);
  return (
    <Body>
      <HeaderBackButtonTitle backTo="/patrimoine" title="Compléter mon patrimoine" />
      <Content>
        {user && (
          <BILinkBox
            link={getBiWebviewUrl(user.biAuthToken)}
            title="Ajouter un établissement bancaire"
            description="Connexion sécurisée et mise à jour automatique de vos soldes bancaires"
          />
        )}

        <SectionBoxes>
          <SectionBox
            link="sync-epargne"
            title="Épargne"
            description="Compte courant, assurance vie, compte d'investissement etc."
          />
          <SectionBox
            link="immobilier"
            title="Immobilier"
            description="Résidence principale, secondaire, investissement locatif, SCPI etc."
          />
          <SectionBox
            link="sync-credit"
            title="Crédits"
            description="Crédit immobilier, crédit à la consommation etc."
          />
          <SectionBox
            link="autre"
            title="Autres"
            description="Bien professionnel, voiture, bijoux etc."
          />
        </SectionBoxes>
      </Content>
    </Body>
  );
};

const CompletePatrimony = () => (
  <Routes>
    <Route path="/" element={<CompletePatrimonyPage />} />
    <Route
      path="sync-epargne"
      element={
        <AddBiOrManual
          title="Ajouter un produit d'épargne"
          manualLink="/patrimoine/completer/epargne"
        />
      }
    />
    <Route
      path="sync-credit"
      element={
        <AddBiOrManual
          title="Ajouter un crédit"
          manualLink="/patrimoine/completer/credit"
        />
      }
    />

    <Route path="epargne" element={<AddSaving />} />
    <Route path="epargne/:id" element={<AddSaving />} />

    <Route path="immobilier" element={<AddRealEstate />} />
    <Route path="immobilier/:id" element={<AddRealEstate />} />

    <Route path="credit" element={<AddCredit />} />
    <Route path="credit/:id" element={<AddCredit />} />

    <Route path="autre" element={<AddOtherAsset />} />
    <Route path="autre/:id" element={<AddOtherAsset />} />

    <Route path="*" element={<Navigate to="/patrimoine/completer" replace />} />
  </Routes>
);

export default CompletePatrimony;
