import React, { FC } from "react";
import styled from "styled-components";

type Props = {
  active?: boolean;
} & React.HTMLProps<HTMLDivElement>;

/* eslint-disable @typescript-eslint/no-unused-vars */
const MobileBoxComponent: FC<Props> = ({ active, ...props }) => {
  return <div {...props}></div>;
};

const MobileBox = styled(MobileBoxComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  color: ${({ active }) => (active ? "var(--black)" : "var(--gray-300)")};
  cursor: pointer;
  transition: 0.2s;
  position: relative;

  svg {
    width: 2em;
    height: 2em;
  }
`;

export default MobileBox;
