import { useMutation, useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import Button from "../../components/Button";
import BackButton from "../../components/buttons/BackButton";
import Input from "../../components/Input";
import Link from "../../components/Link";
import MailToArpagon from "../../components/MailToArpagon";
import AuthenticationLayout from "./AuthenticationLayout";
import { Body } from "./ConfirmEmail";
import { PasswordForgottenForm } from "./PasswordForgotten";
import { useParams } from "react-router-dom";
import {
  changeForgottenPassword,
  checkForgottenPasswordToken,
} from "../../services/auth.service";
import Loader from "../../components/Loader";

const PasswordRecovery = () => {
  const params = useParams();
  const token = params.token!;

  const { isLoading: checkTokenLoading, data: tokenIsCorrect } = useQuery(
    ["checkForgottenPasswordToken"],
    {
      queryFn: () => checkForgottenPasswordToken(token),
      cacheTime: 0,
    }
  );

  const {
    mutateAsync: tryChangePassword,
    isLoading,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: changeForgottenPassword,
  });

  return (
    <AuthenticationLayout>
      <Body>
        <div className="title-container">
          <Link to={isSuccess ? "/connexion" : "/mot-de-passe-oublie"}>
            <BackButton backText="" />
          </Link>
          <div className="title">Nouveau mot de passe</div>
        </div>
        {checkTokenLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : !tokenIsCorrect ? (
          <div className="text">
            Ce lien a expiré ou est incorrect. Seul le dernier lien envoyé par
            email est valide. Vous pouvez rééssayer d'envoyer un lien{" "}
            <Link underLine to="/mot-de-passe-oublie">
              ici
            </Link>
            .
          </div>
        ) : isSuccess ? (
          <div className="text">
            Votre mot de passe a bien été modifié. Vous pouvez maintenant vous{" "}
            <Link underLine to="/connexion">
              connecter à Arpagon
            </Link>
            .
          </div>
        ) : (
          <>
            <div className="text">Entrez votre nouveau mot de passe</div>

            <Formik
              initialValues={{ password: "" }}
              onSubmit={(values) =>
                tryChangePassword({ token, password: values.password })
              }
            >
              {({ values, handleSubmit, handleChange }) => (
                <PasswordForgottenForm onSubmit={handleSubmit}>
                  <Input
                    type="password"
                    autoComplete="off"
                    name="password"
                    onChange={handleChange}
                    placeholder="Mot de passe"
                    label="Mot de passe"
                    value={values.password}
                    required
                  />
                  <Button type="submit" size="fullWidth" loading={isLoading}>
                    Valider
                  </Button>
                </PasswordForgottenForm>
              )}
            </Formik>
          </>
        )}

        {isError || !tokenIsCorrect ? (
          <div className="text" style={{ marginTop: "8px" }}>
            Un problème persiste ? Contactez-nous&nbsp;: <MailToArpagon />
          </div>
        ) : null}
      </Body>
    </AuthenticationLayout>
  );
};

export default PasswordRecovery;
