import { useEffect } from "react";
import styled, { css } from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { ObjectKeys } from "../../utils/utils";
import { PatrimonySections } from "../../types/patrimony.enums";
import PatrimonySection from "../my-patrimony/PatrimonySection";
import {
  getMyPatrimony,
  getConnections,
  syncBiAccounts,
} from "../../services/patrimony.service";
import { ONE_DAY, ONE_HOUR } from "../../utils/time.utils";
import { queryClient } from "../../lib/react-query";
import { spacify } from "../../utils/format.utils";
import logo from "../../assets/logo.svg";

export const Logo = () => (
  <img
    src={logo}
    alt="logo"
    style={{ width: "200px", marginTop: "20px", marginBottom: "20px" }}
  />
);

const StyledPatrimonyBox = styled.div`
  .content {
    .text-row {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
    }
    .value-row {
      font-size: 24px;
      font-weight: 700;
      white-space: nowrap;
      display: flex;
    }
  }

  background-color: var(--white);
  color: var(--black);
  svg {
    fill: black;
  }
`;

const PatrimonyBox = ({ value, type }) => (
  <StyledPatrimonyBox>
    <div className="content">
      <div className="value-row">{value + " €"}</div>
      <div className="text-row">
        <div>{`Mon patrimoine ${type}`}</div>
      </div>
    </div>
  </StyledPatrimonyBox>
);

const ExportPatrimony = () => {
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.position = "static";
      root.style.height = "auto";
    }
  }, []);

  const { data: myPatrimony, isLoading } = useQuery(
    ["myPatrimony"],
    getMyPatrimony,
    {
      cacheTime: Number.POSITIVE_INFINITY,
      staleTime: ONE_HOUR,
    }
  );

  const { data: currentConnections } = useQuery(
    ["connections"],
    getConnections
  );

  const { data: syncedConnections } = useQuery(
    ["syncBiAccounts"],
    syncBiAccounts,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["myPatrimony"]);
        queryClient.invalidateQueries(["connections"]);
      },
      cacheTime: Number.POSITIVE_INFINITY,
      staleTime: ONE_DAY,
      enabled: false,
    }
  );

  const connections = syncedConnections || currentConnections;

  const net = spacify(parseInt(myPatrimony?.totalNet));
  const gross = spacify(parseInt(myPatrimony?.totalGross));
  if (isLoading || !net || !gross) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="patrimony-sections" id="patrimony-sections-wrapper">
        {ObjectKeys(PatrimonySections).map((section, i) => (
          <PatrimonySection
            key={section}
            extraHeader={
              i === 0 ? (
                <>
                  <Logo />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <PatrimonyBox value={net} type="net" />
                    <PatrimonyBox value={gross} type="brut" />
                  </div>
                </>
              ) : null
            }
            {...{
              section,
              connections,
              isLastSection: false,
              refetchSync: () => {},
              isRefreshing: false,
              myPatrimony,
              sectionsRefs: {},
            }}
          />
        ))}
      </div>
    </>
  );
};

export default ExportPatrimony;
