const UserCircle = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M7 10c0-2.7614 2.2386-5 5-5s5 2.2386 5 5-2.2386 5-5 5-5-2.2386-5-5Z"
        fill="#FFE32B"
      />
      <path
        d="M12 0C5.3726 0 0 5.3726 0 12s5.3726 12 12 12 12-5.3726 12-12S18.6274 0 12 0ZM2.1818 12c0-5.4224 4.3958-9.8182 9.8182-9.8182 5.4225 0 9.8182 4.3958 9.8182 9.8182 0 2.423-.8778 4.6411-2.3326 6.3535-.9994-1.2138-2.515-1.9899-4.2129-1.9899H8.7273c-1.6978 0-3.2134.7761-4.2128 1.99C3.0595 16.6411 2.1818 14.423 2.1818 12Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default UserCircle;
