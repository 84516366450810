import { useEffect, useState, useRef } from "react";
import AuthenticationLayout from "./AuthenticationLayout";
import DotProgressBar from "../../components/DotProgressBar";
import { Formik } from "formik";
import Input from "../../components/Input";
import Button from "../../components/Button";
import BackButton from "../../components/buttons/BackButton";
import styled from "styled-components";
import { CompleteProfileUser } from "../../types/user";
import {
  ApproximateAnnualIncomeDisplay,
  ApproximateWealthDisplay,
  BusinessLineDisplay,
  MaritalStatusDisplay,
  ProfessionalStatusDisplay,
} from "../../types/user.enums";
import Select from "../../components/Select";
import RadioGroup, { BooleanRadioGroup } from "../../components/Radiogroup";
import { useMutation } from "@tanstack/react-query";
import { update } from "../../services/user.service";
import { onSuccessUpdateUser } from "../../utils/auth.utils";
import { NO_BREAK_SPACE } from "../../utils/format.utils";

import MobileBirthDateInput from "../../components/MobileBirthDateInput";
import { removeSignupStep, setSignupStep } from "../../utils/storage.utils";

const StyledDotProgressBar = styled(DotProgressBar)`
  margin: 16px 0;
  width: 100%;
`;

const FormContainer = styled.form`
  width: 460px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .info,
  button[type="submit"] {
    margin-top: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Space = styled.div`
  height: 55px;
  @media (max-width: 900px) {
    height: 25px;
  }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NextButton = () => (
    <> 
        <Button type="submit" size="fullWidth" className="desktop-only">Suivant</Button>
        <Button type="submit" bold size="fullWidth" className="mobile-only">Suivant</Button>
    </>
)

const CompleteProfile = () => {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState<Partial<CompleteProfileUser>>({
    firstname: "",
    lastname: "",
    birthDate: "",
    phoneNumber: "",
    maritalStatus: undefined,
    numberOfChildren: undefined,
    professionalStatus: undefined,
    businessLine: undefined,
    approximateWealth: undefined,
    approximateAnnualIncome: undefined,
    isOwner: undefined,
  });

  useEffect(() => {
    setSignupStep("complete-profile");
  }, []);

  const { mutateAsync: tryUpdate, isLoading } = useMutation({
    mutationFn: update,
    onSuccess: (data) => {
      removeSignupStep();
      onSuccessUpdateUser(data);
    },
  });

  const submit = (user: CompleteProfileUser) => {
    tryUpdate(user);
  };
  const containerRef = useRef(null);
  useEffect(() => {
    //@ts-ignore
    containerRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [step]);

  const onSubmitStep = (values: Partial<CompleteProfileUser>, nextStep: number) => {
    setUser({ ...user, ...values });
    setStep(nextStep);
  };

  const onGoBack = (toStep: number) => {
    setStep(toStep);
  }

  return (
    <AuthenticationLayout>
        <div ref={containerRef}>
        {step !== 1 ? (
          <div style={{ marginLeft: '-16px' }}>
              <BackButton 
                onClick={() => onGoBack(step - 1)} 
                style={{ fontSize: '15px' }}
              />
          </div>
        ) : (<Space/>)}
        <Container >
          <StyledDotProgressBar
              className="dot-progress-bar"
              steps={["Contact", "Profil", "Patrimoine"]}
              currentStep={step}
          />
          <div style={{ height: '25px' }}/>
          {step === 1 && (
              <Formik
                initialValues={{
                    lastname: user.lastname,
                    firstname: user.firstname,
                    birthDate: user.birthDate,
                    phoneNumber: user.phoneNumber,
                }}
                onSubmit={(values) => onSubmitStep(values, 2)}
              >
              {({ values, handleSubmit, handleChange, setFieldValue }) => (
                  <FormContainer onSubmit={handleSubmit}>
                  <Input
                      name="lastname"
                      onChange={handleChange}
                      placeholder="Nom"
                      label="Nom"
                      value={values.lastname}
                      required
                  />
                  <Input
                      name="firstname"
                      onChange={handleChange}
                      placeholder="Prénom"
                      label="Prénom"
                      value={values.firstname}
                      required
                  />
                  <Input
                      name="phoneNumber"
                      onChange={handleChange}
                      placeholder="Numéro de téléphone"
                      label="Numéro de téléphone"
                      value={values.phoneNumber}
                      required
                  />

                  {/* Desktop */}
                  <Input
                      type="date"
                      name="birthDate"
                      onChange={handleChange}
                      placeholder="Date de naissance"
                      label="Date de naissance"
                      value={values.birthDate}
                      className="desktop-only"
                      required
                  />
                  {/* Desktop */}
                  {/* Mobile */}
                      <MobileBirthDateInput
                          value={values.birthDate}
                          onChange={(value) => setFieldValue("birthDate", value)}
                      />
                  {/* Mobile */}

                  <div className="info">
                      Nous vous demandons votre numéro de téléphone pour des raisons
                      de sécurité et afin de pouvoir vous contacter pour vous aider à
                      gérer votre compte si besoin
                  </div>
                  <NextButton/>
                  </FormContainer>
              )}
              </Formik>
          )}
          {step === 2 && (
              <Formik
                initialValues={{
                    maritalStatus: user.maritalStatus,
                    numberOfChildren: user.numberOfChildren,
                    professionalStatus: user.professionalStatus || "BOSS",
                    businessLine: user.businessLine || "AGROALIMENTAIRE",
                }}
                onSubmit={(values) => onSubmitStep(values, 3)}
              >
              {({ values, handleSubmit, handleChange, setFieldValue }) => (
                  <FormContainer onSubmit={handleSubmit}>
                  <RadioGroup
                      optionsEnum={MaritalStatusDisplay}
                      selectedValue={values.maritalStatus}
                      title="Statut marital :"
                      name="maritalStatus"
                      setFieldValue={setFieldValue}
                      required
                  />
                  <Input
                      type="number"
                      name="numberOfChildren"
                      onChange={handleChange}
                      placeholder="Nombre d'enfants"
                      label="Nombre d'enfants"
                      value={values.numberOfChildren}
                      min={0}
                      required
                  />
                  <Select
                      name="professionalStatus"
                      optionsEnum={ProfessionalStatusDisplay}
                      onChange={handleChange("professionalStatus")}
                      label="Statut professionnel"
                      value={values.professionalStatus}
                      required
                  />
                  <Select
                      name="businessLine"
                      optionsEnum={BusinessLineDisplay}
                      onChange={handleChange("businessLine")}
                      label="Secteur d'activité"
                      value={values.businessLine}
                      required
                  />
                  <NextButton/>
                  </FormContainer>
              )}
              </Formik>
          )}
          {step === 3 && (
              <Formik
                initialValues={{
                    approximateWealth: user.approximateWealth,
                    approximateAnnualIncome: user.approximateAnnualIncome,
                    isOwner: user.isOwner,
                }}
                onSubmit={(values) => {
                    const userToSubmit = { ...user, ...values } as CompleteProfileUser;
                    setUser(userToSubmit);
                    submit(userToSubmit);
                }}
              >
              {({ values, handleSubmit, setFieldValue }) => (
                  <FormContainer onSubmit={handleSubmit}>
                  <RadioGroup
                      optionsEnum={ApproximateWealthDisplay}
                      selectedValue={values.approximateWealth}
                      title="Montant approximatif de votre patrimoine :"
                      name="approximateWealth"
                      setFieldValue={setFieldValue}
                      required
                  />
                  <div />
                  <RadioGroup
                      optionsEnum={ApproximateAnnualIncomeDisplay}
                      selectedValue={values.approximateAnnualIncome}
                      title="Montant approximatif de vos revenus annuels :"
                      name="approximateAnnualIncome"
                      setFieldValue={setFieldValue}
                      required
                  />
                  <div />
                  <BooleanRadioGroup
                      selectedValue={values.isOwner}
                      trueOption="Propriétaire"
                      falseOption="Locataire"
                      title={`Êtes-vous propriétaire ou locataire de votre résidence principale${NO_BREAK_SPACE}?`}
                      name="isOwner"
                      setFieldValue={setFieldValue}
                      required
                  />
                  <NextButton/>
                  </FormContainer>
              )}
              </Formik>
          )}
        </Container>
      </div>
    </AuthenticationLayout>
  );
};

export default CompleteProfile;
