import { useQuery } from "@tanstack/react-query";
import { useEffect, useState, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import BackButton from "../../components/buttons/BackButton";
import Link from "../../components/Link";
import Loader from "../../components/Loader";
import Transactions, { MovementsDay } from "../../components/Transactions";
import { formatMyBudget, getMyBudget } from "../../services/budget.service";
import { currency } from "../../utils/format.utils";
import {
  Body as AccountBody,
  Container as AccountContainer,
  FiltersContainer,
  Header as AccountHeader,
  NameAndBalance,
} from "../my-patrimony/Account";
import { getMonthInit, stringMonth } from "../../utils/date.utils";
import { getNavigableStringMonths, MyBudget } from "../../types/budget";
import { ObjectKeys } from "../../utils/utils";
import styled from "styled-components";
import MonthSelector from "../../components/MonthSelector";
import SearchBar from "../../components/SearchBar";
import FilterIcon from "../../components/icons/FilterIcon";
import ActionModal from "../../components/modals/ActionModal";
import RadioGroup from "../../components/Radiogroup";
import Select from "../../components/Select";
import { getMe } from "../../services/user.service";

const Header = styled(AccountHeader)`
  .name-and-balance {
    /* TODO */
    /* flex: 0;
    overflow: hidden; */
    .account-name {
      white-space: nowrap;
    }
  }
`;

const ActionModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
  .filters-title {
    font-weight: 500;
  }
  .list {
    font-size: 0.9em;
  }
`;

const Container = styled(AccountContainer)`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .month-selector,
  .month-transactions {
    padding-right: 32px;
    @media (max-width: 900px) {
      padding-right: 16px;
    }
  }
  .month-transactions {
    overflow: auto;
    padding-bottom: 16px;
    .transaction-days {
      *:first-child {
        margin-top: 0;
      }
    }
  }
  .no-transaction {
    text-align: center;
  }
`;

const SortByOptions = {
  "-date": "Date la plus récente",
  date: "Date la moins récente",
  "-amount": "Montant décroissant",
  amount: "Montant croissant",
};

type FiltersType = {
  sortBy: keyof typeof SortByOptions;
  filterBy: { bankName: string };
};

type AmountProp = keyof Pick<
  MyBudget,
  "realIncomes" | "realExpenses" | "realTotal"
>;

const Flows = {
  entrees: {
    title: "Entrées",
    amountProp: "realIncomes" as AmountProp,
    filter: (x: number) => x > 0,
  },
  sorties: {
    title: "Sorties",
    amountProp: "realExpenses" as AmountProp,
    filter: (x: number) => x < 0,
  },
  "entrees-et-sorties": {
    title: "Entrées et sorties",
    amountProp: "realTotal" as AmountProp,
    filter: (x: number) => true,
  },
};
type Flow = keyof typeof Flows;

const BudgetTransactionsPage = ({ flow }: { flow: Flow }) => {
  const { data: user } = useQuery(["user"], getMe);

  /* Months Part Start */

  const { monthParam } = useParams<{ monthParam: string }>();
  const navigate = useNavigate();

  const monthInit = getMonthInit(monthParam);
  const month = monthInit || stringMonth();
  const setMonth = (m: string) => {
    navigate(`/budget/${m}/flux/${flow}`, { replace: true });
  };

  /* Months Part End */

  const { data, isLoading } = useQuery(["budget"], getMyBudget);
  const myBudget = data && formatMyBudget(data, month);
  const months = useMemo(
    () => (data ? getNavigableStringMonths(data.transactions) : []),
    [data]
  );

  useEffect(() => {
    if (!monthInit || (months.length && !months.includes(monthInit))) {
      navigate(`/budget/${stringMonth()}/flux/${flow}`, { replace: true });
    }
  }, [monthInit, months, flow, navigate]);

  const amount = myBudget?.[Flows[flow].amountProp];

  const allTransactions = myBudget?.transactions || [];

  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState<FiltersType>({
    sortBy: "-date",
    filterBy: { bankName: "" },
  });
  const [tempFilters, setTempFilters] = useState<FiltersType>();
  const openFilters = () => setTempFilters(filters);
  const closeFilters = () => setTempFilters(undefined);
  const filtersOpened = !!tempFilters;

  const [displayAccountNameAndBalance, setDisplayAccountNameAndBalance] = useState(true);
  
  const AllBanks = allTransactions.reduce<Record<string, string>>(
    (acc, t) => {
      return acc[t.bankName] ? acc : { ...acc, [t.bankName]: t.bankName };
    },
    { "": "Toutes" }
  );

  const transactions = allTransactions
    .filter(
      (t) =>
        /* filter incomes / expenses */
        Flows[flow].filter(t.value || 0) &&
        /* filter by search value */
        (t.name.toLowerCase().includes(searchValue) ||
          t.bankName.toLowerCase().includes(searchValue)) &&
        /* filter by additionnal filters */
        t.bankName.includes(filters.filterBy.bankName)
    )
    .sort((a, b) => {
      if (filters.sortBy === "-date") {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      } else if (filters.sortBy === "date") {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      } else if (filters.sortBy === "-amount") {
        return (b.value || 0) - (a.value || 0);
      } else if (filters.sortBy === "amount") {
        return (a.value || 0) - (b.value || 0);
      } else {
        return 0;
      }
    });

  return (
    <AccountBody>
      <Header>
        <Link to={`/budget/${month}`}>
          <BackButton />
        </Link>
        {displayAccountNameAndBalance && (
            <NameAndBalance className="name-and-balance">
                <div className="account-name">
                    Flux bancaires - {Flows[flow].title}
                </div>
                <div className="account-balance">
                    {(amount || amount === 0) && currency(amount)}
                </div>
            </NameAndBalance>
        )}
        <FiltersContainer style={displayAccountNameAndBalance ? {} : { flex: 1 }}>
          <SearchBar
            placeholder="Rechercher une transaction"
            onChange={(v) => setSearchValue(v.toLowerCase())}
            value={searchValue}
            onFocus={() => setDisplayAccountNameAndBalance(false)}
            onBlur={() => setDisplayAccountNameAndBalance(true)}
          />
          <FilterIcon onClick={openFilters} />
        </FiltersContainer>
      </Header>
      <ActionModal
        open={filtersOpened}
        close={closeFilters}
        apply={() => {
          tempFilters && setFilters(tempFilters);
          closeFilters();
        }}
      >
        {tempFilters && (
          <ActionModalContent>
            <div className="filters-title">Trier par :</div>
            <RadioGroup
              optionsEnum={SortByOptions}
              selectedValue={tempFilters.sortBy}
              onChange={(sortBy) => {
                setTempFilters((prev) => prev && { ...prev, sortBy });
              }}
            />
            <div className="filters-title">Filtrer par :</div>
            <Select
              label="Banque"
              optionsEnum={AllBanks}
              value={tempFilters.filterBy.bankName}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const bankName = e.target.value as string;
                setTempFilters(
                  (prev) =>
                    prev && {
                      ...prev,
                      filterBy: { ...prev.filterBy, bankName },
                    }
                );
              }}
            />
          </ActionModalContent>
        )}
      </ActionModal>
      <Container>
        <Content>
          {isLoading ? (
            <Loader />
          ) : !myBudget || !user ? (
            <div>Erreur</div>
          ) : (
            <>
              <MonthSelector
                months={months}
                month={month}
                setMonth={setMonth}
              />
              <div className="month-transactions">
                {!transactions.length ? (
                  <div className="no-transaction">
                    Aucune transaction à afficher
                  </div>
                ) : filters.sortBy === "-date" || filters.sortBy === "date" ? (
                  <Transactions
                    transactions={transactions}
                    displayBankIndividually
                    highlightIncomes={
                      flow === "entrees-et-sorties" || undefined
                    }
                    grayOutInternalTransfers
                  />
                ) : (
                  <MovementsDay
                    movements={transactions}
                    displayDateIndividually
                    displayBankIndividually
                    grayOutInternalTransfers
                  />
                )}
              </div>
            </>
          )}
        </Content>
      </Container>
    </AccountBody>
  );
};

const BudgetTransactions = () => (
  <Routes>
    {ObjectKeys(Flows).map((flow) => (
      <Route
        key={flow}
        path={flow}
        element={<BudgetTransactionsPage flow={flow} />}
      />
    ))}
    <Route path="*" element={<Navigate to="/budget" replace />} />
  </Routes>
);

export default BudgetTransactions;
