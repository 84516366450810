import LogoutIcon from "../components/icons/Logout";
import PieChartIcon from "../components/icons/PieChartIcon";
import PiggyBankIcon from "../components/icons/PiggyBankIcon";
import UserIcon from "../components/icons/User";
import WalletIcon from "../components/icons/Wallet";
import LinkDropDown from "../components/navigation/LinkDropDown";
import NavigationLink from "../components/navigation/NavigationLink";
import LogoAlternative from "../assets/LogoAlternative";
import styled from "styled-components";
import Button from "../components/Button";
import { useQuery } from "@tanstack/react-query";
import { getMe } from "../services/user.service";
import Loader from "../components/Loader";
import Patrimony, { PatrimonySectionsOptions } from "./my-patrimony/Patrimony";
import NavigationScroller from "../components/navigation/NavigationScroller";
import Budget, { BudgetSectionsOptions } from "./budget/Budget";
import {
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { getMonthInit, stringMonth } from "../utils/date.utils";
import AnalysisTools, {
  AnalysisToolsLinks,
} from "./analysis-tools/AnalysisTools";
import { logout } from "../utils/auth.utils";
import Profile from "./profile/Profile";
import { useState } from "react";
import { PatrimonySection, PatrimonySections } from "../types/patrimony.enums";
import { BudgetSection, BudgetSections } from "../types/budget.enums";
import { ObjectKeys } from "../utils/utils";
import MyAdvisor from "./my-advisor/MyAdvisor";
import BrowserUserHelpIcon from "../components/icons/BrowserUserHelp";

const Body = styled.div`
  display: grid;
  grid-template-columns: min-content auto;

  @media (max-width: 900px) {
    grid-template-columns: 100%;
    grid-template-rows: auto min-content;
  }
`;

const DrawerMenu = styled.div`
  width: 280px;
  @media (max-width: 1600px) {
    width: 250px;
  }
  padding: 32px 8px 12px 24px;
  background-color: var(--black);
  color: var(--white);
  font-size: 16px;
  display: grid;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: 100%;

  .logo-alternative {
    width: 140px;
    height: 30px;
    margin-bottom: 32px;
    margin-left: 4px;
  }
  .menu {
    display: flex;
    flex-direction: column;
  }
`;

const MobileTabBar = styled.div`
  height: 60px;
  font-size: 10px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  > div {
    flex: 1;
    height: 100%;
    > a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  border-top: #d6d6d6 1px solid;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  z-index: 100;
`;

const ProfileMenuTab = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  gap: 12px;
  align-items: center;
  .title {
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  button {
    padding: 4px;
    &:hover {
      background-color: var(--gray-800);
    }
  }
`;

export const ContentBackground = styled.div`
  background-color: var(--black);
  display: grid;
  overflow: hidden;
  @media (max-width: 900px) {
    padding-bottom: 60px;
  }
`;

const patrimonyOptionsIndex = ObjectKeys(PatrimonySections).reduce(
  (acc, section, i) => ({ ...acc, [section]: i }),
  {} as Record<PatrimonySection, number>
);

const budgetOptionsIndex = ObjectKeys(BudgetSections).reduce(
  (acc, section, i) => ({ ...acc, [section]: i }),
  {} as Record<BudgetSection, number>
);

const AppLayout = () => {
  const { data: user } = useQuery(["user"], getMe);

  const location = useLocation();
  const match = matchPath("/budget/:monthParam/*", location.pathname);
  const month = getMonthInit(match?.params.monthParam) || stringMonth();

  const [selectedPatrimonyOption, setSelectedPatrimonyOption] =
    useState<PatrimonySection>("SAVINGS");

  const [selectedBudgetOption, setSelectedBudgetOption] =
    useState<BudgetSection>("INCOMES");

  const [activeAnalysisRoute, setActiveAnalysisRoute] = useState(0);

  return (
    <Body>
      <DrawerMenu className="desktop-only" id="menu-tab-bar">
        <LogoAlternative />
        <div className="menu">
          <LinkDropDown
            icon={<WalletIcon />}
            title="Mon patrimoine"
            href="/patrimoine"
            selectedItem={patrimonyOptionsIndex[selectedPatrimonyOption]}
            itemsLength={PatrimonySectionsOptions.length}
          >
            {PatrimonySectionsOptions.map(({ id, label }) => (
              <NavigationScroller
                key={id}
                title={label}
                href="/patrimoine"
                anchor={id}
              />
            ))}
          </LinkDropDown>
          <LinkDropDown
            icon={<PiggyBankIcon />}
            title="Mon budget"
            href={`/budget/${month}`}
            selectedItem={budgetOptionsIndex[selectedBudgetOption]}
            itemsLength={BudgetSectionsOptions.length}
          >
            {BudgetSectionsOptions.map(({ id, label }) => (
              <NavigationScroller
                key={id}
                title={label}
                href={`/budget/${month}`}
                anchor={id}
              />
            ))}
          </LinkDropDown>
          <LinkDropDown
            icon={<PieChartIcon />}
            title="Outils d'analyse"
            href="/outils-analyse"
            selectedItem={activeAnalysisRoute}
            itemsLength={AnalysisToolsLinks.length}
          >
            {AnalysisToolsLinks.map(({ link, miniTitle, title }, i) => (
              <NavigationLink
                key={link}
                title={miniTitle || title}
                to={`/outils-analyse/${link}`}
                onActive={() => setActiveAnalysisRoute(i)}
              />
            ))}
          </LinkDropDown>
          <NavigationLink
            activeLink="mon-conseiller"
            to="mon-conseiller"
            icon={<BrowserUserHelpIcon />}
            title="Mon conseiller"
          />
        </div>
        <NavigationLink
          activeLink="profil"
          to="profil/mon-profil"
          icon={<UserIcon />}
          title={
            <ProfileMenuTab>
              <div className="name">{user?.firstname || <Loader />}</div>
              <Button size="minimum" variant="transparent" onClick={logout}>
                <LogoutIcon />
              </Button>
            </ProfileMenuTab>
          }
        />
      </DrawerMenu>

      <ContentBackground>
        <Routes>
          <Route
            path="/patrimoine/*"
            element={
              <Patrimony
                selectedOptionId={selectedPatrimonyOption}
                setSelectedOptionId={setSelectedPatrimonyOption}
              />
            }
          />
          <Route
            path="/budget/*"
            element={
              <Budget
                selectedOptionId={selectedBudgetOption}
                setSelectedOptionId={setSelectedBudgetOption}
              />
            }
          />
          <Route path="/outils-analyse/*" element={<AnalysisTools />} />
          <Route path="/mon-conseiller/*" element={<MyAdvisor />} />
          <Route path="/profil/*" element={<Profile />} />
          <Route path="*" element={<Navigate to="/patrimoine" replace />} />
        </Routes>
      </ContentBackground>

      <MobileTabBar className="mobile-only" id="menu-tab-bar">
        <NavigationLink
          icon={<WalletIcon />}
          title="Mon patrimoine"
          to="/patrimoine"
          isMobileBox
        />
        <NavigationLink
          icon={<PiggyBankIcon />}
          title="Mon budget"
          to="/budget"
          isMobileBox
        />
        <NavigationLink
          icon={<PieChartIcon />}
          title="Outils d'analyse"
          to="/outils-analyse"
          isMobileBox
        />
        <NavigationLink
          icon={<BrowserUserHelpIcon />}
          title="Mon conseiller"
          to="/mon-conseiller"
          isMobileBox
        />
      </MobileTabBar>
    </Body>
  );
};

export default AppLayout;
