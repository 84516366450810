import { useMutation, useQuery } from "@tanstack/react-query";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import PlusIcon from "../../components/icons/Plus";
import Input from "../../components/Input";
import Loader from "../../components/Loader";
import MonthSelector from "../../components/MonthSelector";
import NavPills from "../../components/NavPills";
import { queryClient } from "../../lib/react-query";
import {
  formatMyBudget,
  getMyBudget,
  updateBudget,
  exportBudget,
} from "../../services/budget.service";
import { getMe, update } from "../../services/user.service";
import {
  BudgetedItemsDto,
  GetBudgetDto,
  getNavigableStringMonths,
} from "../../types/budget";
import {
  BudgetExpenseCategories,
  BudgetIncomeCategories,
  BudgetSection,
  BudgetSections,
  CategoriesDictType,
} from "../../types/budget.enums";
import { getMonthInit, stringMonth } from "../../utils/date.utils";
import { currency, spacify } from "../../utils/format.utils";
import { getBudgetScroll, setBudgetScroll } from "../../utils/storage.utils";
import { ONE_HOUR } from "../../utils/time.utils";
import { ObjectEntries, ObjectKeys } from "../../utils/utils";
import { downloadPDF } from "../../utils/export.utils";
import { EmbeddedInput } from "../analysis-tools/utils/borrowing-capacity.utils";
import { CompletePatrimonyRoundLink } from "../my-patrimony/complete/CompletePatrimony";
import {
  Body as PatrimonyBody,
  Content,
  Header as PatrimonyHeader,
  NavPillsContainer,
  PageTitle,
  StyledPatrimonySection,
} from "../my-patrimony/Patrimony";
import BudgetTransactions from "./BudgetTransactions";
import DownloadButton from "../../components/DownloadButton";

import Summary from "./Summary";
import entreesIcon from "../../assets/entrees.png";
import sortiesIcon from "../../assets/sorties.png";
import ecartIcon from "../../assets/ecart.png";
import DownloadIcon from "../../components/icons/DownloadIcon";
import UserCircle from "../../components/icons/UserCircle";
import ProfileButton from "../../components/ProfileButton";

const Body = styled(PatrimonyBody)``;

const BudgetBox = styled.div`
  @media (min-width: 900px) {
    border-radius: 24px;
    box-shadow: var(--box-shadow);
  }
  background-color: var(--black);
  color: var(--white);

  .content {
    padding: 16px;
    .text-row {
      display: flex;
      align-items: center;
      font-size: 14px;
      white-space: nowrap;
    }
    .value-row {
      font-size: 24px;
      font-weight: 700;
      white-space: nowrap;
      display: flex;
    }
  }

  @media (max-width: 900px) {
    .content {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Categories = styled.div`
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
`;

const Category = styled.div`
  padding: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  svg {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .category-title {
    flex: auto;
    flex-basis: min-content;
    position: relative;
    .second-row {
      position: absolute;
      top: 80%;
      left: 1px;
      font-size: 11px;
      color: var(--gray-600);
      white-space: nowrap;
    }
  }
  .category-total {
    ${EmbeddedInput}
    width: 90px;
    font-size: 14px;
    input {
      text-align: right;
      padding: 4px;
      padding-right: 24px;
      height: 100%;
    }
  }

  &:not(:first-child) {
    border-top: var(--border);
  }
`;

export const SummaryWrapper = styled.div`
  .summary-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .estimation-summaries {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    @media (max-width: 1250px) {
      font-size: 15px;
    }
    @media (max-width: 1000px) {
      font-size: 14px;
    }

    @media (min-width: 900px) {
      gap: 32px;
      .estimation-summaries {
        display: flex;
        flex-direction: row;
        gap: 32px;
        .budget-summary {
          overflow: hidden;
          flex-grow: 1;
          flex-basis: 0;
        }
      }
    }
  }
`;

export const BudgetSectionsOptions: Array<{
  id: BudgetSection;
  label: string;
}> = [
  { id: "INCOMES", label: BudgetSections.INCOMES },
  { id: "EXPENSES", label: BudgetSections.EXPENSES },
  { id: "FLOWS", label: BudgetSections.FLOWS },
];

type Props = {
  selectedOptionId: BudgetSection;
  setSelectedOptionId: React.Dispatch<React.SetStateAction<BudgetSection>>;
};

//@ts-ignore
export const BudgetLine = ({
  category,
  c,
  setChangedBudget,
  item,
  validate,
  amount,
}: {
  category: string;
  c: any;
  setChangedBudget: any;
  item: any;
  validate: any;
  amount: any;
}) => {
  const [formated, setFormated] = useState(amount ? spacify(amount) : "");
  return (
    <Category key={category}>
      {c!.icon}
      <div className="category-title">
        {c!.text}
        <div className="second-row">{c!.description}</div>
      </div>
      <div className="category-total">
        <Input
          type="text"
          inputMode="numeric"
          unit="€"
          min={0}
          name={category}
          step={1}
          onChange={(e) => {
            setFormated(
              spacify(Number(e.target.value.replace(/[^0-9.]+/g, "")))
            );
            setChangedBudget((cb: any) => ({
              ...cb,
              [category]: {
                type: item.type,
                amount: Math.round(
                  Number(e.target.value.replace(/[^0-9.]+/g, ""))
                ),
              },
            }));
          }}
          onBlur={validate}
          onKeyUp={(e) => {
            if (e.code === "Enter") validate();
          }}
          value={formated || ""}
          blurOnEnter
        />
      </div>
    </Category>
  );
};

const BudgetPage = ({ selectedOptionId, setSelectedOptionId }: Props) => {
  const { data: user } = useQuery(["user"], getMe);

  /* Months Part Start */

  const { monthParam } = useParams<{ monthParam: string }>();
  const navigate = useNavigate();

  const monthInit = getMonthInit(monthParam);
  const month = monthInit || stringMonth();
  const setMonth = (m: string) => {
    navigate(`/budget/${m}`, { replace: true });
  };

  /* Months Part End */

  const [changedBudget, setChangedBudget] = useState<Partial<BudgetedItemsDto>>(
    {}
  );
  const { data, isLoading } = useQuery(["budget"], getMyBudget, {
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: ONE_HOUR,
  });
  const myBudget = useMemo(
    () => data && formatMyBudget(data, month),
    [data, month]
  );
  const months = useMemo(
    () => (data ? getNavigableStringMonths(data.transactions) : []),
    [data]
  );

  useEffect(() => {
    if (!monthInit || (months.length && !months.includes(monthInit))) {
      navigate(`/budget/${stringMonth()}`, { replace: true });
    }
  }, [monthInit, months, navigate]);

  const { mutateAsync: tryUpdateBudget } = useMutation({
    mutationFn: updateBudget,
    onSuccess: (item) => {
      const previousData = queryClient.getQueryData<GetBudgetDto>(["budget"]);
      if (previousData) {
        const newBudget = previousData.budget.map((x) =>
          x.category === item.category ? { ...x, amount: item.amount } : x
        );
        queryClient.setQueryData<GetBudgetDto>(["budget"], {
          ...previousData,
          budget: newBudget,
        });
      }
    },
    onError: (e) => console.error(e),
    onSettled: (res, error, reqData) =>
      setChangedBudget((cb) => {
        const { [reqData.category]: toRemove, ...newCb } = cb;
        return newCb;
      }),
  });

  /* Scroll Part Start */

  const location = useLocation();

  const incomesRef = useRef<HTMLDivElement>(null);
  const expensesRef = useRef<HTMLDivElement>(null);
  const flowsRef = useRef<HTMLDivElement>(null);

  const sectionsRefs = useMemo(() => {
    return {
      INCOMES: incomesRef,
      EXPENSES: expensesRef,
      FLOWS: flowsRef,
    };
  }, []);

  const scrollTo = useCallback(
    (id: BudgetSection) => {
      const current = sectionsRefs[id].current;
      if (current) {
        setOptionsToScrollTo((prev) => [...prev, id]);
        setTimeout(() => {
          setOptionsToScrollTo((prev) => prev.slice(1));
        }, 1000);
      }
    },
    [sectionsRefs]
  );

  const removeNavigationState = useCallback(
    () => navigate(`/budget/${month}`, { state: null, replace: true }),
    [navigate, month]
  );

  useEffect(() => {
    if (!location.state || !myBudget) return;

    const section = location.state as BudgetSection;

    if (sectionsRefs[section].current) {
      scrollTo(section);
      removeNavigationState();
    }
  }, [location, sectionsRefs, scrollTo, removeNavigationState, myBudget]);

  /* Scroll Part End */

  /* Scroll Restoration Part Start  */

  const sectionsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (myBudget) {
      sectionsContainerRef.current?.scrollTo({ top: getBudgetScroll() });
    }
  }, [myBudget]);

  const [optionsToScrollTo, setOptionsToScrollTo] = useState<BudgetSection[]>(
    []
  );
  const prevOptionsToScrollToLength = useRef(0);

  const [scrollPosition, setScrollPosition] = useState(getBudgetScroll());

  const handleScroll = useCallback(() => {
    const position = sectionsContainerRef.current?.scrollTop;
    if (
      !optionsToScrollTo.length &&
      position !== undefined &&
      position !== scrollPosition
    ) {
      setScrollPosition(position);
    }
  }, [optionsToScrollTo, scrollPosition]);

  useEffect(() => {
    if (prevOptionsToScrollToLength.current && !optionsToScrollTo.length) {
      handleScroll();
    }

    if (optionsToScrollTo.length > prevOptionsToScrollToLength.current) {
      const id = optionsToScrollTo[optionsToScrollTo.length - 1]!;
      const current = sectionsRefs[id].current!;
      current.scrollIntoView({ behavior: "smooth" });
      setSelectedOptionId(id);
      setScrollPosition(current.offsetTop);
    }

    prevOptionsToScrollToLength.current = optionsToScrollTo.length;
  }, [optionsToScrollTo, handleScroll, sectionsRefs, setSelectedOptionId]);

  useEffect(() => {
    const current = sectionsContainerRef.current;
    if (current) {
      current.addEventListener("scroll", handleScroll, { passive: true });
      return () => current.removeEventListener("scroll", handleScroll);
    }
  }, [sectionsContainerRef, handleScroll]);

  useEffect(() => {
    if (getBudgetScroll() !== scrollPosition) {
      setBudgetScroll(scrollPosition);
    }
  }, [scrollPosition]);

  useEffect(() => {
    if (myBudget) {
      const sectionsTop = ObjectKeys(BudgetSections).reduce(
        (acc, section) => ({
          ...acc,
          [section]: sectionsRefs[section].current?.offsetTop || -1000,
        }),
        {} as Record<BudgetSection, number>
      );

      const optionId = ObjectEntries(sectionsTop)
        .reverse()
        .find(([, sectionTop]) => scrollPosition >= sectionTop - 32)?.[0];

      if (optionId) {
        setSelectedOptionId(optionId);
      }
    }
  }, [scrollPosition, myBudget, sectionsRefs, setSelectedOptionId]);

  /* Scroll Restoration Part End  */

  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownload = async () => {
    if (month && myBudget) {
      setIsDownloading(true);
      const pdfData = await exportBudget(month);
      downloadPDF(pdfData, `arpagon-budget${month}.pdf`);
      setIsDownloading(false);
    }
  };

  const estimationSections = [
    {
      title: "Revenus mensuels moyens",
      ref: incomesRef,
      total: myBudget?.budgetedIncomes || 0,
      categories: BudgetIncomeCategories as CategoriesDictType,
    },
    {
      title: "Dépenses mensuelles fixes",
      ref: expensesRef,
      total: myBudget?.budgetedExpenses || 0,
      categories: BudgetExpenseCategories as CategoriesDictType,
    },
  ];
  return (
    <Body>
      <PatrimonyHeader>
        <div className="header-first-row">
          <div className="left-side">
            <div className="mobile-only logo">
              <ProfileButton onClick={() => navigate("/profil")}>
                <UserCircle />
              </ProfileButton>
            </div>
            <PageTitle className="desktop-only">Mon budget</PageTitle>
          </div>
          <DownloadButton onClick={handleDownload}>
            <DownloadIcon isLoading={isDownloading} />
          </DownloadButton>
          <CompletePatrimonyRoundLink />
        </div>
        <div>
          <BudgetBox>
            <div className="content">
              <div className="value-row">
                {isLoading ? (
                  <>
                    <Loader />
                    &nbsp;
                  </>
                ) : (
                  currency(myBudget?.capacity || 0)
                )}
              </div>
              <div className="text-row">
                <div>Ma capacité d'épargne chaque mois</div>
              </div>
            </div>
          </BudgetBox>
        </div>
      </PatrimonyHeader>
      <Content>
        <NavPillsContainer className="mobile-only">
          <NavPills
            options={BudgetSectionsOptions}
            onOptionSelected={scrollTo}
            selectedOptionId={selectedOptionId}
          />
        </NavPillsContainer>
        <div className="desktop-only" />
        <div ref={sectionsContainerRef} className="patrimony-sections">
          {isLoading ? (
            <Loader />
          ) : !myBudget || !user ? (
            <div>Erreur</div>
          ) : !user.isBudgetCompleted ? (
            <>
              <div className="welcome-text">
                Complétez votre budget pour calculer votre objectif d'épargne
                chaque mois et monitorez vos dépenses réalisées sur vos comptes
                bancaires.
              </div>
              <div className="complete-button">
                <Button
                  icon={<PlusIcon />}
                  onClick={() =>
                    update({ isBudgetCompleted: true }).then(() =>
                      queryClient.invalidateQueries(["user"])
                    )
                  }
                >
                  Compléter mon budget
                </Button>
              </div>
            </>
          ) : (
            <>
              {estimationSections.map(({ title, ref, total, categories }) => (
                <StyledPatrimonySection noGap key={title}>
                  <div className="header" ref={ref}>
                    <div className="title section-title">{title}</div>
                    <div className="total"> {currency(total)}</div>
                  </div>
                  <Categories>
                    {ObjectEntries(categories).map(([category, c]) => {
                      const item = myBudget.items[category];
                      const changedItem = changedBudget[category];
                      const amount = changedItem?.amount ?? (item?.amount || 0);

                      const validate = () => {
                        if (!changedItem) return;
                        tryUpdateBudget({
                          category,
                          amount: changedItem.amount,
                        });
                      };

                      return (
                        <BudgetLine
                          category={category}
                          c={c}
                          item={item}
                          amount={amount}
                          validate={validate}
                          setChangedBudget={setChangedBudget}
                        />
                      );
                    })}
                  </Categories>
                </StyledPatrimonySection>
              ))}

              <StyledPatrimonySection noGap isLastSection>
                <div className="header" ref={flowsRef}>
                  <div className="title">
                    Écarts entre mes flux bancaires et mon budget
                  </div>
                </div>
                <SummaryWrapper>
                  <MonthSelector
                    months={months}
                    month={month}
                    setMonth={setMonth}
                    altStyle={true}
                  />
                  <div className="summary-container">
                    <div className="estimation-summaries">
                      <Summary
                        title="Entrées"
                        icon={entreesIcon}
                        link="flux/entrees"
                        label1="Entrées Bancaires"
                        value1={currency(myBudget.realIncomes)}
                        label2="Revenus mensuels moyens"
                        value2={currency(myBudget.budgetedIncomes)}
                        label3="Écarts"
                        value3={`${
                          myBudget.incomesDifference > 0 ? "+" : ""
                        }${currency(myBudget.incomesDifference)}`}
                      />
                      <Summary
                        title="Sorties"
                        icon={sortiesIcon}
                        link="flux/sorties"
                        label1="Sorties Bancaires"
                        value1={currency(myBudget.realExpenses)}
                        label2="Dépenses mensuelles fixes"
                        value2={currency(-myBudget.budgetedExpenses)}
                        label3="Écarts"
                        value3={`${
                          myBudget.expensesDifference > 0 ? "+" : ""
                        }${currency(myBudget.expensesDifference)}`}
                      />
                    </div>
                    <Summary
                      title="Écarts"
                      icon={ecartIcon}
                      link="flux/entrees-et-sorties"
                      label1="Entrées - Sorties bancaires"
                      value1={currency(myBudget.realTotal)}
                      label2="Capacité d'épargne chaque mois"
                      value2={currency(myBudget.capacity)}
                      label3="Écarts"
                      value3={`${
                        myBudget.budgetDifference > 0 ? "+" : ""
                      }${currency(myBudget.budgetDifference)}`}
                    />
                  </div>
                </SummaryWrapper>
              </StyledPatrimonySection>
            </>
          )}
        </div>
      </Content>
    </Body>
  );
};

const Budget = (props: Props) => (
  <Routes>
    <Route path=":monthParam" element={<BudgetPage {...props} />} />
    <Route path=":monthParam/flux/*" element={<BudgetTransactions />} />
    <Route path="/" element={<BudgetPage {...props} />} />
    <Route path="*" element={<Navigate to="/budget" replace />} />
  </Routes>
);

export default Budget;
