const ForkSpoonIcon = ({ big }: { big?: boolean }) => (
  <svg
    width={big ? 48 : 24}
    height={big ? 48 : 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.2" cx="12" cy="12" r="12" fill="#FFAB00" />
    <g>
      <path
        d="M10.8334 4C10.6566 4 10.487 4.07024 10.362 4.19526C10.237 4.32029 10.1667 4.48986 10.1667 4.66667V8C10.1674 8.34903 10.0306 8.6843 9.78608 8.93333C9.7628 8.95676 9.7331 8.97276 9.70074 8.97934C9.66837 8.98592 9.63479 8.98277 9.60421 8.9703C9.57363 8.95782 9.54742 8.93658 9.52889 8.90924C9.51036 8.8819 9.50034 8.84969 9.50008 8.81667V4.66667C9.50008 4.48986 9.42984 4.32029 9.30482 4.19526C9.1798 4.07024 9.01023 4 8.83341 4C8.6566 4 8.48703 4.07024 8.36201 4.19526C8.23699 4.32029 8.16675 4.48986 8.16675 4.66667V8.81533C8.16649 8.84836 8.15647 8.88057 8.13794 8.90791C8.11941 8.93525 8.0932 8.95649 8.06262 8.96897C8.03204 8.98144 7.99846 8.98459 7.96609 8.97801C7.93373 8.97143 7.90403 8.95542 7.88075 8.932C7.63652 8.68329 7.4998 8.34857 7.50008 8V4.66667C7.50008 4.48986 7.42984 4.32029 7.30482 4.19526C7.17979 4.07024 7.01023 4 6.83341 4C6.6566 4 6.48703 4.07024 6.36201 4.19526C6.23699 4.32029 6.16675 4.48986 6.16675 4.66667V8C6.16753 8.55136 6.33882 9.08898 6.65713 9.53918C6.97544 9.98937 7.4252 10.3301 7.94475 10.5147C8.00965 10.5377 8.06584 10.5802 8.10559 10.6364C8.14534 10.6927 8.16671 10.7598 8.16675 10.8287V19.3333C8.16675 19.5101 8.23699 19.6797 8.36201 19.8047C8.48703 19.9298 8.6566 20 8.83341 20C9.01023 20 9.1798 19.9298 9.30482 19.8047C9.42984 19.6797 9.50008 19.5101 9.50008 19.3333V10.8287C9.50012 10.7598 9.52148 10.6927 9.56124 10.6364C9.60099 10.5802 9.65718 10.5377 9.72208 10.5147C10.2416 10.3301 10.6914 9.98937 11.0097 9.53918C11.328 9.08898 11.4993 8.55136 11.5001 8V4.66667C11.5001 4.48986 11.4298 4.32029 11.3048 4.19526C11.1798 4.07024 11.0102 4 10.8334 4V4Z"
        fill="#FFAB00"
      />
      <path
        d="M15.1667 4C14.4594 4 13.7811 4.28095 13.281 4.78105C12.781 5.28115 12.5 5.95942 12.5 6.66667V8C12.5008 8.55136 12.6721 9.08898 12.9904 9.53918C13.3087 9.98937 13.7584 10.3301 14.278 10.5147C14.3429 10.5377 14.3991 10.5802 14.4388 10.6364C14.4786 10.6927 14.5 10.7598 14.5 10.8287V19.3333C14.5 19.5101 14.5702 19.6797 14.6953 19.8047C14.8203 19.9298 14.9899 20 15.1667 20C15.3435 20 15.513 19.9298 15.6381 19.8047C15.7631 19.6797 15.8333 19.5101 15.8333 19.3333V10.8287C15.8334 10.7598 15.8547 10.6927 15.8945 10.6364C15.9342 10.5802 15.9904 10.5377 16.0553 10.5147C16.5749 10.3301 17.0246 9.98937 17.3429 9.53918C17.6613 9.08898 17.8325 8.55136 17.8333 8V6.66667C17.8333 5.95942 17.5524 5.28115 17.0523 4.78105C16.5522 4.28095 15.8739 4 15.1667 4V4ZM14.8333 5.66667C14.6565 5.66667 14.487 5.7369 14.3619 5.86193C14.2369 5.98695 14.1667 6.15652 14.1667 6.33333C14.1667 6.42174 14.1315 6.50652 14.069 6.56904C14.0065 6.63155 13.9217 6.66667 13.8333 6.66667C13.7449 6.66667 13.6601 6.63155 13.5976 6.56904C13.5351 6.50652 13.5 6.42174 13.5 6.33333C13.5 5.97971 13.6405 5.64057 13.8905 5.39052C14.1406 5.14048 14.4797 5 14.8333 5C14.9217 5 15.0065 5.03512 15.069 5.09763C15.1315 5.16014 15.1667 5.24493 15.1667 5.33333C15.1667 5.42174 15.1315 5.50652 15.069 5.56904C15.0065 5.63155 14.9217 5.66667 14.8333 5.66667Z"
        fill="#FFAB00"
      />
    </g>
  </svg>
);

export default ForkSpoonIcon;
