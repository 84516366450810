const UserStates = {
  EMAIL_NOT_CONFIRMED: "EMAIL_NOT_CONFIRMED",
  PROFILE_NOT_COMPLETED: "PROFILE_NOT_COMPLETED",
  COMPLETED: "COMPLETED",
};
export type UserState = keyof typeof UserStates;

export const MaritalStatusDisplay = {
  SINGLE: "Célibataire",
  MARRIED: "Marié-e",
  PACSED: "Pacsé-e",
  WIDOWED: "Veuf-ve",
};

export type MaritalStatus = keyof typeof MaritalStatusDisplay;

/* https://www.youzful-by-ca.fr/c/decouvrir-la-vie-en-entreprise/salaries-independants-intermittents-les-differents-statuts-professionnels */
export const ProfessionalStatusDisplay = {
    EMPLOYEE: "Salarié du secteur privé",
    CIVIL_SERVANT: "Fonctionnaire",
    CRAFTSMAN: "Artisan",
    TRADER: "Commerçant",
    FREELANCE: "Indépendant",
    BOSS: "Chef d'entreprise",
    LIBERAL_WORKER: "Profession libérale",
    FARMER_OPERATOR: "Exploitant agricole",
    STUDENT: "Étudiant",
    RETIRED: "Retraité",
    OTHERS: "Autres"
};

export type ProfessionalStatus = keyof typeof ProfessionalStatusDisplay;

/*http://www.guide-des-salaires.com/secteurs/ */
export const BusinessLineDisplay = {
  AGROALIMENTAIRE: "Agroalimentaire",
  BANQUE_ASSURANCE: "Banque / Assurance / Conseil",
  COMMERCE_NEGOCE_DISTRIBUTION: "Commerce",
  EDITION_COMMUNICATION_MULTIMEDIA: "Édition / Communication / Multimédia",
  INDUSTRIE: "Industrie",
  INFORMATIQUE_TELECOMS: "Informatique / Télécoms",
  SANTE: "Santé",
  SERVICES_AUX_ENTREPRISES: "Services aux entreprises",
  TRANSPORTS_LOGISTIQUE: "Transports / Logistique",
  OTHERS: "Autres"
};

export type BusinessLine = keyof typeof BusinessLineDisplay;

export const ApproximateWealthDisplay = {
  UNDER_200K: "0 - 200 000 €",
  UNDER_500K: "200 000 € - 500 000 €",
  UNDER_1_000K: "500 000 € - 1 000 000 €",
  OVER_1_000K: "Plus de 1 000 000 €",
};

export type ApproximateWealth = keyof typeof ApproximateWealthDisplay;

export const ApproximateAnnualIncomeDisplay = {
  UNDER_50K: "0 - 50 000 €",
  UNDER_100K: "50 000 €- 100 000 €",
  UNDER_200K: "100 000 €- 200 000 €",
  OVER_200K: "Plus de 200 000 €",
};

export type ApproximateAnnualIncome =
  keyof typeof ApproximateAnnualIncomeDisplay;
