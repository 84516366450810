type Props = {
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  onMouseDown?: React.MouseEventHandler<SVGSVGElement>;
  style?: React.CSSProperties;
  onTouchStart?: React.TouchEventHandler<SVGSVGElement>;
};
const CircledRemoveIcon = ({ onClick, onTouchStart, style = {} }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="remove-icon"
    onClick={onClick}
    onTouchStart={onTouchStart}
    style={style}
  >
    <path d="M7.99998 14.6667C4.31798 14.6667 1.33331 11.682 1.33331 8.00001C1.33331 4.31801 4.31798 1.33334 7.99998 1.33334C11.682 1.33334 14.6666 4.31801 14.6666 8.00001C14.6666 11.682 11.682 14.6667 7.99998 14.6667ZM7.99998 7.05734L6.11465 5.17134L5.17131 6.11468L7.05731 8.00001L5.17131 9.88534L6.11465 10.8287L7.99998 8.94268L9.88531 10.8287L10.8286 9.88534L8.94265 8.00001L10.8286 6.11468L9.88531 5.17134L7.99998 7.05734Z" />
  </svg>
);

export default CircledRemoveIcon;
