const CancelRoundIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2752_6154)">
      <path
        d="M10 1.66663C5.39169 1.66663 1.66669 5.39163 1.66669 9.99996C1.66669 14.6083 5.39169 18.3333 10 18.3333C14.6084 18.3333 18.3334 14.6083 18.3334 9.99996C18.3334 5.39163 14.6084 1.66663 10 1.66663ZM13.5834 13.5833C13.2584 13.9083 12.7334 13.9083 12.4084 13.5833L10 11.175L7.59169 13.5833C7.26669 13.9083 6.74169 13.9083 6.41669 13.5833C6.09169 13.2583 6.09169 12.7333 6.41669 12.4083L8.82502 9.99996L6.41669 7.59163C6.09169 7.26663 6.09169 6.74163 6.41669 6.41663C6.74169 6.09163 7.26669 6.09163 7.59169 6.41663L10 8.82496L12.4084 6.41663C12.7334 6.09163 13.2584 6.09163 13.5834 6.41663C13.9084 6.74163 13.9084 7.26663 13.5834 7.59163L11.175 9.99996L13.5834 12.4083C13.9 12.725 13.9 13.2583 13.5834 13.5833Z"
        fill="#B60000"
      />
    </g>
    <defs>
      <clipPath id="clip0_2752_6154">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CancelRoundIcon;
