const CircledPlusIcon = ({
  width = 24,
  height = 24,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#FFE32B" />
    <line
      x1="9.6"
      y1="16"
      x2="22.4"
      y2="16"
      stroke="#202020"
      strokeWidth="3.2"
      strokeLinecap="round"
    />
    <line
      y1="9.6"
      x1="16"
      y2="22.4"
      x2="16"
      stroke="#202020"
      strokeWidth="3.2"
      strokeLinecap="round"
    />
  </svg>
);

export default CircledPlusIcon;
