import styled, { css } from "styled-components";
import CircledRemoveIcon from "./icons/CircledRemoveIcon";
import SearchIcon from "./icons/SearchIcon";
import { useEffect, useRef, useState } from "react";
import { is } from "date-fns/locale";

const Body = styled.div<{ value: string }>`
  position: relative;
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
  .search-icon {
    left: 8px;
    stroke: var(--gray-300);
    pointer-events: none;
  }
  .remove-icon {
    transition: 0.2s;
    right: 8px;
    fill: var(--gray-600);
    ${({ value }) =>
      value
        ? css`
            opacity: 1;
            &:hover {
              cursor: pointer;
              fill: var(--black);
            }
          `
        : css`
            opacity: 0;
            pointer-events: none;
          `}
  }

  input {
    width: 100%;
    border-radius: 8px;
    border: 0;
    font-size: 14px;
    font-family: unset;
    padding: 10px;
    padding-left: 40px;
    border: 1px solid var(--gray-400);
    transition: 0.2s;
    &:focus {
      border-color: var(--gray-700);
    }
    ::placeholder {
      font-size: 12px;
      color: var(--gray-300);
    }
  }
  .search-icon-white {
    stroke: white;
  }
  :hover {
    cursor: pointer;
  }
`;

const DesktopSearchBar = ({ placeholder, onChange, value }: Props) => (
    <Body className="search-bar desktop-only" value={value}>
        <SearchIcon />
        <input
            placeholder={placeholder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            value={value}
        />
        <CircledRemoveIcon onClick={() => value && onChange("")} />
    </Body>
);

const MobileSearchBar = ({ onChange, value, onFocus, onBlur }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<any>(null);
    const isClickInside = useRef(false);
    const timeout = useRef<any>(null);

    useEffect(() => {
      return () => {
        clearTimeout(timeout.current);
      }
    }, []);

    const onClickSearch = () => {
      if (!isOpen) {
          setIsOpen(true);
          onFocus?.();
          inputRef?.current?.focus();
      }
    }

    const onBlurSearch = () => {
      if(!isClickInside.current) {
        setIsOpen(false);
        onBlur?.();
      }
    }

    const onMouseDown = () => {
      isClickInside.current = true;
      onChange("");
      timeout.current = setTimeout(() => {
        isClickInside.current = false;
        inputRef?.current?.focus();
      }, 200);
    }

    return (
        <Body 
            className={"search-bar mobile-only"} 
            value={value}
            onClick={onClickSearch}
            style={{ minWidth: '32px' }}
        >
            {isOpen ? ( <SearchIcon /> ) : null}
            <input
                ref={inputRef}
                style={isOpen ? {} : { width: '1px', height: '1px', border: 'none', padding: '0px', background: '#202020' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                value={value}
                onBlur={onBlurSearch}
            />
            {isOpen ? (
              <CircledRemoveIcon 
                onTouchStart={onMouseDown} 
              />
            ) : null}
            
            {isOpen ? null : ( 
                <SearchIcon className="mobile-only search-icon-white"/>
            )}
        </Body>
    );
}


type Props = {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};
const SearchBar = ({ 
    placeholder, 
    onChange, 
    value,
    onFocus = () => {},
    onBlur = () => {}
}: Props) => {
    return (
    <>
        <DesktopSearchBar 
            placeholder={placeholder} 
            onChange={onChange} 
            value={value} 
        />
        
        <MobileSearchBar 
            onChange={onChange} 
            value={value} 
            onFocus={onFocus}
            onBlur={onBlur}
        />
    </>
  );
};

export default SearchBar;
