import PlusIcon from "../../components/icons/Plus";
import Link from "../../components/Link";
import Button from "../../components/Button";

const CompletePatrimonyButton = () => (
  <div className="complete-button">
    <Link to="completer">
      <Button icon={<PlusIcon />}>Compléter mon patrimoine</Button>
    </Link>
  </div>
);

export default CompletePatrimonyButton;