const UserIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M8.78609 14.5C10.9287 14.5005 13.0714 14.5005 15.214 14.5C15.9407 14.4998 16.4359 14.4997 16.8683 14.576C18.9384 14.941 20.5591 16.5617 20.9241 18.6318C21.0003 19.0642 21.0002 19.5593 21.0001 20.286C21 20.4444 21.0049 20.6038 20.9773 20.7605C20.8678 21.3815 20.3815 21.8677 19.7605 21.9772C19.6244 22.0012 19.4809 22.0005 19.4095 22.0001C14.4704 21.9736 9.52974 21.9736 4.59059 22.0001C4.51923 22.0005 4.37577 22.0012 4.2396 21.9772C3.61859 21.8677 3.13237 21.3815 3.02286 20.7605C2.99524 20.6038 3.0001 20.4444 3.00006 20.286C2.9999 19.5593 2.99979 19.0642 3.07604 18.6318C3.44105 16.5617 5.06178 14.941 7.13183 14.576C7.56424 14.4997 8.05941 14.4998 8.78609 14.5Z" />
    <path d="M6.5 7.5C6.5 4.46243 8.96243 2 12 2C15.0375 2 17.5 4.46243 17.5 7.5C17.5 10.5376 15.0375 13 12 13C8.96243 13 6.5 10.5376 6.5 7.5Z" />
  </svg>
);

export default UserIcon;
