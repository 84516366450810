import {
  getCurrentMonth,
  monthsBetween,
  stringMonth,
} from "./../utils/date.utils";
import { Transaction } from "./movements";
import { BudgetCategory } from "./budget.enums";

type BudgetedItemType = "INCOME" | "EXPENSE";

export type BudgetedItem = {
  id: number;
  userId: number;
  type: BudgetedItemType;
  category: BudgetCategory;
  amount: number;
};

export type BudgetedItemsDto = Record<
  BudgetCategory,
  { type: BudgetedItemType; amount: number }
>;

export type BudgetedItemToUpdateDto = Pick<BudgetedItem, "category" | "amount">;

export function budgetedItemsToDto(budgetedItems: BudgetedItem[]) {
  return budgetedItems.reduce<Partial<BudgetedItemsDto>>(
    (acc, item) => ({
      ...acc,
      [item.category]: { type: item.type, amount: item.amount },
    }),
    {}
  );
}

export function getNavigableStringMonths(transactions: Transaction[]) {
  const timestamps = transactions.map((t) => new Date(t.date).getTime());
  const minDate = new Date(Math.min(...timestamps));
  const maxDate = getCurrentMonth();

  return monthsBetween(minDate, maxDate).map((month) => stringMonth(month));
}

export interface MyBudget {
  capacity: number;
  budgetedIncomes: number;
  budgetedExpenses: number;
  realIncomes: number;
  realExpenses: number;
  realTotal: number;
  incomesDifference: number;
  expensesDifference: number;
  budgetDifference: number;
  items: BudgetedItemsDto;
  transactions: Transaction[];
}

export interface GetBudgetDto {
  budget: BudgetedItem[];
  transactions: Transaction[];
}
