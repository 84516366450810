import { useQuery } from "@tanstack/react-query";
import HeaderBackButtonTitle from "../../../components/HeaderBackButtonTitle";
import { getMe } from "../../../services/user.service";
import { getBiWebviewUrl } from "../../../utils/budget-insight.utils";
import {
  BILinkBox,
  Body,
  Content,
  SectionBox,
} from "./CompletePatrimony";

type Props = {
  title: string;
  manualLink: string;
};
const AddBiOrManual = ({ title, manualLink }: Props) => {
  const { data: user } = useQuery(["user"], getMe);
  return (
    <Body className="complete-patrimony-body">      
      <HeaderBackButtonTitle
        backTo="/patrimoine/completer"
        title={title}
      />
      <Content>
        {user && (
          <BILinkBox
            link={getBiWebviewUrl(user.biAuthToken)}
            title="Synchronisez votre banque"
            description="Connexion sécurisée et mise à jour automatique de vos soldes bancaires"
          />
        )}

        <div style={{ marginTop: "16px" }}>
          <SectionBox
            link={manualLink}
            title="Ajout manuel"
            description="Renseignez manuellement vos données"
          />
        </div>
      </Content>
    </Body>
  );
};

export default AddBiOrManual;
