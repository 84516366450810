import { useState, useRef, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import BackButton from "../../components/buttons/BackButton";
import Link from "../../components/Link";
import Loader from "../../components/Loader";
import Button from "../../components/Button";
import { Formik } from "formik";

import {
  checkConfirmationCode,
  sendConfirmationCode,
} from "../../services/auth.service";
import { User } from "../../types/user";
import {
  logout,
  onSuccessUpdateUser,
} from "../../utils/auth.utils";
import AuthenticationLayout from "./AuthenticationLayout";
import { removeSignupStep, setSignupStep } from "../../utils/storage.utils";

export const Body = styled.div`
  margin-top: 60px;
  @media (max-width: 900px) {
    margin-top: 10px;
  }
  .title-container {
    display: flex;
    width: 100%;
    align-items: center;
    button {
      padding-left: 0;
    }
    .title {
      flex: auto;
      font-weight: 600;
      text-align: center;
      font-size: 1.125rem;
      position: relative;
      left: -16px; // arrow width / 2
      margin: 0 8px;
    }
  }
  .text {
    text-align: center;
    line-height: 25px;
    @media (max-width: 900px) {
      max-width: 500px;
      margin: 0 auto;
    }
  }
  .resend-container {
    display: block;
    margin: 12px auto;
    width: min-content;
    font-weight: 500;
    .resend-loader-container {
      margin-top: 16px;
    }
    .resend {
      white-space: nowrap;
      font-weight: 500;
      cursor: pointer;
      user-select: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .loader-container {
    margin: 40px auto;
    width: min-content;
  }
`;

const CustomBody = styled(Body)`
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    height: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 900px) {
    flex: 1;
  }
`;

const FormContainer = styled.form`
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  @media (max-width: 900px) {
    flex: 1;
  }
`;

const ResendButton = ({ isLoading, onClick }: { isLoading: boolean, onClick: () => void }) => (
  <div className="resend-container">
    {isLoading ? (
      <div className="resend-loader-container">
        <Loader />
      </div>
    ) : (
      <div className="resend" onClick={onClick}>
        Renvoyer le code
      </div>
    )}
  </div>
);

const ConfirmEmailTexts = ({ email }: { email?: string }) => (
  <div className="text" style={{ marginTop: '20px' }}>
    Veuillez indiquer les numéros visibles dans l’email que nous venons de vous envoyer à
    <br />
    {!email ? (
      <Link to="/connexion" underLine className="resend-container">
        Connectez-vous
      </Link>
    ) : (
      <>
        <strong>{email}</strong>
        <br />
      </>
    )}
  </div>
)

const StyledInput = styled.input`
  width: 60px;
  height: 80px;
  padding: 0 20px;
  font-family: unset;
  font-size: 28px;
  border-radius: 4px;
  border: 1px solid var(--gray-400);
  outline: 1px solid var(--transparent);
  transition: 0.2s;
  box-shadow: var(--box-shadow);
  margin: 10px;
`;

const NextButton = ({ onClick }: { onClick: () => void  }) => (
  <> 
      <Button onClick={onClick} size="fullWidth" className="desktop-only">Suivant</Button>
      <Button onClick={onClick} bold size="fullWidth" className="mobile-only">Suivant</Button>
  </>
)
const ConfirmEmail = ({ user }: { user?: User }) => {
  const { token } = useParams();
  const [code] = useState({
    c1: '',
    c2: '',
    c3: '',
    c4: '',
  });

  const c1Ref = useRef(null);
  const c2Ref = useRef(null);
  const c3Ref = useRef(null);
  const c4Ref = useRef(null);

  const {
    mutateAsync: trySendConfimationMail,
    isLoading: sendConfirmationMailIsLoading
  } = useMutation({ mutationFn: sendConfirmationCode });

  const confirmEmailEnabled = !!token;

  const emailConfirmedIsLoading = confirmEmailEnabled;

  const onSubmit = async (values: any) => {
    const submittedCode = `${values.c1}${values.c2}${values.c3}${values.c4}`;
    const data = await checkConfirmationCode(submittedCode);
    removeSignupStep();
    onSuccessUpdateUser(data);
  }

  useEffect(() => {
    setSignupStep("confirm-email");
  }, []);

  return (
    <AuthenticationLayout>
      <CustomBody>
        <div className="title-container">
          {user ? (
            <BackButton backText="" onClick={logout} />
          ) : (
            <Link to="/connexion">
              <BackButton backText="" />
            </Link>
          )}
          <div className="title">Confirmation de votre email</div>
        </div>
        {emailConfirmedIsLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <Container>
            <ConfirmEmailTexts email={user?.email} />

            <Formik
                initialValues={{ ...code }}
                onSubmit={(values) => onSubmit(values)}
              >
              {({ values, handleSubmit, handleChange, setFieldValue }) => (
                <>
                  <FormContainer onSubmit={handleSubmit}>
                    <StyledInput 
                      type="text"
                      ref={c1Ref}
                      value={values.c1} 
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("c1", value);
                        //@ts-ignore
                        if (value !== '') c2Ref?.current?.focus();
                      }} 
                    />
                    <StyledInput
                      type="text"
                      ref={c2Ref}
                      value={values.c2}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("c2", value);
                        //@ts-ignore
                        if (value !== '') c3Ref?.current?.focus();
                        //@ts-ignore
                        else c1Ref?.current?.focus();
                      }}
                    />
                    <StyledInput
                      type="text"
                      ref={c3Ref}
                      value={values.c3}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("c3", value);
                        //@ts-ignore
                        if (value !== '') c4Ref?.current?.focus();
                        //@ts-ignore
                        else c2Ref?.current?.focus();
                      }}
                    />
                    <StyledInput
                      type="text"
                      ref={c4Ref}
                      value={values.c4}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("c4", value);
                        //@ts-ignore
                        if (value === '') c3Ref?.current?.focus();
                      }}
                      onKeyUp={(e) => {
                        if (e.code === "Enter") handleSubmit();
                      }}
                    />
                  </FormContainer>
                  <NextButton onClick={handleSubmit}/>
                </>
              )}
              </Formik>


            {user && (
              <ResendButton 
                isLoading={sendConfirmationMailIsLoading} 
                onClick={() => trySendConfimationMail()} 
              />
            )}
          </Container>
        )}
      </CustomBody>
    </AuthenticationLayout>
  );
};

export default ConfirmEmail;
