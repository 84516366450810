import { FC, useMemo } from "react";
import { useLocation, useNavigate, useResolvedPath } from "react-router-dom";
import Box from "./Box";
import { Container } from "./NavigationLink";
import { getPathMatch } from "./utils";

type NavigationScrollerProps = {
  title: string;
  icon?: React.ReactElement;
  href: string;
  anchor: string;
};
export const NavigationScroller: FC<NavigationScrollerProps> = ({
  title,
  icon,
  href,
  anchor,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = useResolvedPath(href);

  const { isPath } = useMemo(
    () => getPathMatch(location, path),
    [location, path]
  );
  const maybeReplace = isPath ? { replace: true } : {};

  return (
    <Container>
      <Box onClick={() => navigate(href, { state: anchor, ...maybeReplace })}>
        {icon || <div className="no-icon" />}
        <div className="title">{title}</div>
      </Box>
    </Container>
  );
};
export default NavigationScroller;
