const HeartIcon = ({ big }: { big?: boolean }) => (
  <svg
    width={big ? 48 : 24}
    height={big ? 48 : 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.33665 6.36648C8.12818 4.83575 10.4352 5.11472 11.9951 6.47798C13.5533 5.1142 15.8357 4.85501 17.6471 6.36063C19.6295 8.00829 19.8676 10.8183 18.3411 12.7827C17.811 13.4647 16.8248 14.4551 15.8362 15.3979C14.8365 16.3512 13.7974 17.2905 13.136 17.8807L13.1213 17.8937C13.013 17.9905 12.9046 18.0872 12.8047 18.163C12.6923 18.2481 12.5544 18.3358 12.3785 18.3886C12.1304 18.463 11.8603 18.463 11.6123 18.3886C11.4363 18.3358 11.2984 18.2481 11.1861 18.163C11.0861 18.0872 10.9778 17.9905 10.8694 17.8937L10.8547 17.8807C10.1933 17.2905 9.1542 16.3512 8.15453 15.3979C7.16594 14.4551 6.17969 13.4647 5.64965 12.7827C4.11723 10.8107 4.39879 8.02223 6.33665 6.36648ZM11.9657 9.45585C11.8805 9.20005 11.6495 9.0208 11.3805 9.00168C11.1115 8.98257 10.8576 9.12736 10.737 9.36853L9.92123 11H9.66659C9.2984 11 8.99992 11.2985 8.99992 11.6667C8.99992 12.0349 9.2984 12.3333 9.66659 12.3333H10.3333C10.5858 12.3333 10.8166 12.1907 10.9295 11.9648L11.2098 11.4044L12.0341 13.8775C12.1194 14.1333 12.3504 14.3125 12.6193 14.3317C12.8883 14.3508 13.1423 14.206 13.2629 13.9648L14.0786 12.3333H14.3333C14.7015 12.3333 14.9999 12.0349 14.9999 11.6667C14.9999 11.2985 14.7015 11 14.3333 11H13.6666C13.4141 11 13.1833 11.1427 13.0703 11.3685L12.7901 11.929L11.9657 9.45585Z"
      fill="#FF4F4F"
    />
    <circle opacity="0.2" cx="12" cy="12" r="12" fill="#FF4F4F" />
  </svg>
);

export default HeartIcon;
