const ShoppingBagIcon = ({ big }: { big?: boolean }) => (
  <svg
    width={big ? 48 : 24}
    height={big ? 48 : 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.73216 7.33962C9.03858 5.815 10.3852 4.66667 12.0001 4.66667C13.615 4.66667 14.9616 5.815 15.268 7.33962C15.4162 7.34345 15.5533 7.34961 15.6799 7.35953C16.0283 7.38685 16.3491 7.44543 16.6557 7.59098C17.1337 7.81788 17.5334 8.18186 17.8039 8.6366C17.9775 8.92829 18.0657 9.24221 18.1254 9.58653C18.1827 9.91694 18.2208 10.323 18.2667 10.8131L18.6719 15.1346C18.7265 15.7167 18.7713 16.1951 18.7748 16.5853C18.7785 16.989 18.7401 17.3634 18.5872 17.7193C18.3515 18.2681 17.9383 18.7219 17.4139 19.0078C17.0738 19.1933 16.7046 19.2664 16.3023 19.3005C15.9135 19.3333 15.433 19.3333 14.8484 19.3333H9.15183C8.56724 19.3333 8.08666 19.3333 7.69784 19.3005C7.29556 19.2664 6.92635 19.1933 6.58624 19.0078C6.06188 18.7219 5.64871 18.2681 5.41293 17.7193C5.26 17.3634 5.22168 16.989 5.22533 16.5853C5.22886 16.1951 5.27372 15.7167 5.3283 15.1346L5.73343 10.8131C5.77938 10.323 5.81744 9.91694 5.87474 9.58653C5.93445 9.24221 6.02272 8.92829 6.19625 8.63659C6.46678 8.18186 6.86648 7.81788 7.34448 7.59098C7.6511 7.44543 7.97189 7.38685 8.32028 7.35953C8.44684 7.34961 8.58394 7.34345 8.73216 7.33962ZM10.1139 7.33334C10.3884 6.55655 11.1293 6.00001 12.0001 6.00001C12.8709 6.00001 13.6118 6.55655 13.8863 7.33334H10.1139Z"
      fill="#FF2D78"
    />
    <circle opacity="0.2" cx="12" cy="12" r="12" fill="#FF2D78" />
  </svg>
);

export default ShoppingBagIcon;
