import styled from "styled-components";

const ProfileButton = styled.div`
  cursor: pointer;
  display: flex;
  align-self: start;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 16px;
`;
export default ProfileButton;
