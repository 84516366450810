import { Location, Path } from "react-router-dom";

export function getPathMatch(location: Location, toPath: Path) {
  const locationPathname = location.pathname.toLowerCase();
  const toPathname = toPath.pathname.toLowerCase();
  const isPath = locationPathname === toPathname;
  const isSubPath =
    locationPathname.startsWith(toPathname) &&
    locationPathname.charAt(toPathname.length) === "/";
  return { isPath, isSubPath };
}
