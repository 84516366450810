import { useEffect, useState } from "react";
import styled from "styled-components";
import { ObjectEntries } from "../utils/utils";

const Body = styled.div<{ widthMini: string }>`
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 24px;
  padding: 2px;
  display: flex;
  gap: 2px;
  justify-content: space-around;
  user-select: none;

  @media (max-width: 600px) {
    font-size: 14px;
  }

  &.mini {
    display: none;
  }

  @media (max-width: ${({ widthMini }) => widthMini}) {
    &.normal {
      display: none;
    }
    &.mini {
      display: flex;
    }
  }

  .option {
    z-index: 1;
    cursor: pointer;
    border-radius: 24px;
    padding: 8px;
    flex-grow: 1;
    text-align: center;
  }

  position: relative;

  .electron {
    position: absolute;
    pointer-events: none;
    background-color: var(--yellow-400);
    transition: 0.2s;
  }
`;

export type Option<T extends string> = {
  id: T;
  label: string;
  miniLabel?: string;
};

type Props<T extends string> = {
  options: readonly Option<T>[];
  onOptionSelected: (optionId: T) => void;
  selectedOptionId?: T;
  widthMini?: string;
};
const NavPills = <T extends string>(props: Props<T>) => (
  <>
    <Pills {...props} />
    <Pills mini {...props} />
  </>
);

const Pills = <T extends string>({
  mini,
  options,
  onOptionSelected,
  selectedOptionId,
  widthMini = "0px",
}: Props<T> & { mini?: true }) => {
  const [refs, setRefs] = useState(
    {} as Record<T, { left: number; width: number }>
  );

  const [selectedOptionStyle, setSelectedOptionStyle] = useState<{
    left: number;
    width: number;
    display?: "none";
  }>({ left: 0, width: 0, display: "none" });

  useEffect(() => {
    if (selectedOptionId) {
      const optionStyle = ObjectEntries(refs).find(
        ([option]) => option === selectedOptionId
      )?.[1];
      if (optionStyle) {
        setSelectedOptionStyle(optionStyle);
      }
    }
  }, [refs, selectedOptionId]);

  const [tictac, setTictac] = useState(false);

  useEffect(() => {
    const interval = setTimeout(() => {
      setTictac(!tictac);
    }, 1000);
    return () => clearInterval(interval);
  }, [tictac]);

  return (
    <Body className={mini ? "mini" : "normal"} widthMini={widthMini}>
      <>
        <div className="option electron" style={selectedOptionStyle}>
          &nbsp;
        </div>
        {options.map(({ id, label, miniLabel }) => (
          <div
            ref={(el) => {
              el &&
                el.clientWidth &&
                refs[id]?.width !== el.clientWidth &&
                setRefs({
                  ...refs,
                  [id]: { left: el.offsetLeft, width: el.clientWidth },
                });
            }}
            className="option"
            key={id}
            onClick={() => onOptionSelected(id)}
          >
            {(mini && miniLabel) || label}
          </div>
        ))}
      </>
    </Body>
  );
};

export default NavPills;
