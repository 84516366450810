import { get, post } from "../lib/axios";
import { User } from "./../types/user";

export const getMe = () => {
  return get<User>("/user/me");
};

export const update = (data: Partial<User>) => {
  return post<User>("/user/update", data);
};

export const deleteMe = () => {
  return post<User>("/user/delete");
};

export const deleteBiConnections = () => {
  return post<User>("/user/deleteBiUser");
};

export const updateBiAuthToken = (authorizationCode: string) => {
  return post<User>("/user/updateBiAuthToken", { authorizationCode });
};
