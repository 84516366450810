import { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .list-title {
    margin-bottom: 8px;
  }

  .list {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 1em;
    margin-left: 8px;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      grid-row-gap: 1rem;
      & > * {
        margin-right: 0;
      }
    }
  }
`;

type Props = {
  title: string;
} & React.HTMLProps<HTMLElement>;
const TitleList: FC<Props> = ({ title, children }) => {
  return (
    <Container>
      {title && <div className="list-title">{title}</div>}
      <div className="list">{children}</div>
    </Container>
  );
};

export default TitleList;
