import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { config } from "./config/config";

if (config.env !== "development") {
  Sentry.init({
    dsn: "https://68c3fc5909914cfb8342db98ab4563e5@o4504241966088192.ingest.sentry.io/4504241988567040",
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    environment: config.env,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
