import { FC } from "react";
import styled from "styled-components";
import ChevronDownIcon from "../icons/ChevronDown";

type Props = {
  open: boolean;
  active: boolean;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
const RotatingChevronComponent: FC<Props> = ({ open, active, ...props }) => {
  return (
    <div {...props}>
      <ChevronDownIcon />
    </div>
  );
};

const RotatingChevron = styled(RotatingChevronComponent)`
  height: 100%;
  display: grid;
  place-items: center;
  svg {
    stroke: var(--white);
    transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
    transition: 0.2s;
    fill: none !important;
  }
`;

export default RotatingChevron;
