import BackButton from "../../components/buttons/BackButton";
import { useLocation } from "react-router-dom";
import Link from "../../components/Link";
import {
  Body as AccountBody,
  EditAccountButton,
} from "../my-patrimony/Account";
import styled from "styled-components";
import {
  CompletePatrimonyRoundLink,
  SectionBoxes,
} from "../my-patrimony/complete/CompletePatrimony";
import { WeUseDataFromText } from "./utils/borrowing-capacity.utils";
import { currency, percentage } from "../../utils/format.utils";
import { getMyPatrimony } from "../../services/patrimony.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { formatMyBudget, getMyBudget } from "../../services/budget.service";
import Loader from "../../components/Loader";
import {
  Box,
  Container as RiskReturnProfileContainer,
  Details,
  DetailsTab,
  SummaryContainer,
} from "./RiskReturnProfile";
import { Header, HeaderText, HeaderWrapper } from "./AmIRich";
import { getMe, update } from "../../services/user.service";
import { useEffect, useState } from "react";
import {
  turnEmptyStringsToZeros,
  UpdateUserDto,
} from "./utils/borrowing-capacity.utils";
import { ObjectEntries } from "../../utils/utils";
import BarChart from "../../components/charts/BarChart";
import { BorrowingInput, TBody } from "./BorrowingCapacity";
import { getFigures } from "./utils/future-patrimony.utils";
import { CreditTypes } from "../../types/patrimony.enums";
import { format } from "date-fns";
import { onSuccessUpdateUser } from "../../utils/auth.utils";

const StyledAccountBody = styled(AccountBody)`
/* margin-bottom: 60px; */
`;

const Container = styled(RiskReturnProfileContainer)`
  .summary-input-container {
    .input-body {
      width: 35px;
      input {
        height: 2em;
        padding-top: 0;
        padding-bottom: 0;
        padding: 0.5em;
        font-size: 15px;
        text-align: end;
      }
    }
  }

  tr.no-border-top {
    td {
      border-top: none;
    }
  }

  .edit-credit-button {
    a {
      width: min-content;
      display: block;
      margin-left: auto;
      button {
        padding: 0;
      }
    }
  }

  .text-credit {
    padding-left: 0;
    padding-right: 0;
  }
`;

const BoxesWrapper = styled.div`
  @media (max-width: 900px) {
    margin-top: 8px;
  }
`;

const BarChartBox = styled(Box)`
  margin: 24px 0;
  padding: 24px;
  @media (max-width: 1000px) {
    margin-top: 8px;
    margin-bottom: 12px;
  }
  @media (max-width: 600px) {
    padding: 8px;
    padding-top: 16px;
  }
`;

const FuturePatrimony = () => {
  const { data: myPatrimony, isLoading: patrimonyLoading } = useQuery(
    ["myPatrimony"],
    getMyPatrimony
  );

  const { data, isLoading: budgetLoading } = useQuery(["budget"], getMyBudget);
  const budget = data && formatMyBudget(data);

  const location = useLocation();
  const scrollToId = (elementId: string) => {
    const section = document.querySelector(elementId);
    section?.scrollIntoView({ behavior: "auto", block: 'start' });
  };
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      scrollToId(hash);
    }
  }, [location.hash]);

  const { data: user, isLoading: userLoading } = useQuery(["user"], getMe);
  const [changedUser, setChangedUser] = useState<UpdateUserDto>({});
  const { mutateAsync: tryUpdate } = useMutation({
    mutationFn: update,
    onSuccess: onSuccessUpdateUser,
    onError: (e) => console.error(e),
    onSettled: (res, error, reqData) =>
      ObjectEntries(reqData).forEach(
        ([k, v]) =>
          k in changedUser &&
          v !== undefined &&
          delete changedUser[k as keyof UpdateUserDto]
      ),
  });

  const validate = (userToChange?: UpdateUserDto) => {
    if (userToChange) {
      tryUpdate(turnEmptyStringsToZeros(userToChange));
      return;
    }
    if (!ObjectEntries(changedUser).some(([k, v]) => user?.[k] !== v)) return;
    tryUpdate(turnEmptyStringsToZeros(changedUser));
  };

  const inputProps = user && { user, changedUser, setChangedUser, validate };

  const isLoading = patrimonyLoading || budgetLoading || userLoading;

  const isReady = user && budget && myPatrimony;

  const figures = isReady && getFigures(user, budget, myPatrimony);

  const ans = user?.futureYears === 1 ? "an" : "ans";


  return (
    <StyledAccountBody>
      <HeaderWrapper>
        <Header>
          <Link to="/outils-analyse">
            <BackButton />
          </Link>
          <div className="title">
            Quelle sera la valeur de mon patrimoine dans le futur ?
          </div>
          <CompletePatrimonyRoundLink className="desktop-only" />
        </Header>
        <HeaderText>
          <WeUseDataFromText budget />
        </HeaderText>
      </HeaderWrapper>
      <Container>
        {isLoading ? (
          <Loader />
        ) : !isReady || !figures || !inputProps ? (
          <div>Erreur</div>
        ) : (
          <>
            <SummaryContainer>
              <BoxesWrapper>
                <Box className="summary-input-container">
                  Dans&nbsp;
                  <BorrowingInput
                    {...{ userProp: "futureYears", ...inputProps }}
                    step={1}
                    min={1}
                    max={50}
                    maxLength={2}
                  />
                  &nbsp;{ans}, mon patrimoine net pourrait atteindre{" "}
                  <strong>{currency(figures.patrimoineNetFutur)}</strong>
                </Box>
                <SectionBoxes>
                  <Box>
                    Patrimoine brut de{" "}
                    <strong>{currency(figures.creditsFuturs + figures.patrimoineNetFutur)}</strong>
                  </Box>
                  <Box>
                    Endettement de{" "}
                    <strong>
                      {currency(figures.creditsFuturs)}
                    </strong>
                  </Box>
                </SectionBoxes>
              </BoxesWrapper>
              <BarChartBox>{<BarChart data={figures.chartData} />}</BarChartBox>
            </SummaryContainer>
            <Details>
              <div className="text">Détail de nos calculs</div>
              <div className="table-container">
                <DetailsTab>
                  <TBody>
                    <tr>
                      <td>Patrimoine net actuel</td>
                      <td>{currency(myPatrimony.totalNet)}</td>
                    </tr>
                    <tr>
                      <td>Épargne supplémentaire</td>
                      <td>{currency(figures.epargneSupp)}</td>
                    </tr>
                    <tr className="no-border-top">
                      <td>Remboursement des crédits</td>
                      <td>{currency(figures.remboursementDesCredits)}</td>
                    </tr>
                    <tr className="no-border-top">
                      <td>Revalorisation de votre patrimoine</td>
                      <td>{currency(figures.revalorisationPatrimoine)}</td>
                    </tr>
                    <tr>
                      <td>Patrimoine net futur</td>
                      <td className="bold-600">
                        {currency(figures.patrimoineNetFutur)}
                      </td>
                    </tr>
                  </TBody>
                </DetailsTab>

                <DetailsTab>
                  <TBody>
                    <tr>
                      <td>Capacité d'épargne mensuelle</td>
                      <td>{currency(figures.capaciteEpargneMensuelle)}</td>
                    </tr>
                    <tr className="no-border-top">
                      <td>Nombre de mois</td>
                      <td>{figures.nombreDeMois}</td>
                    </tr>
                    <tr className="no-border-top">
                      <td>
                        Épargne supplémentaire après remboursement des emprunts
                      </td>
                      <td>{currency(figures.epargneApresMaturitePrets)}</td>
                    </tr>
                    <tr className="no-border-top">
                      <td>Remboursement prêts in fine</td>
                      <td>{currency(figures.remboursementsInfine)}</td>
                    </tr>
                    <tr>
                      <td>Épargne supplémentaire</td>
                      <td className="bold-600">
                        {currency(figures.epargneSupp)}
                      </td>
                    </tr>
                  </TBody>
                </DetailsTab>

                <DetailsTab>
                  <TBody>
                    <tr>
                      <td>Crédits actuels</td>
                      <td>{currency(figures.creditsActuels)}</td>
                    </tr>
                    <tr className="no-border-top">
                      <td>Crédits futurs</td>
                      <td>{currency(figures.creditsFuturs)}</td>
                    </tr>
                    <tr>
                      <td>Remboursement des crédits</td>
                      <td className="bold-600">
                        {currency(figures.remboursementDesCredits)}
                      </td>
                    </tr>
                  </TBody>
                </DetailsTab>

                <DetailsTab>
                  <TBody>
                    <tr>
                      <td>Patrimoine brut actuel</td>
                      <td>{currency(myPatrimony.totalGross)}</td>
                    </tr>
                    <tr className="no-border-top">
                      <td>Rendement moyen annuel après impôts</td>
                      <td>{percentage(figures.annualYieldAv)}</td>
                    </tr>
                    <tr className="no-border-top">
                      <td>Nombre d'années</td>
                      <td>
                        {user.futureYears}&nbsp;{ans}
                      </td>
                    </tr>
                    <tr>
                      <td>Revalorisation de votre patrimoine</td>
                      <td className="bold-600">
                        {currency(figures.revalorisationPatrimoine)}
                      </td>
                    </tr>
                  </TBody>
                </DetailsTab>
                {!!figures.credits.length && (
                  <>
                    <div className="text text-credit">Crédits en cours</div>
                    {figures.credits.map((credit) => (
                      <DetailsTab key={credit.id} id={`credit_${credit.id}`}>
                        <thead>
                          <tr>
                            <th>{credit.name}</th>
                            <th className="edit-credit-button">
                              <EditAccountButton
                                frType="credit"
                                id={credit.id}
                                fromFuturePatrimony
                              />
                            </th>
                          </tr>
                        </thead>
                        <TBody>
                          <tr>
                            <td>Montant</td>
                            <td>{currency(-credit.eurValue)}</td>
                          </tr>
                          <tr className="no-border-top">
                            <td>Durée résiduelle (mois)</td>
                            <td>
                              {credit.dureeResiduelle
                                ? credit.dureeResiduelle
                                : "Incomplet"}
                            </td>
                          </tr>
                          <tr className="no-border-top">
                            <td>Date de fin</td>
                            <td>
                              {credit.dateFin
                                ? format(credit.dateFin, "dd/MM/yy")
                                : "Incomplet"}
                            </td>
                          </tr>
                          <tr className="no-border-top">
                            <td>Taux hors assurance</td>
                            <td>
                              {credit.interestRate
                                ? percentage(credit.interestRate)
                                : "Incomplet"}
                            </td>
                          </tr>
                          <tr className="no-border-top">
                            <td>Mensualité</td>
                            <td>
                              {credit.monthlyPayment
                                ? currency(credit.monthlyPayment)
                                : "Incomplet"}
                            </td>
                          </tr>
                          <tr className="no-border-top">
                            <td>Type de crédit</td>
                            <td>{CreditTypes[credit.type!]}</td>
                          </tr>
                          <tr>
                            <td>Montant du crédit dans le futur</td>
                            <td className="bold-600">
                              {credit.futureEurValue !== false
                                ? currency(credit.futureEurValue)
                                : "Incomplet"}
                            </td>
                          </tr>
                        </TBody>
                      </DetailsTab>
                    ))}
                  </>
                )}
              </div>
            </Details>
          </>
        )}
      </Container>
    </StyledAccountBody>
  );
};

export default FuturePatrimony;
