import {
  assetsFrom,
  formatMyRiskedPatrimony,
  MyPatrimony,
} from "../../../types/patrimony";
import { MyBudget } from "../../../types/budget";
import { User } from "../../../types/user";
import { sum } from "../../../utils/math.utils";
import { BarChartProps } from "../../../components/charts/BarChart";

export function getFigures(
  user: User,
  budget: MyBudget,
  myPatrimony: MyPatrimony
) {
  const rawCredits = assetsFrom(myPatrimony.sections.CREDITS.categories);
  const nombreDeMois = user.futureYears * 12;

  const credits = rawCredits.map((x) => {
    const isComplete = [
      x.maturityDate,
      x.interestRate,
      x.remainingMaturities,
      x.monthlyPayment,
    ].every((p) => p !== null);
    const dureeResiduelle = !!x.remainingMaturities && x.remainingMaturities;

    const dateFin = !!x.maturityDate && new Date(x.maturityDate);

    const moisDepuisMaturite =
      dureeResiduelle !== false && nombreDeMois - dureeResiduelle;

    const futureEurValue =
      isComplete &&
      moisDepuisMaturite !== false &&
      (moisDepuisMaturite >= 0
        ? 0
        : FV(
            x.interestRate! / 12,
            nombreDeMois,
            x.monthlyPayment!,
            x.eurValue
          ));

    const epargneApresMaturite =
      x.monthlyPayment !== null &&
      moisDepuisMaturite > 0 &&
      (moisDepuisMaturite || 0) * x.monthlyPayment!;

    return {
      ...x,
      isComplete,
      dureeResiduelle,
      dateFin,
      interestRate: x.interestRate !== null && x.interestRate,
      monthlyPayment: x.monthlyPayment !== null && x.monthlyPayment,
      futureEurValue,
      epargneApresMaturite,
      moisDepuisMaturite,
    };
  });

  const capaciteEpargneMensuelle = budget.capacity;
  const remboursementsInfine = sum(
    credits
      .filter((x) => x.type === "IN_FINE" && x.moisDepuisMaturite >= 0)
      .map((x) => (x.isComplete ? x.eurValue : 0))
  );
  const epargneApresMaturitePrets = sum(
    credits.map((x) => x.epargneApresMaturite || 0)
  );
  const epargneSupp =
    capaciteEpargneMensuelle * nombreDeMois +
    remboursementsInfine +
    epargneApresMaturitePrets;

  const creditsActuels = -myPatrimony.sections.CREDITS.total;
  const creditsFuturs = sum(
    credits.map((x) =>
      x.isComplete ? (x.futureEurValue as number) : -x.eurValue
    )
  );
  const remboursementDesCredits = creditsActuels - creditsFuturs;

  const { annualYieldAv } = formatMyRiskedPatrimony(myPatrimony);
  const revalorisationPatrimoine =
    myPatrimony.totalGross *
    (Math.pow(1 + annualYieldAv, user.futureYears) - 1);

  const patrimoineNetFutur =
    myPatrimony.totalNet +
    epargneSupp +
    remboursementDesCredits +
    revalorisationPatrimoine;

  const chartData: BarChartProps["data"] = [
    {
      id: "epargneSupp",
      label: "Épargne supplémentaire",
      value: epargneSupp,
      colors: ["#FFAB00", "#C78500", "#E89B00"],
    },
    {
      id: "remboursementDesCredits",
      label: "Remboursement des crédits",
      value: remboursementDesCredits,
      incomplete: credits.some((x) => !x.isComplete),
      colors: ["#FFD100", "#D2AC00", "#ECC100"],
    },
    {
      id: "revalorisationPatrimoine",
      label: "Revalorisation de votre patrimoine",
      value: revalorisationPatrimoine,
      colors: ["#FFE32B", "#CCB100", "#F0D000"],
    },
  ];

  return {
    // capacité épargne
    capaciteEpargneMensuelle,
    nombreDeMois,
    epargneApresMaturitePrets,
    remboursementsInfine,
    epargneSupp,
    // credits
    creditsActuels,
    remboursementDesCredits,
    creditsFuturs,
    // patrimoine brut actuel
    annualYieldAv,
    revalorisationPatrimoine,
    // patrimoine net et actuel
    patrimoineNetFutur,
    credits,
    chartData,
  } as const;
}

/**
 * Calcul de la valeur future d'un emprunt
 * https://stackoverflow.com/a/17195572
 * @param ir   - interest rate per month
 * @param np   - number of periods (months)
 * @param pmt  - monthly payment
 * @param pv   - present value (negative)
 * @param type - when the payments are due:
 *        0: end of the period, e.g. end of month
 *        1: beginning of period (default)
 */
function FV(ir: number, np: number, pmt: number, pv: number, type = 1) {
  if (ir) {
    const pow = Math.pow(1 + ir, np);
    return (pmt * (1 + ir * type) * (1 - pow)) / ir - pv * pow;
  } else {
    return -1 * (pv + pmt * np);
  }
}
