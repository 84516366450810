interface Config {
  env: "development" | "staging" | "production";
  apiUrl: string;
  bucketUrl: string;
  bi: {
    apiUrl: string;
    clientId: number;
    redirectUri: string;
    redirectReconnectUri: string;
  };
}

const devConfig: Config = {
  env: "development",
  apiUrl: "http://localhost:3001/api", // "http://192.168.1.97:3001/api", //
  bucketUrl: "https://s3.eu-west-3.amazonaws.com/arpagon-medias/",
  bi: {
    apiUrl: "https://arpagon-sandbox.biapi.pro/2.0",
    clientId: 48995363,
    redirectUri: "http://localhost:3000/patrimoine?refetch=true",
    redirectReconnectUri: "http://localhost:3000",
  },
};

const stagingConfig: Config = {
  env: "staging",
  apiUrl: "https://api.staging.arpagon.fr/api",
  bucketUrl: "https://s3.eu-west-3.amazonaws.com/arpagon-medias/",
  bi: {
    apiUrl: "https://arpagon-sandbox.biapi.pro/2.0",
    clientId: 48995363,
    redirectUri: "https://staging.arpagon.fr/patrimoine?refetch=true",
    redirectReconnectUri: "https://staging.arpagon.fr",
  },
};

const prodConfig: Config = {
  env: "production",
  apiUrl: "https://api.arpagon.fr/api",
  bucketUrl: "https://s3.eu-west-3.amazonaws.com/arpagon-medias/",
  bi: {
    apiUrl: "https://arpagon.biapi.pro/2.0",
    clientId: 2380466,
    redirectUri: "https://app.arpagon.fr/patrimoine?refetch=true",
    redirectReconnectUri: "https://app.arpagon.fr",
  },
};

export const config =
  process.env.REACT_APP_ENV === "production"
    ? prodConfig
    : process.env.REACT_APP_ENV === "staging"
    ? stagingConfig
    : devConfig;
