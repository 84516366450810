import { useQuery } from "@tanstack/react-query";
import Login from "./pages/authentication/Login";
import { getMe } from "./services/user.service";
import Loader from "./components/Loader";
import { Routes, Route, Navigate } from "react-router-dom";
import Signup from "./pages/authentication/Signup";
import CompleteProfile from "./pages/authentication/CompleteProfile";
import AppLayout from "./pages/AppLayout";
import ConfirmEmail from "./pages/authentication/ConfirmEmail";
import PasswordForgotten from "./pages/authentication/PasswordForgotten";
import PasswordRecovery from "./pages/authentication/PasswordRecovery";
import Cgu from "./pages/profile/Cgu";
import MentionsLegales from "./pages/profile/MentionLegales";
import PolitiqueConfidentialite from "./pages/profile/PolitiqueConfidentialite";

import ExportRouter from "./pages/exports/ExportRouter";
import { useEffect, useRef } from "react";
import { queryClient } from "./lib/react-query";
import { syncBiAccounts } from "./services/patrimony.service";
import { getSignupStep } from "./utils/storage.utils";
import { logout } from "./utils/auth.utils";

const MainRouter = () => {
  const firstLaunch = useRef(true);
  const { data: user, isLoading } = useQuery(["user"], getMe);
  const {
    refetch: refetchSync,
  } = useQuery(["syncBiAccounts"], syncBiAccounts, {
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      queryClient.invalidateQueries(["connections"]);
    },
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: 0,
  });

  useEffect(() => {
    if (refetchSync && user && user.state !== "EMAIL_NOT_CONFIRMED" && user.state !== "PROFILE_NOT_COMPLETED") {
      refetchSync();
    }
  }, [user, refetchSync]);

  useEffect(() => {
    if (firstLaunch.current) {
      if (
        user && 
        ["EMAIL_NOT_CONFIRMED", "PROFILE_NOT_COMPLETED"].includes(user.state)
      ) {
        firstLaunch.current = false;
        // On set signupstep en arrivant sur l'une des deux pages 
        // email not confirmed ou profile not completed
        // On le delete sur ces deux mêmes pages si l'étape est complétée.
        // Donc si signupstep existe au chargement initial de l'app, on logout
        // Pour ne pas "bloquer" l'utilisateur sur ces pages si il veut créer ou se logger avec un autre compte.
        const signupStep = getSignupStep();
        if (signupStep) {
          logout();
        }
      }
    }
  }, [user]);

  return isLoading ? (
    /* Splash Screen */
    <div>
      <Loader />
    </div>
  ) : (
    <Routes>
      <Route path="/cgu" element={<Cgu />} />
      <Route path="/mentions-legales" element={<MentionsLegales />} />
      <Route
        path="/politique-confidentialite"
        element={<PolitiqueConfidentialite />}
      />
      {!user ? (
        <>
          <Route path="/connexion" element={<Login />} />
          <Route path="/inscription" element={<Signup />} />
          <Route path="/mot-de-passe-oublie" element={<PasswordForgotten />} />
          <Route
            path="/reinitialisation-mot-de-passe/:token"
            element={<PasswordRecovery />}
          />
          <Route path="/confirmation-email/:token" element={<ConfirmEmail />} />
          <Route path="*" element={<Navigate to="/connexion" replace />} />
        </>
      ) : user.state === "EMAIL_NOT_CONFIRMED" ? (
        <>
          <Route
            path="/confirmation-email"
            element={<ConfirmEmail user={user} />}
          />
          <Route
            path="/confirmation-email/:token"
            element={<ConfirmEmail user={user} />}
          />
          <Route
            path="*"
            element={<Navigate to="/confirmation-email" replace />}
          />
        </>
      ) : user.state === "PROFILE_NOT_COMPLETED" ? (
        <>
          <Route path="/completer-profil" element={<CompleteProfile />} />
          <Route
            path="*"
            element={<Navigate to="/completer-profil" replace />}
          />
        </>
      ) : (
        <>
          <Route path="*" element={<AppLayout />} />
          <Route path="/export/*" element={<ExportRouter />} />
        </>
      )}
    </Routes>
  );
};

export default MainRouter;
