const LogoAlternative = () => (
  <svg
    width="117"
    height="24"
    viewBox="0 0 117 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="logo-alternative"
  >
    <path
      d="M11.8649 14.1986H5.42886L4.39713 17.2446H0L6.23951 0H11.1034L17.3429 17.2446H12.8966L11.8649 14.1986ZM10.784 10.956L8.64688 4.64278L6.53429 10.956H10.784Z"
      fill="white"
    />
    <path
      d="M23.4425 5.8219C23.9338 5.06858 24.5479 4.47902 25.2849 4.05322C26.0218 3.61105 26.8406 3.38997 27.7414 3.38997V7.83623H26.5868C25.5387 7.83623 24.7526 8.06551 24.2286 8.52405C23.7045 8.96622 23.4425 9.7523 23.4425 10.8823V17.2446H19.2419V3.53736H23.4425V5.8219Z"
      fill="white"
    />
    <path
      d="M33.9498 5.47799C34.3592 4.8393 34.9242 4.32344 35.6448 3.9304C36.3653 3.53736 37.2087 3.34084 38.175 3.34084C39.305 3.34084 40.3285 3.62743 41.2456 4.20061C42.1627 4.7738 42.8833 5.59263 43.4073 6.65711C43.9477 7.7216 44.2179 8.95803 44.2179 10.3664C44.2179 11.7748 43.9477 13.0194 43.4073 14.1003C42.8833 15.1648 42.1627 15.9918 41.2456 16.5814C40.3285 17.1546 39.305 17.4411 38.175 17.4411C37.2251 17.4411 36.3817 17.2446 35.6448 16.8516C34.9242 16.4585 34.3592 15.9509 33.9498 15.3286V23.7789H29.7492V3.53736H33.9498V5.47799ZM39.9436 10.3664C39.9436 9.31832 39.6489 8.49949 39.0593 7.90993C38.4861 7.30399 37.7737 7.00102 36.9221 7.00102C36.0869 7.00102 35.3746 7.30399 34.785 7.90993C34.2118 8.51586 33.9252 9.34289 33.9252 10.391C33.9252 11.4391 34.2118 12.2661 34.785 12.8721C35.3746 13.478 36.0869 13.781 36.9221 13.781C37.7574 13.781 38.4697 13.478 39.0593 12.8721C39.6489 12.2497 39.9436 11.4145 39.9436 10.3664Z"
      fill="white"
    />
    <path
      d="M45.5865 10.3664C45.5865 8.95803 45.8485 7.7216 46.3726 6.65711C46.913 5.59263 47.6418 4.7738 48.5589 4.20061C49.4759 3.62743 50.4995 3.34084 51.6295 3.34084C52.5957 3.34084 53.4391 3.53736 54.1597 3.9304C54.8966 4.32344 55.4616 4.8393 55.8547 5.47799V3.53736H60.0553V17.2446H55.8547V15.304C55.4452 15.9427 54.8721 16.4585 54.1351 16.8516C53.4145 17.2446 52.5711 17.4411 51.6049 17.4411C50.4913 17.4411 49.4759 17.1546 48.5589 16.5814C47.6418 15.9918 46.913 15.1648 46.3726 14.1003C45.8485 13.0194 45.5865 11.7748 45.5865 10.3664ZM55.8547 10.391C55.8547 9.34289 55.5599 8.51586 54.9703 7.90993C54.3971 7.30399 53.6929 7.00102 52.8577 7.00102C52.0225 7.00102 51.3101 7.30399 50.7206 7.90993C50.1474 8.49949 49.8608 9.31832 49.8608 10.3664C49.8608 11.4145 50.1474 12.2497 50.7206 12.8721C51.3101 13.478 52.0225 13.781 52.8577 13.781C53.6929 13.781 54.3971 13.478 54.9703 12.8721C55.5599 12.2661 55.8547 11.4391 55.8547 10.391Z"
      fill="white"
    />
    <path
      d="M68.302 3.34084C69.2682 3.34084 70.1116 3.53736 70.8322 3.9304C71.5692 4.32344 72.1341 4.8393 72.5272 5.47799V3.53736H76.7278V17.2201C76.7278 18.4811 76.474 19.6192 75.9663 20.6346C75.475 21.6663 74.7135 22.4852 73.6817 23.0911C72.6664 23.697 71.3972 24 69.8742 24C67.8435 24 66.1976 23.5169 64.9366 22.5507C63.6756 21.6008 62.955 20.3071 62.7749 18.6694H66.9264C67.0574 19.1935 67.3685 19.6029 67.8598 19.8976C68.3511 20.2088 68.9571 20.3644 69.6777 20.3644C70.5456 20.3644 71.2334 20.1105 71.7411 19.6029C72.2652 19.1116 72.5272 18.3173 72.5272 17.2201V15.2794C72.1178 15.9181 71.5528 16.4422 70.8322 16.8516C70.1116 17.2446 69.2682 17.4411 68.302 17.4411C67.172 17.4411 66.1485 17.1546 65.2314 16.5814C64.3143 15.9918 63.5855 15.1648 63.0451 14.1003C62.521 13.0194 62.259 11.7748 62.259 10.3664C62.259 8.95803 62.521 7.7216 63.0451 6.65711C63.5855 5.59263 64.3143 4.7738 65.2314 4.20061C66.1485 3.62743 67.172 3.34084 68.302 3.34084ZM72.5272 10.391C72.5272 9.34289 72.2324 8.51586 71.6429 7.90993C71.0697 7.30399 70.3655 7.00102 69.5303 7.00102C68.6951 7.00102 67.9827 7.30399 67.3931 7.90993C66.8199 8.49949 66.5333 9.31832 66.5333 10.3664C66.5333 11.4145 66.8199 12.2497 67.3931 12.8721C67.9827 13.478 68.6951 13.781 69.5303 13.781C70.3655 13.781 71.0697 13.478 71.6429 12.8721C72.2324 12.2661 72.5272 11.4391 72.5272 10.391Z"
      fill="white"
    />
    <path
      d="M86.0063 17.4411C84.6634 17.4411 83.4515 17.1546 82.3707 16.5814C81.3062 16.0082 80.4628 15.1894 79.8405 14.1249C79.2345 13.0604 78.9315 11.8158 78.9315 10.391C78.9315 8.9826 79.2427 7.74616 79.865 6.68168C80.4873 5.60082 81.3389 4.7738 82.4198 4.20061C83.5006 3.62743 84.7125 3.34084 86.0554 3.34084C87.3983 3.34084 88.6102 3.62743 89.691 4.20061C90.7719 4.7738 91.6235 5.60082 92.2458 6.68168C92.8681 7.74616 93.1792 8.9826 93.1792 10.391C93.1792 11.7994 92.8599 13.044 92.2212 14.1249C91.5989 15.1894 90.7391 16.0082 89.6419 16.5814C88.561 17.1546 87.3492 17.4411 86.0063 17.4411ZM86.0063 13.8055C86.8087 13.8055 87.4884 13.5107 88.0452 12.9212C88.6183 12.3316 88.9049 11.4882 88.9049 10.391C88.9049 9.29376 88.6265 8.45036 88.0697 7.8608C87.5293 7.27124 86.8579 6.97646 86.0554 6.97646C85.2366 6.97646 84.5569 7.27124 84.0165 7.8608C83.4761 8.43398 83.2059 9.27738 83.2059 10.391C83.2059 11.4882 83.4679 12.3316 83.9919 12.9212C84.5324 13.5107 85.2038 13.8055 86.0063 13.8055Z"
      fill="white"
    />
    <path
      d="M103.76 3.38997C105.365 3.38997 106.642 3.91402 107.592 4.96213C108.558 5.99386 109.041 7.41863 109.041 9.23644V17.2446H104.865V9.80143C104.865 8.88434 104.628 8.17196 104.153 7.66428C103.678 7.1566 103.039 6.90276 102.237 6.90276C101.434 6.90276 100.796 7.1566 100.321 7.66428C99.8458 8.17196 99.6084 8.88434 99.6084 9.80143V17.2446H95.4078V3.53736H99.6084V5.35517C100.034 4.74923 100.607 4.27431 101.328 3.9304C102.048 3.57011 102.859 3.38997 103.76 3.38997Z"
      fill="white"
    />
    <path
      d="M113.926 17.4411C113.189 17.4411 112.583 17.2283 112.108 16.8025C111.649 16.3603 111.42 15.8199 111.42 15.1812C111.42 14.5261 111.649 13.9775 112.108 13.5353C112.583 13.0931 113.189 12.8721 113.926 12.8721C114.646 12.8721 115.236 13.0931 115.694 13.5353C116.169 13.9775 116.407 14.5261 116.407 15.1812C116.407 15.8199 116.169 16.3603 115.694 16.8025C115.236 17.2283 114.646 17.4411 113.926 17.4411Z"
      fill="white"
    />
    <path
      d="M93.2764 10.3741C93.2764 14.3806 90.0285 17.6285 86.022 17.6285C82.0155 17.6285 78.7677 14.3806 78.7677 10.3741C78.7677 6.36764 82.0155 3.11976 86.022 3.11976C90.0285 3.11976 93.2764 6.36764 93.2764 10.3741Z"
      fill="#FFE32B"
    />
  </svg>
);

export default LogoAlternative;
