const HomeStarIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M8.6866 15.2351L8.66993 15.2201C8.32776 14.8998 8.08932 14.4844 7.98536 14.0274C7.88139 13.5704 7.91665 13.0927 8.0866 12.6559C8.26136 12.2363 8.55731 11.8784 8.9366 11.628C9.31589 11.3775 9.76127 11.246 10.2158 11.2501H11.3416C11.4228 11.2502 11.5022 11.2266 11.5702 11.1822C11.6381 11.1378 11.6916 11.0745 11.7241 11.0001L12.4608 9.29422L12.4716 9.27089C12.6593 8.86845 12.9574 8.52755 13.3312 8.28783C13.705 8.04812 14.1392 7.91943 14.5833 7.91673C15.0106 7.91524 15.4299 8.032 15.795 8.25409C16.16 8.47617 16.4565 8.79492 16.6516 9.17506L16.6633 9.20089L16.9899 9.95589C17.0141 10.012 17.0569 10.058 17.1111 10.0861C17.1653 10.1143 17.2275 10.1228 17.2873 10.1104C17.3471 10.0979 17.4007 10.0652 17.4391 10.0178C17.4776 9.97037 17.4985 9.91111 17.4983 9.85006C17.4983 9.81519 17.5051 9.78066 17.5185 9.74845C17.5319 9.71625 17.5515 9.687 17.5762 9.66238C17.6009 9.63776 17.6302 9.61826 17.6624 9.60499C17.6947 9.59172 17.7292 9.58495 17.7641 9.58506H19.5833C19.6668 9.58526 19.7485 9.56034 19.8177 9.51352C19.8869 9.4667 19.9405 9.40015 19.9714 9.32252C20.0023 9.24489 20.0092 9.15975 19.9911 9.07818C19.973 8.9966 19.9308 8.92235 19.8699 8.86506L10.8574 0.340891C10.6254 0.121955 10.3185 0 9.99952 0C9.68052 0 9.3736 0.121955 9.1416 0.340891L0.130766 8.86339C0.0699857 8.92063 0.0278025 8.99481 0.00968497 9.07631C-0.00843254 9.15781 -0.00164875 9.24287 0.0291568 9.32047C0.0599624 9.39806 0.113369 9.46462 0.182452 9.5115C0.251536 9.55838 0.33311 9.58343 0.416599 9.58339H2.08327C2.19377 9.58339 2.29975 9.62729 2.37789 9.70543C2.45603 9.78357 2.49993 9.88955 2.49993 10.0001V18.3334C2.49993 18.5544 2.58773 18.7664 2.74401 18.9226C2.90029 19.0789 3.11225 19.1667 3.33327 19.1667H8.62243C8.7282 19.167 8.8301 19.127 8.90748 19.0549C8.98485 18.9828 9.03191 18.8839 9.0391 18.7784C9.0571 18.5132 9.12052 18.253 9.2266 18.0092L9.82493 16.6334C9.85997 16.5529 9.86868 16.4634 9.84983 16.3776C9.83097 16.2919 9.78551 16.2143 9.71993 16.1559L8.6866 15.2351Z"
        fill="#FFE32B"
      />
      <path
        d="M19.9242 13.1333C19.8448 12.9417 19.7092 12.7786 19.5353 12.6656C19.3614 12.5525 19.1574 12.4948 18.95 12.5H17C16.9188 12.5001 16.8394 12.4765 16.7714 12.4321C16.7035 12.3877 16.65 12.3244 16.6175 12.25L15.555 9.775L15.5475 9.75833L15.5392 9.74166C15.447 9.56635 15.3082 9.41985 15.1381 9.31826C14.9681 9.21667 14.7733 9.16393 14.5752 9.16582C14.3772 9.16772 14.1834 9.22418 14.0154 9.329C13.8473 9.43382 13.7113 9.58295 13.6225 9.76C13.6174 9.76882 13.6129 9.77802 13.6092 9.7875L12.5458 12.25C12.5134 12.3244 12.4599 12.3877 12.3919 12.4321C12.324 12.4765 12.2445 12.5001 12.1633 12.5H10.2158C10.0088 12.495 9.80517 12.5528 9.63171 12.6659C9.45824 12.7789 9.32312 12.9419 9.24417 13.1333C9.1671 13.3292 9.15027 13.5436 9.19585 13.7491C9.24143 13.9546 9.34734 14.1418 9.5 14.2867L9.51917 14.3042L11.2258 15.8258C11.2912 15.8842 11.3366 15.9616 11.3554 16.0472C11.3743 16.1328 11.3657 16.2221 11.3308 16.3025L10.3717 18.5083C10.2811 18.7116 10.2577 18.9384 10.3049 19.1559C10.3521 19.3734 10.4675 19.5701 10.6342 19.7175C10.8014 19.8711 11.0133 19.9674 11.239 19.9923C11.4647 20.0172 11.6925 19.9694 11.8892 19.8558L14.38 18.4533C14.4424 18.4181 14.5129 18.3996 14.5846 18.3996C14.6563 18.3996 14.7267 18.4181 14.7892 18.4533L17.28 19.855C17.4764 19.969 17.7041 20.0171 17.9298 19.9922C18.1556 19.9673 18.3673 19.8707 18.5342 19.7167C18.7009 19.5695 18.8164 19.373 18.8638 19.1556C18.9111 18.9383 18.8879 18.7115 18.7975 18.5083L17.8375 16.3C17.8027 16.2196 17.794 16.1303 17.8129 16.0447C17.8318 15.9591 17.8771 15.8817 17.9425 15.8233L19.6667 14.2892L19.6867 14.2708C19.8336 14.1255 19.9346 13.9402 19.9768 13.7379C20.019 13.5356 20.0007 13.3253 19.9242 13.1333Z"
        fill="#FFE32B"
      />
    </g>
  </svg>
);

export default HomeStarIcon;
