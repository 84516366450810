const SignalIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99992 0.833252C4.93731 0.833252 0.833252 4.93731 0.833252 9.99992C0.833252 15.0625 4.93731 19.1666 9.99992 19.1666C15.0625 19.1666 19.1666 15.0625 19.1666 9.99992C19.1666 4.93731 15.0625 0.833252 9.99992 0.833252ZM14.1666 5.83325C14.1666 5.37302 13.7935 4.99992 13.3333 4.99992C12.873 4.99992 12.4999 5.37302 12.4999 5.83325V14.1666C12.4999 14.6268 12.873 14.9999 13.3333 14.9999C13.7935 14.9999 14.1666 14.6268 14.1666 14.1666V5.83325ZM9.99992 8.33325C10.4602 8.33325 10.8333 8.70633 10.8333 9.16658V14.1666C10.8333 14.6268 10.4602 14.9999 9.99992 14.9999C9.53967 14.9999 9.16658 14.6268 9.16658 14.1666V9.16658C9.16658 8.70633 9.53967 8.33325 9.99992 8.33325ZM6.66658 11.6666C7.12682 11.6666 7.49992 12.0397 7.49992 12.4999V14.1666C7.49992 14.6268 7.12682 14.9999 6.66658 14.9999C6.20635 14.9999 5.83325 14.6268 5.83325 14.1666V12.4999C5.83325 12.0397 6.20635 11.6666 6.66658 11.6666Z"
    />
  </svg>
);

export default SignalIcon;
