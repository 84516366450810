import { get, post } from "../lib/axios";
import { CreateMeeting, Meeting } from "../types/meeting.enums";
import { getJwt } from "../utils/storage.utils";

export const createMeeting = (meeting: CreateMeeting) => {
  return post<Meeting>("/meeting", { ...meeting });
};

export const getMeetings = () => {
  return get<Meeting[]>("/meeting");
};

export const getMeeting = (id: number) => {
  return get<Meeting>(`/meeting/${id}`);
};

export const sendEmailMeeting = (
  budget: boolean,
  patrimony: boolean,
  meeting: Meeting
) => {
  return post("/meeting/sendMeetingDocuments", {
    budget,
    patrimony,
    jwt: getJwt(),
    meeting: meeting,
  });
};

export const cancelMeeting = (data: {
  id_meeting: string;
  idAdvisor: number;
}) => {
  return post("/meeting/cancel", {
    eventId: data.id_meeting,
    advisorId: data.idAdvisor,
  });
};
