export interface Connection {
  id: number;
  state?: ConnectionState;
  /** Datetime Europe/Paris */
  last_update: string;
}

type ConnectionState = keyof typeof ConnectionStates;

const ConnectionStates = {
  /**	An SCA process must be performed to resume the synchronization process. */
  SCARequired: "SCARequired",

  /**	A web-based authentication process is required using the /webauth endpoint. */
  webauthRequired: "webauthRequired",

  /**	Additional information is needed to resume synchronization, such as an OTP. Connections in this state have a fields property. */
  additionalInformationNeeded: "additionalInformationNeeded",

  /**	User validation is required on a third-party app or device (ex: digital key). */
  decoupled: "decoupled",

  /**	User validation is being processed on our side. This state is temporary. */
  validating: "validating",

  /**	An action is needed on the website by the user, synchronization is blocked. */
  actionNeeded: "actionNeeded",

  /**	The password has expired and needs to be changed by the user before the synchronization can be retried. */
  passwordExpired: "passwordExpired",

  /**	The authentication on website has failed and new credentials must be obtained from the user. Connections in this state have a fields property. */
  wrongpass: "wrongpass",

  /**	The target website or API is temporarily blocking synchronizations due to rate limiting. */
  rateLimiting: "rateLimiting",

  /**	The connector website or API is unavailable. */
  websiteUnavailable: "websiteUnavailable",

  /**	An internal error has occurred during the synchronization. */
  bug: "bug",

  /**	The source is not supported on the connector. */
  notSupported: "notSupported",
};

export const ConnectionStateMessage: Record<ConnectionState, string> = {
  SCARequired: "Votre banque réclame une nouvelle authentification forte.",
  webauthRequired:
    "Votre banque réclame une nouvelle authentification web forte.",
  additionalInformationNeeded: "",
  decoupled:
    "Une validation est requise sur une application ou un appareil tiers.",
  validating: "En cours de validation. Vous n'avez pas d'action à effectuer.",
  actionNeeded:
    "Une action est requise sur votre site ou application bancaire.",
  passwordExpired:
    "Le mot de passe a expiré. Vous devez le renouveler sur votre site ou application bancaire avant de le mettre à jour ici.",
  wrongpass: "Mot de passe erroné.",
  rateLimiting:
    "La connexion sera rétablie automatiquement. Vous n'avez pas d'action à effectuer.",
  websiteUnavailable:
    "La connexion avec l'institution bancaire est momentanément indisponible. Nous la rétablirons dès que possible.",
  bug: "Nous rétablirons automatiquement la connexion. Vous n'avez aucune action à effectuer.",
  notSupported: "L'institution bancaire n'est plus prise en charge.",
};
