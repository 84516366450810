import BackButton from "../../components/buttons/BackButton";
import Link from "../../components/Link";
import { Body as AccountBody } from "../my-patrimony/Account";
import styled from "styled-components";
import {
  CompletePatrimonyRoundLink,
  SectionBoxes,
} from "../my-patrimony/complete/CompletePatrimony";
import {
  EmbeddedInput,
  EmbeddedSelect,
  WeUseDataFromText,
} from "./utils/borrowing-capacity.utils";
import {
  currency,
  percentage,
  round,
  roundPercentage,
  spacify,
} from "../../utils/format.utils";
import { getMyPatrimony } from "../../services/patrimony.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { formatMyBudget, getMyBudget } from "../../services/budget.service";
import Loader from "../../components/Loader";
import {
  Box,
  InnerBoxContentCenter,
  Container as RiskReturnProfileContainer,
  Details,
  DetailsTab,
  SummaryContainer,
} from "./RiskReturnProfile";
import Input, { InputProps } from "../../components/Input";
import { Header, HeaderText, HeaderWrapper } from "./AmIRich";
import NavPills from "../../components/NavPills";
import { getMe, update } from "../../services/user.service";
import { useState } from "react";
import {
  BorrowingDurations,
  DefaultBorrowingRates,
  getFigures,
  HealthIcons,
  turnEmptyStringsToZeros,
  UpdateUserDto,
} from "./utils/borrowing-capacity.utils";
import { User } from "../../types/user";
import { ObjectEntries } from "../../utils/utils";
import Select from "../../components/Select";
import { onSuccessUpdateUser } from "../../utils/auth.utils";
import { useScroll } from "../../hooks/useScroll.hook";

const Container = styled(RiskReturnProfileContainer)`
  ${EmbeddedSelect}
  .summary-input-container {
    .input-body {
      input {
        margin: 3px 0;
        height: 2em;
      }
    }
  }
  .large-input-container {
    .input-body {
      width: 120px !important;
      padding-left: 0.5em;
    }
  }
  @media (max-width: 900px) {
    th {
      text-transform: unset !important;
    }
  }
  @media (max-width: 450px) {
    th {
      font-size: 0.875em;
    }
    th,
    td {
      padding-left: 8px !important;
      padding-right: 8px !important;
      &:first-child {
        padding-right: 0px !important;
        word-break: normal;
        overflow-wrap: anywhere;
        hyphens: auto;
      }
    }
  }
`;

const SummaryTableContainer = styled.div`
  margin: 24px 0;
  @media (max-width: 1000px) {
    margin: 12px 0;
  }
`;

const TdSummary = styled.td`
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`;

export const TBody = styled.tbody`
  tr td:last-child {
    text-align: end;
  }
`;

const StyledInput = styled(Input)`
  ${EmbeddedInput}
`;

const alignEndStyle = { textAlign: "end" } as const;

type BorrowingInputProps = {
  user: User;
  userProp: keyof UpdateUserDto;
  changedUser: UpdateUserDto;
  setChangedUser: React.Dispatch<React.SetStateAction<UpdateUserDto>>;
  validate: (userToChange?: UpdateUserDto) => void;
} & InputProps;
export const BorrowingInput = ({
  user,
  userProp,
  changedUser,
  setChangedUser,
  validate,
  ...props
}: BorrowingInputProps) => {
  const value = changedUser[userProp] ?? user[userProp] ?? "";
  const isPercentage = props.unit === "%";
  const [formated, setFormated] = useState(!!value ? spacify(value) : "");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      e.target.value &&
      (isPercentage
        ? roundPercentage(parseFloat(e.target.value) / 100)
        : parseFloat(e.target.value.replace(/[^0-9.]+/g, "")));

    setFormated(spacify(Number(e.target.value.replace(/[^0-9.]+/g, ""))))

    const userToChange: UpdateUserDto = { [userProp]: newValue };
    if (userProp === "purchasePrice" && newValue !== "") {
      userToChange.purchaseFees = round(0.075 * newValue, 0);
    }
    setChangedUser((prev) => ({ ...prev, ...userToChange }));
  }
  return (
    <StyledInput
      type="text"
      inputMode="numeric"
      min={0}
      onChange={onChange}
      value={value && (isPercentage ? round(value * 100) : formated)}
      onBlur={() => validate()}
      onKeyUp={({ key }) => {
        if (key === "Enter") validate();
      }}
      blurOnEnter
      {...props}
    />
  );
};

const BorrowingSectionsOptions = [
  { id: "NOUVEL_EMPRUNT", label: "Nouvel emprunt", miniLabel: "Emprunt" },
  { id: "VOTRE_ACHAT", label: "Votre achat", miniLabel: "Achat" },
  { id: "BUDGET", label: "Budget" },
  { id: "PATRIMOINE", label: "Patrimoine" },
] as const;

type BorrowingSection = typeof BorrowingSectionsOptions[number]["id"];

const BorrowingCapacity = () => {
  const { data: myPatrimony, isLoading: patrimonyLoading } = useQuery(
    ["myPatrimony"],
    getMyPatrimony
  );

  const { data, isLoading: budgetLoading } = useQuery(["budget"], getMyBudget);
  const budget = data && formatMyBudget(data);

  const { data: user, isLoading: userLoading } = useQuery(["user"], getMe);
  const [changedUser, setChangedUser] = useState<UpdateUserDto>({});
  const { mutateAsync: tryUpdate } = useMutation({
    mutationFn: update,
    onSuccess: onSuccessUpdateUser,
    onError: (e) => console.error(e),
    onSettled: (res, error, reqData) =>
      ObjectEntries(reqData).forEach(
        ([k, v]) =>
          k in changedUser &&
          v !== undefined &&
          delete changedUser[k as keyof UpdateUserDto]
      ),
  });

  const validate = (userToChange?: UpdateUserDto) => {
    if (userToChange) {
      tryUpdate(turnEmptyStringsToZeros(userToChange));
      return;
    }
    if (!ObjectEntries(changedUser).some(([k, v]) => user?.[k] !== v)) return;
    tryUpdate(turnEmptyStringsToZeros(changedUser));
  };

  const inputProps = user && { user, changedUser, setChangedUser, validate };

  const isLoading = patrimonyLoading || budgetLoading || userLoading;

  const figures =
    user && budget && myPatrimony && getFigures(user, budget, myPatrimony);

  const isReady = !!(
    !isLoading &&
    budget &&
    myPatrimony &&
    user &&
    figures &&
    inputProps
  );

  const { scrollTo, scrolledOption, containerRef, refCallback, refs } =
    useScroll<BorrowingSection>("NOUVEL_EMPRUNT", isReady);

  return (
    <AccountBody>
      <HeaderWrapper>
        <Header>
          <Link to="/outils-analyse">
            <BackButton />
          </Link>
          <div className="title">Quelle est ma capacité d'emprunt&nbsp;?</div>
          <CompletePatrimonyRoundLink className="desktop-only" />
        </Header>
        <HeaderText>
          <WeUseDataFromText budget />
        </HeaderText>
      </HeaderWrapper>
      <Container>
        {isLoading ? (
          <Loader />
        ) : !isReady ? (
          <div>Erreur</div>
        ) : (
          <>
            <SummaryContainer>
              <SectionBoxes>
                <Box className="summary-input-container large-input-container">
                  Simuler un emprunt de&nbsp;
                  <BorrowingInput
                    step={100}
                    {...{ userProp: "borrowingAmount", ...inputProps }}
                    unit="€"
                  />
                  <br />
                  pour un achat de&nbsp;
                  <BorrowingInput
                    step={100}
                    {...{ userProp: "purchasePrice", ...inputProps }}
                    unit="€"
                  />
                </Box>
                <Box>
                  <InnerBoxContentCenter>
                    <div>
                        Votre risque de crédit est actuellement{" "}
                        <strong>{figures.risqueActuel}</strong>.
                        <br />
                        Après un nouvel emprunt, il sera{" "}
                        <strong>{figures.risqueFutur}</strong>
                    </div>
                  </InnerBoxContentCenter>
                </Box>
              </SectionBoxes>
              <SummaryTableContainer>
                <DetailsTab>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Actuellement</th>
                      <th>Après emprunt</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ratio d'endettement</td>
                      <TdSummary>
                        <div>
                          {HealthIcons[figures.healthRatioEndettementActuel]}
                          {percentage(figures.ratioEndettementActuel, 0)}
                        </div>
                      </TdSummary>
                      <TdSummary>
                        <div>
                          {HealthIcons[figures.healthRatioEndettementFutur]}
                          {percentage(figures.ratioEndettementFutur, 0)}
                        </div>
                      </TdSummary>
                    </tr>
                    <tr>
                      <td>Reste à vivre (mensuel)</td>
                      <TdSummary>
                        <div>
                          {HealthIcons[figures.healthResteAVivreActuel]}
                          {currency(figures.resteAVivreActuel)}
                        </div>
                      </TdSummary>
                      <TdSummary>
                        <div>
                          {HealthIcons[figures.healthResteAVivreFutur]}
                          {currency(figures.resteAVivreFutur)}
                        </div>
                      </TdSummary>
                    </tr>
                    <tr>
                      <td>Ratio dette / patrimoine brut</td>
                      <TdSummary>
                        <div>
                          {HealthIcons[figures.healthRatioDetteBrutActuel]}
                          {percentage(figures.ratioDetteBrutActuel, 0)}
                        </div>
                      </TdSummary>
                      <TdSummary>
                        <div>
                          {HealthIcons[figures.healthRatioDetteBrutFutur]}
                          {percentage(figures.ratioDetteBrutFutur, 0)}
                        </div>
                      </TdSummary>
                    </tr>
                  </tbody>
                </DetailsTab>
              </SummaryTableContainer>
            </SummaryContainer>
            <Details>
              <div className="text">Détail de nos calculs</div>
              <div className="nav-pills-container mobile-only">
                <NavPills
                  options={BorrowingSectionsOptions}
                  onOptionSelected={scrollTo}
                  widthMini="450px"
                  selectedOptionId={scrolledOption}
                />
              </div>
              <div className="table-container" ref={containerRef}>
                <DetailsTab>
                  <thead ref={refCallback("NOUVEL_EMPRUNT")}>
                    <tr>
                      <th>Votre nouvel emprunt</th>
                      <th></th>
                    </tr>
                  </thead>
                  <TBody>
                    <tr>
                      <td>Montant de l'emprunt</td>
                      <td className="borrowing-input large-input-container">
                        <div>
                          <BorrowingInput
                            step={100}
                            {...{ userProp: "borrowingAmount", ...inputProps }}
                            unit="€"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Durée</td>
                      <td className="borrowing-input">
                        <div style={{ width: "100px", marginLeft: "auto" }}>
                          <Select
                            optionsEnum={BorrowingDurations}
                            value={`${
                              (changedUser.borrowingDuration ||
                                user.borrowingDuration) / 12
                            }`}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              const years = +e.target
                                .value as keyof typeof BorrowingDurations;
                              const borrowingDuration = years * 12;
                              const userToChange = {
                                borrowingDuration,
                                ...DefaultBorrowingRates[years],
                              };
                              setChangedUser((prev) => ({
                                ...prev,
                                ...userToChange,
                              }));
                              validate(userToChange);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Taux emprunt</td>
                      <td className="borrowing-input">
                        <div>
                          <BorrowingInput
                            step={0.01}
                            {...{ userProp: "borrowingRate", ...inputProps }}
                            unit="%"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Taux d'assurance</td>
                      <td className="borrowing-input">
                        <div>
                          <BorrowingInput
                            step={0.01}
                            {...{
                              userProp: "borrowingInsuranceRate",
                              ...inputProps,
                            }}
                            unit="%"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Mensualité assurance comprise</td>
                      <td>{currency(figures.mensualite)}</td>
                    </tr>
                    <tr>
                      <td>Coût du crédit</td>
                      <td>{currency(figures.coutCredit)}</td>
                    </tr>
                  </TBody>
                </DetailsTab>

                <DetailsTab>
                  <thead ref={refCallback("VOTRE_ACHAT")}>
                    <tr>
                      <th>Votre achat</th>
                      <th></th>
                    </tr>
                  </thead>
                  <TBody>
                    <tr>
                      <td>Prix d'achat</td>
                      <td className="borrowing-input large-input-container">
                        <div>
                          <BorrowingInput
                            step={100}
                            {...{ userProp: "purchasePrice", ...inputProps }} // onchange purchaseFees
                            unit="€"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Frais d'acquisition (notaires, agence, autres)</td>
                      <td className="borrowing-input large-input-container">
                        <div>
                          <BorrowingInput
                            step={100}
                            {...{ userProp: "purchaseFees", ...inputProps }}
                            unit="€"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Montant à financer</td>
                      <td>{currency(figures.montantAFinancer)}</td>
                    </tr>
                    <tr>
                      <td>Apport personnel</td>
                      <td>{currency(figures.apportPersonnel)}</td>
                    </tr>
                    <tr>
                      <td>% d'apport personnel</td>
                      <td>{percentage(figures.pourcentageApportPersonnel)}</td>
                    </tr>
                  </TBody>
                </DetailsTab>

                <DetailsTab>
                  <thead ref={refCallback("BUDGET")}>
                    <tr>
                      <th>Impact sur votre budget</th>
                      <th style={alignEndStyle}>Actuellement</th>
                      <th style={alignEndStyle}>Après emprunt</th>
                    </tr>
                  </thead>
                  <tbody className="before-separator">
                    <tr>
                      <td>Revenus perçus après impôts à la source</td>
                      <td style={alignEndStyle}>{currency(figures.revenusApresImpots)}</td>
                      <td style={alignEndStyle}>{currency(figures.revenusApresImpots)}</td>
                    </tr>
                    <tr>
                      <td>Taux d'impôt sur le revenu</td>
                      <td style={alignEndStyle} className="borrowing-input">
                        <div>
                          <BorrowingInput
                            step={0.01}
                            max={100}
                            {...{ userProp: "incomeTaxRate", ...inputProps }}
                            unit="%"
                          />
                        </div>
                      </td>
                      <td style={alignEndStyle}>{percentage(user.incomeTaxRate)} </td>
                    </tr>
                    <tr>
                      <td>Revenus net avant impôt mensuel</td>
                      <td style={alignEndStyle}>{currency(figures.revenusNetsAvantImpot)}</td>
                      <td style={alignEndStyle}>{currency(figures.revenusNetsAvantImpot)}</td>
                    </tr>
                    <tr>
                      <td>Revenus fonciers</td>
                      <td style={alignEndStyle}>{currency(budget.items.REAL_ESTATE.amount)}</td>
                      <td style={alignEndStyle} className="borrowing-input">
                        <div>
                          <BorrowingInput
                            step={10}
                            {...{
                              userProp: "landIncomesAfterBorrowing",
                              ...inputProps,
                            }}
                            unit="€"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Taux de prise en compte des revenus fonciers</td>
                      <td style={alignEndStyle} className="borrowing-input">
                        <div>
                          <BorrowingInput
                            step={1}
                            max={100}
                            {...{
                              userProp: "landIncomesConsiderationRate",
                              ...inputProps,
                            }}
                            unit="%"
                          />
                        </div>
                      </td>
                      <td style={alignEndStyle} className="borrowing-input">
                        <div>
                          <BorrowingInput
                            step={1}
                            max={100}
                            {...{
                              userProp: "landIncomesConsiderationRateAfter",
                              ...inputProps,
                            }}
                            unit="%"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "var(--gray-200)" }}>
                      <td>Total revenus</td>
                      <td style={alignEndStyle}>{currency(figures.totalRevenusActuel)}</td>
                      <td style={alignEndStyle}>{currency(figures.totalRevenusFutur)}</td>
                    </tr>
                    <tr>
                      <td>Montant de votre loyer</td>
                      <td style={alignEndStyle}>{currency(budget.items.RENTS.amount)}</td>
                      <td style={alignEndStyle} className="borrowing-input">
                        <div>
                          <BorrowingInput
                            step={10}
                            {...{
                              userProp: "rentAfterBorrowing",
                              ...inputProps,
                            }}
                            unit="€"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Remboursement des crédits existants</td>
                      <td style={alignEndStyle}>{currency(budget.items.CREDITS_PAYMENTS.amount)}</td>
                      <td style={alignEndStyle}>{currency(budget.items.CREDITS_PAYMENTS.amount)}</td>
                    </tr>
                    <tr>
                      <td>Mensualité nouvel emprunt</td>
                      <td style={alignEndStyle}>-</td>
                      <td style={alignEndStyle}>{currency(figures.mensualite)}</td>
                    </tr>
                    <tr style={{ backgroundColor: "var(--gray-200)" }}>
                      <td>Total charges</td>
                      <td style={alignEndStyle}>{currency(figures.totalChargesActuel)}</td>
                      <td style={alignEndStyle}>{currency(figures.totalChargesFutur)}</td>
                    </tr>
                    <tr>
                      <td>Ratio d'endettement</td>
                      <td style={alignEndStyle} className="bold-700">
                        {percentage(figures.ratioEndettementActuel, 0)}
                      </td>
                      <td style={alignEndStyle} className="bold-700">
                        {percentage(figures.ratioEndettementFutur, 0)}
                      </td>
                    </tr>
                    <tr>
                      <td>Reste à vivre</td>
                      <td style={alignEndStyle} className="bold-700">
                        {currency(figures.resteAVivreActuel)}
                      </td>
                      <td style={alignEndStyle} className="bold-700">
                        {currency(figures.resteAVivreFutur)}
                      </td>
                    </tr>
                    <tr>
                      <td>Nombre adultes</td>
                      <td style={alignEndStyle}>{figures.numberOfAdults}</td>
                      <td style={alignEndStyle}>{figures.numberOfAdults}</td>
                    </tr>
                    <tr>
                      <td>Nombre enfants à charge</td>
                      <td style={alignEndStyle}>{user.numberOfChildren || "-"}</td>
                      <td style={alignEndStyle}>{user.numberOfChildren || "-"}</td>
                    </tr>
                  </tbody>

                  <thead className="separator" />

                  <thead ref={refCallback("PATRIMOINE")}>
                    <tr>
                      <th>Impact sur votre patrimoine</th>
                      <th style={alignEndStyle}>Actuellement</th>
                      <th style={alignEndStyle}>Après emprunt</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total crédits</td>
                      <td style={alignEndStyle}>{currency(figures.totalCreditsActuel)}</td>
                      <td style={alignEndStyle}>{currency(figures.totalCreditsFutur)}</td>
                    </tr>
                    <tr>
                      <td>Patrimoine brut</td>
                      <td style={alignEndStyle}>{currency(figures.patrimoineBrutActuel)}</td>
                      <td style={alignEndStyle}>{currency(figures.patrimoineBrutFutur)}</td>
                    </tr>
                    <tr>
                      <td>Ratio dette / Patrimoine brut</td>
                      <td style={alignEndStyle} className="bold-700">
                        {percentage(figures.ratioDetteBrutActuel, 0)}
                      </td>
                      <td style={alignEndStyle} className="bold-700">
                        {percentage(figures.ratioDetteBrutFutur, 0)}
                      </td>
                    </tr>
                  </tbody>
                </DetailsTab>
                <div
                  className="mobile-only"
                  style={{
                    height: `calc(100% - 1px - ${
                      (refs.current.PATRIMOINE?.clientHeight || 0) * 4
                    }px)`,
                  }}
                />
              </div>
            </Details>
          </>
        )}
      </Container>
    </AccountBody>
  );
};

export default BorrowingCapacity;
